<template>
  <section>
    <v-card rounded="lg" flat color="transparent">
      <v-card-title> {{ $t("permission") }} </v-card-title>
      <v-card-text>
        <v-row>
          <v-col
            xs="12"
            sm="12"
            md="3"
            lg="3"
            xl="3"
            v-for="(item, index) in roles"
            :key="index"
          >
            <v-list shaped class="rounded-lg v-card">
              <v-list-group
                v-if="item.menu.length"
                :key="item.name_la"
                sub-group
                no-action
                :value="item.value"
              >
                <template v-slot:prependIcon>
                  <v-icon small>{{ item.icon }}</v-icon>
                </template>
                <template v-slot:appendIcon>
                  <v-icon small>fal fa-chevron-up</v-icon>
                </template>
                <template v-slot:activator>
                  <v-list-item-title>
                    {{ $i18n.locale === "en" ? item.name_en : item.name_la }}
                  </v-list-item-title>
                </template>
                <v-list-item v-for="(data, key) in item.menu" :key="key" link>
                  <v-list-item-icon>
                    <v-icon small>{{ data.icon }}</v-icon>
                  </v-list-item-icon>
                  <v-list-item-title>
                    {{
                      $i18n.locale === "en" ? data.name_en : data.name_la
                    }}</v-list-item-title
                  >
                </v-list-item>
              </v-list-group>

              <v-list-item v-else :key="index + 1">
                <v-list-item-icon>
                  <v-icon small>{{ item.icon ?? "fal fa-user-cog" }}</v-icon>
                </v-list-item-icon>
                <v-list-item-title>
                  {{
                    $i18n.locale === "en" ? item.name_en : item.name_la
                  }}</v-list-item-title
                >
              </v-list-item>
            </v-list>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <app-custom-dialog v-model="dialog" :footer="false" title="description">
      <div slot="activator">
        <v-row>
          <v-col cols="4">ລະຫັດ</v-col>
          <v-col cols="8">{{ form.id }}</v-col>
          <v-col cols="4">ຊື່</v-col>
          <v-col cols="8">{{ form.name }}</v-col>
          <v-col cols="4">ລີ້ງ</v-col>
          <v-col cols="8">{{ form.to }}</v-col>
          <v-col cols="4">{{ $t("date") }}</v-col>
          <v-col cols="8">{{ form.created_at | moment }}</v-col>
        </v-row>
      </div>
    </app-custom-dialog>
  </section>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import TTextField from "@/components/global/TextField";
import { customerValidate } from "@/validations/shop";
import { success } from "@/controllers/alert";
import { myMixin } from "@/mixin/main";
export default {
  mixins: [customerValidate, myMixin],

  data: () => ({
    update: false,
    dialogAdd: false,
    dialog: false,
    headers: [
      { text: "ລຳດັບ", value: "index" },
      { text: "ໄອຄອນ", value: "icon" },
      { text: "ຊື່ພາສາລາວ", value: "name_la" },
      { text: "ຊື່ພາສາອັ່ງກິດ", value: "name_en" },
      { text: "ລີ້ງ", value: "to" },
      { text: "ຈັດການ", value: "actions", align: "right" },
    ],
    selected: [],
    formShow: {},
    form: {},
  }),
  async created() {
    await this.fetchItem();
  },
  components: { TTextField },
  computed: {
    ...mapGetters({
      roles: "index/roles",
    }),

    titleStatus() {
      return this.update ? "update" : "new_data";
    },
  },

  methods: {
    ...mapActions({
      fetch: "index/fetch",
    }),
    async fetchItem() {
      const body = {
        that: this,
        url: "menu_list",
        commit: "SET_ROLES",
      };
      await this.fetch(body);
    },

    async newItem() {
      await Help.clearForm(this.form, this);
      await this.fetchItem();
      this.update = false;
      this.dialogAdd = !this.dialogAdd;
    },
    async editItem({ id }) {
      try {
        const { data } = await this.$http.get(`roles_permission/${id}`);
        this.formShow = data.data;
        this.selected = Help.pluck(data.permissions, "name");
        this.dialogAdd = !this.dialogAdd;
      } catch (error) {
        console.log(error);
      }
    },

    async viewItem(item) {
      this.form = item;
      this.dialog = !this.dialog;
    },
    async submitForm() {
      var result;
      try {
        this.$v.form.$touch();
        if (!this.$v.form.$invalid) {
          if (this.update) {
            result = await this.$http.post(
              `permission_update/${this.form.id}`,
              this.form
            );
          } else {
            result = await this.$http.post("permission_add", this.form);
          }
          if (result.data.status) {
            this.dialogAdd = !this.dialogAdd;
            await Help.clearForm(this.form, this);
            await this.fetchItem();
            await success(this, result.data.message);
          }
        }
      } catch (error) {
        console.log("error", error);
      }
    },
  },
};
</script>

<style></style>
