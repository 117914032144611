<template>
  <section>
    <v-row class="mb-3">
      <template v-if="summaries.length">
        <v-col v-for="(item, index) in summaries" :key="index">
          <v-card rounded="lg">
            <v-card-text>
              <v-list-item>
                <v-list-item-avatar :color="item.color" rounded="lg">
                  <v-icon color="white">
                    {{ item.icon }}
                  </v-icon>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-subtitle>
                    {{ $t(item.title) }}
                  </v-list-item-subtitle>
                  <v-list-item-title class="text-h6 font-weight-bold">
                    {{ item.total | NumberFormat }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-card-text>
          </v-card>
        </v-col>
      </template>
      <template v-else>
        <v-col v-for="(item, index) in 5" :key="index">
          <v-card rounded class="rounded-lg">
            <v-card-text class="pa-2">
              <v-skeleton-loader type="list-item-avatar-three-line" />
            </v-card-text>
          </v-card>
        </v-col>
      </template>
    </v-row>
    <data-table
      :headers="headers"
      :items="billReports"
      commit="SET_BILL_REPORT"
      :viewItem="viewItem"
    >
      <div slot="activator" class="row">
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-1">
          <v-card-title class="px-0 pt-0">{{ $t("ticket") }} </v-card-title>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-2">
          <TextSelect
            :dense="true"
            :placeholder="false"
            :items="statusList"
            label="status"
            itemValue="value"
            v-model="status"
          />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-2">
          <DatePicker
            :dense="true"
            :placeholder="false"
            label="start_date"
            v-model="start_date"
          />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-2">
          <DatePicker
            :dense="true"
            :placeholder="false"
            label="end_date"
            v-model="end_date"
          />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-2">
          <v-btn
            depressed
            outlined
            class="text-capitalize mr-2"
            color="primary"
            @click="filterDate(start_date, end_date)"
          >
            <v-icon left small>fal fa-search</v-icon>
            {{ $t("filter") }}
          </v-btn>
          <v-btn
            depressed
            class="text-capitalize"
            color="primary"
            @click="exportToExcel(start_date, end_date)"
          >
            <v-icon left small>fal fa-receipt</v-icon>
            Excel
          </v-btn>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-3">
          <TextSearch
            v-model="search"
            @search="fetchItem()"
            @onClear="
              search = '';
              fetchItem();
            "
          />
        </div>
      </div>
    </data-table>
    <app-custom-dialog
      width="1000px"
      v-model="dialog"
      :footer="false"
      title="details"
    >
      <div slot="activator">
        <AppDetail :item="form" />
      </div>
    </app-custom-dialog>
    <!-- bill print -->
    <app-bill v-show="print" :item="form" />

    <!-- AppLoading -->
    <app-loading :overlay="overlay" />
  </section>
</template>

<script>
import DataTable from "@/components/DataTable";
import { mapGetters, mapActions } from "vuex";
import { billReports } from "@/utils/head";
import AppDetail from "@/components/products/billReport";
import { myMixin } from "@/mixin/main";
import AppBill from "@/components/print/bill";
import { returnProduct } from "@/validations/shop";
import TextArea from "@/components/global/TextArea";
import TextSelect from "@/components/global/TextSelect";
import { ticketStatus } from "@/utils/mock";
import { error } from "@/controllers/alert";

// get today date
const today = new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
  .toISOString()
  .substr(0, 10);

// get tomorrow date
const tomorrow = new Date(
  Date.now() - new Date().getTimezoneOffset() * 60000 + 86400000
)
  .toISOString()
  .substr(0, 10);

export default {
  mixins: [returnProduct, myMixin],
  data: () => ({
    search: "",
    overlay: false,
    dialog: false,
    print: false,
    form: {},
    start_date: today,
    summaries: [],
    end_date: tomorrow,
    status: {
      text: "all",
      value: "all",
    },
  }),
  async created() {
    await this.fetchSummary();
    await this.fetchItem(this.start_date, this.end_date);
  },
  watch: {
    status(val) {
      this.fetchItem(this.start_date, this.end_date);
    },
  },
  components: {
    DataTable,
    TextSelect,

    AppDetail,
    TextArea,
    AppBill,
  },
  computed: {
    ...mapGetters({
      billReports: "index/billReports",
    }),
    headers() {
      return billReports(this);
    },
    statusList() {
      return ticketStatus(this);
    },
  },

  methods: {
    ...mapActions({
      fetch: "index/fetch",
    }),
    async fetchSummary() {
      try {
        const param = `start_date=${this.start_date}&end_date=${this.end_date}&store_id=${this.$route.params.store_id}`;
        const { data } = await this.$http.get(`/get_store_bill?${param}`);
        if (data.status) {
          this.summaries = data.data;
        }
      } catch (error) {
        console.log(error);
      }
    },
    async filterDate(start_date, end_date) {
      try {
        await Help.getDifferentMonth(this, start_date, end_date);
        this.overlay = true;
        // setTimeOut for 1 second to show overlay
        setTimeout(() => {
          this.fetchItem(start_date, end_date);
          this.fetchSummary();
          this.overlay = false;
        }, 1000);
      } catch (err) {
        console.log("err", err.message);
        error(this, err.message);
      }
    },
    async fetchItem(start_date, end_date) {
      const { store_id } = this.$route.params;
      const body = {
        that: this,
        url: `get_shop_ticket/${store_id}?start_date=${start_date}&end_date=${end_date}&q=${this.search}&status=${this.status.value}`,
        commit: "SET_BILL_REPORT",
      };
      await this.fetch(body);
    },

    async viewItem({ id }) {
      this.overlay = true;
      const { store_id } = this.$route.params;
      const query = `${id}?store_id=${store_id}`;
      const { data } = await this.$http.get(`shop_ticket_detail/${query}`);
      this.form = data.data;
      setTimeout(() => {
        this.overlay = false;
        this.dialog = !this.dialog;
      }, 1000);
    },
  },
};
</script>

<style></style>
