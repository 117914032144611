<template>
  <section>
    <data-table
      :headers="headers"
      :items="productSizes"
      commit="SET_PRODUCT_SIZE"
      :viewItem="viewItem"
      :editItem="editItem"
      :deleteItem="deleteItem"
    >
      <div slot="activator" class="row justify-content-between">
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-2">
          <v-card-title class="px-0 pt-0"> {{ $t("unit") }} </v-card-title>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-3 offset-lg-5">
          <TextSearch
            v-model="search"
            @search="fetchItem()"
            @onClear="
              search = '';
              fetchItem();
            "
          />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-2 text-end">
          <v-btn depressed color="primary" @click="newItem">
            <v-icon left>fal fa-plus-circle</v-icon>
            {{ $t("new_data") }}
          </v-btn>
        </div>
      </div>
    </data-table>

    <app-custom-dialog
      v-model="dialog"
      :footer="false"
      title="description"
      @submit="submitForm"
    >
      <div slot="activator">
        <v-row>
          <v-col cols="4">{{ $t("id") }}</v-col>
          <v-col cols="8">{{ form.id }}</v-col>
          <v-col cols="4">{{ $t("name") }}</v-col>
          <v-col cols="8">{{ form.name }}</v-col>
          <v-col cols="4">{{ $t("name_en") }}</v-col>
          <v-col cols="8">{{ form.name_en }}</v-col>
          <v-col cols="4">{{ $t("date") }}</v-col>
          <v-col cols="8">{{ form.created_at | moment }}</v-col>
        </v-row>
      </div>
    </app-custom-dialog>
    <app-custom-dialog
      v-model="dialogAdd"
      :footer="true"
      :title="titleStatus"
      @submit="submitForm"
    >
      <div slot="activator">
        <TTextField
          label="name"
          v-model="form.name"
          :errorMessages="nameError"
          @input="$v.form.name.$touch()"
          @blur="$v.form.name.$touch()"
        />
        <TTextField label="name_en" v-model="form.name_en" />

        <p class="pa-0">{{ $t("unit") }}</p>
        <v-radio-group v-model="form.unit">
          <v-radio
            v-for="n in sizeType"
            :key="n"
            :label="namType(n)"
            :value="n"
          ></v-radio>
        </v-radio-group>

        <TTextField
          v-if="form.unit === 'pack'"
          label="pack"
          typeShow="number"
          v-model="form.amount"
          :errorMessages="amountError"
          @input="$v.form.amount.$touch()"
          @blur="$v.form.amount.$touch()"
        />
      </div>
    </app-custom-dialog>
    <!-- AppLoading -->
    <app-loading :overlay="overlay" />
  </section>
</template>

<script>
import DataTable from "@/components/DataTable";
import { mapGetters, mapActions } from "vuex";
import { productSizeHead } from "@/utils/head";
import TTextField from "@/components/global/TextField";
import { sizeValidate } from "@/validations/shop";
import { success, confirm } from "@/controllers/alert";
import { myMixin } from "@/mixin/main";

export default {
  mixins: [sizeValidate, myMixin],
  data: () => ({
    update: false,
    dialogAdd: false,
    dialog: false,
    overlay: false,
    search: "",

    sizeType: ["amount", "pack"],
    form: {
      unit: "amount",
      amount: 1,
    },
  }),
  async created() {
    await this.fetchItem();
  },
  components: { DataTable, TTextField },
  watch: {
    form: {
      deep: true,
      handler(val) {
        if (val.unit === "pack") {
          if (val.amount === 0) {
            this.$set(this.form, "amount", null);
          }
        } else {
          this.$set(this.form, "amount", 1);
        }
      },
    },
  },
  computed: {
    ...mapGetters({
      productSizes: "index/productSizes",
    }),

    titleStatus() {
      return this.update ? "update" : "new_data";
    },
    headers() {
      return productSizeHead(this);
    },
  },

  methods: {
    ...mapActions({
      fetch: "index/fetch",
    }),
    namType(n) {
      if (n === "amount") {
        return this.$t("amount");
      } else {
        return this.$t("pack");
      }
    },
    async fetchItem() {
      const body = {
        that: this,
        url: `product_size_paginate?q=${this.search}`,
        commit: "SET_PRODUCT_SIZE",
      };
      await this.fetch(body);
    },

    async newItem() {
      this.form = {
        unit: "amount",
        amount: 1,
      };
      this.update = false;
      this.dialogAdd = !this.dialogAdd;
    },
    editItem(item) {
      this.update = true;
      this.form = item;
      this.dialogAdd = !this.dialogAdd;
    },
    async deleteItem({ id }) {
      const { isConfirmed } = await confirm({
        props: this,
        text: "want_to_delete",
      });
      if (isConfirmed) {
        const { data } = await this.$http.delete(`product_size/${id}`);
        if (data.status) {
          await Help.clearForm(this.form, this);
          // find the id in array.data and remove it
          const index = this.productSizes.data.findIndex(
            (item) => item.id === id
          );
          this.productSizes.data.splice(index, 1);
          await success(this, data.message);
        }
      }
    },
    viewItem(item) {
      this.form = item;
      this.dialog = !this.dialog;
    },
    async submitForm() {
      try {
        this.$v.form.$touch();
        if (!this.$v.form.$invalid) {
          if (this.update) {
            const { data } = await this.$http.put(
              `product_size/${this.form.id}`,
              this.form
            );
            if (data.status) {
              this.dialogAdd = !this.dialogAdd;
              await this.fetchItem();
              await success(this, data.message);
            }
          } else {
            const { data } = await this.$http.post("product_size", this.form);
            if (data.status) {
              this.dialogAdd = !this.dialogAdd;
              await Help.clearForm(this.form, this);
              this.productSizes.data.push(data.data);
              await success(this, data.message);
            }
          }
        }
      } catch (error) {
        console.log("error", error);
      }
    },
  },
};
</script>

<style></style>
