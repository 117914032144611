<template>
  <v-col cols="12">
    <v-card class="rounded-lg">
      <v-card-title> ສັນຍາລະບົບ</v-card-title>
      <v-card-text ref="content">
        <div class="text-center">
          <p class="mb-0 black--text">ສາທາລະນະລັດ ປະຊາທິປະໄຕ ປະຊາຊົນລາວ</p>
          <p class="black--text">
            ສັນຕິພາບ ເອກະລາດ ປະຊາທິປະໄຕ ເອກະພາບ ວດັທະນາຖາວອນ
          </p>
        </div>

        <v-row>
          <v-col cols="6">
            <div>
              <p class="mb-0 black--text">ສາທາລະນະລດັ ປະຊາທິປະໄຕ ປະຊາຊນົລາວ</p>
              <p class="black--text">
                ສັນຕິພາບ ເອກະລາດ ປະຊາທິປະໄຕ ເອກະພາບ ວດັທະນາຖາວອນ
              </p>
            </div>
          </v-col>
          <v-col cols="6">
            <div class="text-right">
              <p class="mb-0 black--text">ເລກທີ່</p>
              <p class="mb-0 black--text">ນະຄອນຫຼວງວຽງຈັນ,​ວັນທີ່</p>
            </div>
          </v-col>
        </v-row>
        <div class="text-center">
          <p class="mb-0 text-h6 font-weight-bold black--text">
            ໃບສັນຍານຳໃຊ້ລະບົບ
          </p>
          <p class="black--text">ລະບົບຂາຍໜ້າຮ້ານ</p>
        </div>
        <v-row>
          <v-col cols="6">
            <v-card-text class="text-center">
              <v-avatar size="120">
                <v-img :src="urlLogo" />
              </v-avatar>

              <h4 class="pt-2 text-center align-center">
                {{ stores }}
              </h4>
              {{ packages }}
            </v-card-text>
          </v-col>
          <v-col cols="6">
            <v-card-text class="text-center">
              <v-avatar size="120">
                <v-img src="/images/icon.png" />
              </v-avatar>

              <h4 class="pt-2 text-center align-center">Devla Media</h4>
              28492947
            </v-card-text>
          </v-col>
        </v-row>

        <p class="pb-3">ຊື່</p>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn large depressed class="px-10"> ດາວໂຫລດ </v-btn>
        <v-spacer />
      </v-card-actions>
    </v-card>
  </v-col>
</template>

<script>
import VAvatarUploader from "@/components/VAvatarUploader";
import Cookies from "js-cookie";
import { success } from "@/controllers/alert";

export default {
  props: ["user"],
  components: {
    VAvatarUploader,
  },
  computed: {
    url() {
      return Help.img(this.user.avatar);
    },
    urlLogo() {
      if (
        this.user != null &&
        this.user != undefined &&
        this.user.stores != null &&
        this.user.stores != undefined &&
        this.user.stores.logo != null &&
        this.user.stores.logo != undefined
      ) {
        return Help.img(this.user.stores.logo);
      } else {
        return Help.img(this.user.avatar);
      }
    },
    stores() {
      if (
        this.user != null &&
        this.user != undefined &&
        this.user.stores != null &&
        this.user.stores != undefined &&
        this.user.stores.name != null &&
        this.user.stores.name != undefined
      ) {
        return this.user.stores.name;
      }
    },
    packages() {
      if (
        this.user != null &&
        this.user != undefined &&
        this.user.package != undefined &&
        this.user.package.name != null &&
        this.user.package.name != undefined
      ) {
        return this.user.package.name;
      }
    },
  },
  methods: {
    async request(form, config) {
      const { data } = await this.$http.post("change_avatar", form, config);
      if (data.status) {
        await success(this, data.message);
        this.$store.commit("auth/SET_USER", data.data);
        Cookies.set("user", data.data);
      }
    },
    rename(file) {
      const ext = file.name.split(".")[1];
      const name = `1231232.${ext}`;

      return name;
    },
    failed(error) {
      console.log("error", error);
    },
  },
};
</script>

<style></style>
