<template>
  <section>
    <data-table
      :headers="headers"
      :items="reportCategories"
      commit="SET_REPORT_CATEGORIES"
      :viewItem="viewItem"
    >
      <div slot="activator" class="row">
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-3">
          <v-card-title class="px-0 pt-0"> {{ $t("category") }} </v-card-title>
        </div>

        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-4">
          <TextSearch
            v-model="search"
            @search="fetchItem()"
            @onClear="
              search = '';
              fetchItem();
            "
          />
        </div>
        <v-spacer />
      </div>
    </data-table>
    <app-custom-dialog v-model="dialog" :footer="false" title="description">
      <div slot="activator">
        <v-row>
          <v-col cols="4">{{ $t("id") }}</v-col>
          <v-col cols="8">{{ form.id }}</v-col>
          <v-col cols="4">{{ $t("lao_name") }}</v-col>
          <v-col cols="8">{{ form.la_name }}</v-col>
          <v-col cols="4">{{ $t("eng_name") }}</v-col>
          <v-col cols="8">{{ form.en_name }}</v-col>
          <v-col cols="4">{{ $t("sum_stock") }}</v-col>
          <v-col cols="8">{{ form.products_sum_stock }}</v-col>
          <v-col cols="4">{{ $t("sale_sum_qty") }}</v-col>
          <v-col cols="8">{{ form.products_sale_sum_qty }}</v-col>
          <v-col cols="4">{{ $t("sale_sum_total_price") }}</v-col>
          <v-col cols="8">{{
            moneyFormat(form.products_sale_sum_total_price)
          }}</v-col>

          <v-col cols="4">{{ $t("created_at") }}</v-col>
          <v-col cols="8">{{ form.created_at | moment }}</v-col>
        </v-row>
      </div>
    </app-custom-dialog>
    <!-- AppLoading -->
    <app-loading :overlay="overlay" />
  </section>
</template>

<script>
import DataTable from "@/components/DataTable";
import { mapGetters, mapActions } from "vuex";
import TTextField from "@/components/global/TextField";
import { customerValidate } from "@/validations/shop";
import { myMixin } from "@/mixin/main";
import { categoryHead } from "@/utils/head";

export default {
  mixins: [customerValidate, myMixin],

  data: () => ({
    dialog: false,
    overlay: false,
    search: "",

    form: {},
  }),
  async created() {
    await this.fetchItem();
  },
  components: { DataTable, TTextField },
  computed: {
    ...mapGetters({
      reportCategories: "index/reportCategories",
    }),
    headers() {
      return categoryHead(this);
    },
  },

  methods: {
    ...mapActions({
      fetch: "index/fetch",
    }),
    async fetchItem() {
      const body = {
        that: this,
        url: `report_categories?q=${this.search}`,
        commit: "SET_REPORT_CATEGORIES",
      };
      await this.fetch(body);
    },

    viewItem(item) {
      this.form = item;
      this.dialog = !this.dialog;
    },
  },
};
</script>

<style></style>
