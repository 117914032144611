<template>
  <v-card>
    <v-card-text>
      <v-img width="180px" src="/images/carts.png" />
    </v-card-text>
    <v-card-title> {{ $t("no_data") }} </v-card-title>
  </v-card>
</template>

<script>
export default {};
</script>

<style></style>
