<template>
  <section>
    <v-row>
      <v-col cols="12">
        <v-card class="rounded-lg">
          <v-card-title>
            <span class="text-capitalize font-weight-bold">
              <v-icon left small> fal fa-university </v-icon>
              {{ $t("bank_account") }}
            </span>
            <v-spacer />
            <v-btn text color="primary" @click="addNew()">
              <v-icon left> fal fa-plus-circle </v-icon>
              {{ $t("add_account") }}
            </v-btn>
          </v-card-title>
          <v-card-text>
            <v-simple-table>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th>{{ $t("no") }}</th>
                    <th>{{ $t("account_name") }}</th>
                    <th>{{ $t("account_no") }}</th>
                    <th>{{ $t("bank_name") }}</th>
                    <th>{{ $t("currency") }}</th>
                    <th class="text-right">{{ $t("manage") }}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(item, index) in items" :key="index">
                    <td>{{ index + 1 }}</td>
                    <td>{{ item.account_name }}</td>
                    <td>{{ item.account_no }}</td>
                    <td>{{ item.bank_name }}</td>
                    <td>{{ item.currency }}</td>
                    <td class="text-right">
                      <v-btn
                        fab
                        depressed
                        x-small
                        color="info"
                        @click="editData(item)"
                      >
                        <v-icon x-small> fal fa-edit </v-icon>
                      </v-btn>
                      <v-btn
                        class="ml-2"
                        fab
                        depressed
                        x-small
                        color="red"
                        @click="deleteData(item)"
                      >
                        <v-icon x-small> fal fa-trash-alt </v-icon>
                      </v-btn>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-card-text>
        </v-card></v-col
      >
    </v-row>
    <app-custom-dialog
      v-model="dialog"
      :footer="true"
      :title="titleStatus"
      @submit="submitForm"
    >
      <div slot="activator">
        <TTextField label="account_name" v-model="form.account_name" />
        <TTextField label="account_no" v-model="form.account_no" />
        <TTextField label="bank_name" v-model="form.bank_name" />
        <div class="pt-2">{{ $t("choose_currency") }}</div>
        <v-chip-group
          v-model="form.currency"
          mandatory
          column
          active-class="deep-purple--text text--accent-4"
        >
          <v-chip
            v-for="(val, i) in currencies"
            :key="i"
            :value="val.name"
            filter
            outlined
          >
            {{ val.name }}
          </v-chip>
        </v-chip-group>
      </div>
    </app-custom-dialog>
  </section>
</template>

<script>
import { myMixin } from "@/mixin/main";
import TTextField from "@/components/global/TextField";
import { mapGetters } from "vuex";
import { success, confirm } from "@/controllers/alert";

export default {
  data() {
    return {
      dialog: false,
      show_histories: false,
      history_payment: [],
      items: [],
      update: false,
      form: {
        account_name: "",
        account_no: "",
        bank_name: "",
        currency: "LAK",
      },
    };
  },
  mounted() {
    this.fetchBankAccount();
  },
  components: {
    TTextField,
  },
  computed: {
    ...mapGetters({
      currencies: "index/currencies",
    }),
    titleStatus() {
      return this.update ? "update" : "new_data";
    },
  },

  mixins: [myMixin],
  methods: {
    setDefault() {
      this.form = {
        account_name: "",
        account_no: "",
        bank_name: "",
        currency: "LAK",
      };
    },
    async addNew() {
      this.dialog = true;
      this.update = false;
      this.setDefault();
    },
    async fetchBankAccount() {
      try {
        const { data } = await this.$http.get("/bank_accounts");
        this.items = data.data;
      } catch (error) {
        console.log(error);
      }
    },
    async editData(item) {
      this.dialog = true;
      this.update = true;
      this.form = item;
    },
    async submitForm() {
      try {
        let data = {};
        if (this.update) {
          data = await this.$http.put(
            `/bank_accounts/${this.form.id}`,
            this.form
          );
        } else {
          data = await this.$http.post("/bank_accounts", this.form);
        }
        if (data.status) {
          this.dialog = false;
          this.fetchBankAccount();
          this.setDefault();
          await success(this, data.message);
        }
      } catch (error) {
        console.log(error);
      }
    },

    async deleteData(item) {
      const { isConfirmed } = await confirm({
        props: this,
        text: "want_to_delete",
      });
      if (isConfirmed) {
        const { data } = await this.$http.delete(`/bank_accounts/${item.id}`);
        if (data.status) {
          this.items.splice(
            this.items.findIndex((i) => i.id === item.id),
            1
          );
          await success(this, data.message);
        }
      }
    },
  },
};
</script>

<style></style>
