import Vue from "vue";
import Vuetify from "vuetify";
import "vuetify/dist/vuetify.min.css";

Vue.use(Vuetify);

const opts = {
  theme: {
    themes: {
      light: {
        primary: "#5b39c9",
        secondary: "#35378d",
        accent: "#8c9eff",
        info: "#00b0ff",
        success: "#4caf50",
        warning: "#ffeb3b",
        error: "#f44336",
        green: "#4caf50",
      },
      dark: {
        primary: "#5b39c9",
      },
    },
  },
  icons: {
    iconfont: "mdiSvg",
  },
};

export default new Vuetify(opts);
