<template>
  <v-container fluid fill-height class="bg-white">
    <v-row justify="center" align="center">
      <v-col
        v-if="$vuetify.breakpoint.mdAndUp"
        cols="4"
        class="side-color bg-primary"
      >
        <v-card max-width="550" outlined color="transparent" flat>
          <v-img src="/images/login.png" />
        </v-card>
      </v-col>
      <v-col xs="12" sm="12" md="8" lg="8" xl="8">
        <v-btn color="primary" @click="$router.back()" fab outlined icon small>
          <v-icon small>fal fa-chevron-left</v-icon>
        </v-btn>
        <v-row justify="center">
          <v-col xs="12" sm="12" md="6" lg="4" xl="4">
            <v-form @submit.prevent="submitForm(otp)">
              <div
                :class="$vuetify.breakpoint.mdAndUp ? 'mb-8' : ''"
                class="text-center"
              >
                <v-avatar size="120">
                  <v-img src="/images/pos.png"></v-img>
                </v-avatar>
              </div>
              <v-card>
                <v-card-text>
                  <div class="text-center">
                    <h2 class="font-weight-black">ຍຶນຍັນລະຫັດ OTP</h2>
                    <p class="py-3">ປ້ອນລະຫັດ OTP ຂອງທ່ານເພື່ອຍືນຍັນ</p>
                  </div>
                  <div class="d-flex my-5 justify-center">
                    <v-otp-input v-model="otp" @finish="handleOnComplete" />
                  </div>
                </v-card-text>
                <v-card-actions class="mx-1">
                  <v-btn type="submit" color="primary" large block class="px-12"
                    >ຍືນຍັນ</v-btn
                  >
                </v-card-actions>
              </v-card>
            </v-form>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <!-- AppLoading -->
    <app-loading :overlay="overlay" />
  </v-container>
</template>

<script>
import { toast } from "@/controllers/alert";
import TTextField from "@/components/global/TextField";

import { setNewUser } from "@/controllers/auth";

export default {
  layout: "blank",
  components: { TTextField },
  data: () => ({
    overlay: false,
    otp: "",
  }),

  methods: {
    handleOnComplete(value) {
      this.submitForm(value);
    },

    async submitForm(otp) {
      try {
        const form = {
          email: this.$route.query.email,
          otp: otp,
        };
        this.overlay = true;
        const { data } = await this.$http.post("verify", form);
        if (data.status) {
          await toast(this, data.message);
          this.overlay = false;
          await setNewUser(this, data.data);
          this.$router.push("/open-store");
        }
      } catch (error) {
        this.overlay = false;
        console.log("submitForm ===> ", error);
      }
    },
  },
};
</script>

<style lang="css">
.otp-input {
  width: 40px;
  height: 40px;
  padding: 5px;
  margin: 0 10px;
  font-size: 20px;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.3);
  text-align: center;
}
.otp-input.error {
  border: 1px solid red !important;
}
.otp-input::-webkit-inner-spin-button,
.otp-input::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
</style>
