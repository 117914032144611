<template>
  <v-app>
    <v-app-bar flat height="65" fixed app>
      <v-list-item class="px-2">
        <v-badge bordered bottom color="green" dot offset-x="25" offset-y="20">
          <v-list-item-avatar>
            <v-avatar color="#f0f0f0">
              <v-img :src="urlLogo" />
            </v-avatar>
          </v-list-item-avatar>
        </v-badge>

        <v-list-item-content>
          <v-list-item-title> {{ user.stores.name }}</v-list-item-title>
          <v-list-item-subtitle>
            {{
              user.branch_id ? user.branch_id.name : user.email
            }}</v-list-item-subtitle
          >
        </v-list-item-content>
      </v-list-item>

      <v-spacer></v-spacer>

      <v-badge bordered overlap offset-x="20" offset-y="20">
        <template v-slot:badge> {{ defineStore.cart.length }} </template>

        <v-btn icon>
          <v-icon>fal fa-shopping-cart</v-icon>
        </v-btn>
      </v-badge>
    </v-app-bar>
    <v-main
      :style="{ 'max-height': mainContentMaxHeight, 'overflow-y': 'auto' }"
    >
      <!-- Your content here -->
      <v-container>
        <div class="row">
          <div class="col-12">
            <v-card flat rounded="lg" outlined v-if="defineStore.cart.length">
              <v-list dense>
                <template v-for="(item, index) in defineStore.cart">
                  <v-list-item two-line :key="index + 'halo'">
                    <v-list-item-avatar tile class="rounded-lg">
                      <app-img :img="item.item.img" />
                    </v-list-item-avatar>

                    <v-list-item-content>
                      <v-list-item-title>{{
                        item.item.name
                      }}</v-list-item-title>
                      <v-list-item-subtitle>
                        {{ item.item.price_sale | NumberFormat }}
                        x
                        {{ item.qty }}</v-list-item-subtitle
                      >
                    </v-list-item-content>
                    <v-list-item-action>
                      <div>{{ item.price | NumberFormat }}</div>
                    </v-list-item-action>
                  </v-list-item>
                  <v-divider
                    inset
                    class="my-0"
                    v-if="index < defineStore.cart.length - 1"
                    :key="index"
                  ></v-divider>
                </template>
              </v-list>
            </v-card>
            <v-card flat rounded="lg" outlined v-else>
              <v-card-text class="text-center">
                <v-icon size="100">fal fa-shopping-cart</v-icon>
                <br />
                <br />
                <h3>{{ $t("cart_empty") }}</h3>
              </v-card-text>
            </v-card>
          </div>
        </div>
      </v-container>
    </v-main>

    <v-footer app color="transparent" v-if="defineStore.cart.length">
      <v-container>
        <v-row>
          <div class="col-12">
            <v-card flat rounded="lg" outlined>
              <v-card-title>
                <v-row>
                  <v-col cols="6"> {{ $t("sub_total") }} </v-col>
                  <v-col cols="6" class="text-right">
                    {{ defineStore.sub_total | NumberFormat }}</v-col
                  >

                  <v-col cols="6"> {{ $t("thb") }} </v-col>
                  <v-col cols="6" class="text-right"> {{ thRate }}</v-col>

                  <v-col cols="6"> {{ $t("usd") }} </v-col>
                  <v-col cols="6" class="text-right"> {{ usRate }}</v-col>

                  <v-col cols="6"> {{ $t("total_price") }} </v-col>
                  <v-col cols="6" class="text-right">
                    {{ defineStore.total_price | NumberFormat }}</v-col
                  >
                </v-row>
              </v-card-title>
            </v-card>
          </div>
        </v-row>
      </v-container>
    </v-footer>
  </v-app>
</template>

<script>
import { mainStore } from "@/stores/modules/cart";
import { mapGetters } from "vuex";
import { myMixin } from "@/mixin/main";

export default {
  mixins: [myMixin],
  data() {
    return {
      defineStore: mainStore(),
      mainContentMaxHeight: "90vh", // Default value
    };
  },
  mounted() {
    this.calculateMaxHeight();
    window.addEventListener("resize", this.calculateMaxHeight);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.calculateMaxHeight);
  },
  computed: {
    ...mapGetters({
      user: "auth/user",
    }),
    urlLogo() {
      if (this.user && this.user.stores && this.user.stores.logo) {
        const baseURL = process.env.VUE_APP_BASE_URL;
        return `${baseURL}/uploads/${this.user.stores.logo}`;
      } else {
        return "/images/pos.png";
      }
    },

    thRate() {
      if (this.user && this.user.store_id && this.user.store_id.thb) {
        const thb = this.user.store_id.thb;
        const total = this.user.sub_total;
        return this.$options.filters.NumberFormat(total / thb);
      }
      return 0;
    },
    usRate() {
      if (this.user && this.user.store_id && this.user.store_id.usd) {
        const usd = this.user.store_id.usd;
        const total = this.user.sub_total;
        return this.$options.filters.NumberFormat(total / usd);
      }
      return 0;
    },
  },
  methods: {
    calculateMaxHeight() {
      const headerHeight = 65; // Adjust based on your header's height
      const footerHeight = 160; // Adjust based on your footer's height
      const availableHeight = window.innerHeight - headerHeight - footerHeight;
      this.mainContentMaxHeight = `${availableHeight}px`;
    },
  },
};
</script>

<style></style>
