<template>
  <section>
    <section class="bg-primary">
      <div class="container-xl py-12">
        <div class="pt-4 text-center pb-8 font-weight-bold white--text">
          <div class="text-h2">Grocery</div>
          <div>
            <v-img
              :height="$vuetify.breakpoint.smAndDown ? 180 : 350"
              aspect-ratio="2"
              contain
              src="https://loyverse.com/sites/all/themes/loyversecom/images/grocery/grocerypos.webp"
            ></v-img>
          </div>
        </div>
      </div>
    </section>
    <div class="container-xl py-12">
      <div class="row justify-center align-center">
        <div class="col-7">
          <v-card color="transparent" flat>
            <div class="text-center">
              <v-avatar color="primary" size="90">
                <v-icon color="white" x-large> fal fa-shopping-cart </v-icon>
              </v-avatar>
            </div>
            <v-card-title>
              Make the sales process in your store quick and easy
            </v-card-title>
            <v-card-text>
              <p>🚀 PosSmart: A point of sale system for Lao people! 💻🛒</p>

              <v-list-item v-for="(item, index) in list" :key="index">
                <v-list-item-icon>
                  <v-icon>{{ item.icon }}</v-icon>
                </v-list-item-icon>
                <v-list-item-content>{{ item.text }}</v-list-item-content>
              </v-list-item>
            </v-card-text>
          </v-card>
        </div>
        <div class="col-5">
          <v-img
            src="https://loyverse.com/sites/all/themes/loyversecom/images/grocery/grocery-hardware-mob.webp"
          />
        </div>
      </div>
      <div class="row justify-center align-center">
        <div class="col-5">
          <v-img
            src="https://loyverse.com/sites/all/themes/loyversecom/images/grocery/inventory-history.webp"
          />
        </div>
        <div class="col-7">
          <v-card color="transparent" flat>
            <div class="text-center">
              <v-avatar color="primary" size="90">
                <v-icon color="white" x-large> fal fa-shopping-cart </v-icon>
              </v-avatar>
            </div>
            <v-card-title> Manage your inventory </v-card-title>
            <v-card-text>
              <p>🚀 PosSmart: A point of sale system for Lao people! 💻🛒</p>

              <v-list-item v-for="(item, index) in list2" :key="index">
                <v-list-item-icon>
                  <v-icon>{{ item.icon }}</v-icon>
                </v-list-item-icon>
                <v-list-item-content>{{ item.text }}</v-list-item-content>
              </v-list-item>
            </v-card-text>
          </v-card>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  data() {
    return {
      list: [
        {
          text: "Organize sales according to your needs with a customizable menu screen, group your items into categories.",
          icon: "fal fa-wrench",
          color: "primary",
        },
        {
          text: "Add weighted items to a ticket by scanning barcodes with embedded weight.",
          icon: "fal fa-window-restore",
          color: "green",
        },
        {
          text: "Make sales, apply percentage and amount discounts in a glance.",
          icon: "fal fa-wifi",
          color: "red",
        },
        {
          text: "Speed sales with an integrated payment system by accepting major credit and debit cards.",
          icon: "fal fa-user-secret",
          color: "blue",
        },
      ],
      list2: [
        {
          text: "Create new items and check their stock right on the spot from your Grocery POS.",
          icon: "fal fa-wrench",
          color: "primary",
        },
        {
          text: "Add thousands of items, correct their prices or inventory in one click using the export-import functionality.",
          icon: "fal fa-window-restore",
          color: "green",
        },
        {
          text: "Receive email messages about the low stock and send purchase orders to suppliers from your Back office.",
          icon: "fal fa-wifi",
          color: "red",
        },
        {
          text: "Understand the total cost of your inventory and predict potential revenue and profits.",
          icon: "fal fa-user-secret",
          color: "blue",
        },
      ],
    };
  },
};
</script>

<style></style>
