<template>
  <v-app>
    <v-app-bar app elevate-on-scroll flat height="65">
      <div class="container-xl d-flex align-center px-0">
        <v-app-bar-nav-icon @click="$router.go(-1)">
          <v-icon> fal fa-chevron-left </v-icon>
        </v-app-bar-nav-icon>
        <v-toolbar-title> ລາຍການອາຫານ </v-toolbar-title>
        <v-spacer></v-spacer>
      </div>
    </v-app-bar>
    <v-main>
      <div class="container-xl">
        <v-row>
          <v-col cols="12">
            <v-card rounded="lg" class="mt-4">
              <v-card-text class="px-0 pt-1" v-if="products.length">
                <v-list-item
                  v-for="(item, index) in products"
                  :key="index"
                  two-line
                  v-show="products"
                >
                  <v-list-item-avatar tile>
                    <app-img :contain="true" :img="item.product_id.img" />
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title>{{
                      item.product_id.name
                    }}</v-list-item-title>
                    <v-list-item-subtitle>
                      {{ item.qty | NumberFormat }} x
                      {{ item.price_sale | NumberFormat }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-action class="font-weight-bold primary--text">
                    {{ item.total_price | NumberFormat }}
                  </v-list-item-action>
                </v-list-item>
              </v-card-text>
              <v-card-text class="pa-12 text-center" v-else>
                <v-icon size="80" color="grey lighten-1"
                  >fal fa-exclamation-circle</v-icon
                >
                <h3 class="pt-4 pt-2">Data Not Found!</h3>
                <p>Looks like there is no data to display. Please try again</p>
                <v-btn
                  color="primary"
                  @click="$router.go(-1)"
                  depressed
                  class="mt-3"
                >
                  <v-icon left x-small> fal fa-arrow-left </v-icon>
                  Back
                </v-btn>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </div>
    </v-main>
    <v-footer app light color="transparent" v-if="products.length">
      <div class="container-xl px-0 mb-2">
        <v-card rounded="lg">
          <v-card-text class="pa-2">
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>ລວມທັງໝົດ</v-list-item-title>
              </v-list-item-content>
              <v-list-item-action>
                <v-list-item-title
                  class="text-h6 font-weight-bold primary--text"
                >
                  {{ total_price | NumberFormat }}
                </v-list-item-title>
              </v-list-item-action>
            </v-list-item>
          </v-card-text>
        </v-card>
      </div>
    </v-footer>
    <app-loading :overlay="overlay" />
  </v-app>
</template>

<script>
import { myMixin } from "@/mixin/main";
import { mapGetters, mapMutations } from "vuex";

export default {
  mixins: [myMixin],
  data: () => ({
    products: {},
  }),
  mounted() {
    this.fetch_product();
  },
  computed: {
    ...mapGetters({
      overlay: "index/overlay",
    }),
    total_price() {
      return this.products.reduce((a, b) => a + b.total_price, 0);
    },
  },
  methods: {
    ...mapMutations({
      SET_OVERLAY: "index/SET_OVERLAY",
    }),
    async fetch_product() {
      try {
        this.SET_OVERLAY(true);
        const { branch_id, table_id } = this.$route.query;
        const { data } = await this.$http.get(
          `/fetch_table_order?branch_id=${branch_id}&table_id=${table_id}`
        );
        // data.data if not null
        if (data?.data !== null) {
          const object = await Help.reduceProduct(data?.data);
          this.products = object ?? {};
        }
        this.SET_OVERLAY(false);
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>

<style></style>
