<template>
  <section>
    <data-table
      :headers="headers"
      :items="allProducts"
      commit="SET_ALL_PRODUCTS"
      :viewItem="viewItem"
    >
      <div slot="activator" class="row justify-content-between">
        <v-col cols="2">
          <v-card-title class="px-0 pt-0"> Products </v-card-title>
        </v-col>
        <v-col cols="4">
          <TextSearch
            v-model="search"
            @search="fetchItem()"
            @onClear="
              search = '';
              fetchItem();
            "
          />
        </v-col>
      </div>
    </data-table>

    <app-custom-dialog v-model="dialog" :footer="false" title="Detail">
      <div slot="activator">
        <AppDetail :item="form" />
      </div>
    </app-custom-dialog>
    <app-loading :overlay="overlay" />
  </section>
</template>

<script>
import DataTable from "@/components/DataTable";
import { mapGetters, mapActions } from "vuex";
import { productHead } from "@/utils/head";

import AppDetail from "@/components/products/details";
import { myMixin } from "@/mixin/main";
export default {
  mixins: [myMixin],
  data: () => ({
    overlay: false,
    search: "",
    update: false,
    dialogAdd: false,
    dialog: false,
    headers: productHead(),
    form: {},
  }),
  async created() {
    await this.fetchItem();
  },
  components: { DataTable, AppDetail },
  computed: {
    ...mapGetters({
      allProducts: "index/allProducts",
    }),
  },

  methods: {
    ...mapActions({
      fetch: "index/fetch",
    }),
    async fetchItem() {
      const { store_id } = this.$route.params;
      const body = {
        that: this,
        url: `get_shop_products/${store_id}?q=${this.search}`,
        commit: "SET_ALL_PRODUCTS",
      };
      await this.fetch(body);
    },

    viewItem(item) {
      this.form = item;
      this.dialog = !this.dialog;
    },
  },
};
</script>

<style></style>
