<template>
  <section>
    <data-table
      :headers="headers"
      :items="branches"
      commit="SET_BRANCHES"
      :viewItem="viewItem"
    >
      <div slot="activator" class="row justify-sm-space-between">
        <v-col cols="3">
          <v-card-title class="px-0 pt-0"> Branch </v-card-title>
        </v-col>

        <v-col cols="2" class="text-end">
          <v-btn depressed color="primary" @click="newItem">
            <v-icon left>fal fa-plus-circle</v-icon>
            Add New
          </v-btn>
        </v-col>
      </div>
    </data-table>
    <app-custom-dialog v-model="dialog" :footer="false" title="Details">
      <div slot="activator">
        <v-row>
          <v-col cols="4">Id</v-col>
          <v-col cols="8">{{ form.id }}</v-col>
          <v-col cols="4">Name</v-col>
          <v-col cols="8">{{ form.name }}</v-col>
          <v-col cols="4">Description</v-col>
          <v-col cols="8">{{ form.desc }}</v-col>
          <v-col cols="4">Address</v-col>
          <v-col cols="8">{{ form.address }}</v-col>
          <v-col cols="4">Phone No</v-col>
          <v-col cols="8">{{ form.phone_no }}</v-col>
          <v-col cols="4">Mobile No</v-col>
          <v-col cols="8">{{ form.mobile_no }}</v-col>
          <v-col cols="4">Email</v-col>
          <v-col cols="8">{{ form.email }}</v-col>
          <v-col cols="4">Logo</v-col>
          <v-col cols="8">{{ form.logo }}</v-col>
          <v-col cols="4">Store</v-col>
          <v-col cols="8">{{ form.store_id }}</v-col>
          <v-col cols="8">{{ form.created_at | moment }}</v-col>
        </v-row>
      </div>
    </app-custom-dialog>
    <app-custom-dialog
      v-model="dialogAdd"
      :footer="true"
      :title="titleStatus"
      @submit="submitForm"
    >
      <div slot="activator">
        <TTextField
          label="name"
          v-model="form.name"
          :errorMessages="priceErrors"
          @input="$v.form.name.$touch()"
          @blur="$v.form.name.$touch()"
        />
        <TTextField
          label="mobile_no"
          v-model="form.mobile_no"
          :errorMessages="priceErrors"
          @input="$v.form.mobile_no.$touch()"
          @blur="$v.form.mobile_no.$touch()"
        />
        <TTextField
          label="address"
          v-model="form.address"
          :errorMessages="priceErrors"
          @input="$v.form.address.$touch()"
          @blur="$v.form.address.$touch()"
        />
      </div>
    </app-custom-dialog>
  </section>
</template>

<script>
import DataTable from "@/components/DataTable";
import { mapGetters, mapActions } from "vuex";
import { BRANCH_HEAD } from "@/utils/head";
import TTextField from "@/components/global/TextField";
import AppDetail from "@/components/products/details";
import { myMixin } from "@/mixin/main";
import { success } from "@/controllers/alert";
import { CREATE_BRANCH } from "@/validations/admin";

export default {
  mixins: [CREATE_BRANCH, myMixin],

  data: () => ({
    update: false,
    dialogAdd: false,
    dialog: false,
    headers: BRANCH_HEAD(),
    form: {
      package: {},
    },
  }),

  mounted() {
    this.fetchItem();
  },
  components: { DataTable, TTextField, AppDetail },
  computed: {
    ...mapGetters({
      branches: "index/branches",
    }),

    titleStatus() {
      return this.update ? "Update" : "New";
    },
  },

  methods: {
    ...mapActions({
      fetch: "index/fetch",
    }),
    async fetchItem() {
      const store_id = this.$route.params.store_id;
      const body = {
        that: this,
        url: `get_branch/${store_id}`,
        commit: "SET_BRANCHES",
      };
      await this.fetch(body);
    },

    async newItem() {
      await Help.clearForm(this.form, this);
      await this.fetchItem();
      this.update = false;
      this.dialogAdd = !this.dialogAdd;
    },
    editItem(VAL) {
      this.form = VAL;
      this.update = true;
      this.dialogAdd = !this.dialogAdd;
    },

    viewItem(item) {
      this.form = item;
      this.dialog = !this.dialog;
    },
    async submitForm() {
      try {
        this.$v.form.$touch();
        if (!this.$v.form.$invalid) {
          this.form.store_id = this.$route.params.store_id;
          const { data } = await this.$http.post("add_branch", this.form);
          if (data.status) {
            this.dialogAdd = !this.dialogAdd;
            await Help.clearForm(this.form, this);
            await this.fetchItem();
            await success(this, data.message);
          }
        }
      } catch (error) {
        console.error(error);
      }
    },
  },
};
</script>

<style></style>
