<template>
    <v-overlay class="overlay" :value="overlay">
        <v-progress-circular
            color="primary"
            indeterminate
            size="64"
        ></v-progress-circular>
    </v-overlay>
</template>

<script>
export default {
    props: {
        overlay: {
            type: Boolean,
            default: false
        }
    }
};
</script>

<style></style>
