<template>
  <v-container fluid fill-height class="bg-white">
    <v-row justify="center" align="center">
      <v-col
        v-if="$vuetify.breakpoint.mdAndUp"
        cols="4"
        class="side-color bg-primary"
      >
        <v-card max-width="550" outlined color="transparent" flat>
          <v-img src="/images/register.png" />
        </v-card>
      </v-col>
      <v-col xs="12" sm="12" md="8" lg="8" xl="8">
        <v-btn color="primary" @click="$router.back()" fab outlined icon small>
          <v-icon small>fal fa-chevron-left</v-icon>
        </v-btn>
        <v-row justify="center">
          <v-col xs="12" sm="12" md="8" lg="6" xl="6">
            <div class="text-center">
              <v-avatar size="120">
                <v-img src="/images/pos.png"></v-img>
              </v-avatar>
            </div>
            <v-form @submit.prevent="submitForm(form)">
              <v-card-text>
                <div class="text-center">
                  <h2 class="font-weight-black">ສະໝັກສະມາຊິກ</h2>
                  <p>ປ້ອນຂໍ້ມູນຂອງທ່ານເພື່ອທົດລອງນຳໃຊ້ລະບົບ 30 ວັນ</p>
                </div>

                <TTextField
                  :errorMessages="first_nameError"
                  label="first_name"
                  v-model="form.first_name"
                  @input="$v.form.first_name.$touch()"
                  @blur="$v.form.first_name.$touch()"
                />
                <TTextField
                  :errorMessages="emailError"
                  label="email"
                  v-model="form.email"
                  @input="$v.form.email.$touch()"
                  @blur="$v.form.email.$touch()"
                />

                <t-password
                  v-model="form.password"
                  @input="$v.form.password.$touch()"
                  @blur="$v.form.password.$touch()"
                  label="password"
                  showKey="showOldPassword"
                  :errorMessages="passwordErrors"
                  @showOldPassword="showPassword = !showPassword"
                  :type="showPassword"
                />
                <t-password
                  v-model="form.confirm_password"
                  @input="$v.form.confirm_password.$touch()"
                  @blur="$v.form.confirm_password.$touch()"
                  label="confirm_password"
                  showKey="showOldPassword"
                  :errorMessages="confirmPasswordErrors"
                  @showOldPassword="shoConfirmPassword = !shoConfirmPassword"
                  :type="shoConfirmPassword"
                />
                <div class="d-flex">
                  <v-checkbox
                    class="mt-0"
                    hide-details
                    v-model="terms"
                    :label="$t('accept_term')"
                  />
                  <v-btn to="/terms" text color="primary">{{
                    $t("term_services")
                  }}</v-btn
                  >,
                  <v-btn to="/privacy" text color="primary" class="px-0">{{
                    $t("privacy")
                  }}</v-btn>
                </div>
              </v-card-text>
              <v-card-actions class="px-4">
                <v-btn type="submit" color="primary" large block>{{
                  $t("submit")
                }}</v-btn>
              </v-card-actions>
            </v-form>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <!-- AppLoading -->
    <app-loading :overlay="overlay" />
  </v-container>
</template>

<script>
import { toast } from "@/controllers/alert";
import TPassword from "@/components/global/TextPassword";
import TTextField from "@/components/global/TextField";
import { Register } from "@/validations/user";

export default {
  layout: "blank",
  components: { TPassword, TTextField },
  mixins: [Register],
  data: () => ({
    terms: false,
    overlay: false,
    showPassword: false,
    shoConfirmPassword: false,
    form: {
      first_name: "",
      email: "",
      password: "",
      confirm_password: "",
    },
  }),

  methods: {
    async submitForm(form) {
      try {
        this.$v.form.$touch();
        if (!this.$v.form.$invalid) {
          // check terms
          if (!this.terms) {
            toast(this, "accept_con", "error");
            return;
          }
          this.overlay = true;
          const { data } = await this.$http.post("register", form);
          if (data.status) {
            this.overlay = false;
            toast(this, data.message, "success");
            this.$router.push({
              path: "/verify-new-user",
              query: { email: form.email },
            });
          } else {
            toast(this, data.message, "error");
          }
        }
      } catch (error) {
        this.overlay = false;
      }
    },
  },
};
</script>

<style></style>
