<template>
  <section>
    <data-table
      :headers="headers"
      :items="stores"
      commit="SET_STORES"
      :viewItem="viewItem"
      :editItem="editItem"
      :deleteItem="storeAction"
    >
      <div slot="activator" class="row justify-sm-space-between">
        <v-col cols="3">
          <v-chip-group
            v-model="status_filter"
            mandatory
            column
            active-class="deep-purple--text text--accent-4"
          >
            <v-chip
              v-for="(val, i) in status_list"
              :key="i"
              :value="val.value"
              filter
              outlined
            >
              {{ $t(val.text) }}
            </v-chip>
          </v-chip-group>
        </v-col>
        <v-col cols="4">
          <TextSearch
            v-model="search"
            @search="fetchItem()"
            @onClear="
              search = '';
              fetchItem();
            "
          />
        </v-col>
        <v-col cols="2" class="text-end">
          <v-btn depressed color="primary" @click="newItem">
            <v-icon left>fal fa-plus-circle</v-icon>
            Add New
          </v-btn>
        </v-col>
      </div>
    </data-table>
    <app-custom-dialog
      v-model="dialog"
      width="800px"
      :footer="false"
      title="details"
    >
      <div slot="activator">
        <v-row>
          <v-col xs="12" sm="12" md="6" lg="6" xl="6">
            <v-row>
              <v-col cols="5">{{ $t("id") }}</v-col>
              <v-col cols="7">{{ form.id }}</v-col>
              <v-col cols="5">{{ $t("logo") }}</v-col>
              <v-col cols="7">
                <v-avatar tile size="35">
                  <v-btn text @click="openURL(form.logo)">
                    <app-img :img="form.logo" />
                  </v-btn>
                </v-avatar>
              </v-col>
              <v-col cols="5">{{ $t("name") }}</v-col>
              <v-col cols="7">{{ form.name }}</v-col>
              <v-col cols="5">{{ $t("package") }}</v-col>
              <v-col cols="7">
                <v-chip
                  v-if="form.package && form.package.name"
                  label
                  dark
                  :color="packageName(form.package.name).color"
                  small
                  class="text-capitalize"
                >
                  {{ packageName(form.package.name).text }}</v-chip
                ></v-col
              >

              <v-col cols="5">{{ $t("phone_no") }}</v-col>
              <v-col cols="7">{{ form.phone_no }}</v-col>
              <v-col cols="5">{{ $t("mobile_no") }}</v-col>
              <v-col cols="7">{{ form.mobile_no }}</v-col>
              <v-col cols="5">{{ $t("address") }}</v-col>
              <v-col cols="7">{{ form.address }}</v-col>
              <v-col cols="5">{{ $t("start_date") }}</v-col>
              <v-col cols="7">{{ form.start_date }}</v-col>
              <v-col cols="5">{{ $t("end_date") }}</v-col>
              <v-col cols="7">{{ form.end_date }}</v-col>
            </v-row>
          </v-col>
          <v-col xs="12" sm="12" md="6" lg="6" xl="6">
            <v-row>
              <v-col cols="5">{{ $t("product_count") }}</v-col>
              <v-col cols="7">{{ form.product_count | NumberFormat }}</v-col>
              <v-col cols="5">{{ $t("all_products") }}</v-col>
              <v-col cols="7">{{ form.products_count | NumberFormat }}</v-col>
              <v-col cols="5">{{ $t("categories_count") }}</v-col>
              <v-col cols="7">{{ form.categories_count | NumberFormat }}</v-col>
              <v-col cols="5">{{ $t("tickets_count") }}</v-col>
              <v-col cols="7">
                <v-btn
                  depressed
                  small
                  dark
                  color="primary"
                  :to="`store-ticket/` + form.id"
                >
                  {{ form.tickets_count | NumberFormat }}
                </v-btn>
              </v-col>
              <v-col cols="5">{{ $t("users_count") }}</v-col>
              <v-col cols="7">{{
                form.stores_user_count | NumberFormat
              }}</v-col>
              <v-col cols="5">{{ $t("customers_count") }}</v-col>
              <v-col cols="7">{{ form.customers_count | NumberFormat }}</v-col>
              <v-col cols="5">{{ $t("branches_count") }}</v-col>
              <v-col cols="7">{{ form.branches_count | NumberFormat }}</v-col>
              <v-col cols="5">{{ $t("date") }}</v-col>
              <v-col cols="7">{{ form.created_at | moment }}</v-col>
            </v-row>
          </v-col>
        </v-row>
      </div>
    </app-custom-dialog>
    <app-custom-dialog
      v-model="dialogAdd"
      :footer="true"
      :closeBtn="false"
      :title="titleStatus"
      width="1000px"
      @submit="submitForm"
    >
      <div slot="activator">
        <v-row>
          <v-col xs="12" sm="12" md="6" lg="6" xl="6">
            <TTextField
              label="Store Name"
              v-model="form.name"
              :errorMessages="nameErrors"
              @input="$v.form.name.$touch()"
              @blur="$v.form.name.$touch()"
            />
            <TTextField
              label="Mobile No"
              v-model="form.mobile_no"
              :errorMessages="mobile_noErrors"
              @input="$v.form.mobile_no.$touch()"
              @blur="$v.form.mobile_no.$touch()"
            />
            <TTextField
              label="Address"
              v-model="form.address"
              :errorMessages="addressErrors"
              @input="$v.form.address.$touch()"
              @blur="$v.form.address.$touch()"
            />
            <v-radio-group class="py-0 my-0" v-model="form.store_type_code" row>
              <v-radio
                v-for="(item, index) in storeList"
                :key="index"
                :label="item.text"
                :value="item.value"
              ></v-radio>
            </v-radio-group>
            <TextSelect
              label="Owner"
              v-model="form.userId"
              itemValue="id"
              text="first_name"
              :items="users"
              :errorMessages="userIdErrors"
              @input="$v.form.userId.$touch()"
              @blur="$v.form.userId.$touch()"
            />
            <DatePicker label="Start Date" v-model="form.start_date" />
            <DatePicker label="End Date" v-model="form.end_date" />
          </v-col>
          <v-col xs="12" sm="12" md="6" lg="6" xl="6">
            <TextSelect
              label="Package"
              v-model="form.package_id"
              itemValue="id"
              text="name"
              :items="packages"
            />
            <VuetifyMoney label="Day Count" v-model="form.day" />
            <VuetifyMoney label="Month Count" v-model="form.month_count" />
            <VuetifyMoney label="Products Count" v-model="form.product_count" />
            <VuetifyMoney label="Ticket Count" v-model="form.bill_count" />
            <VuetifyMoney label="Users Count" v-model="form.user_count" />
            <VuetifyMoney label="Branch Count" v-model="form.branch_count" />
          </v-col>
        </v-row>
      </div>
    </app-custom-dialog>
    <!-- AppLoading -->
    <app-loading :overlay="overlay" />
  </section>
</template>

<script>
import DataTable from "@/components/DataTable";
import { mapGetters, mapActions } from "vuex";
import { storeHead } from "@/utils/head";
import TTextField from "@/components/global/TextField";
import AppDetail from "@/components/products/details";
import { myMixin } from "@/mixin/main";
import { success, confirm } from "@/controllers/alert";
import { CREATE_STORE } from "@/validations/admin";
import TextSelect from "@/components/global/TextSelect";
import { STORE_TYPE } from "@/utils/mock";
import VuetifyMoney from "@/components/VuetifyMoney";

// get today date
const start_date = new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
  .toISOString()
  .substr(0, 10);

// get end_date add 1 month date
const end_date = new Date(
  Date.now() - new Date().getTimezoneOffset() * 60000 + 86400000 * 30
)
  .toISOString()
  .substr(0, 10);
export default {
  mixins: [CREATE_STORE, myMixin],

  data: () => ({
    status_filter: null,
    status_list: [
      {
        value: null,
        text: "all",
      },
      {
        value: 1,
        text: "active",
      },
      {
        value: 0,
        text: "inactive",
      },
    ],
    overlay: false,
    search: "",
    update: false,
    dialogAdd: false,
    dialog: false,

    users: [],
    packages: [],
    form: {
      name: "",
      start_date: start_date,
      end_date: end_date,
      package: {
        name: "",
      },
      package_id: null,
    },
    storeList: STORE_TYPE(),
  }),
  async created() {
    await this.fetchItem();
  },
  components: {
    DataTable,
    TextSelect,
    TTextField,
    VuetifyMoney,
    AppDetail,
  },

  watch: {
    status_filter() {
      this.fetchItem();
    },
  },

  computed: {
    ...mapGetters({
      stores: "index/stores",
    }),

    titleStatus() {
      return this.update ? "Update" : "New";
    },
    headers() {
      return storeHead(this);
    },
  },

  methods: {
    ...mapActions({
      fetch: "index/fetch",
    }),
    async fetchItem() {
      const body = {
        that: this,
        url: `admin_stores?q=${this.search}&status=${this.status_filter}`,
        commit: "SET_STORES",
      };
      await this.fetch(body);
    },

    async fetchUser() {
      try {
        const { data } = await this.$http.get(`/admin_get_users`);
        if (data.status) {
          this.users = data.data;
        }
      } catch (error) {
        console.log(error);
      }
    },
    async fetchPackage() {
      try {
        const { data } = await this.$http.get(`/admin_get_package`);
        if (data.status) {
          this.packages = data.data;
        }
      } catch (error) {
        console.log(error);
      }
    },
    async newItem() {
      await Help.clearForm(this.form, this);
      await this.fetchItem();
      await this.fetchUser();
      await this.fetchPackage();
      this.form.start_date = start_date;
      this.form.end_date = end_date;
      this.form.userId = null;
      this.form = {
        start_date: start_date,
        end_date: end_date,
        package: {},
        day: 30,
        month_count: 1,
        product_count: 200,
        bill_count: 0,
        user_count: 2,
        branch_count: 1,
      };

      this.update = false;
      this.dialogAdd = !this.dialogAdd;
    },
    async editItem({ id }) {
      const { data } = await this.$http.get(`/admin_get_store/${id}`);
      this.form = data.data;
      this.form.userId = data.data.owner.id;
      await this.fetchPackage();
      await this.fetchUser();
      this.update = true;
      this.dialogAdd = !this.dialogAdd;
    },
    async storeAction(val) {
      try {
        const title = val.status ? "Inactivate" : "Activate";
        const { isConfirmed } = await confirm({
          props: this,
          title: `${title} Store`,
          text: `Are you sure you want to ${title} Or Clear this store?`,
          con_txt: `${title} Store`,
          can_txt: "Clear Store",
        });
        if (isConfirmed) {
          const { data } = await this.$http.put(`store_action/${val.id}`);
          if (data.status) {
            await Help.clearForm(this.form, this);
            await this.fetchItem();
            await success(this, data.message);
          }
        } else {
          await this.clearStore();
        }
      } catch (error) {
        console.error(error);
      }
    },

    async clearStore() {
      try {
        const { isConfirmed } = await confirm({
          props: this,
          title: `Delete Store`,
          text: `This will delete the store and all its products, categories, branches, users, customers and bills. Are you sure you want to continue?`,
          con_txt: `Yes, Delete it!`,
          can_txt: "Cancelled",
        });
        if (isConfirmed) {
          const { data } = await this.$http.delete(
            `clear_store/${this.form.id}`
          );
          if (data.status) {
            this.dialog = false;
            await Help.clearForm(this.form, this);
            await this.fetchItem();
            await success(this, data.message);
          }
        }
      } catch (error) {
        console.error(error);
      }
    },

    async viewItem({ id }) {
      const { data } = await this.$http.get(`/admin_get_store/${id}`);
      if (data.status) {
        this.form = data.data;
        this.dialog = !this.dialog;
      }
    },
    async submitForm() {
      try {
        this.$v.form.$touch();
        // if form is valid
        if (!this.$v.form.$invalid) {
          // check if userId is object or not
          if (typeof this.form.userId === "object") {
            this.form.userId = this.form.userId.id;
          }
          // check if package_id is object or not
          if (typeof this.form.package_id === "object") {
            this.form.package_id = this.form.package_id.id;
          }
          // remove package object from form
          delete this.form.package;
          const url = this.update ? `update_store` : `add_store`;
          const { data } = await this.$http.post(url, this.form);
          if (data.status) {
            this.dialogAdd = false;
            await Help.clearForm(this.form, this);
            await this.fetchItem();
            await success(this, data.message);
          }
        }
      } catch (error) {
        console.error(error);
      }
    },

    openURL(img) {
      const url = Help.product(img);
      window.open(url, "_blank").focus();
    },
  },
};
</script>

<style></style>
