<template>
  <v-app dark>
    <app-drawer-left ref="drawer" />
    <v-app-bar flat height="65" fixed app>
      <v-btn
        small
        fab
        elevation="0"
        @click.stop="$refs.drawer.drawer = !$refs.drawer.drawer"
      >
        <v-icon dark small>fal fa-stream</v-icon>
      </v-btn>

      <v-toolbar-title class="ml-3" v-if="$vuetify.breakpoint.mdAndUp">{{
        title
      }}</v-toolbar-title>
      <v-spacer />
      <v-menu offset-y bottom left open-on-hover>
        <template v-slot:activator="{ attrs, on }">
          <v-btn v-bind="attrs" v-on="on" fab depressed small>
            <v-icon small> fal fa-globe </v-icon>
          </v-btn>
        </template>
        <v-list dense>
          <v-list-item
            v-for="(locale, index) in locales"
            :key="index"
            @click="changeLocale(locale.code)"
          >
            <v-list-item-avatar size="20">
              <v-img :src="locale.flag" />
            </v-list-item-avatar>

            <v-list-item-title>{{ locale.name }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>

      <v-btn class="ml-3" fab depressed small @click="changeTheme">
        <v-icon small>fal fa-{{ darkMode === true ? "sun" : "moon" }}</v-icon>
      </v-btn>
    </v-app-bar>
    <v-main>
      <v-container fluid>
        <router-view></router-view>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import AppDrawerLeft from "@/components/layouts/drawerLeft";
import { mapGetters } from "vuex";
import { setUser, checkToken } from "@/controllers/auth";

export default {
  components: { AppDrawerLeft },
  data() {
    return {
      drawer: true,
      title: "PosSmart",
      darkMode: false,
    };
  },

  created() {
    checkToken(this);
    const themes = localStorage.getItem("darkMode");
    if (themes) {
      if (themes.length === 4) {
        this.$vuetify.theme.dark = true;
      } else {
        this.$vuetify.theme.dark = false;
      }
    }
  },
  mounted() {
    setUser(this);
  },
  computed: {
    ...mapGetters({
      user: "auth/user",
      locales: "auth/locales",
    }),
  },

  methods: {
    changeTheme() {
      this.darkMode = !this.darkMode;
      const value = !this.$vuetify.theme.dark;
      this.$vuetify.theme.dark = value;
      localStorage.setItem("darkMode", value);
    },
    changeLocale(code) {
      localStorage.setItem("locale", code);
      this.$i18n.locale = code;
    },
  },
};
</script>
