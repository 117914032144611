<template>
  <v-card
    v-if="Object.keys(item).length > 0"
    flat
    outlined
    tile
    class="text-center"
  >
    <div class="body font">
      {{ selected.includes("name") ? item.name : "" }}
      {{ selected.includes("size") ? "- " + item.size.name : "" }}
    </div>

    <barcode
      class="barcode"
      :value="item.code"
      :width="width"
      :height="height"
      :fontSize="fontSize"
    />

    <div
      class="font"
      v-bind:style="{
        fontSize: fontSize + 'px',
      }"
    >
      {{ selected.includes("money") ? moneyFormatPrint(item.price_sale) : "" }}
    </div>
  </v-card>
</template>

<script>
import { myMixin } from "@/mixin/main";
import VueBarcode from "vue-barcode";

export default {
  components: {
    barcode: VueBarcode,
  },
  mixins: [myMixin],
  props: {
    item: Object,
    selected: {
      type: Array,
      default: () => ["name", "size", "money"],
    },
    fontSize: {
      type: Number,
      default: 20,
    },
    width: {
      type: Number,
      default: 2,
    },
    height: {
      type: Number,
      default: 35,
    },
  },
};
</script>

<style></style>
