<template>
  <v-row>
    <v-col xs="12" sm="12" md="6" lg="6" xl="6">
      <v-row>
        <v-col cols="4">{{ $t("code") }} </v-col>
        <v-col cols="8"
          >{{ item.code }}
          <v-btn icon small>
            <v-icon x-small @click="copyToClipboard(item.code)"
              >fal fa-copy</v-icon
            >
          </v-btn></v-col
        >
        <v-col cols="4">{{ $t("status") }}</v-col>
        <v-col cols="8">
          <v-chip label small :color="orderStatusColor(item.status)" dark>
            {{ $t(item.status) }}
          </v-chip>
        </v-col>
        <v-col cols="4">{{ $t("table") }}</v-col>
        <v-col cols="8">{{ item.table?.name ?? "..." }}</v-col>
        <v-col cols="4">{{ $t("seller") }} </v-col>
        <v-col cols="8">{{ item.user_id.first_name }}</v-col>
        <v-col cols="4">{{ $t("order_type") }}</v-col>
        <v-col cols="8"
          ><v-chip
            small
            label
            dark
            class="text-capitalize"
            :color="item.is_online_order ? 'green' : 'primary'"
          >
            {{ item.is_online_order ? $t("online") : $t("counter") }}
          </v-chip></v-col
        >
        <v-col cols="4">{{ $t("created_at") }} </v-col>
        <v-col cols="8">{{ item.created_at | moment }}</v-col>
      </v-row>
    </v-col>
    <v-col xs="12" sm="12" md="6" lg="6" xl="6">
      <v-row>
        <v-col cols="4">{{ $t("quantity") }} </v-col>
        <v-col cols="8">{{ item.total_qty ?? 0 }} {{ $t("item") }}</v-col>
        <v-col cols="4">{{ $t("total_price") }} </v-col>
        <v-col cols="8">{{ item.total_price | NumberFormat }}</v-col>
        <v-col cols="4">{{ $t("sum_price_buy") }} </v-col>
        <v-col cols="8">{{ item.total_price_buy | NumberFormat }}</v-col>
        <v-col cols="4">{{ $t("total_sale") }}</v-col>
        <v-col cols="8">{{ item.total_price_sale | NumberFormat }}</v-col>
        <v-col cols="4">{{ $t("profit") }} </v-col>
        <v-col cols="8"
          >{{ item.total_profit | NumberFormat }}
          <v-icon x-small :color="item.total_profit > 0 ? 'green' : 'red'">
            {{
              item.total_profit > 0 ? "fal fa-arrow-up" : "fal fa-arrow-down"
            }}
          </v-icon>
        </v-col>
      </v-row>
    </v-col>
    <v-col cols="12">
      <v-simple-table>
        <template v-slot:default>
          <thead>
            <tr>
              <th scope="col">{{ $t("id") }}</th>
              <th scope="col">{{ $t("image") }}</th>
              <th scope="col">{{ $t("name") }}</th>
              <th scope="col">{{ $t("status") }}</th>
              <th scope="col">{{ $t("price") }}</th>
              <th scope="col">{{ $t("amount") }}</th>
              <th scope="col" class="text-right">{{ $t("total") }}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(data, index) in item.history" :key="index">
              <th scope="row">{{ index + 1 }}</th>
              <td>
                <app-img :img="data.product_id.img" width="40" height="40" />
              </td>
              <td>{{ data.product_id.name }}</td>
              <td>
                <v-chip
                  small
                  label
                  dark
                  class="text-capitalize"
                  :color="orderStatusColor(data.status)"
                >
                  {{ $t(data.status) }}
                </v-chip>
              </td>
              <td>{{ data.price_sale | NumberFormat }}</td>
              <td>{{ data.qty | NumberFormat }}</td>
              <td class="text-right">
                {{ data.total_price | NumberFormat }}
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-col>
  </v-row>
</template>

<script>
import { myMixin } from "@/mixin/main";
export default {
  mixins: [myMixin],
  props: {
    item: {
      type: Object,
    },
  },
};
</script>
