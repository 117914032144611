<template>
  <div id="app">
    <transition name="page" mode="out-in">
      <component :is="layout">
        <router-view />
      </component>
    </transition>
  </div>
</template>
<script>
const default_layout = "default";
import { mapGetters } from "vuex";
export default {
  metaInfo() {
    return {
      title: this.$t(this.$route.name) + " - PosSmart",
      meta: [
        {
          name: "description",
          content: "PosSmart",
        },
        {
          property: "og:title",
          content: `${this.$route.name} - PosSmart`,
        },
        { property: "og:site_name", content: "Epiloge" },
        { property: "og:type", content: "website" },
        { name: "robots", content: "index,follow" },
      ],
    };
  },
  data: () => ({}),

  computed: {
    ...mapGetters({
      user: "auth/user",
    }),
    layout() {
      return this.$route.meta.layout || default_layout;
    },
  },
};
</script>

<style></style>
