<template>
  <section>
    <data-table
      :headers="headers"
      :items="productSizes"
      commit="SET_PRODUCT_SIZE"
      :viewItem="viewItem"
    >
      <div slot="activator" class="row">
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-3">
          <v-card-title class="px-0 pt-0"> {{ $t("unit") }} </v-card-title>
        </div>

        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-4">
          <TextSearch
            v-model="search"
            @search="fetchItem()"
            @onClear="
              search = '';
              fetchItem();
            "
          />
        </div>
        <v-spacer />
      </div>
    </data-table>
    <app-custom-dialog v-model="dialog" :footer="false" title="description">
      <div slot="activator">
        <v-row>
          <v-col cols="4">{{ $t("id") }}</v-col>
          <v-col cols="8">{{ form.id }}</v-col>
          <v-col cols="4">{{ $t("name") }}</v-col>
          <v-col cols="8">{{ form.name }}</v-col>
          <v-col cols="4">{{ $t("unit") }}</v-col>
          <v-col cols="8">
            <v-chip
              label
              dark
              :color="
                form.unit === 'amount' ? 'orange accent-2' : 'green accent-3'
              "
              small
            >
              {{ $t(form.unit) }}</v-chip
            >
          </v-col>
          <v-col cols="4">{{ $t("amount") }}</v-col>
          <v-col cols="8">{{ form.amount }} ຊີ້ນ</v-col>
          <v-col cols="4">{{ $t("products_count") }}</v-col>
          <v-col cols="8">{{ form.products_count }} ລາຍການ</v-col>
          <v-col cols="4">{{ $t("date") }}</v-col>
          <v-col cols="8">{{ form.created_at | moment }}</v-col>
        </v-row>
      </div>
    </app-custom-dialog>
    <!-- AppLoading -->
    <app-loading :overlay="overlay" />
  </section>
</template>

<script>
import DataTable from "@/components/DataTable";
import { mapGetters, mapActions } from "vuex";
import TTextField from "@/components/global/TextField";
import { customerValidate } from "@/validations/shop";
import { myMixin } from "@/mixin/main";
import { productSizeHead } from "@/utils/head";

export default {
  mixins: [customerValidate, myMixin],

  data: () => ({
    dialog: false,
    overlay: false,
    search: "",

    form: {},
  }),
  async created() {
    await this.fetchItem();
  },
  components: { DataTable, TTextField },
  computed: {
    ...mapGetters({
      productSizes: "index/productSizes",
    }),
    headers() {
      return productSizeHead(this);
    },
  },

  methods: {
    ...mapActions({
      fetch: "index/fetch",
    }),
    async fetchItem() {
      const body = {
        that: this,
        url: `product_size_paginate?q=${this.search}`,
        commit: "SET_PRODUCT_SIZE",
      };
      await this.fetch(body);
    },

    viewItem(item) {
      this.form = item;
      this.dialog = !this.dialog;
    },
  },
};
</script>

<style></style>
