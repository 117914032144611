<template>
  <v-card class="rounded-lg">
    <v-card-title>
      <v-icon left small> fal fa-key </v-icon>
      {{ $t("change_password") }}
    </v-card-title>

    <v-card-text class="px-10 pt-0">
      <v-form @submit.prevent="submitForm(form)">
        <v-card-text class="px-0 pt-0">
          <t-password
            v-model="form.old_password"
            @input="$v.form.old_password.$touch()"
            @blur="$v.form.old_password.$touch()"
            label="current_password"
            showKey="showOldPassword"
            :errorMessages="OldPasswordErrors"
            @showOldPassword="showOldPassword = !showOldPassword"
            :type="showOldPassword"
          />
          <t-password
            v-model="form.password"
            @input="$v.form.password.$touch()"
            @blur="$v.form.password.$touch()"
            label="new_password"
            showKey="showPassword"
            :errorMessages="passwordErrors"
            @showPassword="showPassword = !showPassword"
            :type="showPassword"
          />

          <t-password
            v-model="form.confirm_password"
            @input="$v.form.confirm_password.$touch()"
            @blur="$v.form.confirm_password.$touch()"
            @showConfirmPassword="showConfirmPassword = !showConfirmPassword"
            label="confirm_password"
            showKey="showConfirmPassword"
            :errorMessages="confirmPasswordErrors"
            :type="showConfirmPassword"
          />
        </v-card-text>
        <v-card-actions>
          <v-btn type="submit" color="primary" large class="px-10" depressed>
            {{ $t("update") }}
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import TPassword from "@/components/global/TextPassword";
import { mapActions } from "vuex";
import { changePassword } from "@/validations/user";
import { success } from "@/controllers/alert";

export default {
  mixins: [changePassword],

  data: () => ({
    showPassword: false,
    showOldPassword: false,
    showConfirmPassword: false,
    form: {
      old_password: null,
      password: null,
      confirm_password: null,
    },
  }),
  components: {
    TPassword,
  },
  methods: {
    ...mapActions({
      removeAuth: "auth/removeAuth",
    }),

    async submitForm(form) {
      this.$v.form.$touch();
      if (!this.$v.form.$invalid) {
        const { data } = await this.$http.post("change_password", form);
        if (data.status) {
          await success(this, data.message);
          await this.removeAuth();
          this.$router.push("/login");
        }
      }
    },
  },
};
</script>

<style></style>
