<template>
  <section>
    <data-table
      :headers="headers"
      :items="packages"
      commit="SET_STORES"
      :viewItem="viewItem"
      :editItem="editItem"
      :deleteItem="deleteItem"
    >
      <div slot="activator" class="row justify-sm-space-between">
        <v-col cols="3">
          <v-card-title class="px-0 pt-0"> {{ $t("package") }} </v-card-title>
        </v-col>

        <v-col cols="2" class="text-end">
          <v-btn depressed color="primary" @click="newItem">
            <v-icon left>fal fa-plus-circle</v-icon>
            Add New
          </v-btn>
        </v-col>
      </div>
    </data-table>
    <app-custom-dialog
      v-model="dialog"
      :footer="false"
      title="Details"
      @submit="submitForm"
    >
      <div slot="activator">
        <v-row>
          <v-col cols="4">Id</v-col>
          <v-col cols="8">{{ form.id }}</v-col>
          <v-col cols="4">Name</v-col>
          <v-col cols="8"
            ><v-chip label dark :color="packageName(form.name).color" small>
              {{ packageName(form.name).text }}</v-chip
            ></v-col
          >
          <v-col cols="4">Day</v-col>
          <v-col cols="8">{{ form.day }}</v-col>
          <v-col cols="4">Icon</v-col>
          <v-col cols="8">{{ form.icon }}</v-col>
          <v-col cols="4">Month Count</v-col>
          <v-col cols="8">{{ form.month_count }}</v-col>
          <v-col cols="4">Product Count</v-col>
          <v-col cols="8">{{ form.product_count }}</v-col>
          <v-col cols="4">Bill Count</v-col>
          <v-col cols="8">{{ form.bill_count }}</v-col>
          <v-col cols="4">Users Count</v-col>
          <v-col cols="8">{{ form.user_count }}</v-col>
          <v-col cols="4">Branches Count</v-col>
          <v-col cols="8">{{ form.branch_count }}</v-col>
          <v-col cols="4">Price</v-col>
          <v-col cols="8">{{ form.price }}</v-col>
          <v-col cols="4">Date</v-col>
          <v-col cols="8">{{ form.created_at | moment }}</v-col>
        </v-row>
      </div>
    </app-custom-dialog>
    <app-custom-dialog
      v-model="dialogAdd"
      :footer="true"
      :title="titleStatus"
      @submit="submitForm"
    >
      <div slot="activator">
        <TTextField
          label="NAME"
          v-model="form.name"
          :errorMessages="nameErrors"
          @input="$v.form.name.$touch()"
          @blur="$v.form.name.$touch()"
        />
        <TTextField
          label="DAY"
          v-model="form.day"
          :errorMessages="dayErrors"
          @input="$v.form.day.$touch()"
          @blur="$v.form.day.$touch()"
        />
        <TTextField
          label="ICON"
          v-model="form.icon"
          :errorMessages="iconErrors"
          @input="$v.form.icon.$touch()"
          @blur="$v.form.icon.$touch()"
        />
        <TTextField
          label="MONTH AMOUNT"
          v-model.number="form.month_count"
          :errorMessages="month_countErrors"
          @input="$v.form.month_count.$touch()"
          @blur="$v.form.month_count.$touch()"
        />
        <TTextField
          label="PRODUCTS AMOUNT"
          v-model.number="form.product_count"
          :errorMessages="product_countErrors"
          @input="$v.form.product_count.$touch()"
          @blur="$v.form.product_count.$touch()"
        />
        <TTextField
          label="BILL AMOUNT"
          v-model.number="form.bill_count"
          :errorMessages="bill_countErrors"
          @input="$v.form.bill_count.$touch()"
          @blur="$v.form.bill_count.$touch()"
        />
        <TTextField
          label="USERS AMOUNT"
          v-model.number="form.user_count"
          :errorMessages="user_countErrors"
          @input="$v.form.user_count.$touch()"
          @blur="$v.form.user_count.$touch()"
        />
        <TTextField
          label="BRANCH AMOUNT"
          v-model.number="form.branch_count"
          :errorMessages="branch_countErrors"
          @input="$v.form.branch_count.$touch()"
          @blur="$v.form.branch_count.$touch()"
        />
        <TTextField
          label="PRICE"
          v-model="form.price"
          :errorMessages="priceErrors"
          @input="$v.form.price.$touch()"
          @blur="$v.form.price.$touch()"
        />
      </div>
    </app-custom-dialog>
  </section>
</template>

<script>
import DataTable from "@/components/DataTable";
import { mapGetters, mapActions } from "vuex";
import { packageHead } from "@/utils/head";
import TTextField from "@/components/global/TextField";
import AppDetail from "@/components/products/details";
import { myMixin } from "@/mixin/main";
import { success, confirm } from "@/controllers/alert";
import { PACKAGE } from "@/validations/admin";

export default {
  mixins: [PACKAGE, myMixin],
  data: () => ({
    update: false,
    dialogAdd: false,
    dialog: false,

    form: {},
  }),
  async created() {
    await this.fetchItem();
  },
  components: {
    DataTable,

    TTextField,
    AppDetail,
  },
  computed: {
    ...mapGetters({
      packages: "index/packages",
    }),
    headers() {
      return packageHead(this);
    },

    titleStatus() {
      return this.update ? "Update" : "New";
    },
  },

  methods: {
    ...mapActions({
      fetch: "index/fetch",
    }),
    async fetchItem() {
      const body = {
        that: this,
        url: "packages_page",
        commit: "SET_PACKAGE",
      };
      await this.fetch(body);
    },

    async newItem() {
      await Help.clearForm(this.form, this);
      await this.fetchItem();
      this.update = false;
      this.dialogAdd = !this.dialogAdd;
    },
    async editItem({ id }) {
      const { data } = await this.$http.get(`packages/${id}`);
      this.form = data.data;
      this.update = true;
      this.dialogAdd = !this.dialogAdd;
    },
    async deleteItem({ id }) {
      try {
        const { isConfirmed } = await confirm({
          props: this,
          text: "want_to_delete",
        });
        if (isConfirmed) {
          const { data } = await this.$http.delete(`packages/${id}`);
          if (data.status) {
            await Help.clearForm(this.form, this);
            await this.fetchItem();
            await success(this, data.message);
          }
        }
      } catch (error) {
        console.error(error);
      }
    },
    viewItem(item) {
      this.form = item;
      this.dialog = !this.dialog;
    },
    async submitForm() {
      var result;
      try {
        this.$v.form.$touch();
        if (!this.$v.form.$invalid) {
          if (this.update) {
            result = await this.$http.put(
              `packages/${this.form.id}`,
              this.form
            );
          } else {
            result = await this.$http.post("packages", this.form);
          }
          if (result.data.status) {
            this.dialogAdd = !this.dialogAdd;
            await Help.clearForm(this.form, this);
            await this.fetchItem();
            await success(this, result.data.message);
          }
        }
      } catch (error) {
        console.error(error);
      }
    },
  },
};
</script>

<style></style>
