<template>
  <v-row>
    <v-col cols="12" class="text-right">
      <v-btn
        depressed
        small
        color="secondary"
        class="px-4"
        @click="$emit('moveMerge', item)"
      >
        <v-icon left x-small>fal fa-arrows-alt</v-icon>
        {{ $t("move_table") }}
      </v-btn>
      <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            outlined
            small
            color="primary"
            class="px-4 mx-3"
            v-bind="attrs"
            v-on="on"
          >
            <v-icon left x-small> fal fa-cog</v-icon>
            {{ $t("manage") }}
          </v-btn>
        </template>
        <v-list dense>
          <v-list-item
            v-for="(val, i) in items"
            :key="i"
            @click="onConfirm(item.id, val.value, item.total_price)"
            dense
          >
            <v-list-item-icon>
              <v-icon x-small :color="val.color">
                {{ val.icon }}
              </v-icon>
            </v-list-item-icon>
            <v-list-item-title>{{ $t(val.text) }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>

      <v-btn
        depressed
        small
        color="primary"
        class="px-4"
        @click="checkBill('done', item.id, item.total_price)"
      >
        <v-icon left x-small>fal fa-receipt</v-icon>
        {{ $t("check_out") }}
      </v-btn>
    </v-col>
  </v-row>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      default: () => {},
    },
    items: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    onConfirm(id, value, total) {
      this.$emit("onConfirm", id, value, total);
    },
    checkBill(type, id, total) {
      this.$emit("checkBill", type, id, total);
    },
  },
};
</script>

<style></style>
