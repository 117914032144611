import Cookies from "js-cookie";
const BASEURL = process.env.VUE_APP_BASE_URL;
class Help {
  headerFile() {
    const token = Cookies.get("token") || null;
    const headers = {
      Authorization: `Bearer ${token}`,
      headers: { "content-type": "multipart/form-data" },
    };
    return headers;
  }
  // pluck = key => array => Array.from(new Set(array.map(obj => obj[key])));
  pluck(array, key) {
    return array.map((o) => o[key]);
  }
  getId(object) {
    if (typeof object === "object") {
      return object.id;
    } else {
      return object;
    }
  }

  objectsEqual(o1, o2) {
    Object.keys(o1).length === Object.keys(o2).length &&
      Object.keys(o1).every((p) => o1[p] === o2[p]);
  }

  arraysEqual(a, b) {
    if (a === b) return true;
    if (a == null || b == null) return false;
    if (a.length !== b.length) return false;

    // If you don't care about the order of the elements inside
    // the array, you should sort both arrays here.
    // Please note that calling sort on an array will modify that array.
    // you might want to clone your array first.

    for (var i = 0; i < a.length; ++i) {
      if (a[i] !== b[i]) return false;
    }
    return true;
  }
  ToArrayReq(data) {
    let arr = [];
    data.map((item, index) => {
      const data = {
        id: item.id,
        qty: item.qty,
        product_id: item.product_id.id,
        stock: item.product_id.stock + item.qty,
        total_price: item.product_id.price_sale * item.qty,
      };
      arr.push(data);
    });
    return arr;
  }

  salePro({ total_price, received, cart }, { customer_id, status }) {
    var items = [];
    var pStatus = false;
    if (status === "overdue" && received === total_price) {
      pStatus = true;
    } else {
      pStatus = false;
    }

    cart.map((item, index) => {
      if (received - item.price >= 0) {
        // enough
        // status to done
        const data = {
          txn: item.txn,
          id: item.id,
          qty: item.qty,
          discount: item.discount,
          customer_id: customer_id,
          total_price: item.price,
          status: pStatus
            ? "overdue"
            : received - item.price >= 0
            ? "done"
            : pStatus
            ? "overdue"
            : "done",
        };
        items.push(data);
      } else {
        // not enough
        // status to overdue
        const data = {
          txn: item.txn,
          id: item.id,
          discount: item.discount,
          qty: item.qty,
          customer_id: customer_id,
          total_price: item.price,
          status: "overdue",
        };
        items.push(data);
      }
    });
    return items;
  }

  // clearForm
  async clearForm(form, self) {
    Object.keys(form).forEach((key, index) => {
      if (typeof form[key] === "string") {
        self.form[key] = "";
      } else if (typeof form[key] === null) {
        self.form[key] = null;
      } else if (typeof form[key] === "number") {
        self.form[key] = 0;
      } else if (typeof form[key] === "") {
        self.form[key] = "";
      } else if (typeof form[key] === "null") {
        self.form[key] = "";
      }
    });
  }

  product(img) {
    if (img) {
      return `${BASEURL}/uploads/${img}`;
    } else {
      return "/images/product.png";
    }
  }
  img(img) {
    if (img === null || img === undefined) return "/images/pos.png";
    if ((img != null && img.includes("http")) || img.includes("https")) {
      return img;
    } else if (img) {
      return `${BASEURL}/uploads/${img}`;
    }
  }
  defaultImg(img) {
    if (img) {
      return img;
    } else {
      return "/images/upload-file.png";
    }
  }

  moneyFormat(num) {
    const currency = localStorage.getItem("currency") || "LAK";
    // "LAK", "THB", "USD"
    const currencies = [
      {
        text: "LAK",
        symbol: "₭",
      },
      {
        text: "THB",
        symbol: "฿",
      },
      {
        text: "USD",
        symbol: "$",
      },
    ];
    const find = currencies.find((val) => val.text === currency);
    if (num != null && num != undefined && find != undefined) {
      var p = Number(num).toFixed(2).split(".");
      const amt = p[0]
        .split("")
        .reverse()
        .reduce(function (acc, num, i, orig) {
          return num == "-" ? acc : num + (i && !(i % 3) ? "," : "") + acc;
        }, "");
      // if currency is LAK then symbol must be the end
      if (currency === "LAK") {
        return `${amt}${find.symbol}`;
      }
      return `${find.symbol}${amt}`;
    }
  }
  moneyFormatPrint(val) {
    // format number with commas and .00 format
    if (val === null || val === undefined) return "0.00";
    // if string convert to number
    if (typeof val === "string") {
      val = parseFloat(val);
    }
    return val
      .toFixed(2)
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  async cartArray(cart) {
    let arr = [];
    cart.map((val, index) => {
      const data = {
        product_id: val.id,
        stock: val.item.stock,
        qty: val.qty,
      };
      arr.push(data);
    });
    // find the cart each product in txn is not null
    const val = cart.find((val, index) => val.txn != null);
    return {
      cart: arr,
      txn: val?.txn ?? null,
    };
  }

  async tableOrder(cart) {
    let arr = [];
    cart.map((val, index) => {
      const data = {
        product_id: val.id,
        quantity: val.qty,
      };
      arr.push(data);
    });
    // find the cart each product in txn is not null
    const val = cart.find((val, index) => val.txn != null);
    return {
      arr,
      cart_id: val?.txn ?? null,
    };
  }

  async getDifferentMonth(prop, start_date, end_date) {
    var date1 = new Date(start_date);
    var date2 = new Date(end_date);
    const msg1 = prop.$t("start_date_should");
    const msg2 = prop.$t("end_date_should");
    const msg3 = prop.$t("start_date_and");
    // check val should be less than end_date if not throw error message "Start date should be less than end date"
    if (date1 > date2) {
      throw new Error(msg1);
    }
    // check val should be greater than startDate if not throw error message "End date should be greater than start date"
    if (date2 < date1) {
      throw new Error(msg2);
    }
    // startDate and endDate should be between 3 month
    if (this.diffMonths(date1, date2) > 3) {
      throw new Error(msg3);
    }
    // get the difference in months
    var diffMonths = (date2.getFullYear() - date1.getFullYear()) * 12;
    diffMonths -= date1.getMonth();
    diffMonths += date2.getMonth();
    return diffMonths <= 0 ? 0 : diffMonths;
  }

  diffMonths(dt2, dt1) {
    var diff = (dt2.getTime() - dt1.getTime()) / 1000;
    diff /= 60 * 60 * 24 * 7 * 4;
    return Math.abs(Math.round(diff));
  }
  pointFormat(num) {
    if (num != null && num != undefined) {
      // if num 1000 then return 1,000
      const point = num.toLocaleString();
      return point;
    }
    return 0;
  }
  addValueKey(menus) {
    let data = [];
    let trueFound = false;
    if (!menus || menus.length === 0 || menus === null) return data;
    for (let index = 0; index < menus.length; index++) {
      let element = menus[index];

      // If an element with `value` true has been found, assign false
      // to all following elements. Otherwise, try to assign true.
      if (trueFound) {
        element = {
          ...element,
          value: false,
        };
      } else {
        element = {
          ...element,
          value: element.menu.length > 0,
        };

        if (element.value) {
          trueFound = true;
        }
      }

      data.push(element);
    }
    return data;
  }

  menuCheck(menus, selected) {
    let tempSelected = [...selected];
    menus.forEach((item) => {
      if (item.menu.length) {
        let isSubIdInSelected = item.menu.some((sub) =>
          tempSelected.includes(sub.id)
        );
        let isItemIdInSelected = tempSelected.includes(item.id);
        if (isSubIdInSelected && !isItemIdInSelected) {
          // add item.id to tempSelected if any sub.id is in selected
          tempSelected.push(item.id);
        } else if (!isSubIdInSelected && isItemIdInSelected) {
          // remove item.id from tempSelected if no sub.id is in selected
          tempSelected = tempSelected.filter((id) => id !== item.id);
        }
      } else if (tempSelected.includes(item.id)) {
        // add item.id to tempSelected if it is in selected and it has no submenus
        tempSelected.push(item.id);
      }
    });
    // remove duplicate ids with sort a-z
    tempSelected = [...new Set(tempSelected)].sort((a, b) => a - b);
    return tempSelected;
  }

  GenCode() {
    const text = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
    let code = "";
    for (let i = 0; i < 12; i++) {
      code += text.charAt(Math.floor(Math.random() * text.length));
    }
    return code;
  }
  generateUUID() {
    var d = new Date().getTime();
    var uuid = "xxxxxxxxxxxx4xxxyxxxxxxxxxxxxxxx".replace(
      /[xy]/g,
      function (c) {
        var r = (d + Math.random() * 16) % 16 | 0;
        d = Math.floor(d / 16);
        return (c == "x" ? r : (r & 0x3) | 0x8).toString(16);
      }
    );
    return uuid;
  }
  stockArray(data) {
    let arr = [];
    data.map((val, index) => {
      const data = {
        product_id: val.id,
        qty: val.qty,
        price_buy: val.price_buy,
        price_sale: val.price_sale,
        vendor_id: val.vendor_id,
      };
      arr.push(data);
    });
    return arr;
  }
  async comparePrice(props, price_input, price) {
    const _cash_back = props.$t("cash_back");
    const _check_your_amount = props.$t("check_your_amount");
    const _full_payment = props.$t("full_payment");
    if (price_input >= price) {
      const format = this.NumberFormat(price_input - price);
      const msg =
        price_input - price === 0 ? _full_payment : `${_cash_back} ${format}`;
      return {
        status: true,
        total: this.NumberFormat(price),
        message: msg,
      };
    } else {
      const format = this.NumberFormat(price - price_input);
      return {
        status: false,
        total: this.NumberFormat(price),
        message: `${_check_your_amount} ${format}`,
      };
    }
  }

  NumberFormat(val) {
    // format number with commas and .00 format
    if (val === null || val === undefined) return "0.00";
    // if string convert to number
    if (typeof val === "string") {
      val = parseFloat(val);
    }
    return val
      .toFixed(2)
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  NumberPad(val) {
    // check if value contains . then add .00 to the end of the value, else return the value formatted
    if (val === null || val === undefined) return "0.00";
    // if string convert to number
    if (typeof val === "string") {
      val = parseFloat(val);
    }
    if (val.toString().includes(".")) {
      return val
        .toFixed(2)
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
    return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  async posSale(state, payload) {
    const { received, cart, total_discount, total_price } = state;
    const { customer_id, typeDiscount, status, type, note } = payload;
    // convert received to float
    let intReceived = parseFloat(received);
    var total_overdue = 0;
    if (status === "overdue" && intReceived < total_price) {
      total_overdue = total_price - intReceived;
    } else if (status === "overdue" && intReceived === total_price) {
      total_overdue = total_price;
      intReceived = 0;
    } else if (status === "done" && intReceived === total_price) {
      total_overdue = 0;
    } else if (status === "done" && intReceived > total_price) {
      total_overdue = 0;
      total_overdue = 0;
    }
    const totalDiscount = cart.map((e) => e.discount).reduce((a, b) => a + b);
    // get the first txn in cart
    const makeArray = this.salePro(state, payload);
    const totalPrice = makeArray
      .map((e) => e.total_price)
      .reduce((a, b) => a + b);
    const discount_type = typeDiscount === "amount" ? "amount" : "percentage";
    const res = {
      data: makeArray,
      total_discount: total_discount + totalDiscount,
      discount_type: discount_type,
      total_price: totalPrice,
      total_overdue: total_overdue,
      total_paid: intReceived,
      status: status,
      type: type ?? "cash",
      customer_id: customer_id,
      txn: cart[0].txn,
      note: note,
    };
    return res;
  }

  sizeValidate(file) {
    const type = typeof file;
    // if file is not contain http then return true
    if (type === "string" && !file.includes("http")) {
      return true;
    }
    // get image size from env
    const default_size = process.env.VUE_IMG_SIZE || 3; // default 2mb
    // 413 Payload Too Large max size 2mb
    // convert default_size to int
    const size = parseInt(default_size) * 1024 * 1024;
    if (file.size > size) {
      return false;
    } else {
      return true;
    }
  }
  playAudio() {
    const audio = new Audio("/audio/notify.wav");
    audio.play();
  }
  calTableBill(arr = []) {
    // loop each order in table if status is 'cancel' then skip it, and reduce the total
    const total = arr.reduce((acc, item) => {
      if (item.status !== "cancel") {
        return acc + item.total_price;
      } else {
        return acc;
      }
    }, 0);
    return total;
  }
  async mergeProduct(object) {
    // Check if product_id in history is the same, then merge the qty and total_price
    const result = object.history.reduce((acc, item) => {
      const foundIndex = acc.findIndex(
        (val) => val.product_id === item.product_id
      );
      if (foundIndex !== -1) {
        acc[foundIndex].qty += item.qty;
        acc[foundIndex].total_price += item.total_price;
      } else {
        acc.push(item);
      }
      return acc;
    }, []);
    object.items = result;
    return object;
  }

  reduceProduct({ history }) {
    // Check if product_id.id in history is the same, then merge the qty and total_price
    const result = history.reduce((acc, item) => {
      const foundIndex = acc.findIndex(
        (val) => val.product_id.id === item.product_id.id
      );
      if (foundIndex !== -1) {
        acc[foundIndex].qty += item.qty;
        acc[foundIndex].total_price += item.total_price;
      } else {
        acc.push(item);
      }
      return acc;
    }, []);
    return result;
  }

  paginate(item, next, prev) {
    // Extract all parameters from the first_page_url
    let url = new URL(item.first_page_url);
    let params = new URLSearchParams(url.search);
    // Convert params to an object for easier manipulation
    let paramObject = Object.fromEntries(params.entries());
    paramObject.page = next.page;
    paramObject.per_page = next.itemsPerPage;
    const baseURL = process.env.VUE_APP_BASE_URL;
    // get the http or https from baseURL
    const protocol = baseURL.split(":")[0];
    // Ensure the item.path is HTTPS
    let securePath = item.path.replace(/^http:/, `${protocol}:`);
    // Construct the new URL with all parameters
    let newUrl = new URL(securePath);
    newUrl.search = new URLSearchParams(paramObject).toString();

    return newUrl.toString();
  }

  async generateQuotation(q, cart) {
    const arr = cart.map((val) => ({
      product_id: val.id,
      quantity: val.qty,
    }));
    return {
      id: q.id ?? null,
      noted: q.note,
      expiry_date: q.expiry_date,
      customer_id: q.customer_id.id,
      include_tax: q.include_tax,
      currency: q.currency,
      document: q.document,
      quotation_details: arr,
    };
  }
}

export default Help = new Help();
