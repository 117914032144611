<template>
  <v-container fluid fill-height class="bg-white">
    <v-row justify="center" align="center">
      <v-col
        v-if="$vuetify.breakpoint.mdAndUp"
        cols="4"
        class="side-color bg-primary"
      >
        <v-card max-width="550" outlined color="transparent" flat>
          <v-img src="/images/register.png" />
        </v-card>
      </v-col>
      <v-col xs="12" sm="12" md="8" lg="8" xl="8">
        <v-btn color="primary" to="/login" fab outlined icon small>
          <v-icon small>fal fa-chevron-left</v-icon>
        </v-btn>
        <v-row justify="center">
          <v-col xs="12" sm="12" md="8" lg="6" xl="6">
            <div
              :class="$vuetify.breakpoint.mdAndUp ? 'mb-8' : ''"
              class="text-center"
            >
              <v-avatar size="120">
                <v-img src="/images/pos.png"></v-img>
              </v-avatar>
            </div>
            <v-form @submit.prevent="submitForm(form)">
              <v-card-text>
                <div class="text-center">
                  <h2 class="font-weight-black">ເປີດຮ້ານຂາຍສິນຄ້າ</h2>
                  <p class="py-3">ປ້ອນຂໍ້ມູນຮ້ານຄ້າຂອງທ່ານ</p>
                </div>

                <TTextField
                  :errorMessages="nameError"
                  label="store_name"
                  v-model="form.name"
                  @input="$v.form.name.$touch()"
                  @blur="$v.form.name.$touch()"
                />
                <FileInput label="store_logo" v-model="form.file" />
                <div class="pb-2">{{ $t("choose_type") }}</div>

                <v-radio-group
                  class="py-0 my-0"
                  v-model="form.store_type_code"
                  row
                >
                  <v-radio
                    v-for="(item, index) in store_type"
                    :key="index"
                    :label="item.text"
                    :value="item.value"
                  ></v-radio>
                </v-radio-group>

                <TTextField
                  :errorMessages="mobile_noError"
                  label="mobile_no"
                  v-model="form.mobile_no"
                  @input="$v.form.mobile_no.$touch()"
                  @blur="$v.form.mobile_no.$touch()"
                />
                <TTextField
                  :errorMessages="addressError"
                  label="address"
                  v-model="form.address"
                  @input="$v.form.address.$touch()"
                  @blur="$v.form.address.$touch()"
                />
              </v-card-text>
              <v-card-actions class="px-4">
                <v-btn type="submit" color="primary" large block>{{
                  $t("open_new_store")
                }}</v-btn>
              </v-card-actions>
            </v-form>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <!-- AppLoading -->
    <app-loading :overlay="overlay" />
  </v-container>
</template>

<script>
import { toast } from "@/controllers/alert";
import TTextField from "@/components/global/TextField";
import { error } from "@/controllers/alert";

import {
  required,
  maxLength,
  minLength,
  numeric,
} from "vuelidate/lib/validators";
import TextArea from "@/components/global/TextArea";
import FileInput from "@/components/global/FileInput";
import Cookies from "js-cookie";
import { STORE_TYPE } from "@/utils/mock";
export default {
  layout: "blank",
  components: { TTextField, TextArea, FileInput },
  data: () => ({
    overlay: false,

    form: {
      name: "",
      file: "",
      mobile_no: "",
      address: "",
      store_type_code: "POS",
    },
  }),
  validations: {
    form: {
      name: { required },
      mobile_no: {
        required,
        numeric,
        maxLength: maxLength(10),
        minLength: minLength(10),
      },
      address: { required },
    },
  },

  computed: {
    store_type() {
      return STORE_TYPE();
    },
    nameError() {
      const errors = [];
      if (!this.$v.form.name.$dirty) return errors;
      !this.$v.form.name.required && errors.push("ກະລຸນາປ້ອນຊື່!");
      return errors;
    },
    mobile_noError() {
      const errors = [];
      if (!this.$v.form.mobile_no.$dirty) return errors;
      !this.$v.form.mobile_no.required && errors.push("ກະລຸນາປ້ອນເບີໂທ!");
      if (!this.$v.form.mobile_no.numeric) {
        errors.push("ເບີໂທຕ້ອງເປັນຕົວເລກ!");
      }
      if (!this.$v.form.mobile_no.minLength) {
        errors.push("ເບີໂທຍ່າງໜ້ອຍຕ້ອງ 10 ຕົວເລກ ແລະ ຕ້ອງເລີມຕົ້ນດ້ວຍ20!");
      }
      if (!this.$v.form.mobile_no.maxLength) {
        errors.push("ເບີໂທຕ້ອງບໍເກີນ 10 ຕົວເລກ ແລະ ຕ້ອງເລີມຕົ້ນດ້ວຍ20!");
      }

      return errors;
    },
    addressError() {
      const errors = [];
      if (!this.$v.form.address.$dirty) return errors;
      !this.$v.form.address.required && errors.push("ກະລຸນາປ້ອນທີ່ຢູ່!");
      return errors;
    },
  },
  methods: {
    async submitForm(form) {
      try {
        this.$v.form.$touch();
        if (!this.$v.form.$invalid) {
          const user = localStorage.getItem("user");
          const token = Cookies.get("token");
          const parse = JSON.parse(user);
          this.overlay = true;
          // Form data
          const formData = new FormData();
          formData.append("name", form.name);
          if (form.file != "" && form.file != null) {
            const size_check = Help.sizeValidate(form.file);
            if (!size_check) {
              await error(this, "image_over_3mb");
              return;
            }
          }
          formData.append("file", form.file);
          formData.append("mobile_no", form.mobile_no);
          formData.append("address", form.address);
          formData.append("email", parse.email);
          formData.append("token", token);
          formData.append("store_type_code", form.store_type_code);
          const { data } = await this.$http.post("open_store", formData);
          if (data.status) {
            await toast(this, data.message);
            await this.$router.push("/open-branch");
          }
        }
      } catch (error) {
        this.overlay = false;
        console.log("submitForm ===> ", error);
      }
    },
  },
};
</script>

<style></style>
