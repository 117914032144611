<template>
  <section>
    <div class="py-4">
      <div class="row px-8 justify-content-between align-center">
        <div class="subtitle-1 font font-weight-bold grey--text">
          {{ $t("total") }}
        </div>
        <div class="subtitle-1 text-right">
          {{ defineStore.total_price | NumberFormat }} {{ currency }}
          <template v-if="defineStore.total_discount">
            <br />
            -{{ defineStore.total_discount | NumberFormat }} {{ currency }}
          </template>
        </div>
      </div>

      <v-expansion-panels flat>
        <v-expansion-panel>
          <v-expansion-panel-header color="transparent">
            <v-row align="center">
              <v-col cols="4">
                <div class="subtitle-1 font font-weight-bold">
                  {{ $t("total_price") }}
                </div>
              </v-col>
              <v-col cols="8" class="text-right">
                <div
                  class="font-weight-bold text-h5 justify-content-end primary--text"
                >
                  {{ total_price }}
                </div>
              </v-col>
            </v-row>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-row align="center">
              <v-col cols="8">
                <v-row align="center">
                  <v-col cols="4">
                    <div
                      class="subtitle-2 font-weight-bold font grey--text pr-1"
                    >
                      {{ $t("discount") }}
                    </div>
                  </v-col>
                  <v-col cols="8">
                    <div class="d-felx justify-center position-relative">
                      <v-card
                        @click="$emit('openDiscout')"
                        flat
                        class="px-2 py-1 row card-discount"
                      >
                        <v-icon x-small> fal fa-edit </v-icon>
                        <span class="px-2">
                          {{ total_discount }}
                        </span>
                      </v-card>
                      <v-icon
                        v-if="defineStore.total_discount"
                        small
                        color="red"
                        @click="removeDiscount"
                        class="remove-discount"
                      >
                        fas fa-times-circle
                      </v-icon>
                    </div>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="4" class="text-right">
                <v-btn-toggle
                  v-model="model"
                  active-class="primary"
                  mandatory
                  color="primary"
                >
                  <v-btn
                    small
                    v-for="(item, index) in discount_type"
                    :key="index"
                    :value="item.value"
                  >
                    <v-icon small> fal fa-{{ item.icon }} </v-icon>
                  </v-btn>
                </v-btn-toggle>
              </v-col>
            </v-row>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>

      <div class="row px-8 pt-1 justify-content-end">
        <div>
          {{ cartItemQty }}
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { myMixin } from "@/mixin/main";
import { mainStore } from "@/stores/modules/cart";

export default {
  mixins: [myMixin],
  props: {
    value: {},
    discount_type: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      model: this.value,
      toggle_exclusive: 1,
      defineStore: mainStore(),
    };
  },

  watch: {
    value(newVal, oldVal) {
      if (newVal !== oldVal) this.model = newVal;
    },
    model(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.$emit("input", newVal);
      }
    },
  },

  computed: {
    itemColorText() {
      // check the vuetify themes
      const themes = localStorage.getItem("darkMode");
      if (themes) {
        if (themes.length === 4) {
          return "white--text";
        } else {
          return "black--text";
        }
      }
      return "black--text";
    },
    total_price() {
      const { total_price, total_discount } = this.defineStore;
      const total = total_price - total_discount;
      const to_currency = this.$options.filters.NumberFormat(total);
      return `${to_currency} ${this.currency}`;
    },
    total_discount() {
      const { total_discount } = this.defineStore;
      if (total_discount === 0) return "0";
      const to_currency = this.$options.filters.NumberFormat(total_discount);
      return `- ${to_currency}`;
    },
  },
  methods: {
    removeDiscount() {
      this.defineStore.setDiscount(0);
    },
  },
};
</script>

<style lang="scss" scoped>
.remove-discount {
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  cursor: pointer;
  width: 30px;
  height: 30px;
}
.v-expansion-panels {
  padding: 0;
  margin: 0;
  .v-expansion-panel {
    background-color: transparent;
    .v-expansion-panel-header {
      padding-top: 5px;
      padding-bottom: 0px;
      .v-expansion-panel-header__content {
        padding: 0;
        .v-expansion-panel-header__icon {
          margin: 0;
        }
      }
    }
    .v-expansion-panel-content {
      padding: 0;
    }
  }
}
.card-discount.theme--light {
  background-color: #e0e0e0;
}

.card-discount.theme--dark {
  background-color: #252525;
}
</style>
