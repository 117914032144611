<template>
  <v-app>
    <v-app-bar app elevate-on-scroll flat height="65">
      <div class="container-xl d-flex align-center px-0">
        <v-app-bar-nav-icon @click="$router.go(-1)">
          <v-icon> fal fa-chevron-left </v-icon>
        </v-app-bar-nav-icon>
        <v-toolbar-title>ກະຕ່າ</v-toolbar-title>
      </div>
    </v-app-bar>
    <v-main>
      <div class="container-xl">
        <v-row>
          <v-col cols="12" md="6" class="mt-2">
            <v-card rounded="lg">
              <v-card-text class="pa-1" v-if="defineStore.cart.length">
                <v-list-item
                  v-for="(item, index) in defineStore.cart"
                  :key="index"
                  two-line
                >
                  <v-list-item-avatar tile>
                    <app-img :contain="true" :img="item.item.img" />
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title>{{ item.item.name }}</v-list-item-title>
                    <v-list-item-subtitle>
                      {{ item.qty }} x {{ item.price | NumberFormat }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-action>
                    <v-btn icon color="red" depressed @click="removeCart(item)">
                      <v-icon> fal fa-times </v-icon>
                    </v-btn>
                  </v-list-item-action>
                </v-list-item>
              </v-card-text>
              <v-card-text class="pa-12" v-else>
                <v-icon size="80" color="grey lighten-1">
                  fal fa-shopping-cart
                </v-icon>
                <h3 class="pt-4 pt-2">Cart is Empty!</h3>
                <p>
                  Looks like there is no item in your cart. Please try again
                </p>
                <v-btn
                  color="primary"
                  @click="$router.go(-1)"
                  depressed
                  class="mt-3"
                >
                  <v-icon left x-small> fal fa-arrow-left </v-icon>
                  Back
                </v-btn>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12" md="6" class="mb-12" v-if="defineStore.cart.length">
            <v-card rounded="lg">
              <v-card-text class="pa-2">
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>ຍອດຊຳລະ</v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-action>
                    <v-list-item-title
                      class="text-h6 font-weight-bold primary--text"
                    >
                      {{ total | NumberFormat }}
                    </v-list-item-title>
                  </v-list-item-action>
                </v-list-item>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </div>
    </v-main>
    <v-footer app light color="transparent" v-if="defineStore.cart.length">
      <div class="container-xl px-0">
        <v-btn
          class="mb-2"
          color="primary"
          block
          height="50px"
          @click="onConfirm"
        >
          ຢືນຢັນການສັ່ງ
        </v-btn>
      </div>
    </v-footer>
  </v-app>
</template>

<script>
import { myMixin } from "@/mixin/main";
import { toast, confirm } from "@/controllers/alert";
import { mainStore } from "@/stores/modules/cart";

export default {
  mixins: [myMixin],
  data() {
    return {
      defineStore: mainStore(),
      tables: [],
    };
  },
  computed: {
    total() {
      return this.defineStore.cart.reduce((a, b) => a + b.price, 0);
    },
  },
  methods: {
    async onConfirm() {
      const { isConfirmed } = await confirm({ props: this });
      if (isConfirmed) {
        this.checkOut();
      }
    },
    async checkOut() {
      try {
        const { branch_id, table_id } = this.$route.query;
        const { arr } = await Help.tableOrder(this.defineStore.cart);
        const body = {
          table_id: parseInt(table_id),
          branch_id: parseInt(branch_id),
          status_order: "merge",
          is_online_order: 1,
          items: arr,
        };
        const { data } = await this.$http.post(`/make_mart_order`, body);
        if (data.status) {
          this.defineStore.deleteCart();
          await toast(this, data.message);
          this.$router.go(-1);
        }
      } catch (error) {
        console.log(error);
      }
    },

    async removeCart(item) {
      const body = {
        that: this,
        payload: item,
      };
      this.defineStore.removeCart(body);
    },
  },
};
</script>

<style scoped></style>
