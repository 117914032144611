<template>
  <section>
    <v-card rounded="lg" color="transparent">
      <v-card-title class="pt-0"> {{ $t("export") }} </v-card-title>
      <v-card-text>
        <v-data-table
          :headers="headers"
          :items="items"
          :items-per-page="30"
          item-key="index"
        >
          <template v-slot:item.index="{ index }">
            {{ index + 1 }}
          </template>
          <template v-slot:item.icon="{ item }">
            <v-avatar size="35" color="#f5f5f5">
              <v-icon small>{{ item.icon }}</v-icon>
            </v-avatar>
          </template>

          <template v-slot:item.action="{ item }">
            <v-btn
              color="primary"
              depressed
              small
              @click="exportAction(item)"
              class="px-2"
            >
              <v-icon x-small left> fal fa-file-alt </v-icon>
              {{ $t("export") }}
            </v-btn>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>

    <app-custom-dialog
      v-model="dialog"
      :footer="true"
      title="export"
      @submit="submitForm"
    >
      <div slot="activator">
        <v-col cols="12">
          <div class="pb-2">{{ $t("start_date") }}</div>
          <v-menu
            v-model="start"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="start_date"
                filled
                flat
                rounded
                class="rounded-lg"
                :placeholder="$t('start_date')"
                prepend-inner-icon="fal fa-calendar-alt"
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="start_date"
              @input="start = false"
            ></v-date-picker>
          </v-menu>

          <div class="pb-2">ຈົນເຖີງ</div>
          <v-menu
            v-model="end"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="end_date"
                filled
                flat
                rounded
                class="rounded-lg"
                prepend-inner-icon="fal fa-calendar-alt"
                placeholder="ຈົນເຖີງ"
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="end_date"
              @input="end = false"
            ></v-date-picker>
          </v-menu>
        </v-col>
      </div>
    </app-custom-dialog>
  </section>
</template>

<script>
import { toast } from "@/controllers/alert";

export default {
  data: () => ({
    start_date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),
    end_date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),
    start: false,
    end: false,
    dialog: false,

    select: {
      title: "product",
      status: 1,
      api: "users-export",
      icon: "fal fa-shopping-cart",
    },
  }),
  computed: {
    headers() {
      return [
        { text: this.$t("no"), value: "index" },
        { text: this.$t("icon"), value: "icon" },
        { text: this.$t("info"), value: "title" },
        { text: this.$t("manage"), align: "right", value: "action" },
      ];
    },
    items() {
      return [
        {
          title: this.$t("product"),
          status: false,
          api: "products_export",
          icon: "fal fa-shopping-cart",
        },
        {
          title: this.$t("customer"),
          status: false,
          api: "customers_export",
          icon: "fal fa-users",
        },
        {
          title: this.$t("user"),
          status: false,
          api: "users_export",
          icon: "fal fa-user-cog",
        },
        {
          title: this.$t("products_notify"),
          status: false,
          api: "products_notify_export",
          icon: "fal fa-layer-group",
        },
        {
          title: this.$t("overdue"),
          status: false,
          api: "overdue_export",
          icon: "fal fa-receipt",
        },
        {
          title: this.$t("category"),
          status: false,
          api: "categories_export",
          icon: "fal fa-badge-check",
        },
        {
          title: this.$t("unit"),
          status: false,
          api: "product_size_export",
          icon: "fal fa-box",
        },
        {
          title: this.$t("expense_category"),
          status: false,
          api: "expense_category_export",
          icon: "fal fa-bookmark",
        },
        {
          title: this.$t("expense"),
          status: false,
          api: "expense_export",
          icon: "fal fa-wallet",
        },
        {
          title: this.$t("best_sale"),
          status: false,
          api: "product_best_sell_export",
          icon: "fal fa-box-open",
        },
        {
          title: this.$t("tickets"),
          status: false,
          api: "histories_export",
          icon: "fal fa-chart-bar",
        },
        // {
        //     title: this.$t("ປະຫວັດການສັ່ງຊື້"),
        //     status: false,
        //     api: "product_order_export",
        //     icon: "fal fa-file-invoice"
        // },
        {
          title: this.$t("user_sale"),
          status: false,
          api: "users_sale_export",
          icon: "fal fa-balance-scale",
        },
      ];
    },
  },
  methods: {
    async submitForm() {},
    async exportAction(item) {
      if (item.status) {
        this.dialog = !this.dialog;
      } else {
        const data = await this.$http.get(`${item.api}`, {
          responseType: "blob",
        });
        const url = window.URL.createObjectURL(new Blob([data.data]));
        const link = document.createElement("a");
        const date = new Date();
        const fileName = `${item.api}-${date.getDate()}-${
          date.getMonth() + 1
        }-${date.getFullYear()}.xlsx`;
        link.href = url;
        link.setAttribute("download", fileName);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        await toast(this, "downloaded_file_successfully");
      }
    },
  },
};
</script>

<style></style>
