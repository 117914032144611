<template>
  <section class="bg-white py-12">
    <div class="container-xl pt-8 pb-12">
      <div class="d-flex pb-8">
        <v-icon> fal fa-store-alt </v-icon>
        <h4 class="ml-4">ປະເພດຮ້ານຄ້າ</h4>
      </div>
      <v-row>
        <v-col
          xs="12"
          sm="12"
          md="6"
          lg="3"
          cols="12"
          v-for="(item, index) in items"
          :key="index"
        >
          <v-card rounded="xl">
            <v-card-text class="px-0">
              <v-list-item three-line>
                <v-list-item-content>
                  <div class="text-overline mb-4 font-weight-regular">ຮ້ານ</div>
                  <v-list-item-title class="text-h5 mb-1 font-weight-regular">
                    {{ item.title }}
                  </v-list-item-title>
                  <v-list-item-subtitle class="font-weight-light">
                    {{ item.desc }}
                  </v-list-item-subtitle>
                </v-list-item-content>

                <v-list-item-avatar size="50" :color="item.color" rounded="xl">
                  <v-icon color="white">{{ item.icon }}</v-icon>
                </v-list-item-avatar>
              </v-list-item>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </div>
  </section>
</template>

<script>
export default {
  data() {
    return {
      items: [
        {
          title: "ຂາຍເຄື່ອງ",
          icon: "fal fa-shopping-bag",
          desc: "ຮ້ານຂາຍສົ່ງ, ຮ້ານຂາຍຍ່ອຍ",
          name_en: "shop",
          color: "#4CAF50 ",
        },
        {
          title: "ອາຫານ",
          icon: "fal fa-utensils",
          desc: "ຮ້ານອາຫານ",
          name_en: "Restaurant",
          color: "#D32F2F",
        },
        {
          title: "ກາເຝ",
          icon: "fal fa-coffee",
          desc: "ຮ້ານກາເຝ",
          name_en: "Coffee",
          color: "#795548",
        },
        {
          title: "ຂາຍຢາ",
          icon: "fal fa-first-aid",
          desc: "ຮ້ານຂາຍຢາ",
          name_en: "Drugstore",
          color: "#2196F3",
        },
      ],
    };
  },
};
</script>

<style></style>
