<template>
  <section>
    <data-table
      :headers="headers"
      :items="overdueProducts"
      commit="SET_OVERDUE"
      :viewItem="viewItem"
      :editItem="editItem"
    >
      <div slot="activator" class="row">
        <div class="col-sm-12 col-md-6 col-lg-3">
          <v-card-title class="px-0 pt-0">
            {{ $t("overdue") }}
          </v-card-title>
        </div>
        <div class="col-sm-6 col-md-6 col-lg-2">
          <DatePicker
            :dense="true"
            :placeholder="false"
            label="start_date"
            v-model="start_date"
          />
        </div>
        <div class="col-sm-6 col-md-6 col-lg-2">
          <DatePicker
            :dense="true"
            :placeholder="false"
            label="end_date"
            v-model="end_date"
          />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-1">
          <v-btn
            depressed
            outlined
            class="mr-2"
            color="primary"
            @click="filterDate()"
          >
            <v-icon left small>fal fa-search</v-icon>
            {{ $t("filter") }}
          </v-btn>
        </div>
        <v-spacer />
        <div class="col-sm-12 col-md-6 col-lg-3">
          <TextSearch
            v-model="search"
            @search="fetchItem()"
            @onClear="
              search = '';
              fetchItem();
            "
          />
        </div>
        <v-spacer />
      </div>
    </data-table>
    <app-custom-dialog
      width="900px"
      v-model="dialog"
      :footer="false"
      title="description"
    >
      <div slot="activator">
        <AppDetail :item="data" />
      </div>
    </app-custom-dialog>
    <app-custom-dialog
      v-model="dialogAdd"
      :footer="true"
      :title="titleStatus"
      @submit="submitForm"
    >
      <div slot="activator">
        <p>
          {{ $t("grand_total") }}
          {{ form.total_price | NumberFormat }}
        </p>
        <p>
          {{ $t("total_paid") }}
          {{ form.total_paid | NumberFormat }}
        </p>
        <p>
          {{ $t("total_overdue") }}
          {{ total_overdue | NumberFormat }}
        </p>

        <VuetifyMoney
          label="amount"
          typeShow="number"
          :maxValue="123"
          v-model.number="form.total_overdue"
        />
      </div>
    </app-custom-dialog>
    <!-- AppLoading -->
    <app-loading :overlay="overlay" />
  </section>
</template>

<script>
import DataTable from "@/components/DataTable";
import { mapGetters, mapActions } from "vuex";
import { overdueHead } from "@/utils/head";
import AppDetail from "@/components/products/overdue";
import { myMixin } from "@/mixin/main";
import CurrencyTextField from "@/components/global/currency-text-field";
import { success } from "@/controllers/alert";
import VuetifyMoney from "@/components/VuetifyMoney";
import { error } from "@/controllers/alert";
// get today date
const today = new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
  .toISOString()
  .substr(0, 10);

// get tomorrow date
const tomorrow = new Date(
  Date.now() - new Date().getTimezoneOffset() * 60000 + 86400000
)
  .toISOString()
  .substr(0, 10);

export default {
  mixins: [myMixin],

  data: () => ({
    update: false,
    overlay: false,
    search: "",
    dialogAdd: false,
    dialog: false,
    start_date: today,
    end_date: tomorrow,
    total_overdue: 0,
    data: {},
    form: {},
  }),
  async created() {
    await this.fetchItem();
  },
  components: {
    DataTable,
    CurrencyTextField,
    VuetifyMoney,
    AppDetail,
  },
  watch: {},
  computed: {
    ...mapGetters({
      overdueProducts: "index/overdueProducts",
    }),

    titleStatus() {
      return this.update ? "update" : "new_data";
    },
    headers() {
      return overdueHead(this);
    },
  },

  methods: {
    ...mapActions({
      fetch: "index/fetch",
    }),
    async fetchItem() {
      const body = {
        that: this,
        url: `product_overdue?start_date=${this.start_date}&end_date=${this.end_date}&q=${this.search}`,
        commit: "SET_OVERDUE",
      };
      await this.fetch(body);
    },

    async viewItem({ id }) {
      try {
        const { data } = await this.$http.get(`product_overdue_detail/${id}`);
        if (data.status) {
          this.data = data.data;
          this.dialog = true;
        }
      } catch (error) {
        console.log("error", error);
      }
    },
    editItem(item) {
      this.update = true;
      this.form = item;
      this.form.total_paid = item.total_paid;
      this.total_overdue = item.total_overdue;
      this.dialogAdd = !this.dialogAdd;
    },
    async submitForm() {
      try {
        const form = {
          ticket_id: this.form.id,
          total_paid: this.form.total_overdue,
        };
        const { data } = await this.$http.post("product_payment", form);
        if (data.status) {
          this.dialogAdd = !this.dialogAdd;
          await Help.clearForm(this.form, this);
          await this.fetchItem();
          await success(this, data.message);
        }
      } catch (error) {
        console.log("error", error);
      }
    },
    async filterDate(start_date, end_date) {
      try {
        await Help.getDifferentMonth(this, start_date, end_date);
        this.overlay = true;
        // setTimeOut for 1 second to show overlay
        setTimeout(() => {
          this.fetchItem();
          this.overlay = false;
        }, 1000);
      } catch (err) {
        console.log("error", err.message);
        error(this, err.message);
      }
    },
  },
};
</script>

<style></style>
