import Vue from "vue";
import axios from "axios";
import { toast } from "@/controllers/alert";
import Cookies from "js-cookie";
const user = localStorage.getItem("user") || null;
const token = Cookies.get("token") || null;
const isLogged = Cookies.get("isLoggedIn");
const locale = localStorage.getItem("locale") || "la";
// state
export const state = {
  token: token,
  user: user ? JSON.parse(user) : {},
  isLoggedIn: isLogged ? JSON.parse(isLogged) : false,
  store_type: null, // 'POS'" // RESTAURANT,
  locale: locale,
  tax_include: 0,
  tax: 10,
  locales: [
    {
      code: "en",
      name: "English",
      flag: "/images/us.svg",
    },
    {
      code: "la",
      name: "ລາວ",
      flag: "/images/la.svg",
    },
  ],
};
// getters
export const getters = {
  locales: (state) => state.locales,
  isLoggedIn: (state) => state.isLoggedIn,
  user: (state) => state.user,
  token: (state) => state.token,
  store_type: (state) => state.user.stores.store_type_code,
  tax_include: (state) => state.user.stores.tax_include,
  tax: (state) => `(${state.user.stores.tax}%)`,
  tax_rate: (state) => state.user.stores.tax,
};

// mutations
export const mutations = {
  SET_TOKEN(state, payload) {
    state.isLoggedIn = true;
    state.token = payload;
  },
  LOGOUT(state) {
    state.token = "";
    state.user = {};
    state.isLoggedIn = false;
  },
  SET_USER(state, payload) {
    state.user = payload;
    localStorage.setItem("user", JSON.stringify(payload));
  },
};

// actions
export const actions = {
  async logout({ commit }, props) {
    try {
      await props.$http.post("logout");
      Cookies.remove("token");
      Cookies.remove("isLoggedIn");
      Cookies.remove("email");
      localStorage.removeItem("expired_at");
      localStorage.removeItem("user");
      localStorage.removeItem("selectedItem");
      delete props.$http.defaults.headers.common["Authorization"];
      await commit("LOGOUT");
      await toast(props, "logout_successfully");
      window.location.href = "/";
    } catch (e) {
      console.log(e);
    }
  },
  async clearUser({ commit }) {
    try {
      const self = Vue.prototype;
      await commit("LOGOUT");
      Cookies.remove("token");
      Cookies.remove("isLoggedIn");
      localStorage.removeItem("user");
      localStorage.removeItem("selectedItem");
      delete self.$http.defaults.headers.common["Authorization"];
      await self.$http.post("logout");
      window.location.href = "/";
    } catch (e) {
      console.log(e);
    }
  },
  async setDevice(context, token) {
    try {
      const self = Vue.prototype;
      await self.$http.post("/set_device_token", {
        device_token: token,
      });
    } catch (e) {
      console.log(e);
    }
  },
  async removeAuth({ commit }) {
    try {
      await commit("LOGOUT");
      Cookies.remove("token");
      Cookies.remove("isLoggedIn");
      localStorage.removeItem("user");
      localStorage.removeItem("selectedItem");
      delete axios.defaults.headers.common["Authorization"];
    } catch (e) {
      console.log(e);
    }
  },
};
