<template>
  <v-list>
    <v-subheader>{{ $t("choose_customer") }}</v-subheader>
    <div class="px-6">
      <v-text-field
        v-model="model"
        outlined
        flat
        :placeholder="$t('search') + '...'"
        dense
        hide-details
        append-icon="fal fa-search"
      />
    </div>

    <v-list-item
      v-for="(item, key) in customers"
      :key="key"
      @click="chooseCustomer(item)"
    >
      <v-list-item-avatar>
        <v-avatar size="32px" tile>
          <v-icon>fal fa-user-circle</v-icon>
        </v-avatar>
      </v-list-item-avatar>
      <v-list-item-content>
        <v-list-item-title>{{ item?.first_name ?? "" }}</v-list-item-title>
        <v-list-item-subtitle>{{
          item.phone_no + " (" + moneyFormatPrint(item.point) + ")"
        }}</v-list-item-subtitle>
      </v-list-item-content>
      <v-list-item-action>
        <v-icon v-if="customer_id === item.id" color="green accent-2"
          >fal fa-check-circle</v-icon
        >
      </v-list-item-action>
    </v-list-item>
  </v-list>
</template>

<script>
import { myMixin } from "@/mixin/main";

export default {
  mixins: [myMixin],
  props: {
    value: {},
    customers: {
      type: Array,
      default: () => [],
    },
    customer_id: {},
  },
  data() {
    return {
      model: this.value,
    };
  },

  watch: {
    value(newVal, oldVal) {
      if (newVal !== oldVal) this.model = newVal;
    },
    model(newVal, oldVal) {
      if (newVal !== oldVal) this.$emit("input", newVal);
    },
  },

  methods: {
    chooseCustomer(item) {
      this.$emit("chooseCustomer", item);
    },
  },
};
</script>

<style></style>
