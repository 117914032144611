<template>
  <section>
    <data-table
      :headers="headers"
      :items="category"
      commit="SET_CATEGORY"
      :viewItem="viewItem"
      :editItem="editItem"
      :deleteItem="deleteItem"
    >
      <div slot="activator" class="row justify-content-between">
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-2">
          <v-card-title class="px-0 pt-0"> {{ $t("category") }} </v-card-title>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-4">
          <TextSearch
            v-model="search"
            @search="fetchItem()"
            @onClear="
              search = '';
              fetchItem();
            "
          />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-2 text-end">
          <v-btn depressed color="primary" @click="newItem">
            <v-icon left>fal fa-plus-circle</v-icon>
            {{ $t("new_data") }}
          </v-btn>
        </div>
      </div>
    </data-table>
    <app-custom-dialog
      v-model="dialog"
      :footer="false"
      title="description"
      @submit="submitForm"
    >
      <div slot="activator">
        <v-row>
          <v-col cols="4">{{ $t("id") }}</v-col>
          <v-col cols="8">{{ form.id }}</v-col>
          <v-col cols="4">{{ $t("name") }}</v-col>
          <v-col cols="8">{{ form.name }}</v-col>
          <v-col cols="4">{{ $t("date") }}</v-col>
          <v-col cols="8">{{ form.created_at | moment }}</v-col>
        </v-row>
      </div>
    </app-custom-dialog>
    <app-custom-dialog
      v-model="dialogAdd"
      :footer="true"
      :title="titleStatus"
      @submit="submitForm"
    >
      <div slot="activator">
        <TTextField
          :errorMessages="nameError"
          label="name"
          v-model="form.name"
          @input="$v.form.name.$touch()"
          @blur="$v.form.name.$touch()"
        />
      </div>
    </app-custom-dialog>
    <!-- AppLoading -->
    <app-loading :overlay="overlay" />
  </section>
</template>

<script>
import DataTable from "@/components/DataTable";
import { mapGetters, mapActions } from "vuex";
import { expenseCategory } from "@/utils/head";
import TTextField from "@/components/global/TextField";
import { expenseCatValidate } from "@/validations/shop";
import { success, confirm } from "@/controllers/alert";
import { myMixin } from "@/mixin/main";

export default {
  mixins: [expenseCatValidate, myMixin],

  data: () => ({
    update: false,
    dialogAdd: false,
    dialog: false,
    overlay: false,
    search: "",

    form: {},
  }),
  async created() {
    await this.fetchItem();
  },
  components: { DataTable, TTextField },
  computed: {
    ...mapGetters({
      category: "index/category",
    }),
    headers() {
      return expenseCategory(this);
    },

    titleStatus() {
      return this.update ? "update" : "new_data";
    },
  },

  methods: {
    ...mapActions({
      fetch: "index/fetch",
    }),
    async fetchItem() {
      const body = {
        that: this,
        url: `expense_category_paginate?q=${this.search}`,
        commit: "SET_CATEGORY",
      };
      await this.fetch(body);
    },

    async newItem() {
      await Help.clearForm(this.form, this);
      await this.fetchItem();
      this.update = false;
      this.dialogAdd = !this.dialogAdd;
    },
    editItem(item) {
      this.update = true;
      this.form = item;
      this.dialogAdd = !this.dialogAdd;
    },
    async deleteItem({ id }) {
      const { isConfirmed } = await confirm({
        props: this,
        text: "want_to_delete",
      });
      if (isConfirmed) {
        const { data } = await this.$http.delete(`expense_category/${id}`);
        if (data.status) {
          await Help.clearForm(this.form, this);
          await this.fetchItem();
          await success(this, data.message);
        }
      }
    },
    viewItem(item) {
      this.form = item;
      this.dialog = !this.dialog;
    },
    async submitForm() {
      var result;
      try {
        this.$v.form.$touch();
        if (!this.$v.form.$invalid) {
          if (this.update) {
            result = await this.$http.put(
              `expense_category/${this.form.id}`,
              this.form
            );
          } else {
            result = await this.$http.post("expense_category", this.form);
          }
        }
        if (result.data.status) {
          this.dialogAdd = !this.dialogAdd;
          await Help.clearForm(this.form, this);
          await this.fetchItem();
          await success(this, result.data.message);
        }
      } catch (error) {
        console.log("error", error);
      }
    },
  },
};
</script>

<style></style>
