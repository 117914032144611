import Vue from "vue";
import VueI18n from "vue-i18n";

// Importing locales
import en from "@/locales/en.json";
import la from "@/locales/la.json";

Vue.use(VueI18n);

const messages = {
  en: en,
  la: la,
};
// get saved locale from local storage
const savedLocale = localStorage.getItem("locale") || "la";
// Create VueI18n instance with options
const i18n = new VueI18n({
  locale: savedLocale,
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || "la",
  messages: messages,
});
export default i18n;
