<template>
  <section>
    <v-row>
      <v-col cols="12">
        <v-card class="rounded-lg">
          <v-card-title>
            <span class="text-capitalize font-weight-bold">
              <v-icon left small> fal fa-box-open </v-icon>
              {{ $t("package") }}
            </span>
            <v-spacer />
            <v-btn text color="primary" @click="fetchHistoryPayment()">
              {{ $t("payment_history") }}
            </v-btn>
          </v-card-title>
          <v-card-text>
            <v-simple-table v-if="!show_histories">
              <template v-slot:default>
                <thead>
                  <tr>
                    <th>{{ $t("package") }}</th>
                    <th>{{ $t("month") }}</th>
                    <th>{{ $t("product") }}</th>
                    <th>{{ $t("bill") }}</th>
                    <th>{{ $t("user") }}</th>
                    <th class="text-right">{{ $t("branch") }}</th>
                    <th class="text-right">{{ $t("price") }}</th>
                    <th class="text-right">{{ $t("purchase") }}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(item, index) in items" :key="index">
                    <td>
                      <v-chip
                        label
                        dark
                        :color="packageName(item.name).color"
                        small
                        :class="index == 0 ? 'black--text' : 'white--text'"
                      >
                        {{ packageName(item.name).text }}</v-chip
                      >
                    </td>

                    <td>{{ item.month_count }}</td>
                    <td>
                      {{ product_count(item.product_count) }}
                    </td>
                    <td>
                      {{ bill_count(item.bill_count) }}
                    </td>
                    <td>{{ user_count(item.user_count) }}</td>
                    <td class="text-right">
                      {{ branch_count(item.branch_count) }}
                    </td>
                    <td class="text-right">
                      {{ price_show(index, item.price) }}
                    </td>
                    <td class="text-right">
                      <v-tooltip bottom v-if="MyPackage(item.id)">
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            v-bind="attrs"
                            v-on="on"
                            fab
                            depressed
                            x-small
                            color="success"
                            @click="$emit('makeOrder', item.id)"
                          >
                            <v-icon x-small> fal fa-check </v-icon>
                          </v-btn>
                        </template>
                        <span>{{ $t("current_package") }}</span>
                      </v-tooltip>
                      <template v-else-if="checkMyPackage(item.id)">
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              v-bind="attrs"
                              v-on="on"
                              fab
                              depressed
                              x-small
                            >
                              <v-icon color="warning" x-small>
                                fal fa-ellipsis-h
                              </v-icon>
                            </v-btn>
                          </template>
                          <span>{{ $t("checking") }}</span>
                        </v-tooltip>
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              v-bind="attrs"
                              v-on="on"
                              @click="$emit('cancelOrder', item.id)"
                              fab
                              depressed
                              x-small
                            >
                              <v-icon color="red" x-small>
                                fal fa-times
                              </v-icon>
                            </v-btn>
                          </template>
                          <span>{{ $t("cancel_order") }}</span>
                        </v-tooltip>
                      </template>
                      <v-tooltip v-else bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            v-bind="attrs"
                            v-on="on"
                            fab
                            depressed
                            x-small
                            @click="$emit('makeOrder', item.id)"
                          >
                            <v-icon x-small> fal fa-shopping-cart </v-icon>
                          </v-btn>
                        </template>
                        <span>{{ $t("package_order") }}</span>
                      </v-tooltip>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
            <v-simple-table v-else>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th>{{ $t("id") }}</th>
                    <th>{{ $t("package") }}</th>
                    <th>{{ $t("status") }}</th>
                    <th class="text-right">{{ $t("price") }}</th>
                    <th class="text-right">{{ $t("owner_order") }}</th>
                    <th class="text-right">{{ $t("date") }}</th>
                    <th class="text-right">{{ $t("bill") }}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(item, index) in history_payment" :key="index">
                    <td>{{ index + 1 }}</td>
                    <td>
                      <v-chip
                        label
                        dark
                        :color="packageName(item.package.name).color"
                        small
                        class="text-capitalize"
                        :class="index == 0 ? 'black--text' : 'white--text'"
                      >
                        {{ packageName(item.package.name).text }}</v-chip
                      >
                    </td>
                    <td>
                      <v-chip
                        label
                        dark
                        small
                        class="text-capitalize"
                        :color="colorsStatus(item.status)"
                      >
                        {{ $t(item.status) }}
                      </v-chip>
                    </td>
                    <td class="text-right">
                      {{ item.price | NumberFormat }}
                    </td>
                    <td class="text-right">{{ item.user.first_name }}</td>
                    <td class="text-right">
                      {{ item.created_at | moment }}
                    </td>
                    <td class="text-right" @click="printInvoice(item)">
                      <v-btn fab small depressed>
                        <v-icon small> fal fa-receipt</v-icon>
                      </v-btn>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-card-text>
          <AppInvoice v-show="print" :item="printBill" /> </v-card
      ></v-col>
      <v-col cols="12">
        <v-card class="rounded-lg">
          <v-card-title>
            <span class="text-capitalize font-weight-bold">
              <v-icon left small> fal fa-credit-card </v-icon>
              {{ $t("payment_method") }}
            </span>
            <v-spacer />
          </v-card-title>
          <v-card-text>
            <v-row align="center">
              <v-col cols="5">
                <v-img width="250" height="250" src="/images/qr.png" />
              </v-col>
              <v-col cols="7" class="px-0">
                <v-list-item class="px-0">
                  <v-badge
                    bordered
                    bottom
                    color="green"
                    dot
                    offset-x="25"
                    offset-y="20"
                  >
                    <v-list-item-avatar width="50" height="50">
                      <v-avatar color="#f0f0f0">
                        <v-img src="/images/bcel.png" />
                      </v-avatar>
                    </v-list-item-avatar>
                  </v-badge>

                  <v-list-item-content>
                    <v-list-item-title>
                      ທະນາຄານ ການຄ້າຕ່າງປະເທດລາວ ມະຫາຊົນ
                    </v-list-item-title>
                    <v-list-item-subtitle class="text-uppercase">
                      Banque Pour Le Commerce Exterieur Lao
                      Public</v-list-item-subtitle
                    >
                  </v-list-item-content>
                </v-list-item>
                <v-divider />
                <v-row>
                  <v-col cols="5">{{ $t("account_name") }}</v-col>
                  <v-col cols="7"> {{ account.name }} </v-col>
                  <v-col cols="5">{{ $t("account_number") }}</v-col>
                  <v-col cols="7">
                    {{ account.number }}
                    <v-icon class="ml-2" x-small @click="copy(account.number)"
                      >fal fa-copy</v-icon
                    ></v-col
                  >
                  <v-col cols="5">{{ $t("account_currency") }}</v-col>
                  <v-col cols="7"> {{ account.currency }}</v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </section>
</template>

<script>
import { myMixin } from "@/mixin/main";
import AppInvoice from "@/components/print/invoice";

export default {
  components: {
    AppInvoice,
  },
  data() {
    return {
      print: false,
      show_histories: false,
      history_payment: [],
      printBill: {
        package: {
          name: "",
        },
      },
      account: {
        name: "VONGKEO KEOSAVANH",
        number: "092-12-13357515",
        currency: "LAK",
      },
    };
  },
  props: {
    items: {
      type: Array,
      default() {
        return [];
      },
    },
    myOrder: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  mixins: [myMixin],
  methods: {
    MyPackage(packageId) {
      const { stores } = this.user;
      return stores.package_id === packageId;
    },
    checkMyPackage(id) {
      if (this.myOrder) {
        return this.myOrder.package_id === id;
      }
      return false;
    },
    colorsStatus(status) {
      if (status === "pending") {
        return "warning";
      } else if (status === "processing") {
        return "info";
      } else if (status === "completed") {
        return "green accent-3";
      } else if (status === "decline") {
        return "error";
      } else if (status === "cancel") {
        return "error";
      }
    },

    async fetchHistoryPayment() {
      try {
        this.show_histories = !this.show_histories;
        const { data } = await this.$http.get("/history_payment");
        if (data.status) {
          this.history_payment = data.data.data;
        }
      } catch (error) {
        console.log(error);
      }
    },
    printInvoice(item) {
      this.printBill = item;
      setTimeout(() => {
        this.$htmlToPaper("invoice");
      }, 500);
    },
    copy(val) {
      // remove - from string
      const str = val.replace(/-/g, "");
      // create a textarea
      this.copyToClipboard(str);
    },
  },
};
</script>

<style></style>
