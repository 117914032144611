<template>
  <div>
    <div v-if="placeholder" class="pb-2">{{ $t(label) }}</div>
    <v-menu
      ref="menu"
      v-model="menu"
      :close-on-content-click="false"
      transition="scale-transition"
      offset-y
      min-width="auto"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-text-field
          v-model="model"
          readonly
          v-bind="attrs"
          v-on="on"
          :placeholder="$t(label)"
          :rounded="rounded"
          :flat="flat"
          :outlined="outlined"
          :dense="dense"
          :disabled="disabled"
          :hide-details="hideDetails"
          :error-messages="errorMessages"
          :counter="counter"
          :filled="filled"
        >
          <template v-slot:append>
            <v-icon small>fal fa-calendar-alt</v-icon>
          </template>
        </v-text-field>
      </template>
      <v-date-picker
        v-model="model"
        no-title
        scrollable
        @input="menu = false"
      />
    </v-menu>
    <div v-if="hideDetails" class="mb-2" />
  </div>
</template>

<script>
export default {
  props: {
    value: {},
    placeholder: { type: Boolean, default: true },
    label: { type: String, require: true },
    flat: { type: Boolean, default: false },
    errorMessages: { type: Array, require: true },
    outlined: { type: Boolean, default: true },
    dense: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    counter: { type: Boolean, default: false },
    filled: { type: Boolean, default: false },
    rounded: { type: Boolean, default: false },
  },
  data() {
    return {
      menu: false,
      model: this.value,
    };
  },

  watch: {
    value(newVal, oldVal) {
      if (newVal !== oldVal) this.model = newVal;
    },
    model(newVal, oldVal) {
      if (newVal !== oldVal) this.$emit("input", newVal);
    },
  },
  computed: {
    hideDetails() {
      if (this.errorMessages && this.errorMessages.length) {
        return false;
      }
      return true;
    },
  },
};
</script>
