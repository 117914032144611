<template>
  <section>
    <data-table
      :headers="headers"
      :items="users"
      commit="SET_USERS"
      :viewItem="viewItem"
    >
      <div slot="activator" class="row justify-content-between">
        <v-col cols="2">
          <v-card-title class="px-0 pt-0"> Users </v-card-title>
        </v-col>
      </div>
    </data-table>
    <app-custom-dialog v-model="dialog" :footer="false" title="Details">
      <div slot="activator">
        <v-row>
          <v-col cols="4">ID</v-col>
          <v-col cols="8">{{ form.id }}</v-col>
          <v-col cols="4">First Name</v-col>
          <v-col cols="8">{{ form.first_name }}</v-col>
          <v-col cols="4">Last Name</v-col>
          <v-col cols="8">{{ form.last_name }}</v-col>
          <v-col cols="4">Phone No</v-col>
          <v-col cols="8">{{ form.phone_no }}</v-col>
          <v-col cols="4">Role</v-col>
          <v-col cols="8">
            <v-chip
              dark
              :color="RoleColor(form.role)"
              small
              class="text-capitalize"
              label
            >
              {{ userRole(form.role) }}</v-chip
            >
          </v-col>
          <v-col cols="4">Date</v-col>
          <v-col cols="8">{{ form.created_at | moment }}</v-col>
        </v-row>
      </div>
    </app-custom-dialog>
  </section>
</template>

<script>
import DataTable from "@/components/DataTable";
import { mapGetters, mapActions } from "vuex";
import TTextField from "@/components/global/TextField";
import { AddUserStore } from "@/validations/shop";
import TextSelect from "@/components/global/TextSelect";
import TPassword from "@/components/global/TextPassword";
import { myMixin } from "@/mixin/main";

export default {
  mixins: [AddUserStore, myMixin],
  data: () => ({
    dialog: false,
    _id: null,
  }),
  async created() {
    await this.fetchItem();
  },
  components: {
    DataTable,

    TTextField,
    TextSelect,
    TPassword,
  },
  computed: {
    ...mapGetters({
      users: "index/users",
    }),
  },

  methods: {
    ...mapActions({
      fetch: "index/fetch",
    }),

    async fetchItem() {
      const { store_id } = this.$route.params;
      const body = {
        that: this,
        url: `get_shop_users/${store_id}`,
        commit: "SET_USERS",
      };
      await this.fetch(body);
    },

    async viewItem({ id }) {
      try {
        const { data } = await this.$http.get(`get_user_detail/${id}`);
        this.form = data.data;
        this.dialog = !this.dialog;
      } catch (error) {
        console.log("error", error);
      }
    },
  },
};
</script>

<style></style>
