<template>
  <section class="bg-white py-12">
    <div class="container-xl pt-8 pb-12">
      <div class="row pb-8 justify-content-between">
        <div class="d-flex pt-4">
          <v-icon> fal fa-play </v-icon>
          <h4 class="ml-4">ຄຣິບແນະນຳ</h4>
        </div>
        <v-btn rounded depressed @click="openYoutube">
          <v-icon left x-small> fal fa-layer-group </v-icon>
          Youtube
        </v-btn>
      </div>
      <v-row>
        <v-col
          xs="12"
          sm="12"
          md="6"
          cols="12"
          lg="4"
          v-for="(item, index) in items"
          :key="index"
        >
          <v-card flat class="rounded-xl" color="#f8f5fd">
            <v-card-text class="pb-0">
              <LazyYoutube
                :src="'https://www.youtube.com/embed/' + item.video"
              />
            </v-card-text>
            <v-card-title class="pb-0">{{ item.title }}</v-card-title>

            <v-card-text>
              <v-chip-group
                active-class="deep-purple accent-4 white--text"
                column
              >
                <v-chip v-for="(data, key) in item.desc" :key="key">{{
                  data
                }}</v-chip>
              </v-chip-group>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </div>
  </section>
</template>

<script>
import { LazyYoutube, LazyVimeo } from "vue-lazytube";

export default {
  components: {
    LazyYoutube,
    LazyVimeo,
  },
  props: {
    items: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    openYoutube() {
      window.open("https://youtube.com/@PosSmart?si=yl019yALXNeYpy4F");
    },
  },
};
</script>

<style></style>
