<template>
  <section>
    <div class="row justify-content-between pa-3">
      <div>{{ $t(title) }}</div>
      <div>{{ value }}</div>
    </div>
    <v-divider v-if="divider" />
  </section>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: true,
    },
    value: {
      default: "",
    },
    divider: {
      type: Boolean,
      default: true,
    },
  },
};
</script>

<style></style>
