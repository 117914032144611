<template>
  <v-container fluid fill-height class="bg-white">
    <v-row justify="center" align="center">
      <v-col
        v-if="$vuetify.breakpoint.mdAndUp"
        cols="4"
        class="side-color bg-primary"
      >
        <v-card max-width="550" outlined color="transparent" flat>
          <v-img src="/images/register.png" />
        </v-card>
      </v-col>
      <v-col xs="12" sm="12" md="8" lg="8" xl="8">
        <v-btn color="primary" to="/login" fab outlined icon small>
          <v-icon small>fal fa-chevron-left</v-icon>
        </v-btn>
        <v-row justify="center">
          <v-col xs="12" sm="12" md="8" lg="6" xl="6">
            <div
              :class="$vuetify.breakpoint.mdAndUp ? 'mb-8' : ''"
              class="text-center"
            >
              <v-avatar size="120">
                <v-img src="/images/pos.png"></v-img>
              </v-avatar>
            </div>
            <v-form @submit.prevent="submitForm(form)">
              <v-card-text>
                <div class="text-center">
                  <h2 class="font-weight-black">ເປີດສາຂາ</h2>
                  <p class="py-3">ປ້ອນຂໍ້ມູນສາຂາຂອງທ່ານ</p>
                </div>

                <TTextField
                  :errorMessages="nameError"
                  label="branch_name"
                  v-model="form.name"
                  @input="$v.form.name.$touch()"
                  @blur="$v.form.name.$touch()"
                />
                <TTextField
                  :errorMessages="mobile_noError"
                  label="mobile_no"
                  v-model="form.mobile_no"
                  @input="$v.form.mobile_no.$touch()"
                  @blur="$v.form.mobile_no.$touch()"
                />
                <TTextField
                  :errorMessages="addressError"
                  label="address"
                  v-model="form.address"
                  @input="$v.form.address.$touch()"
                  @blur="$v.form.address.$touch()"
                />
              </v-card-text>
              <v-card-actions class="px-4">
                <v-btn type="submit" color="primary" large block>{{
                  $t("create_new_branch")
                }}</v-btn>
              </v-card-actions>
            </v-form>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <!-- AppLoading -->
    <app-loading :overlay="overlay" />
  </v-container>
</template>

<script>
import { toast } from "@/controllers/alert";
import TTextField from "@/components/global/TextField";
import { mapGetters } from "vuex";
import {
  required,
  maxLength,
  minLength,
  numeric,
} from "vuelidate/lib/validators";
import { authenticated } from "@/controllers/auth";
export default {
  layout: "blank",
  components: { TTextField },
  data: () => ({
    overlay: false,
    form: {
      name: "",
      mobile_no: "",
      address: "",
    },
  }),
  validations: {
    form: {
      name: { required },
      mobile_no: {
        required,
        numeric,
        maxLength: maxLength(10),
        minLength: minLength(10),
      },
      address: { required },
    },
  },

  computed: {
    ...mapGetters({
      token: "auth/token",
    }),
    nameError() {
      const errors = [];
      if (!this.$v.form.name.$dirty) return errors;
      const msg = this.$t("validation_required");
      !this.$v.form.name.required && errors.push(msg);
      return errors;
    },
    mobile_noError() {
      const errors = [];
      if (!this.$v.form.mobile_no.$dirty) return errors;
      const msg = this.$t("validation_required");
      !this.$v.form.mobile_no.required && errors.push(msg);
      if (!this.$v.form.mobile_no.numeric) {
        const msg = this.$t("validation_numeric");
        errors.push(msg);
      }
      if (!this.$v.form.mobile_no.minLength) {
        const msg = this.$t("validation_min_length");
        errors.push(msg);
      }
      if (!this.$v.form.mobile_no.maxLength) {
        const msg = this.$t("validation_max_length");
        errors.push(msg);
      }

      return errors;
    },
    addressError() {
      const errors = [];
      if (!this.$v.form.address.$dirty) return errors;
      const msg = this.$t("validation_required");
      !this.$v.form.address.required && errors.push(msg);
      return errors;
    },
  },
  methods: {
    async submitForm(form) {
      try {
        this.$v.form.$touch();
        if (!this.$v.form.$invalid) {
          this.overlay = true;
          const body = {
            name: form.name,
            mobile_no: form.mobile_no,
            address: form.address,
            token: this.token,
          };
          const { data } = await this.$http.post("open_branch", body);
          if (data.status) {
            await toast(this, "create_new_branch_success");
            await authenticated(this, data);
          }
        }
      } catch (error) {
        this.overlay = false;
        console.log("error ", error);
      }
    },
  },
};
</script>

<style></style>
