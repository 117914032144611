<template>
  <v-app-bar
    class="pos-header"
    dark
    elevation="1"
    elevate-on-scroll
    color="primary"
    fixed
    app
  >
    <div class="container-xl d-flex align-center">
      <v-app-bar-nav-icon
        v-if="$vuetify.breakpoint.smAndDown"
        @click="$emit('drawer')"
      />
      <v-btn to="/" link dark text>
        <div class="d-flex align-center">
          <v-avatar
            size="40"
            width="40"
            height="40"
            v-if="$vuetify.breakpoint.mdAndUp"
          >
            <v-img width="35" height="35" contain :src="img" />
          </v-avatar>

          <div class="text-capitalize px-0 title font-weight-bolder">
            {{ title }}
          </div>
        </div>
      </v-btn>
      <v-spacer />
      <template v-if="!$vuetify.breakpoint.smAndDown">
        <template v-for="(item, index) in items">
          <v-menu v-if="item.items.length" :key="index" offset-y bottom left>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                text
                class="font-weight-medium text-capitalize"
              >
                {{ $t(item.text) }}
                <v-icon small right> fal fa-angle-down </v-icon>
              </v-btn>
            </template>
            <v-list dense>
              <v-list-item
                dense
                v-for="(subItem, i) in item.items"
                :key="i"
                :to="subItem.to"
                router
                exact
              >
                <v-list-item-title>{{ $t(subItem.text) }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>

          <v-btn
            v-else
            :to="item.to"
            text
            :key="index"
            class="font-weight-medium rounded-xl text-capitalize"
          >
            {{ $t(item.text) }}
          </v-btn>
        </template>
        <v-btn
          depressed
          outlined
          to="/register"
          class="font-weight-medium rounded-xl text-capitalize"
        >
          {{ $t("register") }}
        </v-btn>
        <v-menu offset-y bottom right>
          <template v-slot:activator="{ attrs, on }">
            <v-btn icon v-bind="attrs" v-on="on" class="font-weight-medium">
              <v-icon> fal fa-globe </v-icon>
            </v-btn>
          </template>
          <v-list dense>
            <v-list-item
              v-for="(locale, index) in locales"
              :key="index"
              @click="changeLocale(locale.code)"
            >
              <v-list-item-avatar size="20">
                <v-img :src="locale.flag" />
              </v-list-item-avatar>

              <v-list-item-title>{{ locale.name }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </template>
    </div>
  </v-app-bar>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    title: {
      type: String,
      default: "PosSmart",
    },
    img: {
      type: String,
      default: "/images/logo_white.png",
    },
  },
  computed: {
    ...mapGetters({
      locales: "auth/locales",
    }),
  },
  methods: {
    changeLocale(code) {
      localStorage.setItem("locale", code);
      this.$i18n.locale = code;
    },
  },
};
</script>

<style></style>
