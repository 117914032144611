<template>
  <div class="row">
    <div class="col-12">
      <div class="qrcode-container">
        <img class="qrcode-image rounded-lg" :src="urlQR" alt="QR Code" />
        <div class="center-image-wrapper">
          <!--  <div class="center-image" style="background-color: white; padding: 5px">
            <img src="/images/lao_qr.png" alt="Center Image" />
          </div> -->
        </div>
      </div>
      <p class="price-amount ma-0">
        {{ moneyFormat(defineStore.total_price) }}
      </p>
    </div>

    <div class="col-12 pt-0 px-8 pb-5">
      <v-btn
        @click="$emit('submit')"
        class="font-weight-bold white--text"
        large
        depressed
        block
        color="#4BB543"
      >
        <v-icon small left> fal fa-badge-check</v-icon>
        ສຳເລັດ
      </v-btn>
    </div>
  </div>
</template>

<script>
import { myMixin } from "@/mixin/main";
import { mainStore } from "@/stores/modules/cart";

export default {
  props: {
    item: {
      type: Object,
      default: () => {
        return {
          defineStore: mainStore(),
          qrString: "",
        };
      },
    },
  },
  mixins: [myMixin],
  data() {
    return {
      qrCodeImage: "",
    };
  },
  computed: {
    urlQR() {
      if (this.user && this.user.stores && this.user.stores.qr) {
        const baseURL = process.env.VUE_APP_BASE_URL;
        return `${baseURL}/uploads/${this.user.stores.qr}`;
      } else {
        return "/images/pos.png";
      }
    },
  },
};
</script>

<style>
.qrcode-container {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.qrcode-image,
.center-image {
  max-width: 100%;
  max-height: 100%;
}

.qrcode-image {
  width: 240px;
  height: 240px;
}

.center-image-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.center-image {
  display: inline-block;
}

.center-image img {
  width: 25px;
  height: 25px;
}

.price-amount {
  margin-top: 5px;
  font-size: 18px;
  text-align: center;
  font-weight: bold;
}
</style>
