<template>
  <section>
    <v-card flat rounded="lg" color="transparent">
      <v-card-title class="pt-0">
        <div>{{ $t("manage_employee") }}</div>
        <v-spacer></v-spacer>
        <v-btn depressed color="primary" @click="newItem">
          <v-icon left>fal fa-plus-circle</v-icon>
          {{ $t("add_new_staff") }}
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col
            v-for="(item, index) in users.data"
            :key="index"
            xs="12"
            sm="12"
            md="3"
            lg="3"
            xl="3"
          >
            <v-card rounded="lg">
              <v-card-text>
                <v-card-actions>
                  <div>
                    <v-chip
                      dark
                      :color="RoleColor(item.role)"
                      small
                      class="text-capitalize mr-3"
                      label
                    >
                      {{ userRole(item.role) }}</v-chip
                    >

                    <v-chip
                      v-if="user.id != item.id"
                      @click="menuAction(2, item)"
                      small
                      label
                      :color="item.is_active === 1 ? 'green' : 'red'"
                    >
                      <v-icon x-small color="white">
                        fal fa-{{
                          item.is_active ? "user-check" : "user-alt-slash"
                        }}
                      </v-icon>
                    </v-chip>
                  </div>

                  <v-spacer></v-spacer>

                  <v-menu transition="slide-x-transition" bottom right>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn icon v-bind="attrs" v-on="on">
                        <v-icon small> fal fa-ellipsis-v-alt </v-icon>
                      </v-btn>
                    </template>
                    <v-list>
                      <v-list-item @click="menuAction(0, item)">
                        <v-list-item-title
                          >{{ $t("change_password") }}
                        </v-list-item-title>
                      </v-list-item>
                      <v-list-item
                        v-if="user.id != item.id"
                        @click="menuAction(1, item)"
                      >
                        <v-list-item-title>{{
                          $t("permission")
                        }}</v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </v-card-actions>

                <v-card-text class="text-center">
                  <v-avatar size="90">
                    <app-img width="90" height="90" :img="item.avatar" />
                  </v-avatar>
                  <div class="subtitle-1 font-weight-bold text-capitalize pt-2">
                    {{ item.first_name }}
                  </div>
                  <div class="body">
                    {{ item.email }}
                  </div>
                </v-card-text>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <app-custom-dialog
      v-model="dialog"
      :footer="false"
      title="description"
      @submit="dialog != dialog"
    >
      <div slot="activator">
        <v-row>
          <v-col cols="4">{{ $t("id") }}</v-col>
          <v-col cols="8">{{ form.id }}</v-col>
          <v-col cols="4">{{ $t("first_name") }}</v-col>
          <v-col cols="8">{{ form.first_name }}</v-col>
          <v-col cols="4">{{ $t("last_name") }}</v-col>
          <v-col cols="8">{{ form.last_name }}</v-col>
          <v-col cols="4">{{ $t("phone_no") }}</v-col>
          <v-col cols="8">{{ form.phone_no }}</v-col>
          <v-col cols="4">{{ $t("permission") }}</v-col>
          <v-col cols="8">
            <v-chip
              dark
              :color="RoleColor(form.role)"
              small
              class="text-capitalize"
              label
            >
              {{ userRole(form.role) }}</v-chip
            >
          </v-col>
          <v-col cols="4">{{ $t("created_at") }}</v-col>
          <v-col cols="8">{{ form.created_at | moment }}</v-col>
        </v-row>
      </div>
    </app-custom-dialog>
    <app-custom-dialog
      v-model="dialogAdd"
      :footer="true"
      :title="titleStatus"
      @submit="submitForm"
    >
      <div slot="activator">
        <v-row>
          <v-col xs="12" sm="12" md="6" lg="6" xl="6">
            <TTextField
              @input="$v.form.first_name.$touch()"
              @blur="$v.form.first_name.$touch()"
              :errorMessages="first_nameError"
              label="first_name"
              v-model="form.first_name"
          /></v-col>
          <v-col xs="12" sm="12" md="6" lg="6" xl="6">
            <TTextField label="last_name" v-model="form.last_name"
          /></v-col>
        </v-row>

        <TTextField
          label="phone_no"
          typeShow="number"
          v-model="form.phone_no"
          :errorMessages="phone_numberError"
          @input="$v.form.phone_no.$touch()"
          @blur="$v.form.phone_no.$touch()"
        />
        <v-row>
          <v-col xs="12" sm="12" md="6" lg="6" xl="6">
            <TextSelect
              label="role"
              v-model="form.role"
              :errorMessages="roleErrors"
              @input="$v.form.role.$touch()"
              @blur="$v.form.role.$touch()"
              itemValue="id"
              text="name"
              :items="status"
          /></v-col>
          <v-col xs="12" sm="12" md="6" lg="6" xl="6">
            <TextSelect
              label="branch"
              v-model="form.branch_id"
              :errorMessages="branch_idErrors"
              @input="$v.form.branch_id.$touch()"
              @blur="$v.form.branch_id.$touch()"
              :items="branches.data"
              itemValue="id"
              text="name"
          /></v-col>
        </v-row>

        <TTextField
          :errorMessages="emailError"
          label="email"
          v-model="form.email"
          @input="$v.form.email.$touch()"
          @blur="$v.form.email.$touch()"
        />

        <TPassword
          v-model="form.password"
          @input="$v.form.password.$touch()"
          @blur="$v.form.password.$touch()"
          :errorMessages="passwordErrors"
          label="password"
          showKey="showPassword"
          @showPassword="showPassword = !showPassword"
          :type="showPassword"
        />

        <TPassword
          v-model="form.confirm_password"
          @input="$v.form.confirm_password.$touch()"
          @blur="$v.form.confirm_password.$touch()"
          :errorMessages="confirmPasswordErrors"
          @showCP="showCP = !showCP"
          label="confirm_password"
          showKey="showCP"
          :type="showCP"
        />
      </div>
    </app-custom-dialog>
    <app-custom-dialog
      v-model="changePassword"
      :footer="true"
      title="change_password"
      @submit="submitFormChangePassword"
    >
      <div slot="activator">
        <TPassword
          v-model="formChange.password"
          @input="$v.formChange.password.$touch()"
          @blur="$v.formChange.password.$touch()"
          :errorMessages="passwordErrorsC"
          label="password"
          showKey="showPassword"
          @showPassword="showPasswordC = !showPasswordC"
          :type="showPasswordC"
        />

        <TPassword
          v-model="formChange.confirm_password"
          @input="$v.formChange.confirm_password.$touch()"
          @blur="$v.formChange.confirm_password.$touch()"
          :errorMessages="confirmPasswordErrorsC"
          @showCP="showCPC = !showCPC"
          label="confirm_password"
          showKey="showCP"
          :type="showCPC"
        />
      </div>
    </app-custom-dialog>

    <app-custom-dialog
      v-model="dialogRole"
      :footer="true"
      title="permission"
      width="800px"
      @submit="submitPermission"
    >
      <div slot="activator">
        <div id="columns">
          <ul class="my-checkbox">
            <template v-for="(item, index) in menus">
              <template v-if="item.menu.length">
                <li :key="index" :class="item.icon ? 'menu' : 'role'">
                  <v-checkbox
                    dense
                    disabled
                    hide-details
                    v-model="selected"
                    :label="$i18n.locale === 'en' ? item.name_en : item.name_la"
                    :value="item.id"
                  />
                  <v-checkbox
                    v-for="(sub, i) in item.menu"
                    :key="sub.name_la + i"
                    class="sub-menu"
                    dense
                    hide-details
                    v-model="selected"
                    :label="$i18n.locale === 'en' ? sub.name_en : sub.name_la"
                    :value="sub.id"
                  />
                </li>
              </template>
              <li v-else :key="index" :class="item.icon ? 'menu' : 'role'">
                <v-checkbox
                  dense
                  hide-details
                  v-model="selected"
                  :label="$i18n.locale === 'en' ? item.name_en : item.name_la"
                  :value="item.id"
                /></li
            ></template>
          </ul>
        </div>
      </div>
    </app-custom-dialog>
    <app-loading :overlay="overlay" />
  </section>
</template>

<script>
import DataTable from "@/components/DataTable";
import { mapGetters, mapActions } from "vuex";
import TTextField from "@/components/global/TextField";
import { AddUserStore } from "@/validations/shop";
import TextSelect from "@/components/global/TextSelect";
import TPassword from "@/components/global/TextPassword";
import { myMixin } from "@/mixin/main";
import { success, confirm } from "@/controllers/alert";

export default {
  mixins: [AddUserStore, myMixin],
  data: () => ({
    update: false,
    dialogRole: false,
    dialogAdd: false,
    dialog: false,
    showPassword: false,
    showCP: false,
    showPasswordC: false,
    showCPC: false,
    changePassword: false,
    menus: [],
    selected: [],
    _id: null,
    id: null,
    formChange: {},
    overlay: false,

    form: {
      id: 0,
      first_name: "",
      last_name: "",
      phone_no: "",
      role: null,
      branch_id: null,
      email: "",
      password: "",
      confirm_password: "",
    },
  }),
  async created() {
    await this.fetchItem();
  },

  components: {
    DataTable,
    TTextField,
    TextSelect,
    TPassword,
  },

  computed: {
    ...mapGetters({
      users: "index/users",
      branches: "index/branches",
    }),

    titleStatus() {
      return this.update ? "update" : "new_data";
    },
    status() {
      return [
        {
          id: 3,
          name: this.$t("manager"),
        },
        {
          id: 4,
          name: this.$t("assistant"),
        },
        {
          id: 5,
          name: this.$t("staff"),
        },
      ];
    },
  },

  methods: {
    ...mapActions({
      fetch: "index/fetch",
    }),
    async fetchBranch() {
      const body = {
        that: this,
        url: "branches_paginate",
        commit: "SET_BRANCHES",
      };
      await this.fetch(body);
    },

    async fetchItem() {
      const body = {
        that: this,
        url: "get_users",
        commit: "SET_USERS",
      };
      await this.fetch(body);
    },

    async newItem() {
      await Help.clearForm(this.form, this);
      await this.fetchBranch();
      await this.fetchItem();
      this.update = false;
      this.dialogAdd = !this.dialogAdd;
    },
    async changePass(item) {
      this.id = item.id;
      this.changePassword = !this.changePassword;
    },
    async roleSetting(item) {
      this.overlay = true;
      this.selected = [];
      this.menus = [];
      const { data } = await this.$http.get(`get_user_role/${item.id}`);
      // filter data checked
      data.data.forEach((item) => {
        if (item.checked) {
          this.selected.push(item.id);
        }
        if (item.menu.length) {
          item.menu.forEach((sub) => {
            if (sub.checked) {
              this.selected.push(sub.id);
            }
          });
        }
      });
      this.form = item;
      this.menus = data.data;
      this.dialogRole = !this.dialogRole;
      this.overlay = false;
    },
    async userRoleAction(val) {
      const status =
        val.is_active === 0 ? "active_account" : "inactive_account!";
      const { isConfirmed } = await confirm({
        props: this,
        text: status,
      });
      if (isConfirmed) {
        const { data } = await this.$http.post(
          `store_set_action/${val.id}`,
          val
        );
        if (data.status) {
          await this.fetchItem();
          await success(this, data.message);
        }
      }
    },
    async menuAction(val, item) {
      switch (val) {
        case 0:
          await this.changePass(item);
          break;
        case 1:
          await this.roleSetting(item);
          break;
        case 2:
          await this.userRoleAction(item);
          break;
        default:
          break;
      }
    },

    async submitFormChangePassword() {
      try {
        this.overlay = true;
        this.formChange.userId = this.id;
        const { data } = await this.$http.post(
          `store_change_password`,
          this.formChange
        );
        if (data.status) {
          this.overlay = false;
          this.changePassword = !this.changePassword;
          await success(this, data.message);
        }
      } catch (error) {
        console.log("error", error);
      }
    },
    async submitForm() {
      try {
        this.$v.form.$touch();
        if (!this.$v.form.$invalid) {
          this.overlay = true;

          const body = {
            ...this.form,
            branch_id: this.form.branch_id.id,
            role: this.form.role.id,
          };
          const { data } = await this.$http.post("store_create_user", body);
          if (data.status) {
            this.overlay = false;
            this.dialogAdd = !this.dialogAdd;
            await Help.clearForm(this.form, this);
            await this.fetchItem();
            await success(this, data.message);
          }
        }
      } catch (error) {
        console.log("error", error);
      }
    },
    async submitPermission() {
      try {
        const form = {
          userId: this.form.id,
          menus: Help.menuCheck(this.menus, this.selected),
        };
        const { data } = await this.$http.post("menu_assigned", form);
        if (data.status) {
          this.selected = [];
          this.menus = [];
          this.dialogRole = !this.dialogRole;
          await Help.clearForm(this.form, this);
          await this.fetchItem();
          await success(this, data.message);
        }
      } catch (error) {
        console.log("error", error);
      }
    },
    items(id) {
      if (id != this.user.id) {
        return ["change_password", "permission"];
      } else {
        return ["permission"];
      }
    },
  },
};
</script>

<style></style>
