<template>
  <v-navigation-drawer
    v-model="drawer"
    :width="$vuetify.breakpoint.mobile ? 300 : 400"
    right
    fixed
    app
    :class="defineStore.cart.length ? 'drawer-left' : 'no-data'"
  >
    <template v-slot:prepend>
      <!-- header -->
      <app-header
        @openDiscout="openDiscout"
        v-model="typeDiscount"
        :discount_type="discount_type"
      />
    </template>
    <!-- no product widget -->
    <app-no-product v-if="defineStore.cart.length == 0" />
    <!-- no product widget -->
    <app-cart-list v-else @dialogQTY="dialogQTY" />

    <template v-slot:append v-if="defineStore.cart.length">
      <!-- right action -->
      <app-right-action
        @addQuotation="addQuotation"
        @fetchTable="fetchTable"
        @dialogPayOpen="dialogPayOpen"
        :new_quatation="new_quatation"
        :store_type="store_type"
      />
    </template>

    <!-- Payment -->
    <app-custom-dialog
      v-model="dialogPay"
      :footer="false"
      title="payment"
      width="400px"
    >
      <div slot="activator">
        <NumericKeyboard
          :type="store_type"
          v-model="pay_amount"
          :total_price="defineStore.total_price"
          @overdue="makeOverdue"
          @customer="makeCustomer"
          @submit="makeSubmit"
          :open_noted="open_noted"
          @openNoted="openNoted"
        />
      </div>
    </app-custom-dialog>

    <!-- bottom sheet customer-->
    <v-bottom-sheet
      v-model="sheet"
      scrollable
      max-height="100%"
      style="max-height: 100vh"
    >
      <app-customer
        v-model="customer_search"
        :customers="customers"
        :customer_id="customer_id"
        @chooseCustomer="chooseCustomer"
      />
    </v-bottom-sheet>
    <!-- Receipt print -->
    <app-bill v-show="print" :item="printBill" />
    <!-- quotation print -->
    <app-print v-if="show_quotation" v-show="print" :item="printBill" />
    <!-- app table -->
    <app-custom-dialog
      width="1000px"
      v-model="chooseTable"
      @submit="confirmTableOrder()"
      title="choose_table"
    >
      <div slot="activator">
        <app-choose-table
          :tables="tables"
          :table_id="table_id"
          :order_id="order_id"
          @setTable="setTable"
          @mergeTable="mergeTable"
        />
      </div>
    </app-custom-dialog>
    <app-loading :overlay="overlay" />

    <app-custom-dialog
      v-model="open_noted"
      :footer="true"
      title="noted"
      @submit="openNoted()"
    >
      <div slot="activator">
        <app-text-area v-model="note" label="noted" />
      </div>
    </app-custom-dialog>
    <numeric-pad
      v-model="amount"
      title="discount_option"
      :dialog="dialog"
      @close="dialog = false"
    />
    <numeric-pad
      v-model="amountQty"
      title="quantity_product"
      :dialog="dialogQ"
      @close="dialogQ = false"
    />
  </v-navigation-drawer>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from "vuex";
import AppNoProduct from "@/components/products/no_product";
import AppQR from "@/components/qr";
import AppCartList from "@/components/products/cart_list";
import AppHeader from "@/components/console/header";
import { myMixin } from "@/mixin/main";
import AppBill from "@/components/print/bill";
import VuetifyMoney from "@/components/VuetifyMoney";
import { toast, confirm } from "@/controllers/alert";
import NumericKeyboard from "@/components/NumericKeyboard";
import AppChooseTable from "@/components/restaurant/chooseTable";
import { mainStore } from "@/stores/modules/cart";
import AppPrint from "@/components/documents/print";
import AppTextArea from "@/components/global/TextArea";
import AppTitle from "@/components/settings/app_title";
import NumericPad from "@/components/NumericPad";
import AppRightAction from "@/components/layouts/btn_action";
import AppCustomer from "@/components/layouts/choose_customer";

export default {
  components: {
    AppNoProduct,
    AppQR,
    AppCartList,
    AppBill,
    VuetifyMoney,
    AppHeader,
    NumericKeyboard,
    AppChooseTable,
    AppPrint,
    AppTextArea,
    AppTitle,
    NumericPad,
    AppRightAction,
    AppCustomer,
  },
  mixins: [myMixin],
  data() {
    return {
      number: "",
      defineStore: mainStore(),
      table_id: "",
      order_id: "",
      chooseTable: false,
      show_quotation: false,
      print: false,
      dialog: false,
      dialogPay: false,
      typeDiscount: "amount",
      amount: "0",
      customer_id: null,
      pay_amount: "",
      status_payment: "done",
      right: true,
      drawer: true,
      sheet: false,
      printBill: {},
      last_amount: 0,
      customer_search: "",
      order_table: {},
      open_noted: false,
      note: "",
      windowListener: this.handleKeydown,
      dialogQ: false,
      amountQty: "0",
      cartItem: {
        qty: 0,
        item: {},
      },
    };
  },
  computed: {
    ...mapGetters({
      user: "auth/user",
      discount_type: "index/discount_type",
      customers: "index/customers",
      tables: "index/tables",
      overlay: "index/overlay",
      newQuotation: "index/newQuotation",
    }),
    new_quatation() {
      // check if newQuotation is not empty and contain the customer_id in newQuotation then return true
      if (this.newQuotation && this.newQuotation.customer_id) {
        this.show_quotation = true;
        return true;
      }
      return false;
    },
    quotationText() {
      if (this.newQuotation && this.newQuotation.update) {
        return this.$t("update_quotation");
      }
      return this.$t("add_quotation");
    },
  },
  mounted() {
    if (this.$vuetify.breakpoint.mobile) {
      this.drawer = false;
    }
    if (this.store_type === "POS") {
      this.fetchItem();
    }
    this.windowListener = this.handleKeydown.bind(this);
    window.addEventListener("keydown", this.windowListener);
  },

  beforeDestroy() {
    window.removeEventListener("keydown", this.windowListener);
  },

  watch: {
    amount(val) {
      if (
        val != "" &&
        val != null &&
        val != undefined &&
        this.defineStore.cart.length > 0
      ) {
        var totalPriceCart = this.defineStore.cart
          .map((e) => e.price)
          .reduce((a, b) => a + b);
        if (val === "") return (total_price = 0);
        if (this.typeDiscount === "percentage") {
          var totalValue = totalPriceCart * (val / 100);
          this.defineStore.setDiscount(totalValue);
        } else {
          const totalDiscount = totalPriceCart - val;
          const new_total_price = totalPriceCart - totalDiscount;
          this.defineStore.setDiscount(new_total_price);
        }
      }
    },
    pay_amount(val) {
      if (val != "" && val != null && val != undefined) {
        this.defineStore.setReceived(val);
      }
    },
    typeDiscount() {
      this.defineStore.updateCart();
      this.defineStore.setDiscount(0);
      this.amount = "0";
    },
    customer_search(val) {
      if (val === "") {
        this.fetchItem();
      }
      // check if customer is empty
      if (this.customers.length === 0) {
        return;
      }
      // filter customer by name and phone
      const filter = this.customers.filter((e) => {
        return (
          e.first_name.toLowerCase().includes(val.toLowerCase()) ||
          e.phone_no.toLowerCase().includes(val.toLowerCase())
        );
      });
      this.SET_CUSTOMER(filter);
    },

    amountQty(val) {
      if (
        val != "" &&
        val != null &&
        val != undefined &&
        this.cartItem &&
        this.cartItem.item
      ) {
        const { item } = this.cartItem;
        const body = {
          that: this,
          id: item.id,
          qty: parseInt(val),
        };
        this.defineStore.setQty(body);
      }
    },
  },
  methods: {
    ...mapMutations({
      SET_PAYMENT: "index/SET_PAYMENT",
      SET_CUSTOMER: "index/SET_CUSTOMER",
      SET_TABLES: "index/SET_TABLES",
      SET_OVERLAY: "index/SET_OVERLAY",
      SET_NEW_QUOTATION: "index/SET_NEW_QUOTATION",
    }),

    ...mapActions({
      fetch: "index/fetch",
    }),

    async fetchItem() {
      const body = {
        that: this,
        url: "customers",
        commit: "SET_CUSTOMER",
      };
      await this.fetch(body);
    },
    cancelDiscount() {
      if (this.defineStore.cart.length > 0) {
        var total = this.defineStore.cart
          .map((e) => e.price)
          .reduce((a, b) => a + b);
        this.defineStore.setTotalPrice(total);
        this.amount = 0;
      }
      this.dialog = false;
    },

    removeNumber() {
      const myString = this.pay_amount;
      if (myString.length - 1 != 0) {
        var newStr = myString.substring(0, myString.length - 1);
        this.pay_amount = newStr;
      } else {
        this.pay_amount = "";
      }
    },
    dialogPayOpen() {
      this.dialogPay = true;
      const { total_price, total_discount } = this.defineStore;
      const total = total_price - total_discount;
      this.pay_amount = total.toString();
    },

    async fetchTable() {
      this.SET_OVERLAY(true);
      const body = {
        url: "store_table",
        that: this,
        commit: "SET_TABLES",
      };
      await this.fetch(body);
      this.SET_OVERLAY(false);
      this.chooseTable = true;
    },
    async submitPayment() {
      const body = {
        that: this,
        customer_id: this.customer_id,
        user: this.user,
        typeDiscount: this.typeDiscount,
        status: this.status_payment,
        note: this.note,
      };
      this.defineStore.saleProduct(body).then((result) => {
        if (result && result.status) {
          this.printBill = result.data;
          setTimeout(() => {
            this.$htmlToPaper("printBill");
          }, 500);
        }
        this.fetchMySale();
      });
      this.customer_id = null;
      this.status_payment = "done";
      return;
    },

    async setTable(id) {
      try {
        this.table_id = id;
        this.order_table = {};
        const { data } = await this.$http.get(`get_table_order/${id}?check=0`);
        this.order_table = data.data;
      } catch (error) {
        console.log(error);
      }
    },

    async confirmTableOrder() {
      // check table_id is empty
      if (!this.table_id || this.table_id === "") {
        return toast(this, "choose_table_required", "error");
      }
      // check cart is empty
      if (this.defineStore.cart.length === 0 || this.defineStore.cart === "") {
        return toast(this, "choose_product_required", "error");
      }
      await this.makeTableOrder();
    },
    async mergeTable(id) {
      if (id === this.order_id) {
        this.order_id = "";
        return;
      }
      this.order_id = id;
    },

    async makeOverdue() {
      this.sheet = true;
      this.status_payment = "overdue";
    },
    async makeCustomer() {
      this.status_payment = "done";
      this.sheet = true;
    },
    async makeSubmit() {
      const { total_price, total_discount } = this.defineStore;
      if (
        total_price > this.pay_amount &&
        this.customer_id === null &&
        total_discount === 0
      ) {
        const { isConfirmed } = await confirm({
          props: this,
          title: "not_enough_money",
          text: "ok_to_overdue",
        });
        if (isConfirmed) {
          await toast(this, "choose_customer", "info");
          this.sheet = true;
          this.status_payment = "overdue";
        }
      } else {
        this.submitPayment();
      }
    },

    async makeTableOrder() {
      try {
        const { arr, cart_id } = await Help.tableOrder(this.defineStore.cart);
        let status_order = "new";
        if (this.order_table) {
          status_order = "merge";
        }
        const body = {
          table_id: this.table_id,
          cart_id,
          ticket_id: this.order_table?.id || null,
          status_order: status_order,
          items: arr,
        };
        const { data } = await this.$http.post("/make_table_order", body);
        if (data && data.status) {
          this.table_id = "";
          this.order_id = "";
          this.order_table = {};
          // clear cart
          this.defineStore.deleteCart();
          this.SET_PAYMENT();
          this.chooseTable = false;
          await toast(this, "add_order_success");
        }
      } catch (error) {
        console.log(error);
      }
    },

    async addQuotation() {
      try {
        const req_body = await Help.generateQuotation(
          this.newQuotation,
          this.defineStore.cart
        );
        let api = "quotation_add";
        if (this.newQuotation.update) {
          api = `quotation_update`;
        }
        const { data } = await this.$http.post(api, req_body);
        if (data && data.status) {
          this.defineStore.deleteCart();
          this.SET_NEW_QUOTATION({});
          // confirm
          const { isConfirmed } = await confirm({
            props: this,
            text: "add_quotation_success",
            text: "want_to_print",
            con_txt: "print",
            can_txt: "done",
          });
          if (isConfirmed) {
            this.printBill = data.data;
            setTimeout(() => {
              this.$htmlToPaper("quotationPrint");
            }, 500);
          }
        }
      } catch (error) {
        console.log(error);
      }
    },

    handleKeydown(event) {
      if (this.open_noted) return;
      if (this.dialog) return;
      if (event.key === "F12") {
        event.preventDefault();
        if (this.store_type === "POS") {
          this.dialogPayOpen();
        } else {
          this.fetchTable();
        }
      }
      if (event.ctrlKey) {
        if (event.key === "d") {
          event.preventDefault();
          const { total_discount } = this.defineStore;
          this.amount = total_discount.toString();
          this.openDiscout();
        }
      }
    },

    openNoted() {
      this.open_noted = !this.open_noted;
      if (this.open_noted) {
        this.note = "";
      }
    },

    openDiscout() {
      this.dialog = !this.dialog;
    },

    chooseCustomer({ id }) {
      this.customer_id = id;
      this.sheet = false;
    },
    dialogQTY(item) {
      this.dialogQ = true;
      this.amountQty = item.qty.toString();
      this.cartItem = item;
    },
  },
};
</script>

<style scoped></style>
