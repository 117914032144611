<template>
  <v-card color="primary accent-2 py-12" rounded="0">
    <div class="container-xl pt-8 pb-12">
      <section class="py-12">
        <div class="d-flex pb-8">
          <v-icon color="white"> fal fa-cogs </v-icon>
          <h4 class="white--text ml-4">ອຸປະກອນ</h4>
        </div>

        <div class="row">
          <div class="grid-row" v-for="(item, index) in items" :key="index">
            <v-card rounded="xl" flat>
              <v-img contain :src="item.img" />

              <v-card-title class="primary--text">
                {{ item.price }}
              </v-card-title>
            </v-card>
          </div>
        </div>
      </section>
    </div>
  </v-card>
</template>

<script>
export default {
  data() {
    return {
      items: [
        {
          img: "/images/1.png",
          price: "ຄອມພິວເຕີ",
          title: "ຄອມພິວເຕີທີ່ມີຄວາມສະຫຼຸບແລະຄວາມສະຫຼຸບການຂາຍໃຫ້ກັບລູກຄ້າ",
        },
        {
          img: "/images/2.png",
          price: "ເຄື່ອງປິນໃບບີນ",
          title: "ປີນໃບຮັບເງີນແລະລາຍລະອຽດການຂາຍໃຫ້ກັບລູກຄ້າ",
        },
        {
          img: "/images/3.png",
          price: "ເຄື່ອງພີມບາໂຄດ",
          title: "ຕິດສິນຄ້າທີຍັງບໍມີບາໂຄດ ຫຼື ສິນຄ້າທີຕ້ອງການບາໂຄດໃຫ່ມ",
        },
        {
          img: "/images/4.png",
          price: "ລີ້ນຊັກເກັບເງີນ",
          title: "ໃຊ້ເກັບເງີນສົດແລະຄວາມປອດໄພໃນການຈັດເກັບເງີນ",
        },
        {
          img: "/images/5.png",
          price: "ເຄື່ອງສະແກນບາໂຄດ",
          title: "ຊ່ວຍຄົ້ນຫາສິນຄ້າແລະເຮັດໃຫ້ຂະບວນການຂາຍໄວຂື້ນ",
        },
      ],
    };
  },
};
</script>

<style>
.grid-row {
  flex: 0 0 20%;
  max-width: 20%;
  padding: 0 8px;
  margin-bottom: 20px;
}
@media (max-width: 600px) {
  .grid-row {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
</style>
