import Vue from "vue";
import VueHtmlToPaper from "vue-html-to-paper";

const options = {
  name: "_blank",
  specs: ["fullscreen=no", "titlebar=no", "scrollbars=no"],
  styles: [
    "/css/main.css",
    "/css/vuetify.css",
    "/css/fonts.css",
    "/css/print.css",
  ],
  timeout: 1000, // default timeout before the print window appears
  autoClose: false, // if false, the window will not close after printing
  windowTitle: window.document.title, // override the window title
};

Vue.use(VueHtmlToPaper, options);
