<template>
  <section class="bg-primary">
    <div class="container-xl py-12">
      <v-card color="transparent" flat dark class="py-8">
        <v-row>
          <v-col cols="12">
            <h1
              class="pt-4 text-center pb-8 font-weight-bold white--text"
              :class="$vuetify.breakpoint.smAndDown ? ' text-h4' : ' text-h2 '"
            >
              PosSmart
            </h1>
          </v-col>
          <div class="col-xs-12 col-sm-12 col-md-12 col-lg-4 col-xl-4">
            <v-card flat color="transparent">
              <v-card-title> ລະບົບຂາຍທີ່ທັນສະໄຫມ </v-card-title>
              <p>
                🚀 PosSmart: ລະບົບຂາຍຈັດການໜ້າຮ້ານທີ່ທັນສະໄຫມ ແລະ
                ເປັນລະບົບພັດທະນາຂື້ນມາໂດຍສີມືຂອງຄົນລາວ! 💻🛒
              </p>
              <v-card-text>
                <ul class="list-none">
                  <li
                    class="pb-3 white--text"
                    v-for="(item, index) in list"
                    :key="index"
                  >
                    <v-icon size="6" class="pr-4">fas fa-circle</v-icon>
                    {{ item.text }}
                  </li>
                </ul>
              </v-card-text>
              <div class="d-flex">
                <v-card color="transparent" flat height="50" width="100">
                  <a
                    href="https://play.google.com/store/apps/details?id=com.devla.pos"
                    target="_blank"
                  >
                    <v-img src="/images/play_store.png" />
                  </a>
                </v-card>
                <v-card color="transparent" flat height="50" width="100">
                  <v-img src="/images/app_store.png" />
                </v-card>
              </div>
            </v-card>
          </div>
          <div
            class="col-xs-12 col-sm-12 col-md-12 col-lg-8 col-xl-8"
            v-if="$vuetify.breakpoint.mdAndUp"
          >
            <v-img
              :height="$vuetify.breakpoint.smAndDown ? 180 : 350"
              aspect-ratio="2"
              contain
              src="/images/01.png"
            ></v-img>
          </div>
        </v-row>
      </v-card>
    </div>
  </section>
</template>

<script>
export default {
  data: () => ({
    list: [
      {
        icon: "fal fa-shopping-cart",
        text: "ລະບົບອອນໄລນ໌",
      },
      {
        icon: "fal fa-shopping-cart",
        text: "ໃຊ້ງານງ່າຍ",
      },
      {
        icon: "fal fa-shopping-cart",
        text: "ລາຄາຖືກ",
      },
      {
        icon: "fal fa-shopping-cart",
        text: "ພ້ອມໃຫ້ການຊ່ວຍເຫຼືອ 24/7",
      },
      {
        icon: "fal fa-shopping-cart",
        text: "ທົດລອງໃຊ້ງານຟຣີ 30 ວັນ",
      },
    ],
  }),
};
</script>

<style></style>
