<template>
  <v-row>
    <v-col cols="6">
      <v-row>
        <v-col cols="4">{{ $t("") }}ລະຫັດ</v-col>
        <v-col cols="8">
          <v-chip color="primary" dark label small>
            {{ item.code }}
          </v-chip>
        </v-col>
        <v-col v-if="item.user" cols="4">{{ $t("maker") }}</v-col>
        <v-col v-if="item.user" cols="8">{{ item.user.first_name }}</v-col>

        <v-col cols="4">{{ $t("product_item") }}</v-col>
        <v-col cols="8">{{ item.stock_history_count }} {{ $t("item") }}</v-col>
        <v-col cols="4">{{ $t("created_at") }} </v-col>
        <v-col cols="8">{{ item.created_at | moment }}</v-col>
      </v-row>
    </v-col>
    <v-col cols="6">
      <v-row>
        <v-col cols="4">{{ $t("total_buy") }}</v-col>
        <v-col cols="8" class="primary--text">{{
          _totalBuy | NumberFormat
        }}</v-col>
        <v-col cols="4">{{ $t("total_sale") }} </v-col>
        <v-col cols="8" class="red--text">{{
          _totalSell | NumberFormat
        }}</v-col>
        <v-col cols="4">{{ $t("total_profit") }} </v-col>
        <v-col cols="8" class="green--text">{{
          _totalProfit | NumberFormat
        }}</v-col>
      </v-row>
    </v-col>
    <v-col cols="12">
      <v-simple-table>
        <template v-slot:default>
          <thead>
            <tr>
              <th scope="col">{{ $t("no") }}</th>
              <th scope="col">{{ $t("image") }}ຮູບພາບ</th>
              <th scope="col">{{ $t("name") }}ຊື່</th>
              <th scope="col">{{ $t("price_buy") }}ຕົ້ນທືນ</th>
              <th scope="col">{{ $t("before_import") }}</th>
              <th scope="col">{{ $t("import_amount") }}</th>
              <th scope="col">{{ $t("after_import") }}</th>
              <th scope="col">{{ $t("price_sale") }}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(data, index) in item.history" :key="index">
              <td>{{ index + 1 }}.</td>
              <td>
                <app-img :img="data.product.img" />
              </td>
              <td>{{ data.product.name }}</td>
              <td>{{ data.price_buy | NumberFormat }}</td>
              <td>{{ data.last_stock | NumberFormat }}</td>
              <td>{{ data.qty | NumberFormat }}</td>
              <td>
                {{ data.current_stock | NumberFormat }}
              </td>
              <td>
                {{ data.price_sale | NumberFormat }}
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-col>
  </v-row>
</template>

<script>
import { myMixin } from "@/mixin/main";
export default {
  mixins: [myMixin],
  props: {
    item: {
      type: Object,
    },
  },

  computed: {
    _totalBuy() {
      let total = 0;
      this.item.history.forEach((element) => {
        total += element.price_buy * element.qty;
      });
      return total;
    },

    _totalSell() {
      let total = 0;
      this.item.history.forEach((element) => {
        total += element.price_sale * element.qty;
      });
      return total;
    },

    _totalProfit() {
      let total = 0;
      this.item.history.forEach((element) => {
        total += (element.price_sale - element.price_buy) * element.qty;
      });
      return total;
    },
  },
};
</script>

<style></style>
