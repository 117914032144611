<template>
  <section>
    <data-table :headers="headers" :items="suppliers" commit="SET_SUPPLIERS">
      <div slot="activator" class="row">
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-3">
          <v-card-title class="px-0 pt-0"> {{ $t("supplier") }} </v-card-title>
        </div>

        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-4">
          <TextSearch
            v-model="search"
            @search="fetchItem()"
            @onClear="
              search = '';
              fetchItem();
            "
          />
        </div>
        <v-spacer />
      </div>
    </data-table>
    <!-- AppLoading -->
    <app-loading :overlay="overlay" />
  </section>
</template>

<script>
import DataTable from "@/components/DataTable";
import { mapGetters, mapActions } from "vuex";
import { supplierReportHead } from "@/utils/head";
export default {
  data: () => ({
    update: false,
    dialogAdd: false,
    dialog: false,
    overlay: false,
    search: "",

    form: {},
  }),
  async created() {
    await this.fetchItem();
  },
  components: { DataTable },
  computed: {
    ...mapGetters({
      suppliers: "index/suppliers",
    }),
    headers() {
      return supplierReportHead(this);
    },
  },

  methods: {
    ...mapActions({
      fetch: "index/fetch",
    }),
    async fetchItem() {
      const body = {
        that: this,
        url: `suppliers_paginate?q=${this.search}`,
        commit: "SET_SUPPLIERS",
      };
      await this.fetch(body);
    },
  },
};
</script>

<style></style>
