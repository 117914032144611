<template>
  <section>
    <data-table
      :headers="headers"
      :items="notifyProducts"
      commit="SET_NOTIFY"
      :viewItem="viewItem"
    >
      <div slot="activator" class="row">
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-3">
          <v-card-title class="px-0 pt-0">
            {{ $t("notify_product") }}
          </v-card-title>
        </div>

        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-3 offset-lg-6">
          <TextSearch
            v-model="search"
            @search="fetchItem()"
            @onClear="
              search = '';
              fetchItem();
            "
          />
        </div>
        <v-spacer />
      </div>
    </data-table>
    <app-custom-dialog v-model="dialog" :footer="false" title="description">
      <div slot="activator">
        <AppDetail :item="form" />
      </div>
    </app-custom-dialog>
    <!-- AppLoading -->
    <app-loading :overlay="overlay" />
  </section>
</template>

<script>
import DataTable from "@/components/DataTable";
import { mapGetters, mapActions } from "vuex";
import { notifyProducts } from "@/utils/head";
import AppDetail from "@/components/products/details";
export default {
  data: () => ({
    overlay: false,
    search: "",
    dialogAdd: false,
    dialog: false,

    update: false,
    form: {},
  }),
  async created() {
    await this.fetchItem();
  },
  components: { DataTable, AppDetail },
  computed: {
    ...mapGetters({
      notifyProducts: "index/notifyProducts",
    }),

    titleStatus() {
      return this.update ? "update" : "new_data";
    },
    headers() {
      return notifyProducts(this);
    },
  },

  methods: {
    ...mapActions({
      fetch: "index/fetch",
    }),
    async fetchItem() {
      const body = {
        that: this,
        url: `product_notify?q=${this.search}`,
        commit: "SET_NOTIFY",
      };
      await this.fetch(body);
    },

    async viewItem({ id }) {
      try {
        const { data } = await this.$http.get(`products/${id}`);
        if (data.status) {
          this.form = data.data;
          this.dialog = !this.dialog;
        }
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>

<style></style>
