<template>
  <section>
    <v-row class="mb-3">
      <v-col v-for="(item, index) in summaries" :key="index">
        <v-card rounded="lg">
          <v-card-text>
            <v-list-item>
              <v-list-item-avatar :color="item.color" rounded="lg">
                <v-icon color="white">
                  {{ item.icon }}
                </v-icon>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-subtitle> {{ item.title }} </v-list-item-subtitle>
                <v-list-item-title class="text-h6 font-weight-bold">
                  {{ item.total | NumberFormat }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <data-table
      :headers="headers"
      :items="allProducts"
      commit="SET_ALL_PRODUCTS"
      :viewItem="viewItem"
      :editItem="editItem"
      :deleteItem="deleteItem"
      :proStatus="proStatus"
    >
      <div slot="activator" class="row">
        <div class="col-xs-6 col-sm-6 col-md-6 col-lg-2">
          <v-card-title class="py-0"> ສິນຄ້າທັງໝົດ </v-card-title>
        </div>
        <div class="col-xs-6 col-sm-6 col-md-6 col-lg-2 text-right offset-lg-3">
          <TextSelect
            :dense="true"
            :placeholder="false"
            :items="categories"
            label="category"
            v-model="category_id"
            text="la_name"
            itemValue="id"
          />
        </div>
        <div class="col-xs-6 col-sm-6 col-md-6 col-lg-3 text-right">
          <TextSearch
            v-model="search"
            @search="fetchItem()"
            @onClear="
              search = '';
              fetchItem();
            "
          />
        </div>
        <div class="col-xs-6 col-sm-6 col-md-6 col-lg-2 text-right">
          <v-btn
            min-width="auto"
            class="mr-3"
            depressed
            outlined
            color="primary"
            to="/trash-products"
          >
            <v-icon small>fal fa-trash</v-icon>
          </v-btn>
          <v-btn
            min-width="auto"
            depressed
            dark
            color="primary"
            to="/manage-add-product"
          >
            <v-icon left small>fal fa-plus-circle</v-icon>
            ເພີ່ມສິນຄ້າ
          </v-btn>
        </div>
      </div>
    </data-table>

    <app-custom-dialog
      v-model="dialog"
      :footer="false"
      title="description"
      @submit="submitForm"
    >
      <div slot="activator">
        <AppDetail :item="form" />
      </div>
    </app-custom-dialog>
    <app-loading :overlay="overlay" />
  </section>
</template>

<script>
import DataTable from "@/components/DataTable";
import { mapGetters, mapActions } from "vuex";
import { productHead } from "@/utils/head";
import AppDetail from "@/components/products/details";
import { myMixin } from "@/mixin/main";
import { success, confirm } from "@/controllers/alert";
import TextSelect from "@/components/global/TextSelect";

export default {
  mixins: [myMixin],
  data: () => ({
    overlay: false,
    search: "",
    category_id: {
      id: 0,
      la_name: "ທັງໝົດ",
    },
    update: false,
    dialogAdd: false,
    dialog: false,
    headers: productHead(),
    form: {},
    summaries: [],
  }),
  async created() {
    await this.fetchSummary();
    await this.fetchCategory();
    await this.fetchItem();
  },
  watch: {
    category_id(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.search = "";
        this.fetchItem();
      }
    },
  },
  components: {
    DataTable,

    AppDetail,
    TextSelect,
  },
  computed: {
    ...mapGetters({
      category: "index/category",
      allProducts: "index/allProducts",
    }),
    categories() {
      if (
        this.category != null &&
        this.category != undefined &&
        this.category.length
      ) {
        const object = {
          id: 0,
          name: "ທັງໝົດ",
        };
        this.category.unshift(object);
        return this.category;
      } else {
        return [];
      }
    },
  },

  methods: {
    ...mapActions({
      fetch: "index/fetch",
    }),
    async fetchCategory() {
      const body = {
        url: "categories",
        that: this,
        commit: "SET_CATEGORY",
      };
      await this.fetch(body);
    },
    async fetchItem() {
      this.overlay = true;
      const body = {
        that: this,
        url: `products_all?cat_id=${this.category_id.id}&q=${this.search}`,
        commit: "SET_ALL_PRODUCTS",
      };
      await this.fetch(body);
      this.overlay = false;
    },

    async newItem() {
      await Help.clearForm(this.form, this);
      await this.fetchItem();
      this.update = false;
      this.dialogAdd = !this.dialogAdd;
    },
    editItem({ id }) {
      this.$router.push(`/manage-edit-product/${id}`);
    },
    async fetchSummary() {
      try {
        const { data } = await this.$http.get(`/get_summary`);
        if (data.status) {
          this.summaries = data.data;
        }
      } catch (error) {
        console.log(error);
      }
    },
    async deleteItem({ id }) {
      const { isConfirmed } = await confirm({
        props: this,
        text: "want_to_delete",
      });
      if (isConfirmed) {
        const { data } = await this.$http.delete(`products/${id}`);
        if (data.status) {
          await Help.clearForm(this.form, this);
          await this.fetchItem();
          await success(this, data.message);
        }
      }
    },
    viewItem(item) {
      this.form = item;
      this.dialog = !this.dialog;
    },
    async submitForm() {
      var result;
      try {
        if (this.update) {
          result = await this.$http.post(
            `product_size/${this.form.id}`,
            this.form
          );
        } else {
          result = await this.$http.post("product_size", this.form);
        }
        if (result.data.status) {
          this.dialogAdd = !this.dialogAdd;
          await Help.clearForm(this.form, this);
          await this.fetchItem();
          await success(this, data.message);
        }
      } catch (error) {
        console.log("error", error);
      }
    },

    async proStatus(val) {
      try {
        const { data } = await this.$http.put(`product_active/${val.id}`);
        if (data.status) {
          await this.fetchItem();
          await success(this, data.message);
        } else {
          await errorAlert(this, data.message);
        }
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>

<style></style>
