<template>
  <v-col cols="3">
    <v-card class="rounded-lg">
      <v-list flat>
        <v-subheader>
          {{ $t("setting") }}
        </v-subheader>
        <v-list-item-group
          v-model="selectedItem"
          color="primary"
          mandatory
          active-class="font-weight-bold"
        >
          <template v-for="(item, index) in items">
            <v-list-item :key="item.text" @click="$emit('handle', item.value)">
              <v-list-item-avatar
                :color="item.color"
                class="rounded-lg"
                size="30"
              >
                <v-icon dark x-small>fal fa-{{ item.icon }}</v-icon>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>{{ $t(item.text) }}</v-list-item-title>
              </v-list-item-content>
              <v-list-item-action v-if="!$vuetify.breakpoint.mobile">
                <v-switch
                  v-if="item.text === 'dark_mode'"
                  color="primary"
                  v-model="darkMode"
                  hide-details
                  @change="$emit('handle', item.value)"
                  inset
                />

                <v-icon
                  :color="selectedItem === index ? 'primary' : 'grey'"
                  v-else
                  >fal fa-angle-right</v-icon
                >
              </v-list-item-action>
            </v-list-item>
            <v-divider
              inset
              class="my-0"
              v-if="index < items.length - 1"
              :key="index"
            ></v-divider>
          </template>
        </v-list-item-group>
      </v-list>
    </v-card>
  </v-col>
</template>

<script>
export default {
  props: ["items", "value"],
  data() {
    return {
      darkMode: this.value,
      selectedItem: 0,
    };
  },
  mounted() {
    const index = localStorage.getItem("selectedItem");
    if (index) this.selectedItem = parseInt(index);
  },
  destroyed() {
    localStorage.removeItem("selectedItem");
  },
  watch: {
    value(newVal, oldVal) {
      if (newVal !== oldVal) this.darkMode = newVal;
    },
    darkMode(newVal, oldVal) {
      if (newVal !== oldVal) this.$emit("input", newVal);
    },
    selectedItem(index) {
      localStorage.setItem("selectedItem", index);
    },
  },
};
</script>

<style></style>
