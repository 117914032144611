<template>
  <v-navigation-drawer v-model="drawer" fixed app>
    <v-list>
      <template v-for="(item, i) in items">
        <v-list-group
          v-if="item.items && item.items.length > 0"
          :key="i"
          sub-group
          no-action
        >
          <template v-slot:prependIcon>
            <v-icon small>{{ item.icon }}</v-icon>
          </template>
          <template v-slot:appendIcon>
            <v-icon small>fal fa-chevron-down</v-icon>
          </template>
          <template v-slot:activator>
            <v-list-item-title> {{ item.title }}</v-list-item-title>
          </template>
          <v-list-item
            v-for="(subItem, i) in item.items"
            :key="i"
            :to="subItem.to"
            router
            exact
          >
            <v-list-item-action>
              <v-icon small left>{{ subItem.icon }}</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>
                {{ $t(subItem.text) }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-group>

        <v-list-item :key="i" :to="item.to" router exact v-else>
          <v-list-item-action>
            <v-icon small left>{{ item.icon }}</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>
              {{ $t(item.text) }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </template>
      <v-list-item to="/register" router exact>
        <v-list-item-action>
          <v-icon small left> fal fa-registered</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title> {{ $t("register") }} </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
export default {
  props: {
    items: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      drawer: false,
    };
  },
};
</script>

<style></style>
