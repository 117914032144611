<template>
  <section class="bg-white py-12">
    <div class="container-xl pt-8 pb-12">
      <v-row>
        <v-col xs="12" sm="12" md="12" lg="6" cols="12">
          <v-card flat>
            <v-img
              :height="$vuetify.breakpoint.smAndDown ? 200 : 350"
              aspect-ratio="2"
              contain
              src="/images/03.png"
            ></v-img>
          </v-card>
        </v-col>
        <v-col xs="12" sm="12" md="12" lg="6" cols="12">
          <v-card flat>
            <v-card-title>
              <v-icon left color="primary">fal fa-cog</v-icon>
              <span class="body">ຂັ້ນຕອນການທົດລອງນຳໃຊ້ລະບົບ</span>
            </v-card-title>
            <v-card-text>
              <ul class="list-none">
                <li class="pb-3" v-for="(item, index) in list" :key="index">
                  <v-icon size="6" class="pr-4">fas fa-circle</v-icon>
                  {{ item }}
                </li>
              </ul>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </div>
  </section>
</template>

<script>
export default {
  data() {
    return {
      list: [
        "ເຂົ້າມາ https://pos.devla.la",
        "ໄປຫາໜ້າ ສະໝັກສະມາຊິກ",
        "ປ້ອນຂໍ້ມູນສ່ວນຕົວ",
        "ປ້ອນຂໍ້ມູນຮ້ານຄ້າຂອງເຮົາ",
        "ໄປຫາຈັດການສິນຄ້າ",
        "ຂາຍສິນຄ້າ",
      ],
    };
  },
};
</script>

<style></style>
