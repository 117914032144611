export const state = () => ({
  per_page: 30,
  config: {},
  products: [],
  allProducts: [],
  category: [],
  productSizes: [],
  customers: [],
  history: [],
  expense: [],
  expired: [],
  summary: [],
  orders: [],
  stores: [],
  feedback: [],
  summaryDetails: [],
  summaryBestSeller: [],
  notifyProducts: [],
  expiredProducts: [],
  overdueProducts: [],
  bestSales: [],
  sortProducts: [],
  sizes: [],
  stocks: [],
  billReports: [],
  paymentId: 1,
  discount_type: [
    {
      icon: "money-bill",
      value: "amount",
    },
    {
      icon: "percent",
      value: "percentage",
    },
  ],
  roles: [],
  users: [],
  reportUsers: [],
  productStores: [],
  barcode: [],
  reportCategories: [],
  reportCustomers: [],
  mySale: {},
  reportUserSale: [],
  suppliers: [],
  packages: [],
  branches: [],
  orders: [],
  trash: [],
  product_purchase: [],
  product_barcode: [],
  tables: [],
  overlay: false,
  quotations: [],
  newQuotation: {},
  currencies: [
    {
      name: "LAK",
      symbol: "₭",
    },
    {
      name: "USD",
      symbol: "$",
    },
    {
      name: "THB",
      symbol: "฿",
    },
  ],
});
export const getters = {
  config: (state) => state.config,
  barcode: (state) => state.barcode,
  suppliers: (state) => state.suppliers,
  reportUserSale: (state) => state.reportUserSale,
  mySale: (state) => state.mySale,
  reportCategories: (state) => state.reportCategories,
  reportCustomers: (state) => state.reportCustomers,
  productStores: (state) => state.productStores,
  reportUsers: (state) => state.reportUsers,
  users: (state) => state.users,
  per_page: (state) => state.per_page,
  billReports: (state) => state.billReports,
  stocks: (state) => state.stocks,
  sortProducts: (state) => state.sortProducts,
  sizes: (state) => state.sizes,
  bestSales: (state) => state.bestSales,
  allProducts: (state) => state.allProducts,
  category: (state) => state.category,
  productSizes: (state) => state.productSizes,
  products: (state) => state.products,
  customers: (state) => state.customers,
  expense: (state) => state.expense,
  discount_type: (state) => state.discount_type,
  history: (state) => state.history,
  summary: (state) => state.summary,
  summaryDetails: (state) => state.summaryDetails,
  summaryBestSeller: (state) => state.summaryBestSeller,
  orders: (state) => state.orders,
  expired: (state) => state.expired,
  stores: (state) => state.stores,
  feedback: (state) => state.feedback,
  notifyProducts: (state) => state.notifyProducts,
  overdueProducts: (state) => state.overdueProducts,
  roles: (state) => state.roles,
  paymentId: (state) => state.paymentId,
  expiredProducts: (state) => state.expiredProducts,
  packages: (state) => state.packages,
  branches: (state) => state.branches,
  orders: (state) => state.orders,
  trash: (state) => state.trash,
  product_purchase: (state) => state.product_purchase,
  product_barcode: (state) => state.product_barcode,
  tables: (state) => state.tables,
  overlay: (state) => state.overlay,
  quotations: (state) => state.quotations,
  newQuotation: (state) => state.newQuotation,
  currencies: (state) => state.currencies,
};

export const mutations = {
  SET_CONFIG(state, data) {
    state.config = data;
  },
  SET_REPORT_CATEGORIES(state, data) {
    state.reportCategories = data;
  },
  SET_PRODUCT_BARCODE(state, data) {
    state.product_barcode = data;
  },
  SET_USER_SALE(state, data) {
    state.reportUserSale = data;
  },
  SET_MY_SALE(state, data) {
    state.mySale = data;
  },
  SET_REPORT_CUSTOMERS(state, data) {
    state.reportCustomers = data;
  },
  SET_BAR_CODE(state, data) {
    state.barcode.push(data);
  },
  SET_PRODUCT_STORES(state, data) {
    state.productStores = data;
  },

  SET_PRODUCT_SIZE(state, data) {
    state.productSizes = data;
  },

  SET_REPORT_USERS(state, data) {
    state.reportUsers = data;
  },
  SET_PRODUCT_PURCHASE(state, data) {
    state.product_purchase = data;
  },
  SET_USERS(state, data) {
    state.users = data;
  },
  SET_ROLES(state, data) {
    state.roles = data;
  },
  SET_BILL_REPORT(state, payload) {
    state.billReports = payload;
  },
  SET_EXPIRED_PRODUCTS(state, payload) {
    state.expiredProducts = payload;
  },
  SET_STOCKS(state, payload) {
    state.stocks = payload;
  },
  SET_SIZES(state, payload) {
    state.sizes = payload;
  },
  SET_SORT_PRODUCTS(state, payload) {
    state.sortProducts = payload;
  },
  SET_BEST_SALE(state, payload) {
    state.bestSales = payload;
  },
  SET_OVERDUE(state, payload) {
    state.overdueProducts = payload;
  },
  SET_NOTIFY(state, payload) {
    state.notifyProducts = payload;
  },
  SET_EXPIRED(state, payload) {
    state.expired = payload;
  },
  SET_FEEDBACK(state, payload) {
    state.feedback = payload;
  },
  SET_STORES(state, payload) {
    state.stores = payload;
  },
  SET_ORDER(state, payload) {
    state.orders = payload;
  },
  SET_PRODUCT(state, payload) {
    state.products = payload;
  },
  SET_ALL_PRODUCTS(state, payload) {
    state.allProducts = payload;
  },
  SET_TRASH(state, payload) {
    state.trash = payload;
  },
  SET_REPORT_DETAILS(state, payload) {
    state.summaryDetails = payload;
  },
  SET_BEST_SELLER(state, payload) {
    state.summaryBestSeller = payload;
  },
  SET_SUMMARY(state, payload) {
    state.summary = payload;
  },
  SET_PAYMENT(state, payload) {
    state.paymentId = payload;
  },
  SET_PACKAGE(state, payload) {
    state.packages = payload;
  },
  SET_HISTORY(state, payload) {
    state.history = payload;
  },
  SET_ORDER(state, payload) {
    state.orders = payload;
  },
  SET_CATEGORY(state, payload) {
    state.category = payload;
  },
  SET_CUSTOMER(state, payload) {
    state.customers = payload;
  },
  SET_EXPENSE(state, payload) {
    state.expense = payload;
  },
  SET_SUPPLIERS(state, payload) {
    state.suppliers = payload;
  },
  SET_BRANCHES(state, payload) {
    state.branches = payload;
  },
  SET_TABLES(state, payload) {
    state.tables = payload;
  },
  SET_OVERLAY(state, payload) {
    state.overlay = payload;
  },
  SET_QUOTATIONS(state, payload) {
    state.quotations = payload;
  },
  SET_NEW_QUOTATION(state, payload) {
    state.newQuotation = payload;
  },
  SET_CURRENCY(state, payload) {
    state.currencies = payload;
  },
};
export const actions = {
  async fetch(context, { commit, url, that }) {
    try {
      const { data } = await that.$http.get(url);
      if (data.status) {
        await context.commit(commit, data.data);
      }
    } catch (error) {
      console.error("fetch ", error);
    }
  },
};
export const strict = false;
