import {
  required,
  maxLength,
  minLength,
  email,
  sameAs,
  numeric,
} from "vuelidate/lib/validators";
const storeMixin = {
  validations: {
    form: {
      name: { required },
      phone_no: {
        required,
        minLength: minLength(8),
        maxLength: maxLength(10),
      },
      desc: { required },
      checkbox: {
        checked(val) {
          return val;
        },
      },
    },
    branch: {
      name: { required },
      phone_no: {
        required,
        minLength: minLength(8),
        maxLength: maxLength(10),
      },
      desc: { required },
    },
  },
  computed: {
    checkboxErrors() {
      const errors = [];
      if (!this.$v.form.checkbox.$dirty) return errors;
      const msg = this.$t("validation_required");

      !this.$v.form.checkbox.checked && errors.push(msg);
      return errors;
    },
    nameError() {
      const errors = [];
      if (!this.$v.form.name.$dirty) return errors;
      const msg = this.$t("validation_required");

      !this.$v.form.name.required && errors.push(msg);
      return errors;
    },
    phoneNumberError() {
      const errors = [];
      if (!this.$v.form.phone_no.$dirty) return errors;
      const msg = this.$t("validation_required");
      !this.$v.form.phone_no.required && errors.push(msg);

      if (!this.$v.form.phone_no.minLength) {
        const msg = this.$t("phone_min_length");
        errors.push(msg);
      }
      if (!this.$v.form.phone_no.maxLength) {
        const msg = this.$t("phone_max_length");
        errors.push(msg);
      }
      return errors;
    },
    descError() {
      const errors = [];
      if (!this.$v.form.desc.$dirty) return errors;
      const msg = this.$t("validation_required");
      !this.$v.form.desc.required && errors.push(msg);
      return errors;
    },
    branchNameError() {
      const errors = [];
      if (!this.$v.branch.name.$dirty) return errors;
      const msg = this.$t("validation_required");
      !this.$v.branch.name.required && errors.push(msg);
      return errors;
    },
    branchPhoneNumberError() {
      const errors = [];
      if (!this.$v.branch.phone_no.$dirty) return errors;
      const msg = this.$t("validation_required");
      !this.$v.branch.phone_no.required && errors.push(msg);
      if (!this.$v.form.phone_no.minLength) {
        const msg = this.$t("phone_min_length");
        errors.push(msg);
      }
      if (!this.$v.form.phone_no.maxLength) {
        const msg = this.$t("phone_max_length");
        errors.push(msg);
      }

      return errors;
    },
    branchDescError() {
      const errors = [];
      if (!this.$v.branch.desc.$dirty) return errors;
      const msg = this.$t("validation_required");
      !this.$v.branch.desc.required && errors.push(msg);
      return errors;
    },
  },
};

const addProduct = {
  validations: {
    form: {
      code: {
        required,
        minLength: minLength(5),
        maxLength: maxLength(13),
      },
      name: { required },
      notify_stock: {
        required,
        numeric,
      },
      price_sale: { required },
      price_buy: { required },
      category_id: { required },
      size_id: { required },
      vendor_id: { required },
    },
  },
  computed: {
    codeError() {
      const errors = [];
      if (!this.$v.form.code.$dirty) return errors;
      const msg = this.$t("validation_required");
      !this.$v.form.code.required && errors.push(msg);
      if (!this.$v.form.code.minLength) {
        const msg = this.$t("code_min_length");
        errors.push(msg);
      }
      if (!this.$v.form.code.maxLength) {
        const msg = this.$t("code_max_length");
        errors.push(msg);
      }
      return errors;
    },
    nameError() {
      const errors = [];
      if (!this.$v.form.name.$dirty) return errors;
      const msg = this.$t("validation_required");
      !this.$v.form.name.required && errors.push(msg);
      return errors;
    },

    notify_stockError() {
      const errors = [];
      if (!this.$v.form.notify_stock.$dirty) return errors;
      if (!this.$v.form.notify_stock.required) {
        const msg = this.$t("validation_required");
        errors.push(msg);
      }
      //   should be number
      if (!this.$v.form.notify_stock.numeric) {
        const msg = this.$t("validation_required");
        errors.push(msg);
      }
      return errors;
    },
    price_saleError() {
      const errors = [];
      if (!this.$v.form.price_sale.$dirty) return errors;
      const msg = this.$t("validation_required");
      !this.$v.form.price_sale.required && errors.push(msg);
      return errors;
    },
    price_buyError() {
      const errors = [];
      if (!this.$v.form.price_buy.$dirty) return errors;
      const msg = this.$t("validation_required");
      !this.$v.form.price_buy.required && errors.push(msg);
      return errors;
    },
    category_idError() {
      const errors = [];
      if (!this.$v.form.category_id.$dirty) return errors;
      if (!this.$v.form.category_id.required) {
        const msg = this.$t("validation_required");
        errors.push(msg);
      }
      return errors;
    },
    size_idError() {
      const errors = [];
      if (!this.$v.form.size_id.$dirty) return errors;
      if (!this.$v.form.size_id.required) {
        const msg = this.$t("validation_required");
        errors.push(msg);
      }
      return errors;
    },
    vendor_idError() {
      const errors = [];
      if (!this.$v.form.vendor_id.$dirty) return errors;
      if (!this.$v.form.vendor_id.required) {
        const msg = this.$t("validation_required");
        errors.push(msg);
      }
      return errors;
    },
  },
};
const CategoryValidate = {
  validations: {
    form: {
      la_name: { required },
    },
  },
  computed: {
    nameError() {
      const errors = [];
      if (!this.$v.form.la_name.$dirty) return errors;
      if (!this.$v.form.la_name.required) {
        const msg = this.$t("validation_required");
        errors.push(msg);
      }
      return errors;
    },
  },
};
const sizeValidate = {
  validations: {
    form: {
      name: { required },
      unit: { required },
      amount: { required },
    },
  },
  computed: {
    nameError() {
      const errors = [];
      if (!this.$v.form.name.$dirty) return errors;
      const msg = this.$t("validation_required");
      !this.$v.form.name.required && errors.push(msg);
      return errors;
    },
    unitError() {
      const errors = [];
      if (!this.$v.form.unit.$dirty) return errors;
      const msg = this.$t("validation_required");
      !this.$v.form.unit.required && errors.push(msg);
      return errors;
    },
    amountError() {
      const errors = [];
      if (!this.$v.form.amount.$dirty) return errors;
      const msg = this.$t("validation_required");
      !this.$v.form.amount.required && errors.push(msg);
      return errors;
    },
  },
};
const expenseCatValidate = {
  validations: {
    form: {
      name: { required },
    },
  },
  computed: {
    nameError() {
      const errors = [];
      if (!this.$v.form.name.$dirty) return errors;
      if (!this.$v.form.name.required) {
        const msg = this.$t("validation_required");
        errors.push(msg);
      }
      return errors;
    },
  },
};
const expenseValidate = {
  validations: {
    form: {
      name: { required },
      price: { required },
      category_id: { required },
      type: { required },
    },
  },
  computed: {
    nameError() {
      const errors = [];
      if (!this.$v.form.name.$dirty) return errors;
      const msg = this.$t("validation_required");
      !this.$v.form.name.required && errors.push(msg);
      return errors;
    },
    priceError() {
      const errors = [];
      if (!this.$v.form.price.$dirty) return errors;
      const msg = this.$t("validation_required");
      !this.$v.form.price.required && errors.push(msg);
      return errors;
    },
    category_idError() {
      const errors = [];
      if (!this.$v.form.category_id.$dirty) return errors;
      if (!this.$v.form.category_id.required) {
        const msg = this.$t("validation_required");
        errors.push(msg);
      }
      return errors;
    },
    typeError() {
      const errors = [];
      if (!this.$v.form.type.$dirty) return errors;
      if (!this.$v.form.type.required) {
        const msg = this.$t("validation_required");
        errors.push(msg);
      }
      return errors;
    },
  },
};
const customerValidate = {
  validations: {
    form: {
      first_name: { required },
      last_name: { required },
      phone_no: {
        required,
        minLength: minLength(8),
        maxLength: maxLength(10),
      },
      point: { required },
    },
  },
  computed: {
    first_nameError() {
      const errors = [];
      if (!this.$v.form.first_name.$dirty) return errors;
      const msg = this.$t("validation_required");
      !this.$v.form.first_name.required && errors.push(msg);
      return errors;
    },
    last_nameError() {
      const errors = [];
      if (!this.$v.form.last_name.$dirty) return errors;
      if (!this.$v.form.last_name.required) {
        const msg = this.$t("validation_required");
        errors.push(msg);
      }
      return errors;
    },
    phone_numberError() {
      const errors = [];
      if (!this.$v.form.phone_no.$dirty) return errors;
      if (!this.$v.form.phone_no.required) {
        const msg = this.$t("validation_required");
        errors.push(msg);
      }
      if (!this.$v.form.phone_no.minLength) {
        const msg = this.$t("phone_min_length");
        errors.push(msg);
      }
      if (!this.$v.form.phone_no.maxLength) {
        const msg = this.$t("phone_max_length");
        errors.push(msg);
      }
      return errors;
    },
    pointError() {
      const errors = [];
      if (!this.$v.form.point.$dirty) return errors;
      if (!this.$v.form.point.required) {
        const msg = this.$t("validation_required");
        errors.push(msg);
      }
      return errors;
    },
  },
};

const returnProduct = {
  validations: {
    body: {
      return_type: { required },
      remark: { required },
    },
  },
  computed: {
    return_typeError() {
      const errors = [];
      if (!this.$v.body.return_type.$dirty) return errors;
      const msg = this.$t("validation_required");
      !this.$v.body.return_type.required && errors.push(msg);
      return errors;
    },
    remarkError() {
      const errors = [];
      if (!this.$v.body.remark.$dirty) return errors;
      if (!this.$v.body.remark.required) {
        const msg = this.$t("validation_required");
        errors.push(msg);
      }
      return errors;
    },
  },
};

const AddUserStore = {
  validations: {
    form: {
      first_name: { required },
      role: { required },
      branch_id: { required },
      phone_no: { required, minLength: minLength(8), maxLength: maxLength(10) },
      password: { required, minLength: minLength(8) },
      confirm_password: { required, sameAsPassword: sameAs("password") },
      email: {
        email,
        required,
      },
    },
    formChange: {
      password: { required, minLength: minLength(8) },
      confirm_password: { required, sameAsPassword: sameAs("password") },
    },
  },

  computed: {
    first_nameError() {
      const errors = [];
      if (!this.$v.form.first_name.$dirty) return errors;
      const msg = this.$t("validation_required");
      !this.$v.form.first_name.required && errors.push(msg);
      return errors;
    },
    roleErrors() {
      const errors = [];
      if (!this.$v.form.role.$dirty) return errors;
      const msg = this.$t("validation_required");
      !this.$v.form.role.required && errors.push(msg);
      return errors;
    },
    branch_idErrors() {
      const errors = [];
      if (!this.$v.form.branch_id.$dirty) return errors;
      const msg = this.$t("validation_required");
      !this.$v.form.branch_id.required && errors.push(msg);
      return errors;
    },
    phone_numberError() {
      const errors = [];
      if (!this.$v.form.phone_no.$dirty) return errors;

      if (!this.$v.form.phone_no.minLength) {
        const msg = this.$t("phone_min_length");
        errors.push(msg);
      }
      if (!this.$v.form.phone_no.maxLength) {
        const msg = this.$t("phone_max_length");
        errors.push(msg);
      }
      return errors;
    },
    emailError() {
      const errors = [];
      if (!this.$v.form.email.$dirty) return errors;
      if (!this.$v.form.email.required) {
        const msg = this.$t("validation_required");
        errors.push(msg);
      }
      if (!this.$v.form.email.email) {
        const msg = this.$t("email_invalid");
        errors.push(msg);
      }
      return errors;
    },
    passwordErrors() {
      const errors = [];
      if (!this.$v.form.password.$dirty) return errors;
      if (!this.$v.form.password.required) {
        const msg = this.$t("validation_required");
        errors.push(msg);
      }
      if (!this.$v.form.password.minLength) {
        const msg = this.$t("password_min_length");
        errors.push(msg);
      }
      return errors;
    },
    confirmPasswordErrors() {
      const errors = [];
      if (!this.$v.form.confirm_password.$dirty) return errors;
      if (!this.$v.form.confirm_password.required) {
        const msg = this.$t("validation_required");
        errors.push(msg);
      }

      if (!this.$v.form.confirm_password.sameAsPassword) {
        const msg = this.$t("password_not_match");
        errors.push(msg);
      }
      return errors;
    },
    passwordErrorsC() {
      const errors = [];
      if (!this.$v.formChange.password.$dirty) return errors;
      if (!this.$v.formChange.password.required) {
        const msg = this.$t("validation_required");
        errors.push(msg);
      }
      if (!this.$v.formChange.password.minLength) {
        const msg = this.$t("password_min_length");
        errors.push(msg);
      }
      return errors;
    },
    confirmPasswordErrorsC() {
      const errors = [];
      if (!this.$v.formChange.confirm_password.$dirty) return errors;
      if (!this.$v.formChange.confirm_password.required) {
        const msg = this.$t("validation_required");
        errors.push(msg);
      }
      if (!this.$v.formChange.confirm_password.sameAsPassword) {
        const msg = this.$t("password_not_match");
        errors.push(msg);
      }

      return errors;
    },
  },
};

const VENDOR = {
  validations: {
    form: {
      name: { required },
      address: { required },
      phone_no: {
        required,
        minLength: minLength(8),
        maxLength: maxLength(10),
      },
      mobile_no: {
        required,
        minLength: minLength(8),
        maxLength: maxLength(10),
      },
    },
  },

  computed: {
    nameErrors() {
      const errors = [];
      if (!this.$v.form.name.$dirty) return errors;
      const msg = this.$t("validation_required");
      !this.$v.form.name.required && errors.push(msg);
      return errors;
    },
    addressErrors() {
      const errors = [];
      if (!this.$v.form.address.$dirty) return errors;
      const msg = this.$t("validation_required");
      !this.$v.form.address.required && errors.push(msg);
      return errors;
    },
    phone_noErrors() {
      const errors = [];
      if (!this.$v.form.phone_no.$dirty) return errors;
      const msg = this.$t("validation_required");
      !this.$v.form.phone_no.required && errors.push(msg);
      if (!this.$v.form.phone_no.minLength) {
        const msg = this.$t("phone_min_length");
        errors.push(msg);
      }
      if (!this.$v.form.phone_no.maxLength) {
        const msg = this.$t("phone_max_length");
        errors.push(msg);
      }
      return errors;
    },
    mobile_noErrors() {
      const errors = [];
      if (!this.$v.form.mobile_no.$dirty) return errors;
      if (!this.$v.form.mobile_no.required) {
        const msg = this.$t("validation_required");
        errors.push(msg);
      }
      if (!this.$v.form.mobile_no.minLength) {
        const msg = this.$t("phone_min_length");
        errors.push(msg);
      }
      if (!this.$v.form.mobile_no.maxLength) {
        const msg = this.$t("phone_max_length");
        errors.push(msg);
      }
      return errors;
    },
  },
};

const BRANCHES = {
  validations: {
    form: {
      name: { required },
      address: { required },
      phone_no: {
        required,
        minLength: minLength(8),
        maxLength: maxLength(10),
      },
      mobile_no: {
        required,
        minLength: minLength(8),
        maxLength: maxLength(10),
      },
    },
  },

  computed: {
    nameErrors() {
      const errors = [];
      if (!this.$v.form.name.$dirty) return errors;
      const msg = this.$t("validation_required");
      !this.$v.form.name.required && errors.push(msg);
      return errors;
    },
    addressErrors() {
      const errors = [];
      if (!this.$v.form.address.$dirty) return errors;
      const msg = this.$t("validation_required");
      !this.$v.form.address.required && errors.push(msg);
      return errors;
    },
    phone_noErrors() {
      const errors = [];
      if (!this.$v.form.phone_no.$dirty) return errors;
      const msg = this.$t("validation_required");
      !this.$v.form.phone_no.required && errors.push(msg);
      if (!this.$v.form.phone_no.minLength) {
        const msg = this.$t("phone_min_length");
        errors.push(msg);
      }
      if (!this.$v.form.phone_no.maxLength) {
        const msg = this.$t("phone_max_length");
        errors.push(msg);
      }
      return errors;
    },
    mobile_noErrors() {
      const errors = [];
      if (!this.$v.form.mobile_no.$dirty) return errors;
      const msg = this.$t("validation_required");
      !this.$v.form.mobile_no.required && errors.push(msg);
      if (!this.$v.form.mobile_no.minLength) {
        const msg = this.$t("phone_min_length");
        errors.push(msg);
      }
      if (!this.$v.form.mobile_no.maxLength) {
        const msg = this.$t("phone_max_length");
        errors.push(msg);
      }
      return errors;
    },
  },
};

const TABLE_VALIDATE = {
  validations: {
    form: {
      name: { required },
    },
  },

  computed: {
    nameError() {
      const errors = [];
      if (!this.$v.form.name.$dirty) return errors;
      const msg = this.$t("validation_required");
      !this.$v.form.name.required && errors.push(msg);
      return errors;
    },
  },
};

export {
  expenseValidate,
  customerValidate,
  storeMixin,
  addProduct,
  CategoryValidate,
  sizeValidate,
  returnProduct,
  expenseCatValidate,
  AddUserStore,
  VENDOR,
  BRANCHES,
  TABLE_VALIDATE,
};
