<template>
  <div class="pa-3">
    <v-btn
      v-if="new_quatation"
      @click="addQuotation()"
      class="font-weight-bold white--text"
      x-large
      depressed
      block
      color="primary"
    >
      <v-icon small left> fal fa-quote-right </v-icon>
      {{ quotationText }}
    </v-btn>
    <v-btn
      v-else
      @click="store_type === 'POS' ? dialogPayOpen() : fetchTable()"
      class="font-weight-bold white--text"
      x-large
      depressed
      block
      color="primary"
    >
      <v-icon small left> fal fa-money-bill-wave-alt </v-icon>
      {{ store_type === "POS" ? $t("payment") : $t("choose_table") }}
      <span class="body font-weight-light">(F12)</span>
    </v-btn>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  props: {
    new_quatation: {
      type: Boolean,
      default: false,
    },
    store_type: {
      type: String,
      default: "",
    },
  },
  computed: {
    ...mapGetters({
      newQuotation: "index/newQuotation",
    }),
    quotationText() {
      const doc = this.newQuotation.document;
      if (this.newQuotation && this.newQuotation.update) {
        return this.$t(`update_${doc}`);
      }
      return this.$t(`add_${doc}`);
    },
  },
  methods: {
    addQuotation() {
      this.$emit("addQuotation");
    },
    fetchTable() {
      this.$emit("fetchTable");
    },
    dialogPayOpen() {
      this.$emit("dialogPayOpen");
    },
  },
};
</script>

<style></style>
