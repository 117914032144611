<template>
  <v-container fill-height class="bg-white">
    <v-btn color="primary" @click="$router.back()" fab outlined icon small>
      <v-icon small>fal fa-chevron-left</v-icon>
    </v-btn>
    <v-card>
      <v-card-text class="overflow-auto">
        <h1>Terms of Service</h1>
        <p>
          Welcome to PosSmart! By using our services, you agree to be bound by
          the following terms and conditions:
        </p>
        <h4>1. Use of Our Services</h4>
        <p>
          PosSmart provides a point-of-sale system for small businesses to
          manage their sales, inventory, and customer data from a central
          location accessible through the internet. You may use our services for
          lawful purposes and in compliance with these Terms of Service.
        </p>
        <h4>2. Intellectual Property Rights</h4>
        <p>
          The content, features, and functionality of PosSmart are owned by us
          and are protected by international copyright, trademark, patent, trade
          secret, and other intellectual property or proprietary rights laws.
        </p>
        <h4>3. Sale and Discount</h4>
        <p>
          Our system allows you to make sales and apply discounts to products at
          your discretion. You are responsible for ensuring that all sales and
          discounts are in compliance with applicable laws and regulations.
        </p>
        <h4>4. Product and Stock Management</h4>
        <p>
          You may use our system to manage your product inventory and stock
          levels. You are responsible for ensuring that your inventory and stock
          levels are accurate and up-to-date.
        </p>
        <h4>5. Customer Management</h4>
        <p>
          You may use our system to manage your customer relationships and data.
          You are responsible for ensuring that all customer data is accurate
          and up-to-date, and that you comply with all applicable laws and
          regulations regarding the use and protection of customer data.
        </p>
        <h4>6. Expense Management</h4>
        <p>
          Our system includes expense management features to help you track your
          business expenses. You are responsible for ensuring that all expenses
          are accurately recorded and that you comply with all applicable laws
          and regulations regarding the reporting and management of business
          expenses.
        </p>
        <h4>7. Sale History</h4>
        <p>
          You may use our system to view your sales history and generate
          reports. You are responsible for ensuring that all sales data is
          accurate and up-to-date, and that you comply with all applicable laws
          and regulations regarding the use and reporting of sales data.
        </p>
        <h4>8. Tickets and Receipts</h4>
        <p>
          Our system allows you to generate tickets and receipts for your
          customers. You are responsible for ensuring that all tickets and
          receipts accurately reflect the products and services provided, and
          that you comply with all applicable laws and regulations regarding the
          use and issuance of tickets and receipts.
        </p>
        <h4>9. Cash Back</h4>
        <p>
          Our system includes cash back features to allow you to provide refunds
          to your customers. You are responsible for ensuring that all refunds
          are accurately processed and that you comply with all applicable laws
          and regulations regarding refunds and returns.
        </p>
        <h4>10. User Permissions</h4>
        <p>
          You may use our system to manage user permissions and access levels.
          You are responsible for ensuring that all user access and permissions
          are appropriately set and that you comply with all applicable laws and
          regulations regarding user access and data security.
        </p>
        <h4>11. Vendor and Branch Management</h4>
        <p>
          You may use our system to manage your vendors and branches. You are
          responsible for ensuring that all vendor and branch data is accurate
          and up-to-date, and that you comply with all applicable laws and
          regulations regarding vendor and branch management.
        </p>
        <h4>12. Currency Management</h4>
        <p>
          Our system includes currency management features to allow you to work
          with multiple currencies. You are responsible for ensuring that all
          currency conversions are accurately processed and that you comply with
          all applicable laws and regulations regarding currency exchange.
        </p>
        <h4>13. Store Management</h4>
        <p>
          You may use our system to manage multiple stores. You are responsible
          for ensuring that all store data is accurate and up-to-date, and that
          you comply with all applicable laws and regulations regarding store
          management.
        </p>
        <h4>14. User Management</h4>
        <p>
          You may use our system to manage user accounts and access. You are
          responsible for ensuring that all user data is accurate and
          up-to-date, and that you comply with all applicable laws and
          regulations regarding user data and privacy.
        </p>
        <h4>15. Limitation of Liability</h4>
        <p>
          In no event shall PosSmart or its affiliates, directors, employees,
          agents, suppliers, or licensors be liable for any direct, indirect,
          punitive, incidental, special, or consequential damages arising out of
          or in any way connected with the use of our services or any
          information or content provided on our website or through our
          services.
        </p>
        <h4>16. Indemnification</h4>
        <p>
          You agree to indemnify and hold PosSmart and its affiliates,
          directors, employees, agents, suppliers, and licensors harmless from
          any claim or demand, including reasonable attorneys' fees, made by any
          third-party due to or arising out of your breach of these Terms of
          Service or your violation of any law or the rights of a third-party.
        </p>
        <h4>17. Governing Law</h4>
        <p>
          These Terms of Service shall be governed by and construed in
          accordance with the laws of [insert your country or state here],
          without regard to its conflict of law provisions.
        </p>
        <h4>18. Changes to Terms of Service</h4>
        <p>
          We reserve the right to modify or replace these Terms of Service at
          any time. If a revision is material we will provide at least 30 days'
          notice prior to any new terms taking effect. What constitutes a
          material change will be determined at our sole discretion.
        </p>
        <h4>19. Contact Us</h4>
        <p>
          If you have any questions about these Terms of Service, please contact
          us at at vongkeokeosavanh@gmail.com or phone at 02028492947. You can
          also visit their website at www.devla.la or www.pos.devla.la
        </p>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
export default {};
</script>

<style></style>
