<template>
  <v-list dense>
    <template v-for="(item, index) in defineStore.cart">
      <v-list-item :key="item.item.name + index">
        <v-list-item-avatar tile class="rounded-lg">
          <app-img :img="item.item.img" />
        </v-list-item-avatar>

        <v-list-item-content>
          <v-list-item-title> {{ item.item.name }}</v-list-item-title>
          <v-list-item-subtitle>
            {{ item.price | NumberFormat }}
          </v-list-item-subtitle>
        </v-list-item-content>
        <v-list-item-action>
          <v-row>
            <v-col cols="12">
              <v-btn
                color="#f0f0f0"
                fab
                x-small
                depressed
                @click="decrementCart(item.item)"
              >
                <v-icon x-small color="red">fal fa-minus</v-icon>
              </v-btn>

              <v-chip
                label
                class="mx-1"
                @click="$emit('dialogQTY', { item: item.item, qty: item.qty })"
              >
                {{ item.qty }}
              </v-chip>

              <v-btn
                color="#f0f0f0"
                fab
                x-small
                depressed
                @click="incrementCart(item.item)"
              >
                <v-icon color="green" x-small>fal fa-plus</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-list-item-action>
      </v-list-item>

      <v-divider
        v-if="index < defineStore.cart.length - 1"
        :key="index"
        inset
        class="my-0"
      ></v-divider>
    </template>
  </v-list>
</template>

<script>
import { myMixin } from "@/mixin/main";
import { mainStore } from "@/stores/modules/cart";

export default {
  mixins: [myMixin],
  data: () => ({
    defineStore: mainStore(),
  }),
  created() {},
  methods: {
    async decrementCart(data) {
      this.qty = this.qty - 1;
      const body = {
        payload: data,
      };
      this.defineStore.decrementCart(body);
    },
    async incrementCart(data) {
      this.qty = this.qty + 1;
      const body = {
        that: this,
        payload: data,
      };
      this.defineStore.addToCart(body);
    },
  },
};
</script>

<style></style>
