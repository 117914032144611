<template>
    <section class="bg-white">
        <v-container fill-height>
            <v-row justify="center" align="center">
                <v-col cols="12">
                    <v-card light flat>
                        <v-card-title class="justify-center display-4"
                            >404</v-card-title
                        >
                        <v-card-title class="justify-center display-2"
                            >Look like you're lost</v-card-title
                        >
                        <v-card-text
                            class="justify-center text-center text-capitalize"
                        >
                            the page you are looking for not avaible!
                        </v-card-text>
                        <div class="four_zero_four_bg" />
                        <v-card-actions class="justify-center text-center">
                            <v-btn
                                class="px-12"
                                @click="$router.go(-1)"
                                x-large
                                color="primary"
                                depressed
                            >
                                ກັບຄືນ
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
    </section>
</template>

<script>
export default {
    head() {
        return {
            title: "404"
        };
    }
};
</script>

<style scoped>
/*======================
    404 page
=======================*/
.bg-white {
    background-color: #fff;
    height: 100%;
}
.page_404 {
    padding: 40px 0;
    background: #fff;
    font-family: "Arvo", serif;
}

.page_404 img {
    width: 100%;
}

.four_zero_four_bg {
    background-image: url(https://cdn.dribbble.com/users/285475/screenshots/2083086/dribbble_1.gif);
    height: 400px;
    background-position: center;
}

.four_zero_four_bg h1 {
    font-size: 80px;
}

.four_zero_four_bg h3 {
    font-size: 80px;
}

.link_404 {
    color: #fff !important;
    padding: 10px 20px;
    background: #39ac31;
    margin: 20px 0;
    display: inline-block;
}
.contant_box_404 {
    margin-top: -50px;
}
</style>
