<template>
  <section>
    <data-table
      :headers="headers"
      :items="customers"
      commit="SET_CUSTOMER"
      :viewItem="viewItem"
    >
      <div slot="activator" class="row justify-content-between">
        <v-col cols="2">
          <v-card-title class="px-0 pt-0"> Customers </v-card-title>
        </v-col>
      </div>
    </data-table>
    <app-custom-dialog v-model="dialog" :footer="false" title="Detail">
      <div slot="activator">
        <v-row>
          <v-col cols="4">ID</v-col>
          <v-col cols="8">{{ form.id }}</v-col>
          <v-col cols="4">First Name</v-col>
          <v-col cols="8">{{ form.first_name }}</v-col>
          <v-col cols="4">Last Name</v-col>
          <v-col cols="8">{{ form.last_name }}</v-col>
          <v-col cols="4">Phone No</v-col>
          <v-col cols="8">{{ form.phone_no }}</v-col>
          <v-col cols="4">Point</v-col>
          <v-col cols="8">{{ form.point }}</v-col>
          <v-col cols="4">Address</v-col>
          <v-col cols="8">{{ form.address }}</v-col>
          <v-col cols="4">Date</v-col>
          <v-col cols="8">{{ form.created_at | moment }}</v-col>
        </v-row>
      </div>
    </app-custom-dialog>

    <!-- AppLoading -->
    <app-loading :overlay="overlay" />
  </section>
</template>

<script>
import DataTable from "@/components/DataTable";
import { mapGetters, mapActions } from "vuex";
import { customerHead } from "@/utils/head";
import TTextField from "@/components/global/TextField";
import { customerValidate } from "@/validations/shop";
import { myMixin } from "@/mixin/main";

export default {
  mixins: [customerValidate, myMixin],

  data: () => ({
    overlay: false,
    search: "",
    update: false,
    dialogAdd: false,
    dialog: false,
    headers: customerHead(),
    form: {},
  }),
  async created() {
    await this.fetchItem();
  },
  components: { DataTable, TTextField },
  computed: {
    ...mapGetters({
      customers: "index/customers",
    }),
  },

  methods: {
    ...mapActions({
      fetch: "index/fetch",
    }),
    async fetchItem() {
      const { store_id } = this.$route.params;
      const body = {
        that: this,
        url: `get_shop_customers/${store_id}`,
        commit: "SET_CUSTOMER",
      };
      await this.fetch(body);
    },

    viewItem(item) {
      this.form = item;
      this.dialog = !this.dialog;
    },
  },
};
</script>

<style></style>
