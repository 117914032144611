<template>
  <v-col cols="12" class="px-0">
    <v-card class="rounded-lg">
      <v-card-title> {{ $t("feedback") }}</v-card-title>
      <v-card-text>
        <v-form @submit.prevent="submitForm(form)">
          <v-card>
            <v-card-text class="px-0">
              <TTextField label="title" v-model="form.title" />
              <v-textarea
                name="input-7-1"
                filled
                outlined
                flat
                v-model="form.desc"
                class=""
                placeholder="description"
              ></v-textarea>
            </v-card-text>
            <v-card-actions>
              <v-btn type="submit" large class="px-10" depressed>
                {{ $t("submitText") }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-form>
      </v-card-text>
    </v-card>
  </v-col>
</template>

<script>
import TTextField from "@/components/global/TextField";
import { success } from "@/controllers/alert";

export default {
  data: () => ({
    form: {
      title: null,
      desc: null,
    },
  }),
  components: {
    TTextField,
  },
  methods: {
    async submitForm(form) {
      const { data } = await this.$http.post("feedback", form);
      if (data.status) {
        await success(this, data.message);
        await Help.clearForm(this.form, this);
      }
    },
  },
};
</script>

<style></style>
