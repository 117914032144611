<template>
  <div id="watermark">
    <v-container id="invoice">
      <v-card rounded="lg" class="pa-5">
        <v-card-text>
          <div class="d-flex justify-space-between">
            <div
              class="text-capitalize px-0 title font-weight-bolder primary--text font"
            >
              PosSmart
            </div>
            <div class="font text-right">
              {{ $t("receipt_no") }} :
              {{ item.bill_no }}
              <br />
              {{ $t("date") }} :
              {{ item.created_at | moment }}
            </div>
          </div>
        </v-card-text>

        <v-card-title
          class="font-weight-bold d-flex justify-center text-h4 font"
        >
          {{ $t("receipt") }}
        </v-card-title>
        <v-card-text class="pt-12 pb-4">
          <v-row>
            <v-col cols="6"
              ><div class="font-weight-bold font">{{ $t("bill_to") }}</div>
              <div class="font primary--text">
                {{ store.name }}
              </div>
              <div class="font">
                {{ store.address }}
                <br />
                {{ store.mobile_no }}
              </div></v-col
            >
            <v-col cols="6" class="text-right"
              ><div class="font-weight-bold font">{{ $t("payable_to") }}</div>
              <div class="font">PosSmart</div>
              <div class="font">
                {{ $t("my_address") }}
                <br />
                020 2849 2947
              </div></v-col
            >
          </v-row>
        </v-card-text>
        <v-card-text>
          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="font text-left">{{ $t("package") }}</th>
                  <th class="font text-left">{{ $t("status") }}</th>
                  <th class="font text-left">{{ $t("price") }}</th>
                  <th class="text-right font">{{ $t("quantity") }}</th>
                  <th class="text-right font">{{ $t("total") }}</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td class="font text-left">{{ item.package.name }}</td>
                  <td class="font text-left">{{ item.status }}</td>
                  <td class="font text-left">
                    {{ item.price | NumberFormat }}
                  </td>
                  <td class="text-right font">{{ item.month_count }}</td>
                  <td class="text-right font">
                    {{ (item.price * item.month_count) | NumberFormat }}
                  </td>
                </tr>
                <tr>
                  <td colspan="4" class="text-right font-weight-bold font">
                    {{ $t("total") }}
                  </td>
                  <td class="font-weight-bold text-right font">
                    {{ (item.price * item.month_count) | NumberFormat }}
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card-text>
        <v-card-text class="pt-4">
          <h5 class="font">Thank you for your business</h5>
          <p class="ma-0 font">PosSmart Team</p>
          <a
            href="https://pos.devla.la"
            target=" _blank"
            class="text-lowercase pa-0 font"
          >
            https://pos.devla.la
          </a>
        </v-card-text>
      </v-card>
    </v-container>
  </div>
</template>

<script>
import { myMixin } from "@/mixin/main";
export default {
  mixins: [myMixin],
  props: {
    item: {
      type: Object,
      default() {
        return {
          package: {
            name: "",
          },
        };
      },
    },
  },

  computed: {
    store() {
      return this.user.stores;
    },
  },
};
</script>
