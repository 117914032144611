<template>
  <section>
    <v-row>
      <v-col cols="6">
        <v-card>
          <v-card-title>{{ $t("bill_customization") }}</v-card-title>
          <v-card-text>
            <v-row>
              <v-col cols="12">
                <CheckBox v-model="show_logo" label="show_logo" />
                <CheckBox v-model="branch" label="branch" />
                <CheckBox v-model="customer_point" label="customer_point" />
                <CheckBox v-model="customer_address" label="customer_address" />
                <CheckBox v-model="thb_currency" label="thb_currency" />
                <CheckBox v-model="usd_currency" label="usd_currency" />
                <CheckBox v-model="tax_include" label="show_vat" />
                <CheckBox v-model="barcode" label="barcode" />
                <CheckBox v-model="show_qr" label="qr" />
                <TTextField label="footer_bill" v-model="footer_text" />
              </v-col>
            </v-row>
          </v-card-text>

          <v-card-actions class="pr-8 pt-0 mt-0 pb-4">
            <v-spacer />
            <v-btn
              color="primary"
              depressed
              class="px-10"
              @click="updateBill"
              >{{ $t("save") }}</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-col>
      <v-col cols="6">
        <v-card>
          <v-card-title>{{ $t("example_bill") }}</v-card-title>
          <v-card-text>
            <AppReceipt
              :item="item"
              :show_logo="show_logo"
              :branch="branch"
              :customer_point="customer_point"
              :customer_address="customer_address"
              :thb_currency="thb_currency"
              :tax_include="tax_include"
              :usd_currency="usd_currency"
              :barcode="barcode"
              :show_qr="show_qr"
              :footer_text="footer_text"
            />
          </v-card-text>

          <v-card-actions class="pr-8 pt-0 mt-0 pb-4">
            <v-spacer />
            <v-btn
              color="primary"
              depressed
              outlined
              class="px-4"
              @click="printBill"
            >
              <v-icon small left> fal fa-print </v-icon>
              {{ $t("print") }}</v-btn
            >
            <v-spacer />
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </section>
</template>

<script>
import TTextField from "@/components/global/TextField";
import { billPOS } from "@/utils/mock";
import { success } from "@/controllers/alert";
import AppReceipt from "@/components/settings/bills/AppReceipt";
import { mapGetters } from "vuex";
import { setUser } from "@/controllers/auth";
import CheckBox from "@/components/global/CheckBox";

export default {
  components: {
    TTextField,
    AppReceipt,
    CheckBox,
  },
  data: () => ({
    item: billPOS(),
    show_logo: true,
    branch: true,
    customer_point: true,
    customer_address: true,
    tax_include: true,
    thb_currency: true,
    usd_currency: true,
    barcode: true,
    show_qr: true,
    footer_text: "ຂອບໃຈທຸກທ່ານທີ່ມາອຸດໜູນ",
  }),

  mounted() {
    this.setValue();
  },

  computed: {
    ...mapGetters({
      user: "auth/user",
    }),
  },

  methods: {
    async setValue() {
      const store = this.user.stores;
      this.show_logo = this._convertValue(store.show_logo);
      this.branch = this._convertValue(store.branch);
      this.customer_point = this._convertValue(store.customer_point);
      this.customer_address = this._convertValue(store.customer_address);
      this.thb_currency = this._convertValue(store.thb_currency);
      this.usd_currency = this._convertValue(store.usd_currency);
      this.barcode = this._convertValue(store.barcode);
      this.show_qr = this._convertValue(store.show_qr);
      this.footer_text = store.footer_text;
      this.tax_include = this._convertValue(store.tax_include);
    },

    _convertValue(value) {
      return value ? true : false;
    },

    async updateBill() {
      const form = {
        show_logo: this.show_logo,
        branch: this.branch,
        customer_point: this.customer_point,
        customer_address: this.customer_address,
        thb_currency: this.thb_currency,
        usd_currency: this.usd_currency,
        barcode: this.barcode,
        show_qr: this.show_qr,
        footer_text: this.footer_text,
        tax_include: this.tax_include,
      };
      const { data } = await this.$http.post("store_bill_update", form);
      if (data.status) {
        setUser(this);
        await success(this, data.message);
      }
    },

    printBill() {
      setTimeout(() => {
        this.$htmlToPaper("printBill");
      }, 500);
    },
  },
};
</script>

<style></style>
