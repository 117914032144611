<template>
  <section>
    <v-card color="primary" tile class="white--text text-center">
      <v-card-text>
        <div class="container-xl">
          <v-card-text>
            <v-btn
              v-for="(item, index) in link"
              :key="index"
              class="mx-4 white--text"
              icon
            >
              <v-btn icon :href="item.to" target="_blank">
                <v-icon color="white" size="24px">
                  {{ item.icon }}
                </v-icon>
              </v-btn>
            </v-btn>
          </v-card-text>

          <v-card-text class="white--text pt-0">
            ລະບົບ PosSmart ແມ່ນລະບົບຈັດການໜ້າຮ້ານຄ້າ, ເຊິ່ງເປັນລະບົບຂາຍສິນຄ້າແບບ
            Online, ທີ່ໃຊ້ງານງ່າຍສະດວກແລະວ່ອງໄວ,ນອກຈາກນັ້ນລະບົບຍັງສາມາດນຳໃຊ້
            Tablet, Mobile ເຂົ້າໄປໃຊ້ງ່າຍໄດ້ແບບສະດວກສະບາຍ
            ເພາະທາງເຮົາໄດ້ອອກແບບໜ້າຕາໃຫ້ໃຊ້ງານງ່າຍ ແລະ ດວກທີ່ສຸດ,
            ເຊິ່ງລະບົບຂອງເຮົາແມ່ນລະບົບທີ່ຄົນລາວເຮົາພັດທະນາຂື້ນມາ 100%,
            ໂດຍຜູ້ພັດທະນາແມ່ນຜູ້ທີ່ມີປະສົບການໃນການເຮັດວຽກທີ່ຫຼາກຫຼາຍປະສົບການໃນການແກ້ໄຂປັນຫາຕ່າງໆທີຖືກຕ້ອງແມ່ນແໝ້ມຍຳທີສຸດ.
          </v-card-text>

          <v-divider dark />

          <v-card-text class="white--text">
            CopyRight &copy; 2020
            <strong>PosSmart</strong>
            All Rights Reserved.
            {{ version }}
          </v-card-text>
        </div>
      </v-card-text>
    </v-card>
  </section>
</template>

<script>
export default {
  name: "basic",
  data() {
    return {
      version: process.env.VUE_APP_VERSION,
      link: [
        {
          icon: "fab fa-facebook",
          to: "https://www.facebook.com/pos.devla.la",
        },
        {
          icon: "fab fa-twitter",
          to: "https://twitter.com/vongkeokeosavan",
        },
        {
          icon: "fab fa-youtube",
          to: "https://www.youtube.com/@PosSmart",
        },
        {
          icon: "fab fa-instagram",
          to: "https://www.instagram.com/mr.vongkeoksv/?hl=en",
        },
      ],
    };
  },
};
</script>
