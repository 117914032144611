<template>
  <v-container class="sale-layout" fluid :class="noProducts ? 'no-data' : ''">
    <template v-if="productList.length">
      <div v-if="!noProducts" class="row">
        <div v-for="(item, index) in productList" :key="index" class="w-20">
          <app-card :item="item" />
        </div>
      </div>
      <template v-else>
        <div v-for="(item, index) in 20" :key="index" class="w-20">
          <div class="main-card">
            <v-card rounded class="rounded-lg">
              <v-skeleton-loader type="card, list-item" />
            </v-card>
          </div>
        </div>
      </template>
    </template>
    <app-no-product v-else />
    <div class="footer">
      <v-row justify="center">
        <v-col v-for="(item, index) in bottomeAction" :key="index">
          <v-btn
            @click="item.action"
            block
            class="d-flex font-weight-bold"
            large
            depressed
            dark
            :color="item.color"
          >
            <v-icon small left>
              {{ item.icon }}
            </v-icon>
            {{ item.text
            }}<span class="caption font-weight-light"
              >({{ item.sortcut }})</span
            >
          </v-btn>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>
import AppCard from "@/components/products/card";
import AppNoProduct from "@/components/products/no-product";
import { mapGetters, mapActions, mapMutations } from "vuex";
import { mainStore } from "@/stores/modules/cart";
import { toast, confirm } from "@/controllers/alert";

export default {
  data() {
    return {
      defineStore: mainStore(),
      windowListener: this.handleKeydown,
    };
  },
  mounted() {
    this.windowListener = this.handleKeydown.bind(this);
    window.addEventListener("keydown", this.windowListener);
  },

  beforeDestroy() {
    window.removeEventListener("keydown", this.windowListener);
  },
  components: {
    AppCard,
    AppNoProduct,
  },
  computed: {
    ...mapGetters({
      products: "index/products",
      product: "index/product",
    }),

    productList() {
      if (
        this.products != null &&
        this.products != undefined &&
        this.products.data != null &&
        this.products.data != undefined &&
        this.products.data.length
      ) {
        return this.products.data;
      } else {
        return [];
      }
    },
    noProducts() {
      if (
        this.products != null &&
        this.products != undefined &&
        this.products.data != null &&
        this.products.data != undefined &&
        this.products.data.length === 0
      ) {
        return true;
      } else {
        return false;
      }
    },
    bottomeAction() {
      return [
        {
          icon: "fal fa-trash",
          text: this.$t("clear"),
          action: this.removeCart,
          color: "error",
          sortcut: "Ctrl+C",
        },
        {
          icon: "fal fa-shopping-cart",
          text: this.$t("pause"),
          action: this.makeOrder,
          color: "secondary",
          sortcut: "Ctrl+P",
        },
        {
          icon: "fal fa-user",
          text: this.$t("customer"),
          action: () => this.$router.push({ name: "manage_customers" }),
          color: "primary",
          sortcut: "Ctrl+U",
        },

        {
          icon: "fal fa-file-alt",
          text: this.$t("document"),
          action: () => this.$router.push({ name: "documents" }),
          color: "info",
          sortcut: "Ctrl+D",
        },
      ];
    },
  },

  created() {
    this.fetchProduct();
  },
  methods: {
    ...mapMutations({
      SET_PAYMENT: "index/SET_PAYMENT",
    }),

    ...mapActions({
      fetch: "index/fetch",
    }),
    async fetchProduct() {
      const body = {
        that: this,
        url: "products_sale",
        commit: "SET_PRODUCT",
      };
      await this.fetch(body);
    },
    async removeCart() {
      // check if cart is empty
      if (this.defineStore.cart.length === 0) return;
      const { isConfirmed } = await confirm({
        props: this,
        text: "clear_cart",
        text: "are_you_sure_clear_cart",
        con_txt: "confirm",
        can_txt: "cancel",
      });
      if (isConfirmed) {
        this.defineStore.deleteCart();
      }
    },
    async makeOrder() {
      if (this.defineStore.cart.length === 0) return;
      const { cart, txn } = await Help.cartArray(this.defineStore.cart);
      const body = {
        customer_id: this.customer_id,
        txn: txn,
        data: cart,
      };
      const { data } = await this.$http.post("/make_cart_order", body);
      if (data && data.status) {
        // clear cart
        this.defineStore.deleteCart();
        this.SET_PAYMENT();
        await toast(this, data.message);
      }
    },

    handleKeydown(event) {
      if (event.ctrlKey) {
        switch (event.key) {
          case "c":
            event.preventDefault();
            this.removeCart();
            break;
          case "p":
            event.preventDefault();
            this.makeOrder();
            break;
          case "d":
            event.preventDefault();
            this.$router.push({ name: "documents" });
            break;
          case "u":
            event.preventDefault();
            this.$router.push({ name: "manage_customers" });
            break;
          default:
            break;
        }
      }
    },
  },
};
</script>

<style>
.v-skeleton-loader__image.v-skeleton-loader__bone {
  height: 150px;
}
.v-skeleton-loader__card-heading .v-skeleton-loader__heading {
  margin: 8px 0px 0px 16px;
}
.v-skeleton-loader__list-item v-skeleton-loader__bone {
  height: 100%;
}
/* // sale-layout is the holder of the product cards and the footer, I need footer to be fixed at the bottom of the page height 50px and the product cards to be scrollable */

.sale-layout {
  height: calc(92vh - 50px); /* Full height minus the footer height */
  overflow-y: auto;
}

.sale-layout .footer {
  position: fixed;
  height: 50px;
  bottom: 0;
  left: 0;
  right: 0;
  padding-left: 265px;
  padding-right: 410px;
}
</style>
