<template>
  <v-dialog
    v-model="value"
    scrollable
    persistent
    :max-width="width"
    class="rounded-lg"
  >
    <v-form @submit.prevent="$emit('submit')">
      <v-card>
        <v-card-title>
          <span class="text-capitalize font-weight-bold">
            {{ is_translate ? $t(title) : title }}</span
          >
          <v-spacer />
          <v-btn
            v-if="closeBtn"
            small
            fab
            depressed
            @click="$emit('input', false)"
          >
            <v-icon small color="red">fal fa-times</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text>
          <slot name="activator"></slot>
        </v-card-text>

        <v-card-actions v-if="footer" class="mb-2 mr-2">
          <v-spacer></v-spacer>
          <v-btn
            text
            outlined
            depressed
            color="primary"
            class="px-6 mr-2"
            @click="$emit('input', false)"
          >
            <v-icon left color="red"> fal fa-times </v-icon>
            {{ $t(cancelText) }}
          </v-btn>
          <v-btn type="submit" color="primary" dark depressed class="px-6">
            <v-icon left color="green" v-if="showSubmitIcon">
              fal fa-check
            </v-icon>

            {{ $t(submitText) }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
export default {
  props: {
    value: {},
    title: {},
    closeBtn: {
      type: Boolean,
      default: true,
    },
    footer: {
      type: Boolean,
      default: true,
    },
    width: {
      default: "600px",
    },
    is_translate: {
      type: Boolean,
      default: true,
    },
    cancelText: {
      default: "cancel",
    },
    submitText: {
      default: "ok",
    },
    showSubmitIcon: {
      type: Boolean,
      default: true,
    },
  },
};
</script>

<style></style>
