<template>
  <v-card
    v-if="Object.keys(item).length > 0"
    flat
    outlined
    class="printableArea font font-bill my-0 py-0"
    id="printKitchen"
  >
    <v-card-text class="pa-1">
      <div class="text-center justify-content-center">
        <h4 class="font pt-2 text-capitalize">
          {{ item.store_id.name }}
        </h4>
        <h3 class="text-center font font-weight-bolder black--text">
          {{ $t("item") }}
        </h3>
      </div>
      <div class="font default">
        {{ $t("table") }} : <span class="bold">{{ item.table.name }}</span>
      </div>
      <div class="font default">
        {{ $t("all") }} : {{ item.histories_count }} {{ $t("item") }}
      </div>
      <div class="font default">
        {{ $t("created_at") }} : {{ item.created_at | moment }}
      </div>
      <hr class="dot" />
      <v-simple-table class="bill-table">
        <template v-slot:default>
          <thead>
            <tr>
              <th class="font font-bill-head text-left">{{ $t("item") }}</th>
              <th class="font font-bill-head text-center">
                {{ $t("quantity") }}
              </th>
              <th class="font font-bill-head text-right">{{ $t("status") }}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(data, index) in item.histories" :key="index">
              <td class="font font-bill text-left">
                {{ data.product_rest.name }}
              </td>
              <td class="font font-bill text-center">
                {{ data.qty | NumberFormat }}
              </td>
              <td class="font font-bill text-right">
                <div class="v-card-box"></div>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
      <hr class="dot" />
    </v-card-text>
  </v-card>
</template>

<script>
import { myMixin } from "@/mixin/main";
export default {
  mixins: [myMixin],
  props: {
    item: {
      type: Object,
      default: () => {
        return {
          store_id: {
            name: "",
          },
          items: [],
        };
      },
    },
  },
  data() {
    return {
      checkbox: false,
    };
  },
};
</script>
