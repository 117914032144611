<template>
  <section class="bg-white py-12">
    <div class="container-xl pt-8 pb-12">
      <div class="row pb-8 justify-content-between">
        <div class="d-flex align-center">
          <v-icon> fal fa-layer-group </v-icon>
          <h4 class="ml-4">
            {{ $t("package") }}
          </h4>
        </div>
        <v-btn @click="isRestaurant" rounded depressed>
          <v-icon left x-small> fal fa-layer-group </v-icon>
          ຮ້ານອາຫານ
        </v-btn>
      </div>
      <v-row>
        <v-col
          xs="12"
          sm="12"
          md="4"
          v-for="(item, index) in items"
          :key="index"
        >
          <v-card flat rounded="xl" :color="colors[index]">
            <v-card-text class="pa-10">
              <div class="text-center">
                <v-avatar color="white">
                  <v-icon small>{{ item.icon }}</v-icon>
                </v-avatar>
                <div class="mt-3 font-weight-bold text-capitalize">
                  {{ item.name }}
                </div>
                <div class="mt-3 font-weight-bold text-capitalize">
                  {{ price_show(index, item.price, show_restaurant) }}
                </div>
              </div>
              <v-divider />
              <template v-if="index != 2">
                <app-package
                  :content="`ສິນຄ້າໃຫ່ມ ${product_count(item.product_count)}`"
                />
                <app-package
                  :content="`ຈຳນວນຜູ້ໃຊ້ ${user_count(item.user_count)}`"
                />
                <app-package
                  :content="`ຈຳນວນສາຂາ ${branch_count(item.branch_count)}`"
                />
              </template>
              <template v-else>
                <app-package content="500 ກີບ/ສິນຄ້າ" />
                <app-package content="50,000 ກີບ/ຜູ້ໃຊ້" />
                <app-package content="200,000 ກີບ/ສາຂາ" />
              </template>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </div>
  </section>
</template>

<script>
import { myMixin } from "@/mixin/main";
import AppPackage from "@/components/landing/AppPackage.vue";
export default {
  mixins: [myMixin],
  components: {
    AppPackage,
  },
  data: () => ({
    show_restaurant: false,
    colors: ["#f8f5fd", "#faf8fc", "#f9f5f3"],
  }),
  props: {
    items: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  methods: {
    isRestaurant() {
      this.show_restaurant = !this.show_restaurant;
    },
  },
};
</script>

<style></style>
