<template>
  <section>
    <data-table
      :headers="headers"
      :items="orders"
      commit="SET_ORDER"
      :viewItem="viewItem"
      :editItem="editItem"
      :deleteItem="declineForm"
    >
      <div slot="activator" class="row">
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-6">
          <v-card-title class="px-0 pt-0">{{ $t("order") }} </v-card-title>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-2 offset-lg-4">
          <TextSelect
            :dense="true"
            :placeholder="false"
            :items="status_list"
            label="status"
            itemValue="value"
            v-model="status_filter"
          />
        </div>
      </div>
    </data-table>
    <app-custom-dialog
      v-model="decline"
      :closeBtn="false"
      title="decline"
      cancelText="Cancel"
      submitText="Decline"
      @submit="declineOrder"
    >
      <div slot="activator">
        <TTextField label="noted" v-model="noted" />
      </div>
    </app-custom-dialog>
    <app-custom-dialog v-model="dialog" :footer="false" title="details">
      <div slot="activator">
        <v-row>
          <v-col cols="4">Id</v-col>
          <v-col cols="8">{{ form.id }}</v-col>
          <v-col cols="4">Store Logo</v-col>
          <v-col cols="8">
            <v-avatar size="35">
              <app-img :img="form.store.logo" />
            </v-avatar>
          </v-col>
          <v-col cols="4">Store Name</v-col>
          <v-col cols="8">{{ form.store.name }}</v-col>
          <v-col cols="4">Store Phone No</v-col>
          <v-col cols="8">{{ form.store.phone_no }}</v-col>
          <v-col cols="4">Store Start</v-col>
          <v-col cols="8">{{ form.store.start_date }}</v-col>
          <v-col cols="4">Store Expire</v-col>
          <v-col cols="8">{{ form.store.end_date }}</v-col>
          <v-col cols="4">Package</v-col>
          <v-col cols="8">
            <v-chip
              label
              dark
              :color="packageName(form.package.name).color"
              small
            >
              {{ packageName(form.package.name).text }}</v-chip
            ></v-col
          >
          <v-col cols="4">Price</v-col>
          <v-col cols="8">{{ moneyFormat(form.package.price) }}</v-col>
          <v-col cols="4">Month Amount</v-col>
          <v-col cols="8">{{ form.package.month_count }} Month</v-col>
          <v-col cols="4">Date</v-col>
          <v-col cols="8">{{ form.created_at | moment }}</v-col>
        </v-row>
      </div>
    </app-custom-dialog>
  </section>
</template>

<script>
import DataTable from "@/components/DataTable";
import { mapGetters, mapActions } from "vuex";
import { orderHead } from "@/utils/head";
import TextSelect from "@/components/global/TextSelect";
import TTextField from "@/components/global/TextField";
import { myMixin } from "@/mixin/main";
import { success, confirm } from "@/controllers/alert";
import { OrderStatus } from "@/utils/mock";

export default {
  mixins: [myMixin],
  data: () => ({
    dialog: false,
    decline: false,
    status_filter: {
      value: null,
      text: "All",
    },

    noted: "",
    form: {
      package: {},
      store: {},
    },
  }),
  async created() {
    await this.fetchItem();
  },
  components: { DataTable, TTextField, TextSelect },
  computed: {
    ...mapGetters({
      orders: "index/orders",
    }),
    headers() {
      return orderHead(this);
    },
    status_list() {
      return OrderStatus(this);
    },
  },
  watch: {
    status_filter(val) {
      this.fetchItem();
    },
  },

  methods: {
    ...mapActions({
      fetch: "index/fetch",
    }),
    async fetchItem() {
      const body = {
        that: this,
        url: "admin_orders?status=" + this.status_filter.value,
        commit: "SET_ORDER",
      };
      await this.fetch(body);
    },

    async newItem() {
      await Help.clearForm(this.form, this);
      await this.fetchItem();
      this.update = false;
      this.dialogAdd = !this.dialogAdd;
    },

    async editItem(item) {
      const { isConfirmed } = await confirm({
        props: this,
        title: "Approve Order",
        text: "Are you sure to approve this order",
        con_txt: "Approve",
        can_txt: "Cancel",
      });

      const body = {
        order_id: item.id,
        store_id: item.store_id,
        package_id: item.package_id,
      };
      if (isConfirmed) {
        const { data } = await this.$http.post(`orders_approved`, body);
        if (data.status) {
          await Help.clearForm(this.form, this);
          await this.fetchItem();
          await success(this, data.message);
        }
      }
    },

    async declineOrder() {
      try {
        const body = {
          order_id: this.form.id,
          noted: this.noted,
        };
        const { data } = await this.$http.post(`orders_decline`, body);
        if (data.status) {
          await Help.clearForm(this.form, this);
          await this.fetchItem();
          await success(this, data.message);
        }
      } catch (error) {
        console.log(error);
      }
    },
    viewItem(item) {
      this.form = item;
      this.dialog = !this.dialog;
    },
    declineForm(item) {
      this.form = item;
      this.decline = !this.decline;
    },
  },
};
</script>

<style></style>
