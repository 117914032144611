<template>
  <section>
    <v-card flat color="transparent">
      <v-row>
        <div class="col-md-3 col-lg-3 col-xl-3 col-sm-12 col-xs-12">
          <v-card rounded="lg">
            <v-toolbar flat>
              <v-toolbar-title>{{ $t("product_item") }}</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-btn icon @click="fetchItem()">
                <v-icon x-small> fal fa-redo-alt </v-icon>
              </v-btn>
            </v-toolbar>

            <v-card-text class="pt-0">
              <div class="d-flex">
                <TextSearch
                  v-model="search"
                  @search="fetchItem()"
                  @onClear="
                    search = '';
                    fetchItem();
                  "
                />
              </div>

              <v-list dense height="75vh" class="overflow-auto">
                <span
                  v-for="(item, index) in product_purchase.data"
                  :key="index"
                >
                  <v-list-item :key="item.name">
                    <v-list-item-avatar tile class="rounded-lg">
                      <app-img :img="item.img" />
                    </v-list-item-avatar>

                    <v-list-item-content>
                      <v-list-item-title> {{ item.name }}</v-list-item-title>
                      <v-list-item-subtitle>
                        {{ moneyFormat(item.price_sale) }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-btn
                        @click="setToPurchase(item.code)"
                        fab
                        depressed
                        small
                      >
                        <v-icon x-small> fal fa-plus </v-icon>
                      </v-btn>
                    </v-list-item-action>
                  </v-list-item>
                  <v-divider
                    v-if="index < product_purchase.data.length - 1"
                    :key="index"
                    inset
                    class="my-0"
                  ></v-divider>
                </span>
              </v-list>
            </v-card-text>
          </v-card>
        </div>
        <div class="col-md-9 col-lg-9 col-xl-9 col-sm-12 col-xs-12">
          <v-card rounded="lg">
            <v-card-text>
              <v-simple-table fixed-header height="60vh" class="overflow-auto">
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-left">{{ $t("no") }}</th>
                      <th class="text-left">{{ $t("id") }}</th>
                      <th class="text-left">{{ $t("image") }}</th>
                      <th class="text-left">{{ $t("name") }}</th>
                      <th class="text-right">{{ $t("price") }}</th>
                      <th class="text-center">{{ $t("amount") }}</th>
                      <th class="text-right">{{ $t("total_price") }}</th>
                      <th class="text-right">{{ $t("manage") }}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(item, index) in desserts" :key="index">
                      <td class="text-left">{{ index + 1 }}</td>
                      <td class="text-left">{{ item.code }}</td>
                      <td class="text-left">
                        <app-img :img="item.img" />
                      </td>
                      <td class="text-left">{{ item.name }}</td>
                      <td class="text-right">
                        {{ item.price_buy | NumberFormat }}
                      </td>
                      <td class="text-center d-flex">
                        <v-btn
                          fab
                          depressed
                          x-small
                          class="mr-2"
                          @click="decrement(item)"
                        >
                          <v-icon color="success" x-small>fal fa-minus</v-icon>
                        </v-btn>
                        <div class="width-box">
                          <v-text-field
                            v-model.number="item.qty"
                            type="number"
                            dense
                            hide-details
                          />
                        </div>

                        <v-btn fab depressed x-small @click="increment(item)">
                          <v-icon color="red" x-small>fal fa-plus</v-icon>
                        </v-btn>
                      </td>
                      <td class="text-right">
                        {{ (item.price_buy * item.qty) | NumberFormat }}
                      </td>
                      <td class="text-right">
                        <v-btn fab depressed x-small @click="viewItem(item)">
                          <v-icon color="blue" x-small>fal fa-eye</v-icon>
                        </v-btn>
                        <v-btn
                          fab
                          depressed
                          x-small
                          class="mx-2"
                          @click="editItem(item)"
                        >
                          <v-icon color="primary" x-small>fal fa-edit</v-icon>
                        </v-btn>
                        <v-btn fab depressed x-small @click="deleteItem(index)">
                          <v-icon color="error" x-small>fal fa-trash</v-icon>
                        </v-btn>
                      </td>
                    </tr>
                    <tr>
                      <th colspan="6" rowspan="3" class="text-center">
                        {{ $t("total") }}
                      </th>
                      <td class="text-right">{{ $t("price_buy") }}</td>
                      <td class="text-right red--text">
                        {{ total_buy | NumberFormat }}
                      </td>
                    </tr>
                    <tr>
                      <td class="text-right">{{ $t("price_sale") }}</td>
                      <td class="text-right primary--text">
                        {{ total_sale | NumberFormat }}
                      </td>
                    </tr>
                    <tr>
                      <td class="text-right">{{ $t("profit") }}</td>
                      <td class="text-right green--text">
                        {{ total_profit | NumberFormat }}
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
              <v-divider />
              <div class="text-right" v-if="desserts.length">
                <v-btn color="primary" @click="addStock()">
                  {{ $t("add_stock") }}
                </v-btn>
              </div>
            </v-card-text>
          </v-card>
        </div>
      </v-row>
    </v-card>

    <app-custom-dialog v-model="dialog" :footer="false" title="description">
      <div slot="activator">
        <AppDetail :item="form" />
      </div>
    </app-custom-dialog>
    <app-custom-dialog v-model="dialogAdd" :footer="false" title="add_stock">
      <div slot="activator">
        <TTextField
          label="amount"
          typeShow="number"
          v-model.number="form.qty"
        />
        <VuetifyMoney
          label="price_buy"
          :minValue="0"
          v-model.number="form.price_buy"
        />
        <VuetifyMoney
          label="price_sale"
          :minValue="0"
          v-model.number="form.price_sale"
        />
        <TextSelect
          v-if="suppliers.length"
          label="supplier"
          v-model="form.vendor_id"
          itemValue="id"
          text="name"
          :dense="true"
          :items="suppliers"
        />
      </div>
    </app-custom-dialog>
    <!-- AppLoading -->
    <app-loading :overlay="overlay" />
  </section>
</template>

<script>
import TTextField from "@/components/global/TextField";
import VuetifyMoney from "@/components/VuetifyMoney";
import { myMixin } from "@/mixin/main";
import AppDetail from "@/components/products/details";
import { mapGetters, mapActions } from "vuex";
import DataTable from "@/components/DataTable";
import TextSelect from "@/components/global/TextSelect";
import { confirm, toast } from "@/controllers/alert";

export default {
  components: {
    VuetifyMoney,
    TTextField,
    AppDetail,
    DataTable,
    TextSelect,
  },
  mixins: [myMixin],
  data() {
    return {
      dialogAdd: false,
      dialog: false,
      overlay: false,
      search: "",
      desserts: [],
      code: "",
      form: {},
    };
  },

  created() {
    this.fetchItem();
    this.fetchVendor();
    this.fetchBranch();
  },

  computed: {
    ...mapGetters({
      user: "auth/user",
      suppliers: "index/suppliers",
      branches: "index/branches",
      product_purchase: "index/product_purchase",
    }),
    total_buy() {
      let total = 0;
      this.desserts.forEach((item) => {
        total += item.price_buy * item.qty;
      });
      return total;
    },
    total_sale() {
      let total = 0;
      this.desserts.forEach((item) => {
        total += item.price_sale * item.qty;
      });
      return total;
    },
    total_profit() {
      return this.total_sale - this.total_buy;
    },
  },

  methods: {
    ...mapActions({
      fetch: "index/fetch",
    }),
    async fetchItem() {
      this.overlay = true;
      const body = {
        that: this,
        url: `products_all?q=${this.search}`,
        commit: "SET_PRODUCT_PURCHASE",
      };
      await this.fetch(body);
      this.overlay = false;
    },

    async fetchBranch() {
      const body = {
        that: this,
        url: "branches",
        commit: "SET_BRANCHES",
      };
      await this.fetch(body);
    },
    async fetchVendor() {
      const body = {
        that: this,
        url: "suppliers",
        commit: "SET_SUPPLIERS",
      };
      await this.fetch(body);
    },

    decrement(item) {
      // if qty <= 1 then remove item
      if (item.qty <= 1) {
        this.deleteItem(item);
      }
      item.qty -= 1;
    },
    increment(item) {
      item.qty += 1;
    },
    viewItem(item) {
      this.form = item;
      this.dialog = !this.dialog;
    },
    editItem(item) {
      this.update = true;
      this.form = item;
      this.dialogAdd = !this.dialogAdd;
    },
    deleteItem(index) {
      // splice array by index
      this.desserts.splice(index, 1);
    },

    async setToPurchase(search) {
      try {
        if (!search) return;
        let qty = 1;
        const { data } = await this.$http.get(`products_barcode/${search}`);
        if (data.status) {
          // check duplicate item then add to array
          const duplicate = this.desserts.find(
            (item) => item.id === data.data.id
          );
          if (duplicate) {
            duplicate.qty += qty;
          } else {
            this.desserts.push(
              Object.assign({}, data.data, {
                qty,
              })
            );
            // remove item from product_purchase
            this.product_purchase.data = this.product_purchase.data.filter(
              (item) => item.id !== data.data.id
            );
          }
        }
      } catch (error) {
        console.log("error", error);
      }
    },
    async addStock() {
      try {
        const { isConfirmed } = await confirm({
          props: this,
          text: "want_to_purchase",
        });

        if (isConfirmed) {
          this.overlay = true;
          const form = {
            data: Help.stockArray(this.desserts),
          };
          const { data } = await this.$http.post("stock", form);
          if (data.status) {
            this.overlay = false;
            this.desserts = [];
            toast(this, data.message);
          }
        }
      } catch (error) {
        this.overlay = false;
        console.log("error", error);
      }
    },
  },
};
</script>

<style></style>
