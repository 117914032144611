<template>
  <v-app>
    <app-navigation :items="items" :title="title" :img="img" ref="drawer" />
    <app-bar
      :items="items"
      :title="title"
      :img="img"
      @drawer="() => ($refs.drawer.drawer = !$refs.drawer.drawer)"
    />
    <v-main>
      <router-view />
    </v-main>
    <app-footer />
  </v-app>
</template>

<script>
import AppBar from "@/components/landing/app_bar";
import AppNavigation from "@/components/landing/navigation";
import AppFooter from "@/components/landing/footer";
import { appBarHead } from "@/utils/mock";
export default {
  name: "basic",
  components: {
    AppBar,
    AppNavigation,
    AppFooter,
  },
  data() {
    return {
      img: "/images/logo_white.png",
      title: "PosSmart",
      items: appBarHead(),
    };
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    handleScroll() {
      // check window.scrollY > 0
      if (window.scrollY > 0) {
        this.img = "/images/logo.png";
      } else {
        this.img = "/images/logo_white.png";
      }
    },
  },
};
</script>
