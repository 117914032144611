<template>
  <v-row class="pa-2">
    <v-col cols="12">
      <v-row>
        <v-col
          cols="2"
          v-for="(item, index) in tables"
          :key="index"
          class="pa-1"
        >
          <v-card
            flat
            outlined
            height="100"
            class="align-center text-center d-flex hover-table rounded-lg"
            @click="setTable(item.id)"
            :color="compareTable(item.id) ? 'primary' : ''"
          >
            <v-card-text class="text-center">
              <span
                class="font-weight-bold"
                :class="compareTable(item.id) ? 'white--text' : ''"
                >{{ item.name }}</span
              >
            </v-card-text>
            <v-icon
              class="position-dot"
              :color="tableStatusColor(item.status)"
              small
            >
              {{ iconStatus(item.status) }}
            </v-icon>
          </v-card>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import { myMixin } from "@/mixin/main";
export default {
  mixins: [myMixin],
  props: {
    tables: {
      type: Array,
      default: () => [],
    },
    table_id: {},
    order_id: {},
  },
  methods: {
    setTable(id) {
      this.$emit("setTable", id);
    },
    mergeTable(id) {
      this.$emit("mergeTable", id);
    },
    compareTable(id) {
      if (this.table_id === id) {
        return true;
      }
      return false;
    },
  },
};
</script>

<style></style>
