import axios from "axios";
import Cookies from "js-cookie";
const themes = localStorage.getItem("darkMode");
const themClass = themes ? "theme-dark" : "theme-light";
const themClassPay = themes ? "theme-dark payment" : "theme-light payment";

const confirmAlert = async (props, title) => {
  const _title = props.$t("confirm");
  const _cancel = props.$t("cancel");
  const _ok = props.$t("ok");
  const result = await props.$swal.fire({
    title: _title,
    text: title,
    icon: "warning",
    showCancelButton: true,
    confirmButtonColor: "#00c853",
    cancelButtonColor: "#dd2c00",
    cancelButtonText: _cancel,
    confirmButtonText: _ok,
  });
  return result;
};
/**
 *
 *
 * @param {*} props
 * @param {*} title
 */
const success = async (props, text) => {
  const _title = props.$t("success");
  const __msg = props.$t("process_success");
  await props.$swal.fire({
    icon: "success",
    title: _title,
    text: props.$t(text) ?? __msg,
    showConfirmButton: false,
    timer: 1500,
  });
};

const successSale = async (props, price, title) => {
  const _print = props.$t("print");
  const _success = props.$t("success");
  const result = await props.$swal.fire({
    icon: "success",
    title: price,
    text: title,
    showConfirmButton: true,
    showCancelButton: true,
    cancelButtonColor: "#00e676",
    confirmButtonColor: "#5b39c9",
    confirmButtonText: _print,
    cancelButtonText: _success,
    customClass: themClassPay,
  });
  return result;
};

const successPos = async (props, data) => {
  let msg = "";
  const _cash_back = props.$t("cash_back");
  const _not_enough = props.$t("not_enough");
  const _full_payment = props.$t("full_payment");
  const _overdue = props.$t("overdue");
  const _price = props.$t("price");
  const _print = props.$t("print");
  const _success = props.$t("success");
  const _discount = props.$t("discount");
  const { total_price, status, total_paid, total_discount } = data;

  let _received = Help.moneyFormatPrint(data.total_price);
  // check if total paid is greater than total price
  if (total_paid > total_price && status === "done") {
    // calculate cash back
    msg = `${_cash_back} ${Help.moneyFormatPrint(total_paid - total_price)}`;
  } else if (total_paid === total_price && status === "done") {
    // check if total paid is equal to total price
    msg = _full_payment;
  } else if (total_paid === 0 && status === "overdue") {
    // check if total paid is equal
    msg = _overdue;
    _received = `-${_received}`;
  } else if (
    // check if total paid is less than total price
    total_paid < total_price &&
    total_paid !== total_price &&
    status === "done" &&
    total_discount <= 0
  ) {
    msg = `${_not_enough} ${Help.moneyFormatPrint(total_price - total_paid)}`;
  } else if (
    total_paid < total_price &&
    total_paid !== total_price &&
    status === "done" &&
    total_discount > 0
  ) {
    // discount is applied
    msg = `${_discount} ${Help.moneyFormatPrint(total_discount)}`;
    _received = Help.moneyFormatPrint(total_paid);
  }

  const result = await props.$swal.fire({
    icon: "success",
    title: msg,
    text: `${_price} ${_received}`,
    showConfirmButton: true,
    showCancelButton: true,
    cancelButtonColor: "#00e676",
    confirmButtonColor: "#5b39c9",
    confirmButtonText: _print,
    cancelButtonText: _success,
    customClass: themClassPay,
  });
  return result;
};

/**
 *
 *
 * @param {*} props
 * @param {*} title
 */
const error = async (props, item) => {
  let error = [];
  const _title = props.$t("error");
  if (item !== null && item !== undefined) {
    if (typeof item === "object") {
      // iterate over object
      Object.keys(item).forEach((key, index) => {
        console.log(`${key}: ${item[key]}`);
        error = item[key];
      });
    } else {
      error = item;
    }
    await props.$swal.fire({
      icon: "error",
      title: _title,
      text: error,
      showConfirmButton: true,
      confirmButtonColor: "#5b39c9",
    });
  }
};

/**
 *
 *
 * @param {*} props
 * @param {*} title
 */
const cancel = async (props, title) => {
  const _success = props.$t("success");
  await props.$swal.fire({
    icon: "error",
    title: _success,
    text: title,
    showConfirmButton: false,
    timer: 1500,
  });
};

const handleError = async (prop, errorType) => {
  switch (errorType.code) {
    case 400 || 409 || 404:
      await error(prop, errorType.message);
      break;
    case 401:
      await axios.post("/api/app/logout");
      const _mgs = prop.$t("session_expired");
      await prop.$swal.fire({
        icon: "error",
        title: _mgs,
        text: errorType.message,
        showConfirmButton: true,
      });
      Cookies.remove("token");
      Cookies.remove("user");
      Cookies.remove("isLoggedIn");
      delete axios.defaults.headers.common["Authorization"];
      window.location.href = "/login";
      break;
    default:
      await error(prop, errorType.message);
      break;
  }
};
const toast = async (props, title, type) => {
  // check if title contain object or string \\ if object then iterate over object
  if (typeof title === "object") {
    Object.keys(title).forEach((key, index) => {
      title = title[key][0];
    });
  }
  const msg = props.$t(title);
  const _msg1 = props.$t("new_notification");
  await props.$toast.open({
    type: type ? type : "success",
    message: msg ?? _msg1,
  });
};

const errorAlert = async (self, item) => {
  let error = [];
  if (
    item !== null &&
    item !== undefined &&
    item.message !== null &&
    item.message !== undefined
  ) {
    if (typeof item.message === "object") {
      Object.keys(item.message).forEach((key, index) => {
        console.log(`${key}: ${item.message[key]}`);
        error = item.message[key];
      });
    } else {
      error = item.message;
    }
    const _title = self.$t("failed");
    const _ok = self.$t("ok");
    const result = await self.$swal.fire({
      icon: "error",
      title: _title,
      text: error,
      showConfirmButton: true,
      confirmButtonColor: "#5b39c9",
      confirmButtonText: _ok,
      customClass: themClass,
    });
    return result;
  }
};
const confirm = async ({
  props,
  title = "are_you_sure",
  text = "want_to_proceed",
  con_txt = "ok",
  can_txt = "cancel",
}) => {
  const _title = props.$t(title);
  const _text = props.$t(text);
  const _con_txt = props.$t(con_txt);
  const _can_txt = props.$t(can_txt);
  const result = await props.$swal.fire({
    title: _title,
    text: _text,
    icon: "warning",
    showCancelButton: true,
    confirmButtonColor: "#5b39c9",
    cancelButtonColor: "#473e16",
    cancelButtonText: _can_txt,
    confirmButtonText: _con_txt,
    customClass: themClass,
  });

  return result;
};
export {
  toast,
  confirmAlert,
  success,
  error,
  cancel,
  handleError,
  successPos,
  successSale,
  errorAlert,
  confirm,
};
