<template>
  <v-card
    v-if="Object.keys(item).length > 0"
    flat
    outlined
    class="printableArea font font-bill my-0 py-0"
    id="printBill"
  >
    <v-card-text class="pa-1">
      <div class="text-center justify-content-center">
        <img
          v-if="Img && show_logo"
          width="70"
          :src="Img"
          alt="logo"
          class="logo"
        />
        <h4 class="font pt-2 text-capitalize">
          {{ item.store_id.name }}
        </h4>

        <h3 class="text-center font font-weight-bolder">
          {{ $t("receipt") }}
        </h3>
      </div>
      <div class="font default">
        {{ $t("code") }} : <span class="bold">{{ item.code }}</span>
      </div>
      <div v-if="branch" class="font default">
        {{ $t("branch") }} : {{ branchMsg }}
      </div>
      <v-row class="justify-content-between align-items-end">
        <v-col cols="6" class="font default">
          <div class="font default">
            {{ $t("date") }} : {{ item.created_at | moment }}
          </div>
          <div class="font default">
            {{ $t("seller") }} : {{ item.user_id.first_name }}
          </div>
          <template v-if="item.customer_id">
            <div class="font default">
              {{ $t("customer") }} : {{ customer }}
              <span v-if="customer_point"> | {{ customerPoint }}</span>
            </div>
            <div class="font default" v-if="customer_address">
              {{ $t("customer_address") }} : {{ customerAddress }}
            </div>
          </template>
        </v-col>
        <v-col cols="6" class="font default text-right">
          <div class="font default">
            {{ $t("total_paid") }} : {{ item.total_paid | NumberFormat }}
          </div>
          <div class="font default">
            {{ $t("cash_back") }} :
            {{ cash_back }}
          </div>
          <div class="font default">
            {{ $t("status") }} : {{ $t(item.status) }}
          </div>
        </v-col>
      </v-row>

      <hr class="dot" />
      <v-simple-table class="bill-table">
        <template v-slot:default>
          <thead>
            <tr>
              <th class="font font-bill-head text-left">{{ $t("item") }}</th>
              <th class="font font-bill-head text-center">
                {{ $t("amount") }}
              </th>
              <th class="font font-bill-head text-right">{{ $t("price") }}</th>
              <th class="font font-bill-head text-right">
                {{ $t("total") }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(data, index) in item.history" :key="index">
              <td class="font font-bill text-left">
                {{ data.product_id.name }}
              </td>
              <td class="font font-bill text-center">
                {{ data.qty }}
              </td>
              <td class="font font-bill text-right">
                {{ data.price_sale | NumberFormat }}
              </td>
              <td class="font font-bill text-right">
                {{ data.total_price | NumberFormat }}
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
      <hr class="dot" />

      <div class="printClass font default">
        <div>{{ $t("amount_paid") }}</div>
        <div class="black--text">
          {{ item.total_price_sale | NumberFormat }}
        </div>
      </div>
      <div class="printClass font default">
        <div>{{ $t("discount") }}</div>
        <div class="black--text">
          {{ total_discount }}
        </div>
      </div>
      <div class="printClass font default" v-if="tax_include">
        <div>VAT {{ tax }}</div>
        <div class="black--text">
          {{ item.tax_amount | NumberFormat }}
        </div>
      </div>

      <div class="printClass font default" v-if="thb_currency">
        <div>THB</div>
        <div class="black--text">
          {{ thRate }}
        </div>
      </div>
      <div class="printClass font default" v-if="usd_currency">
        <div>USD</div>
        <div class="black--text">
          {{ usRate }}
        </div>
      </div>

      <div class="printClass font default font-weight-bold">
        <div>{{ $t("grand_total") }}</div>
        <div class="black--text">
          {{ item.total_paid | NumberFormat }}
        </div>
      </div>
      <template v-if="item.note">
        <div class="printClass font default text-left font-weight-bold">
          {{ $t("noted") }}
        </div>
        <span class="printClass font default text-left">- {{ item.note }}</span>
      </template>
      <barcode
        v-if="barcode"
        class="barcode"
        :displayValue="false"
        :value="item.code"
        :width="2"
        :height="35"
      />
      <div class="font text-center">{{ footer_text }}</div>

      <!-- qr -->
      <div class="text-center" v-if="urlQR && show_qr">
        <img width="100" :src="urlQR" alt="qr" />
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
import { myMixin } from "@/mixin/main";
import VueBarcode from "vue-barcode";

export default {
  mixins: [myMixin],
  components: {
    barcode: VueBarcode,
  },
  props: {
    item: Object,
    show_logo: Boolean,
    branch: Boolean,
    customer_point: Boolean,
    customer_address: Boolean,
    thb_currency: Boolean,
    usd_currency: Boolean,
    tax_include: Boolean,
    barcode: Boolean,
    show_qr: Boolean,
    footer_text: String,
  },

  computed: {
    ...mapGetters({
      user: "auth/user",
    }),
    urlQR() {
      if (this.user && this.user.stores && this.user.stores.qr) {
        const baseURL = process.env.VUE_APP_BASE_URL;
        return `${baseURL}/uploads/${this.user.stores.qr}`;
      } else {
        return null;
      }
    },
    Img() {
      if (this.user && this.user.stores && this.user.stores.logo) {
        // check logo is contain default string or not
        if (!this.user.stores.logo.includes("default")) {
          return Help.img(this.user.stores.logo);
        }
      }
    },

    branchMsg() {
      if (this.item.branch_id && this.item.branch_id.name) {
        return `${this.item.branch_id.name}`;
      }
      return "";
    },

    customer() {
      if (this.item.customer_id && this.item.customer_id.first_name) {
        return `${this.item.customer_id.first_name}`;
      }
      return "";
    },
    customerPoint() {
      if (this.item.customer_id && this.item.customer_id.point) {
        return `${this.$options.filters.NumberFormat(
          this.item.customer_id.point
        )}`;
      }
      return "";
    },
    customerAddress() {
      if (this.item.customer_id && this.item.customer_id.address) {
        return `${this.item.customer_id.address}`;
      }
      return "";
    },
    thRate() {
      if (this.item && this.item.store_id && this.item.store_id.thb) {
        const thb = this.item.store_id.thb;
        const total = this.item.total_price;
        return this.$options.filters.NumberFormat(total / thb);
      }
      return 0;
    },
    usRate() {
      if (this.item && this.item.store_id && this.item.store_id.usd) {
        const usd = this.item.store_id.usd;
        const total = this.item.total_price;
        return this.$options.filters.NumberFormat(total / usd);
      }
      return 0;
    },
    cash_back() {
      if (this.item && this.item.total_price) {
        const { total_discount, total_price, total_paid } = this.item;
        const total = total_price - total_discount;
        const total_due = total - total_paid;
        return this.$options.filters.NumberFormat(total_due);
      }
      return 0;
    },
    total_discount() {
      if (this.item && this.item.total_discount) {
        return `- ${this.$options.filters.NumberFormat(
          this.item.total_discount
        )}`;
      }
      return 0;
    },
  },
};
</script>
