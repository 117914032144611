<template>
    <section>
        <v-card
            color="primary"
            rounded="0"
            flat
            :height="$vuetify.breakpoint.smAndDown ? 300 : 500"
            dark
        >
            <v-card-text class="py-8">
                <v-row align="center" justify="center">
                    <v-col class="text-center" cols="12">
                        <h1 class="mb-4 font-weight-bold text-h2 white--text">
                            PosSmart
                        </h1>
                        <h4 class="subheading white--text">
                            ລະບົບຈັດການໜ້າຮ້ານແບບອອນໄລນ໌
                        </h4>
                        <v-img
                            :height="$vuetify.breakpoint.smAndDown ? 200 : 350"
                            aspect-ratio="2"
                            contain
                            src="/images/04.png"
                        ></v-img>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
        <div class="container-xl">
            <section class="py-12">
                <h4 class="pb-8">
                    ລູກຄ້າ
                </h4>
                <v-row>
                    <v-col
                        xs="12"
                        sm="12"
                        md="6"
                        lg="3"
                        v-for="(item, index) in 4"
                        :key="index"
                    >
                        <v-card rounded="lg" flat>
                            <v-img
                                aspect-ratio="2"
                                contain
                                src="/images/04.png"
                            >
                                <div class="pa-2 d-flex justify-content-end">
                                    <v-chip label small color="primary">
                                        Premier
                                    </v-chip>
                                </div>
                            </v-img>
                            <v-card-title>
                                ຮ້ານຄ້າ A
                            </v-card-title>
                            <v-card-text>
                                hello world customer
                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>
            </section>
        </div>
    </section>
</template>

<script>
export default {};
</script>

<style></style>
