<template>
  <section>
    <data-table
      :headers="headers"
      :items="feedback"
      commit="SET_FEEDBACK"
      :viewItem="viewItem"
    >
      <div slot="activator" class="row">
        <v-col cols="3">
          <v-card-title class="px-0 pt-0"> ຄຳເຫັນ </v-card-title>
        </v-col>
      </div>
    </data-table>

    <app-custom-dialog v-model="dialog" :footer="false" title="description">
      <div slot="activator">
        <v-row>
          <v-col cols="4">ລະຫັດ</v-col>
          <v-col cols="8">{{ form.id }}</v-col>
          <v-col cols="4">ຫົວຂໍ້</v-col>
          <v-col cols="8">{{ form.title }}</v-col>
          <v-col cols="4">description</v-col>
          <v-col cols="8">{{ form.desc }}</v-col>
          <v-col cols="4">ສະຖານະ</v-col>
          <v-col cols="8">
            <v-chip label dark :color="form.status ? 'green' : 'red'" small>
              {{ form.status ? "ອ່ານແລ້ວ" : "ຍັງ" }}</v-chip
            >
          </v-col>
          <v-col cols="4">ຊື່ຮ້ານ</v-col>
          <v-col cols="8">{{ form.store.name }}</v-col>

          <v-col cols="4">ຮູບພາບຮ້ານ</v-col>
          <v-col cols="8">
            <v-avatar tile size="35">
              <v-img
                :src="IMAGES(form.store.logo)"
                :lazy-src="`https://picsum.photos/10/6?image=${1 * 5 + 10}`"
              >
                <template v-slot:placeholder>
                  <v-row
                    class="fill-height ma-0"
                    align="center"
                    justify="center"
                  >
                    <v-progress-circular
                      indeterminate
                      color="grey lighten-5"
                    ></v-progress-circular>
                  </v-row>
                </template>
              </v-img>
            </v-avatar>
          </v-col>
          <v-col cols="4">ຊື່ຮ້ານ</v-col>
          <v-col cols="8">{{ form.store.name }}</v-col>
          <v-col cols="4">ເບີໂທ</v-col>
          <v-col cols="8">{{ form.store.phone_no }}</v-col>
          <v-col cols="4">ວັນເລີມ</v-col>
          <v-col cols="8">{{ form.store.start_date }}</v-col>
          <v-col cols="4">ວັນໝົດອາຍຸ</v-col>
          <v-col cols="8">{{ form.store.end_date }}</v-col>
          <v-col cols="4">{{ $t("date") }}</v-col>
          <v-col cols="8">{{ form.created_at | moment }}</v-col>
        </v-row>
      </div>
    </app-custom-dialog>
  </section>
</template>

<script>
import DataTable from "@/components/DataTable";
import { mapGetters, mapActions } from "vuex";
import { feedbackHead } from "@/utils/head";
import TTextField from "@/components/global/TextField";
import AppDetail from "@/components/products/details";
import { myMixin } from "@/mixin/main";

export default {
  mixins: [myMixin],

  data: () => ({
    dialog: false,

    form: {
      store: {},
    },
  }),
  async created() {
    await this.fetchItem();
  },
  components: { DataTable, TTextField, AppDetail },
  computed: {
    ...mapGetters({
      feedback: "index/feedback",
    }),
    headers() {
      return feedbackHead(this);
    },

    titleStatus() {
      return this.update ? "update" : "new_data";
    },
  },

  methods: {
    ...mapActions({
      fetch: "index/fetch",
    }),
    async fetchItem() {
      const body = {
        that: this,
        url: "admin_feedback",
        commit: "SET_FEEDBACK",
      };
      await this.fetch(body);
    },

    async viewItem(item) {
      this.form = item;
      this.dialog = !this.dialog;
      await this.$http.put(`feedback/${item.id}`, item);
      await this.fetchItem();
    },
  },
};
</script>

<style></style>
