<template>
  <section>
    <data-table
      :headers="headers"
      :items="expiredProducts"
      commit="SET_EXPIRED_PRODUCTS"
      :viewItem="viewItem"
    >
      <div slot="activator" class="row">
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-4">
          <v-card-title class="px-0 pt-0">
            {{ $t("expired_product") }}
          </v-card-title>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-2 offset-lg-3">
          <DatePicker
            v-model="date"
            :dense="true"
            :placeholder="false"
            label="end_date"
          />
        </div>

        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-3">
          <TextSearch
            v-model="search"
            @search="fetchItem()"
            @onClear="
              search = '';
              fetchItem();
            "
          />
        </div>
      </div>
    </data-table>
    <app-custom-dialog v-model="dialog" :footer="false" title="description">
      <div slot="activator">
        <AppDetail :item="form" />
      </div>
    </app-custom-dialog>
    <!-- AppLoading -->
    <app-loading :overlay="overlay" />
  </section>
</template>

<script>
import DataTable from "@/components/DataTable";
import { mapGetters, mapActions } from "vuex";
import { notifyProducts } from "@/utils/head";
import AppDetail from "@/components/products/details";

export default {
  data: () => ({
    update: false,
    overlay: false,
    search: "",
    dialogAdd: false,
    dialog: false,

    form: {},
    date: new Date().toISOString().substr(0, 10),
  }),
  async created() {
    await this.fetchItem();
  },
  components: { DataTable, AppDetail },
  computed: {
    ...mapGetters({
      expiredProducts: "index/expiredProducts",
    }),

    titleStatus() {
      return this.update ? "update" : "new_data";
    },
    headers() {
      return notifyProducts(this);
    },
  },
  watch: {
    date(val) {
      this.fetchItem();
    },
  },

  methods: {
    ...mapActions({
      fetch: "index/fetch",
    }),
    async fetchItem() {
      const body = {
        that: this,
        url: `product_expire?q=${this.search}&date=${this.date}`,
        commit: "SET_EXPIRED_PRODUCTS",
      };
      await this.fetch(body);
    },

    async viewItem({ id }) {
      try {
        const { data } = await this.$http.get(`products/${id}`);
        if (data.status) {
          this.form = data.data;
          this.dialog = !this.dialog;
        }
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>

<style></style>
