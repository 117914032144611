<template>
  <v-img
    class="rounded-lg"
    :src="image"
    :height="height"
    :width="width"
    :contain="contain"
  >
  </v-img>
</template>

<script>
export default {
  data() {
    return {
      loading: false,
    };
  },
  props: {
    img: { required: true },
    contain: { type: Boolean, default: false },
    height: { type: String, default: "50" },
    width: { type: String, default: "50" },
  },
  computed: {
    image() {
      if (this.img.includes("http") || this.img.includes("https")) {
        return this.img;
      } else if (this.img) {
        const baseURL = process.env.VUE_APP_BASE_URL;
        return `${baseURL}/uploads/${this.img}`;
      }
      return "/images/pos.png";
    },
  },
};
</script>

<style></style>
