<template>
  <section>
    <data-table
      :headers="headers"
      :items="reportUsers"
      commit="SET_REPORT_USERS"
      :viewItem="viewItem"
      :editItem="userReport"
    >
      <div slot="activator" class="row">
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-3">
          <v-card-title class="px-0 pt-0"> {{ $t("user") }} </v-card-title>
        </div>

        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-3 offset-lg-6">
          <TextSearch
            v-model="search"
            @search="fetchItem()"
            @onClear="
              search = '';
              fetchItem();
            "
          />
        </div>
        <v-spacer />
      </div>
    </data-table>
    <app-custom-dialog v-model="dialog" :footer="false" title="description">
      <div slot="activator">
        <v-row>
          <v-col cols="4">{{ $t("id") }}</v-col>
          <v-col cols="8">{{ form.id }}</v-col>
          <v-col cols="4">{{ $t("image") }}</v-col>
          <v-col cols="8">{{ form.avatar }}</v-col>
          <v-col cols="4">{{ $t("first_name") }}</v-col>
          <v-col cols="8">{{ form.first_name }}</v-col>
          <v-col cols="4">{{ $t("phone_no") }}</v-col>
          <v-col cols="8">{{ form.phone_no }}</v-col>
          <v-col cols="4">{{ $t("role") }}</v-col>
          <v-col cols="8">
            <v-chip label small> {{ userRole(form.role) }}</v-chip>
          </v-col>
          <v-col cols="4">{{ $t("email") }}</v-col>
          <v-col cols="8">{{ form.email }}</v-col>
          <v-col cols="4">{{ $t("tickets_count") }}</v-col>
          <v-col cols="8">{{ form.tickets_count }}</v-col>
          <v-col cols="4">{{ $t("total_paid") }}</v-col>
          <v-col cols="8">{{ moneyFormat(form.tickets_sum_total_paid) }}</v-col>

          <v-col cols="4">{{ $t("date") }}</v-col>
          <v-col cols="8">{{ form.created_at | moment }}</v-col>
        </v-row>
      </div>
    </app-custom-dialog>
    <!-- AppLoading -->
    <app-loading :overlay="overlay" />
  </section>
</template>

<script>
import DataTable from "@/components/DataTable";
import { mapGetters, mapActions } from "vuex";
import TTextField from "@/components/global/TextField";
import { customerValidate } from "@/validations/shop";
import { myMixin } from "@/mixin/main";
import { reportUser } from "@/utils/head";

export default {
  mixins: [customerValidate, myMixin],

  data: () => ({
    dialog: false,
    overlay: false,
    search: "",

    form: {},
  }),
  async created() {
    await this.fetchItem();
  },
  components: { DataTable, TTextField },
  computed: {
    ...mapGetters({
      reportUsers: "index/reportUsers",
    }),
    headers() {
      return reportUser(this);
    },
  },

  methods: {
    ...mapActions({
      fetch: "index/fetch",
    }),
    async fetchItem() {
      const body = {
        that: this,
        url: `report_users?q=${this.search}`,
        commit: "SET_REPORT_USERS",
      };
      await this.fetch(body);
    },

    viewItem(item) {
      this.form = item;
      this.dialog = !this.dialog;
    },

    userReport(item) {
      this.$router.push("/user-report/" + item.id);
    },
  },
};
</script>

<style></style>
