<template>
  <v-row>
    <v-col xs="12" sm="12" md="6" lg="6" xl="6">
      <v-row>
        <v-col cols="5" v-if="item.document === 'quotation'">{{
          $t("quotation_no")
        }}</v-col>
        <v-col cols="5" v-else>{{ $t("invoice_no") }}</v-col>
        <v-col cols="7"> {{ item.quotation_no }} </v-col>
        <v-col cols="5">{{ $t("customer") }}</v-col>
        <v-col cols="7"> {{ item.customer.first_name }} </v-col>
        <v-col cols="5">{{ $t("phone_no") }}</v-col>
        <v-col cols="7"> {{ item.customer.phone_no }} </v-col>
        <v-col cols="5">{{ $t("status") }}</v-col>
        <v-col cols="7">
          <v-chip label small :color="orderStatusColor(item.status)" dark>
            {{ $t(item.status) }}
          </v-chip>
        </v-col>
        <template v-if="item.expiry_date">
          <v-col cols="5">{{ $t("expired_date") }}</v-col>
          <v-col cols="7"> {{ item.expiry_date }} </v-col>
        </template>
        <v-col cols="5">{{ $t("currency") }}</v-col>
        <v-col cols="7"> {{ item.currency }} </v-col>
      </v-row>
    </v-col>
    <v-col xs="12" sm="12" md="6" lg="6" xl="6">
      <v-row>
        <!-- <v-col cols="5">{{ $t("tax_include") }}</v-col>
        <v-col cols="7"> {{ item.include_tax ? $t("yes") : $t("not") }} </v-col> -->
        <v-col cols="5">{{ $t("total_items") }}</v-col>
        <v-col cols="7">
          {{ item.quotation_details_count | NumberFormat }}
          {{ $t("item") }}</v-col
        >
        <v-col cols="5">{{ $t("amount_paid") }}</v-col>
        <v-col cols="7">{{ item.total_amount | NumberFormat }}</v-col>
        <v-col cols="5">VAT {{ tax }}</v-col>
        <v-col cols="7">{{ item.tax_amount | NumberFormat }}</v-col>
        <v-col cols="5">{{ $t("grand_total") }}</v-col>
        <v-col cols="7" class="font-weight-bold">{{
          item.grand_total | NumberFormat
        }}</v-col>
        <v-col cols="5">{{ $t("created_at") }}</v-col>
        <v-col cols="7"> {{ item.created_at | moment }} </v-col>
      </v-row>
    </v-col>

    <v-col cols="12">
      <v-simple-table>
        <template v-slot:default>
          <thead>
            <tr>
              <th scope="col">{{ $t("no") }}</th>
              <th scope="col">{{ $t("image") }}</th>
              <th scope="col">{{ $t("name") }}</th>
              <th scope="col" class="text-right">{{ $t("price") }}</th>
              <th scope="col" class="text-right">{{ $t("quantity") }}</th>
              <th scope="col" class="text-right">{{ $t("total") }}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(data, index) in item.quotation_details" :key="index">
              <th scope="row">{{ index + 1 }}</th>
              <td>
                <app-img :img="data.product.img" width="40" height="40" />
              </td>
              <td>{{ data.product.name.substring(0, 20) + ".." }}</td>
              <td class="text-right">
                {{ data.unit_price | NumberFormat }}
              </td>
              <td class="text-right">{{ data.quantity | NumberFormat }}</td>
              <td class="text-right">
                {{ data.total_price | NumberFormat }}
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-col>
    <v-col cols="12" class="text-right" v-if="item.status === 'pending'">
      <v-btn
        class="mr-2"
        color="primary"
        @click="$emit('btnAction', 'accepted')"
      >
        {{ $t("accepted") }}
      </v-btn>
      <v-btn color="red" @click="$emit('btnAction', 'rejected')">
        {{ $t("rejected") }}
      </v-btn>
    </v-col>
  </v-row>
</template>

<script>
import { myMixin } from "@/mixin/main";
export default {
  mixins: [myMixin],
  props: {
    item: {
      type: Object,
      default: () => ({}),
    },
  },
};
</script>

<style></style>
