<template>
  <v-card class="rounded-lg">
    <v-card-title>
      <v-icon left small> fal fa-edit </v-icon>
      {{ $t("user_information") }}
    </v-card-title>
    <v-card-text class="pa-10 pt-0">
      <v-row align="center">
        <v-col xs="12" sm="12" md="4" lg="4" xl="4" class="text-center">
          <v-card-text>
            <v-badge
              bordered
              bottom
              color="primary"
              offset-x="50"
              offset-y="22"
              icon="fal fa-camera"
              overlap
            >
              <v-avatar color="#f5f5f5" size="158">
                <v-avatar-uploader
                  :url="url"
                  :clickable="true"
                  field="file"
                  :rename="rename"
                  @failed="failed"
                  :request="request"
                />
              </v-avatar>
            </v-badge>
          </v-card-text>
        </v-col>
        <v-divider vertical />
        <v-col xs="12" sm="12" md="8" lg="8" xl="8">
          <v-card-text>
            <AppTitle title="first_name" :value="user.first_name" />
            <AppTitle title="last_name" :value="user.last_name" />
            <AppTitle title="email" :value="user.email" />
            <AppTitle title="phone" :value="user.phone_no" />
            <AppTitle title="address" :value="user.address" :divider="false" />
          </v-card-text>
        </v-col>
      </v-row>
      <TTextField label="first_name" v-model="form.first_name" />
      <TTextField label="last_name" v-model="form.last_name" />
      <TTextField label="phone" v-model="form.phone_no" />
      <TTextField label="address" v-model="form.address" />
    </v-card-text>
    <v-card-actions class="px-10 pb-10">
      <v-btn
        @click="updateUser()"
        depressed
        color="primary"
        large
        class="px-12"
        >{{ $t("update") }}</v-btn
      >
    </v-card-actions>
  </v-card>
</template>

<script>
import VAvatarUploader from "@/components/VAvatarUploader";
import { success } from "@/controllers/alert";
import { mapGetters } from "vuex";
import TTextField from "@/components/global/TextField";
import { setUser } from "@/controllers/auth";
import AppTitle from "@/components/settings/app_title";

export default {
  components: {
    VAvatarUploader,
    TTextField,
    AppTitle,
  },
  data() {
    return {
      form: {
        first_name: "",
        last_name: "",
        phone_no: "",
        avatar: "",
      },
    };
  },
  mounted() {
    this.form = this.user;
  },
  computed: {
    ...mapGetters({
      user: "auth/user",
    }),
    url() {
      return Help.img(this.user.avatar);
    },
  },
  methods: {
    async request(form, config) {
      const { data } = await this.$http.post("change_avatar", form, config);
      if (data.status) {
        await success(this, data.message);
        await setUser(this);
      }
    },
    rename(file) {
      const ext = file.name.split(".")[1];
      const name = `1231232.${ext}`;
      return name;
    },
    failed(error) {
      console.log("error", error);
    },
    async updateUser() {
      try {
        const form = {
          first_name: this.form.first_name,
          last_name: this.form.last_name,
          address: this.form.address,
          phone_no: this.form.phone_no,
        };
        const { data } = await this.$http.post("update_profile", form);
        if (data.status) {
          await setUser(this);
          await success(this, data.message);
        }
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>

<style></style>
