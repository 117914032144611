import { required, minLength, email, sameAs } from "vuelidate/lib/validators";
const login = {
  validations: {
    form: {
      email: {
        required,
      },
      password: {
        required,
      },
    },
  },
  computed: {
    emailError() {
      const errors = [];
      if (!this.$v.form.email.$dirty) return errors;
      !this.$v.form.email.required && errors.push("ກະລຸນາປ້ອນອີເມວ!");
      // !this.$v.form.email.email && errors.push("ອີເມວບໍ່ຖືກຕ້ອງ!");
      return errors;
    },
    passwordError() {
      const errors = [];
      if (!this.$v.form.password.$dirty) return errors;
      !this.$v.form.password.required && errors.push("ກະລຸນາປ້ອນລະຫັດຜ່ານ!");
      return errors;
    },
  },
};
const Register = {
  validations: {
    form: {
      first_name: { required },
      password: { required, minLength: minLength(8) },
      confirm_password: { required, sameAsPassword: sameAs("password") },
      email: {
        email,
        required,
      },
    },
  },
  computed: {
    first_nameError() {
      const errors = [];
      if (!this.$v.form.first_name.$dirty) return errors;
      !this.$v.form.first_name.required && errors.push("ກະລຸນາປ້ອນຊື່!");
      return errors;
    },
    emailError() {
      const errors = [];
      if (!this.$v.form.email.$dirty) return errors;
      !this.$v.form.email.required && errors.push("ກະລຸນາປ້ອນອີເມວ!");
      !this.$v.form.email.email && errors.push("ອີເມວບໍ່ຖືກຕ້ອງ!");
      return errors;
    },
    passwordErrors() {
      const errors = [];
      if (!this.$v.form.password.$dirty) return errors;
      !this.$v.form.password.required && errors.push("ກະລຸນາປ້ອນລະຫັດຜ່ານ!");
      !this.$v.form.password.minLength &&
        errors.push("ລະຫັດຜ່ານຢ່າງນ້ອຍ 8 ຕົວຂື້ນໄປ!");
      return errors;
    },
    confirmPasswordErrors() {
      const errors = [];
      if (!this.$v.form.confirm_password.$dirty) return errors;
      !this.$v.form.confirm_password.required &&
        errors.push("ກະລຸນາປ້ອນລະຫັດຍຶນຍັນ!");
      !this.$v.form.confirm_password.sameAsPassword &&
        errors.push("ລະຫັດຍືນຍັນບໍຕົງກັບລະຫັດຜ່ານ!");
      return errors;
    },
  },
};
const changePassword = {
  validations: {
    form: {
      old_password: {
        required,
      },
      password: {
        required,
        minLength: minLength(8),
      },
      confirm_password: {
        required,
        sameAsPassword: sameAs("password"),
      },
    },
  },
  computed: {
    OldPasswordErrors() {
      const errors = [];
      if (!this.$v.form.old_password.$dirty) return errors;
      this.OldPasswordError = "";
      !this.$v.form.old_password.required &&
        errors.push("ກະລຸນາປ້ອນລະຫັດຜ່ານໂຕເກົ່າ");
      return errors;
    },
    passwordErrors() {
      const errors = [];
      if (!this.$v.form.password.$dirty) return errors;
      this.passwordError = "";
      !this.$v.form.password.required &&
        errors.push("ກະລຸນາປ້ອນລະຫັດຜ່ານໂຕໃຫ່ມ");
      !this.$v.form.password.minLength &&
        errors.push("ລະຫັດຜ່ານຍ່າງນ້ອຍແມ່ນ 8 ໂຕຂື້ນໄປ");
      return errors;
    },
    confirmPasswordErrors() {
      const errors = [];
      if (!this.$v.form.confirm_password.$dirty) return errors;
      this.confirmPasswordError = "";
      !this.$v.form.confirm_password.required &&
        errors.push("ກະລຸນາປ້ອນລະຫັດຍືນຍັນໂຕໃຫ່ມອີກເທື່ອ");
      !this.$v.form.confirm_password.sameAsPassword &&
        errors.push(
          "ລະຫັດຜ່ານໃຫ່ມແລະລະຫັດຍຶນຍັນບໍຕົງກັນ, ກະລຸນາກວດສອບຄືນອີກຄັ້ງ"
        );
      return errors;
    },
  },
};

export { login, Register, changePassword };
