<template>
  <section>
    <data-table
      title="ປະຫວັດການຂາຍ"
      :headers="headers"
      :items="history"
      :checkSearch="true"
      commit="SET_PRODUCT"
      :viewItem="viewItem"
    />
    <app-custom-dialog
      v-model="dialog"
      :footer="false"
      title="description"
      @submit="submitForm"
    >
      <div slot="activator">
        <AppDetail :item="form" />
      </div>
    </app-custom-dialog>
    <app-custom-dialog
      v-model="dialogAdd"
      :footer="true"
      :title="titleStatus"
      @submit="submitForm"
    >
      <div slot="activator">
        <TTextField label="category" v-model="form.name" />
      </div>
    </app-custom-dialog>
  </section>
</template>

<script>
import DataTable from "@/components/DataTable";
import { mapGetters, mapActions } from "vuex";
import { historySaleHead } from "@/utils/head";

import TTextField from "@/components/global/TextField";
import AppDetail from "@/components/products/detailsHistory";
import { success, confirm } from "@/controllers/alert";

export default {
  data: () => ({
    update: false,
    dialogAdd: false,
    dialog: false,
    headers: historySaleHead(),
    form: {},
  }),
  async created() {
    await this.fetchItem();
  },
  components: { DataTable, TTextField, AppDetail },
  computed: {
    ...mapGetters({
      history: "index/history",
    }),

    titleStatus() {
      return this.update ? "update" : "new_data";
    },
  },

  methods: {
    ...mapActions({
      fetch: "index/fetch",
    }),
    async fetchItem() {
      const body = {
        that: this,
        url: "history_product",
        commit: "SET_HISTORY",
      };
      await this.fetch(body);
    },

    async newItem() {
      await Help.clearForm(this.form, this);
      await this.fetchItem();
      this.update = false;
      this.dialogAdd = !this.dialogAdd;
    },
    editItem(item) {
      this.update = true;
      this.form = item;
      this.dialogAdd = !this.dialogAdd;
    },
    async deleteItem({ id }) {
      const { isConfirmed } = await confirm({
        props: this,
        text: "want_to_delete",
      });
      if (isConfirmed) {
        const { data } = await this.$http.post(`categories?id=${id}`);
        if (data.status) {
          await Help.clearForm(this.form, this);
          await this.fetchItem();
          await success(this, data.message);
        }
      }
    },
    viewItem(item) {
      this.form = item;
      this.dialog = !this.dialog;
    },
    async submitForm() {
      var result;
      try {
        if (this.update) {
          result = await this.$http.post(
            `categories/${this.form.id}`,
            this.form
          );
        } else {
          result = await this.$http.post("categories", this.form);
        }
        if (result.data.status) {
          this.dialogAdd = !this.dialogAdd;
          await Help.clearForm(this.form, this);
          await this.fetchItem();
          await success(this, result.data.message);
        }
      } catch (error) {
        console.log("error", error);
      }
    },
  },
};
</script>

<style></style>
