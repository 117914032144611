<template>
  <v-dialog v-model="dialog" persistent max-width="290">
    <v-card>
      <v-card-title>
        <span class="text-capitalize font-weight-bold">{{ $t(title) }}</span>
        <v-spacer />

        <v-icon class="close-btn" @click="closeDialog" color="red" small>
          fal fa-times
        </v-icon>
      </v-card-title>

      <v-card-text class="text-center align-center">
        <v-card
          height="60"
          class="display-1 d-flex justify-end align-center px-4 number-card"
        >
          <v-btn
            small
            icon
            @click="onKeyPress({ icon: 'fal fa-times-circle' })"
          >
            <v-icon small> fas fa-times-circle </v-icon>
          </v-btn>
          <v-spacer />

          {{ displayValue | ToNumber }}
        </v-card>
      </v-card-text>

      <v-card-text class="pb-2">
        <v-row no-gutters>
          <v-col
            class="numeric-pad"
            v-for="(key, index) in keys"
            :key="index"
            cols="4"
          >
            <v-btn tile @click="onKeyPress(key)">
              <v-icon v-if="key.icon">{{ key.icon }}</v-icon>
              <span v-else>{{ key }}</span>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-actions>
        <v-btn color="primary" block @click="submitValue">{{
          $t(submitText)
        }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { myMixin } from "@/mixin/main";

export default {
  name: "NumericPad",
  props: {
    title: {
      type: String,
      required: true,
    },
    value: {
      type: String,
      required: true,
    },
    submitText: {
      type: String,
      default: "ok",
    },
    dialog: {
      type: Boolean,
      default: true,
    },
  },
  mixins: [myMixin],
  data() {
    return {
      displayValue: this.value || "0",
      keys: [
        "7",
        "8",
        "9",
        "4",
        "5",
        "6",
        "1",
        "2",
        "3",
        ".",
        "0",
        { icon: "fal fa-backspace" },
      ],
    };
  },

  mounted() {
    this.windowListener();
  },

  methods: {
    onKeyPress(key) {
      if (key.icon === "fal fa-times-circle") {
        this.displayValue = "0";
      } else if (key.icon === "fal fa-backspace") {
        if (this.displayValue.length <= 1) {
          this.displayValue = "0";
        } else {
          this.displayValue = this.displayValue.slice(0, -1);
        }
      } else {
        // if the value is 0, replace it with the key
        if (this.displayValue === "0") {
          this.displayValue = key;
        } else {
          this.displayValue += key;
        }
      }
    },
    submitValue() {
      this.$emit("input", this.displayValue);
      this.closeDialog();
      this.displayValue = "0";
    },
    closeDialog() {
      this.$emit("close");
    },
    windowListener(event) {
      window.addEventListener("keydown", (e) => {
        if (!this.dialog) return;
        e.preventDefault();
        // if backspace key is pressed
        switch (e.key) {
          case "1":
            this.onKeyPress("1");
            break;
          case "2":
            this.onKeyPress("2");
            break;
          case "3":
            this.onKeyPress("3");
            break;
          case "4":
            this.onKeyPress("4");
            break;
          case "5":
            this.onKeyPress("5");
            break;
          case "6":
            this.onKeyPress("6");
            break;
          case "7":
            this.onKeyPress("7");
            break;
          case "8":
            this.onKeyPress("8");
            break;
          case "9":
            this.onKeyPress("9");
            break;
          case "0":
            this.onKeyPress("0");
            break;
          case ".":
            this.onKeyPress(".");
            break;
          case "Backspace":
            this.onKeyPress({ icon: "fal fa-backspace" });
            break;
        }
      });
    },
  },
  watch: {
    value(newVal) {
      this.displayValue = newVal;
    },
  },
};
</script>

<style scoped>
.numeric-pad .v-btn {
  height: 60px;
  width: 100%;
  font-weight: 100;
  font-size: 1.2rem;
  box-shadow: none;
  border-right: 0.1px solid #ccc;
  border-bottom: 0.1px solid #ccc;
}

.numeric-pad {
  border-top: 0.1px solid #ccc;
  border-left: 0.1px solid #ccc;
}
.numeric-pad .row .col button.submit-btn {
  background-color: #5b39c9;
  color: #fff;
}
.numeric-pad .v-btn.theme--dark:hover {
  background-color: #333;
}

.numeric-pad .v-btn.theme--light:hover {
  background-color: #e6e4e4;
}
.number-card.theme--light {
  background-color: #e0e0e0;
}
.number-card.theme--dark {
  background-color: #333;
}
.close-btn {
  cursor: pointer;
  border-radius: 50%;
  width: 30px;
  height: 30px;
}

.close-btn.theme--dark {
  background-color: #333;
}

.close-btn.theme--light {
  background-color: #f5f5f5;
}
</style>
