<template>
  <section>
    <data-table
      :headers="headers"
      :items="reportUserSale"
      commit="SET_USER_SALE"
      :viewItem="viewItem"
      :editItem="editItem"
    >
      <div slot="activator" class="row">
        <v-col cols="2">
          <v-menu
            ref="menu"
            v-model="menu"
            :close-on-content-click="false"
            :return-value.sync="start_date"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="start_date"
                class="date-picker"
                placeholder="Start Date"
                readonly
                hide-details
                dense
                outlined
                color="primary"
                v-bind="attrs"
                v-on="on"
              >
                <template v-slot:append>
                  <v-icon small>fal fa-calendar-alt</v-icon>
                </template>
              </v-text-field>
            </template>
            <v-date-picker v-model="start_date" no-title scrollable>
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="menu = false">
                ຍົກເລີກ
              </v-btn>
              <v-btn
                text
                color="primary"
                @click="filterDate(1, start_date, end_date)"
              >
                ຕົກລົງ
              </v-btn>
            </v-date-picker>
          </v-menu></v-col
        >

        <v-col cols="2">
          <v-menu
            ref="menu2"
            v-model="menu2"
            :close-on-content-click="false"
            :return-value.sync="end_date"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="end_date"
                class="date-picker"
                placeholder="Start Date"
                readonly
                hide-details
                dense
                outlined
                color="primary"
                v-bind="attrs"
                v-on="on"
              >
                <template v-slot:append>
                  <v-icon small>fal fa-calendar-alt</v-icon>
                </template>
              </v-text-field>
            </template>
            <v-date-picker v-model="end_date" no-title scrollable>
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="menu2 = false">
                ຍົກເລີກ
              </v-btn>
              <v-btn
                text
                color="primary"
                @click="filterDate(2, start_date, end_date)"
              >
                ຕົກລົງ
              </v-btn>
            </v-date-picker>
          </v-menu>
        </v-col>
        <v-col cols="4" offset="2">
          <TextSearch
            v-model="search"
            @search="fetchItem()"
            @onClear="
              search = '';
              fetchItem();
            "
          />
        </v-col>
      </div>
    </data-table>
    <app-custom-dialog
      width="1000px"
      v-model="dialog"
      :footer="false"
      title="description"
    >
      <div slot="activator">
        <AppDetail :item="form" />
      </div>
    </app-custom-dialog>
    <app-bill v-show="print" :item="form" />
    <!-- AppLoading -->
    <app-loading :overlay="overlay" />
  </section>
</template>

<script>
import DataTable from "@/components/DataTable";
import { mapGetters, mapActions } from "vuex";
import { billReports } from "@/utils/head";
import AppDetail from "@/components/products/billReport";
import { myMixin } from "@/mixin/main";
import AppBill from "@/components/print/bill";
import { error } from "@/controllers/alert";
// get yesterday date
const yesterday = new Date(
  Date.now() - 864e5 - new Date().getTimezoneOffset() * 60000
)
  .toISOString()
  .substr(0, 10);

// get today date
const today = new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
  .toISOString()
  .substr(0, 10);
export default {
  mixins: [myMixin],
  data: () => ({
    start_date: yesterday,
    end_date: today,
    dialog: false,
    print: false,
    overlay: false,
    menu: false,
    menu2: false,
    headers: billReports(),
    form: {},
  }),
  async created() {
    await this.fetchItem(this.start_date, this.end_date);
  },
  components: {
    DataTable,

    AppDetail,
    AppBill,
  },

  computed: {
    ...mapGetters({
      reportUserSale: "index/reportUserSale",
    }),
  },

  methods: {
    ...mapActions({
      fetch: "index/fetch",
    }),
    async filterDate(type, start_date, end_date) {
      try {
        await Help.getDifferentMonth(this, start_date, end_date);
        if (type === 1) {
          this.$refs.menu.save(start_date);
          this.menu = false;
        } else {
          this.$refs.menu2.save(end_date);
          this.menu2 = false;
        }
        this.overlay = true;
        // setTimeOut for 1 second to show overlay
        setTimeout(() => {
          this.fetchItem(start_date, end_date);
          this.overlay = false;
        }, 1000);
      } catch (err) {
        console.log("error", err.message);
        error(this, err.message);
        return;
      }
    },
    async fetchItem(start_date, end_date) {
      const body = {
        that: this,
        url: `user_sale/${this.$route.params.id}?start_date=${start_date}&end_date=${end_date}&q=${this.search}`,
        commit: "SET_USER_SALE",
      };
      await this.fetch(body);
    },

    async viewItem({ id }) {
      this.overlay = true;
      const { data } = await this.$http.get(`bill_detail/${id}`);
      this.form = data.data;
      // setTimeOut for 1 second to show overlay
      setTimeout(() => {
        this.overlay = false;
        this.dialog = !this.dialog;
      }, 1000);
    },

    editItem(item) {
      this.form = item;
      setTimeout(() => {
        this.$htmlToPaper("printBill");
      }, 500);
    },
  },
};
</script>

<style></style>
