<template>
  <section>
    <v-row>
      <v-col v-for="(item, index) in details.summary" :key="index">
        <v-card rounded="lg">
          <v-card-text>
            <v-list-item>
              <v-list-item-avatar :color="item.color" rounded="lg">
                <v-icon color="white">
                  {{ item.icon }}
                </v-icon>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-subtitle>
                  {{ $t(item.text) }}
                </v-list-item-subtitle>
                <v-list-item-title class="text-h6 font-weight-bold">
                  {{ item.total | NumberFormat }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-row>
      <div class="col-xs-12 col-ms-12 col-md-7 col-lg-7 col-xl-7">
        <v-card class="rounded-lg">
          <v-card-text>
            <apexchart
              width="100%"
              height="495"
              type="area"
              :options="chartOptions"
              :series="series"
            >
            </apexchart>
          </v-card-text>
        </v-card>
      </div>
      <div class="col-xs-12 col-ms-12 col-md-5 col-lg-5 col-xl-5">
        <v-card class="rounded-lg">
          <v-card-text>
            <apexchart
              type="bar"
              width="100%"
              height="500"
              :options="chartOptions2"
              :series="series2"
            >
            </apexchart>
          </v-card-text>
        </v-card>
      </div>
    </v-row>
  </section>
</template>

<script>
import AppCardTitle from "@/components/expense/card-title";
import { mapGetters, mapActions } from "vuex";
import { myMixin } from "@/mixin/main";

export default {
  components: { AppCardTitle },
  mixins: [myMixin],

  data: () => ({
    chartOptions: {
      chart: {
        type: "area",
        zoom: {
          enabled: true,
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: "straight",
      },
      title: {
        text: "Store Performance",
        align: "left",
      },
      labels: [
        "jan",
        "feb",
        "mar",
        "apr",
        "may",
        "jun",
        "jul",
        "aug",
        "sep",
        "oct",
        "nov",
        "dec",
      ],

      yaxis: {
        opposite: true,
      },
    },
    series: [],
    series2: [],
    chartOptions2: {
      title: {
        text: "Hottest Stores",
        align: "left",
      },
      plotOptions: {
        bar: {
          horizontal: false,
        },
      },
      colors: ["#00E396"],
      dataLabels: {},
      legend: {
        show: true,
        showForSingleSeries: true,
        customLegendItems: ["Price", "Amount"],
        markers: {
          fillColors: ["#00E396", "#775DD0"],
        },
      },
    },
  }),

  async created() {
    await this.fetchItem();
    await this.fetchSummaryDetail();
  },
  watch: {
    summary(val) {
      const res = Help.pluck(val, "total");
      this.series = [
        {
          name: "Total Sales",
          data: res,
        },
      ];
    },
    details(val) {
      const data = [];
      const name = Help.pluck(val.bestSeller, "name");
      val.bestSeller.map((item, index) => {
        const detail = {
          x: item.name,
          y: item.tickets_count,
          goals: [
            {
              name: item.name,
              value: item.tickets_count,
              strokeWidth: 5,
              strokeColor: "#775DD0",
            },
          ],
        };
        data.push(detail);
      });

      this.series2 = [
        {
          name: "Hottest Stores",
          data: data,
        },
      ];

      this.chartOptions2 = {
        labels: name,
      };
    },
  },
  computed: {
    ...mapGetters({
      summary: "index/summary",
      details: "index/summaryDetails",
    }),
  },

  methods: {
    ...mapActions({
      fetch: "index/fetch",
    }),

    async fetchItem(val) {
      let url = `admin_summaries`;
      if (val) {
        url = `admin_summaries?q=${val}`;
      }
      const body = {
        that: this,
        url: url,
        commit: "SET_SUMMARY",
      };
      await this.fetch(body);
    },
    async fetchSummaryDetail() {
      const body = {
        that: this,
        url: "admin_summary",
        commit: "SET_REPORT_DETAILS",
      };
      await this.fetch(body);
    },

    async filter(val) {},
  },
};
</script>

<style></style>
