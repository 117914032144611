<template>
  <section>
    <v-card :color="colorPad" flat class="mb-4">
      <v-card-text class="d-flex justify-content-between py-5 px-3">
        <v-btn icon @click="clear">
          <v-icon>fal fa-times-circle</v-icon>
        </v-btn>
        <div class="font-weight-bold text-h4">
          {{ formattedValue }}
        </div>
      </v-card-text>
    </v-card>

    <div class="numeric-keyboard">
      <v-row v-for="(row, rowIndex) in keys" :key="rowIndex" class="ma-0">
        <v-col v-for="key in row" :key="key.id" cols="3" class="pa-0">
          <v-btn
            tile
            :class="key.class"
            @click="key.action ? key.action() : appendToInput(key.label)"
          >
            <v-icon v-if="key.icon">{{ key.icon }}</v-icon>
            <span v-else>{{ key.label }}</span>
          </v-btn>
        </v-col>
      </v-row>
    </div>
  </section>
</template>

<script>
export default {
  name: "NumericKeyboard",
  props: {
    open_noted: {
      type: Boolean,
      default: false,
    },
    total_price: {
      type: Number,
      required: true,
    },
    value: {
      type: String,
      required: true,
    },
    type: {
      type: String,
      default: "RESTAURANT",
    },
  },
  data() {
    return {
      windowListener: this.handleKeydown,
    };
  },

  mounted() {
    this.windowListener = this.handleKeydown.bind(this);
    window.addEventListener("keydown", this.windowListener);
  },

  watch: {
    open_noted() {
      // remove event listener and add it again
      window.removeEventListener("keydown", this.windowListener);
      this.windowListener();
    },
  },

  destroyed() {
    window.removeEventListener("keydown", this.windowListener);
  },

  computed: {
    colorPad() {
      return localStorage.getItem("darkMode") === "true" ? "#333" : "#f0f0f0";
    },
    formattedValue() {
      return Help.NumberPad(this.value);
    },
    keys() {
      const baseKeys = [
        [
          { id: 1, label: "7" },
          { id: 2, label: "8" },
          { id: 3, label: "9" },
          { id: 4, label: "100,000", class: "primary--text" },
        ],
        [
          { id: 5, label: "4" },
          { id: 6, label: "5" },
          { id: 7, label: "6" },
          { id: 8, label: "50,000", class: "primary--text" },
        ],
        [
          { id: 9, label: "1" },
          { id: 10, label: "2" },
          { id: 11, label: "3" },
          { id: 12, label: "20,000", class: "primary--text" },
        ],
      ];
      const restaurantKeys = [
        ...baseKeys,
        [
          { id: 13, label: "." },
          { id: 14, label: "0" },
          { id: 15, icon: "fal fa-backspace", action: this.backspace },
          { id: 16, label: "OK", action: () => this.$emit("submit") },
        ],
      ];
      const otherKeys = [
        ...baseKeys,
        [
          { id: 17, label: "." },
          { id: 18, label: "0" },
          { id: 19, icon: "fal fa-backspace", action: this.backspace },
          {
            id: 20,
            label: this.$t("full"),
            class: "primary--text",
            action: this.full,
          },
          {
            id: 21,
            icon: "fal fa-user-circle",
            action: () => this.$emit("customer"),
          },
          {
            id: 22,
            icon: "fal fa-piggy-bank",
            action: () => this.$emit("overdue"),
          },
          {
            id: 23,
            icon: "fal fa-edit",
            action: () => this.$emit("openNoted"),
          },
          {
            id: 24,
            label: this.$t("ok"),
            action: () => this.$emit("submit"),
            class: "submit-btn",
          },
        ],
      ];
      return this.type === "RESTAURANT" ? restaurantKeys : otherKeys;
    },
  },
  watch: {
    open_noted() {
      window.removeEventListener("keydown", this.windowListener);
      this.windowListener();
    },
  },
  methods: {
    appendToInput(value) {
      // if value have , remove it
      if (value.includes(",")) {
        //  convert value to number
        const valInt = parseFloat(value.replace(/,/g, "")) || 0;
        // convert this.value to number
        const thisValueInt = parseFloat(this.value.replace(/,/g, "")) || 0;
        // total with convert to string
        const total = (thisValueInt + valInt).toString();
        // if this.value is 0, replace it with valInt
        this.$emit("input", total);
        return;
      }
      // value = value.replace(/,/g, "");
      this.$emit("input", this.value + value);
    },
    backspace() {
      // length the value if value is "" then set to 0
      if (this.value.length === 0 || this.value.length <= 1) {
        this.$emit("input", "0");
        return;
      } else {
        // remove last character
        if (this.value.includes(".")) {
          // 119191.04999999999 convert to float : 119191.05
          const value = parseFloat(this.value).toFixed(2);
          // convert to string
          const val = value.toString();
          // if value has a dot, remove it
          const removeDot = val.replace(".", "");
          // remove last character
          this.$emit("input", removeDot.slice(0, -1));
        } else {
          this.$emit("input", this.value.slice(0, -1));
        }
      }
    },
    clear() {
      this.$emit("input", "0");
    },
    full() {
      const total = this.total_price;
      this.$emit("input", total.toString());
    },
    handleKeydown(event) {
      if (this.open_noted) return;
      // if backspace key is pressed
      switch (event.key) {
        case "1":
          event.preventDefault();
          this.appendToInput("1");
          break;
        case "2":
          event.preventDefault();
          this.appendToInput("2");
          break;
        case "3":
          event.preventDefault();
          this.appendToInput("3");
          break;
        case "4":
          event.preventDefault();
          this.appendToInput("4");
          break;
        case "5":
          event.preventDefault();
          this.appendToInput("5");
          break;
        case "6":
          event.preventDefault();
          this.appendToInput("6");
          break;
        case "7":
          event.preventDefault();
          this.appendToInput("7");
          break;
        case "8":
          event.preventDefault();
          this.appendToInput("8");
          break;
        case "9":
          event.preventDefault();
          this.appendToInput("9");
          break;
        case "0":
          event.preventDefault();
          this.appendToInput("0");
          break;
        case ".":
          event.preventDefault();
          this.appendToInput(".");
          break;
        case "Enter":
          event.preventDefault();
          this.$emit("submit");
          break;
        case "Backspace":
          event.preventDefault();
          this.backspace();
          break;
      }
    },
  },
};
</script>

<style scoped>
.numeric-keyboard .v-btn {
  height: 78px;
  width: 100%;
  font-weight: 400;
  font-size: 1rem;
  box-shadow: none;
  border-right: 0.1px solid #ccc;
  border-bottom: 0.1px solid #ccc;
}

.numeric-keyboard {
  border-top: 0.1px solid #ccc;
  border-left: 0.1px solid #ccc;
}
.numeric-keyboard .row .col button.submit-btn {
  background-color: #5b39c9;
  color: #fff;
}
.numeric-keyboard .v-btn.theme--dark:hover {
  background-color: #333;
}

.numeric-keyboard .v-btn.theme--light:hover {
  background-color: #e6e4e4;
}
</style>
