<template>
  <section>
    <v-app-bar flat height="65" fixed app>
      <v-btn link to="/sale" text class="text-h6">
        {{ $t("kitchen") }}
      </v-btn>
      <v-spacer></v-spacer>
      <v-row>
        <v-col cols="2" offset="2">
          <TextSelect
            :dense="true"
            :placeholder="false"
            :items="statusList"
            text="text"
            itemValue="value"
            v-model="status_action"
          />
        </v-col>
        <v-col cols="2">
          <DatePicker
            v-model="start_date"
            :dense="true"
            :placeholder="false"
            label="start_date"
          />
        </v-col>
        <v-col cols="2">
          <DatePicker
            v-model="end_date"
            :dense="true"
            :placeholder="false"
            label="end_date"
          />
        </v-col>
        <v-col cols="4">
          <TextSearch
            v-model="search"
            @search="fetchItem()"
            @onClear="
              search = '';
              fetchItem();
            "
          />
        </v-col>
      </v-row>

      <v-btn class="ml-3" fab depressed small @click="darkThemes">
        <v-icon small> fal fa-{{ darkMode ? "sun" : "moon" }} </v-icon>
      </v-btn>
    </v-app-bar>
    <v-main>
      <v-container>
        <v-subheader>
          {{ $t("summary") }}
        </v-subheader>
        <v-row>
          <v-col cols="3" v-for="(item, index) in summaries" :key="index">
            <v-card rounded="lg">
              <v-card-text>
                <v-list-item>
                  <v-list-item-avatar :color="item.color" rounded="lg">
                    <v-icon color="white">
                      {{ item.icon }}
                    </v-icon>
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-subtitle>
                      {{ $t(item.text) }}
                    </v-list-item-subtitle>
                    <v-list-item-title class="text-h6 font-weight-bold">
                      {{ item.value }}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <v-subheader>{{ $t("order_items") }}</v-subheader>
        <masonry
          :cols="{ default: 4, 1000: 3, 700: 2, 400: 1 }"
          :gutter="{ default: '20px', 700: '20px' }"
        >
          <div v-for="(item, index) in items" :key="index" class="mb-5">
            <v-card rounded="lg">
              <v-card-text>
                <v-list-item dense>
                  <v-list-item-avatar
                    tile
                    color="green"
                    dark
                    class="rounded-lg justify-content-center"
                  >
                    {{ item.queue_no }}
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ $t("table") }} {{ item.table.name }}
                    </v-list-item-title>
                    <v-list-item-subtitle>
                      {{ item.created_at | moment }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-action>
                    <v-menu offset-y>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn icon depressed v-bind="attrs" v-on="on">
                          <v-icon> fal fa-ellipsis-h </v-icon>
                        </v-btn>
                      </template>
                      <v-list dense>
                        <v-list-item
                          v-for="(val, i) in actionList"
                          :key="i"
                          @click="billAction(item.id, val.value, 'ticket')"
                          dense
                        >
                          <v-list-item-icon>
                            <v-icon small :color="val.color">
                              {{ val.icon }}
                            </v-icon>
                          </v-list-item-icon>
                          <v-list-item-title>
                            {{ val.text }}
                          </v-list-item-title>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                  </v-list-item-action>
                </v-list-item>
                <v-divider class="ma-0"></v-divider>
                <v-list-item
                  dense
                  v-for="(data, index) in item.items"
                  :key="index"
                >
                  <v-list-item-avatar> {{ index + 1 }}. </v-list-item-avatar>

                  <v-list-item-content>
                    <v-list-item-title>{{
                      data.product_id.name
                    }}</v-list-item-title>
                    <v-list-item-subtitle
                      >x {{ data.qty }}</v-list-item-subtitle
                    >
                  </v-list-item-content>
                  <v-list-item-action>
                    <v-menu offset-y>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn icon depressed v-bind="attrs" v-on="on" small>
                          <v-icon> fal fa-ellipsis-h </v-icon>
                        </v-btn>
                      </template>
                      <v-list dense>
                        <v-list-item
                          v-for="(val, i) in actionItems"
                          :key="i"
                          @click="
                            kitchenActionBill(
                              data.queue_id,
                              val.value,
                              'item',
                              data.product_id.id
                            )
                          "
                          dense
                        >
                          <v-list-item-icon>
                            <v-icon small :color="val.color">
                              {{ val.icon }}
                            </v-icon>
                          </v-list-item-icon>
                          <v-list-item-title>
                            {{ val.text }}
                          </v-list-item-title>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                  </v-list-item-action>
                </v-list-item>
              </v-card-text>
            </v-card>
          </div>
        </masonry>
      </v-container>
    </v-main>
    <AppBill v-show="print" :item="form" />
    <!-- AppLoading -->
    <app-loading :overlay="overlay" />
    <!-- noted cancel -->
    <app-custom-dialog
      v-model="cancelForm"
      :footer="true"
      title="cancel_reason"
      @submit="kitchenActionBill(id, status, 'ticket', null)"
    >
      <div slot="activator">
        <v-row>
          <v-col cols="12">
            <v-textarea
              v-model="noted"
              :label="$t('noted')"
              outlined
              color="primary"
              rows="3"
            ></v-textarea>
          </v-col>
        </v-row>
      </div>
    </app-custom-dialog>

    <v-fab-transition>
      <v-btn fab dark fixed bottom right color="primary" @click="fetchItem()">
        <v-icon small> fal fa-sync </v-icon>
      </v-btn>
    </v-fab-transition>
  </section>
</template>

<script>
import PubNub from "pubnub";
import {
  pubnubConfig,
  kitchenList,
  kitchenItem,
  kitchenStatusAction,
} from "@/utils/mock";
import AppBill from "@/components/print/kitchen";
import { myMixin } from "@/mixin/main";
import { toast } from "@/controllers/alert";
import TextSelect from "@/components/global/TextSelect";

// get today date
const today = new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
  .toISOString()
  .substr(0, 10);
// get tomorrow date
const tomorrow = new Date(
  Date.now() - new Date().getTimezoneOffset() * 60000 + 86400000
)
  .toISOString()
  .substr(0, 10);

export default {
  mixins: [myMixin],
  components: {
    AppBill,
    TextSelect,
  },
  data: () => ({
    pubnub: new PubNub(pubnubConfig()),
    darkMode: null,
    print: false,
    form: {},
    menu: false,
    cancelForm: false,
    search: "",
    overlay: false,
    start_date: today,
    end_date: tomorrow,
    items: [],
    summaries: [],

    noted: "",
    status: null,
    id: null,
    status_action: {
      text: "cooking",
      value: "cooking",
    },
  }),
  mounted() {
    if (this.store_type != "RESTAURANT") {
      window.location.href = "/sale";
    }
    this.pubnub.addListener({
      message: (event) => {
        toast(this, event.message, "success");
        Help.playAudio();
        this.fetchItem();
      },
    });
    const channel = `${this.user.stores.code}_kitchen`;
    this.pubnub.subscribe({ channels: [channel] });
    this.fetchItem();
    this.fetchSummary();
    const themes = localStorage.getItem("darkMode");
    if (themes) {
      if (themes.length === 4) {
        this.darkMode = true;
        this.$vuetify.theme.dark = true;
      } else {
        this.darkMode = false;
        this.$vuetify.theme.dark = false;
      }
    }
  },
  watch: {
    start_date(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.fetchItem();
        this.fetchSummary();
      }
    },
    end_date(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.fetchItem();
        this.fetchSummary();
      }
    },
    status_action(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.fetchItem();
      }
    },
  },
  computed: {
    actionList() {
      return kitchenList(this);
    },
    actionItems() {
      return kitchenItem(this);
    },
    statusList() {
      return kitchenStatusAction(this);
    },
  },
  methods: {
    darkThemes() {
      this.darkMode = !this.darkMode;
      this.$vuetify.theme.dark = this.darkMode;
      if (this.darkMode) {
        localStorage.setItem("darkMode", "true");
      } else {
        localStorage.removeItem("darkMode");
      }
    },
    async fetchItem() {
      try {
        this.overlay = true;
        const param = `start_date=${this.start_date}&end_date=${this.end_date}&q=${this.search}&status=${this.status_action.value}`;
        const { data } = await this.$http.get(`/get_ticket?${param}`);
        if (data.status) {
          this.items = data.data;
          this.overlay = false;
        }
      } catch (error) {
        console.log(error);
      }
    },
    async printBill(id) {
      const { data } = await this.$http.get(`/get_ticket_detail/${id}`);
      if (data.status) {
        this.form = data.data;
        setTimeout(() => {
          this.$htmlToPaper("printKitchen");
        }, 500);
      }
    },
    async fetchSummary() {
      try {
        const param = `start_date=${this.start_date}&end_date=${this.end_date}&q=${this.search}`;
        const { data } = await this.$http.get(`/get_kitchen_summary?${param}`);
        if (data.status) {
          this.summaries = data.data;
        }
      } catch (error) {
        console.log(error);
      }
    },
    async billAction(id, status, type) {
      this.status = status;
      this.id = id;
      if (status === "print") {
        this.printBill(id);
      } else if (status === "cancel") {
        this.cancelForm = true;
        this.noted = "";
        return;
      } else {
        this.kitchenActionBill(id, status, type, null);
      }
    },
    async kitchenActionBill(id, status, type, product_id) {
      const body = {
        queue_id: id,
        status: status,
        type: type,
        noted: this.noted,
        product_id: product_id,
      };
      try {
        const { data } = await this.$http.post(`update_status_ticket`, body);
        if (data.status) {
          this.cancelForm = false;
          this.fetchItem();
          this.fetchSummary();
          toast(this, data.message, "success");
        }
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>

<style></style>
