<template>
  <v-checkbox
    v-model="model"
    v-bind:dense="dense"
    color="primary"
    :disabled="disabled"
    :label="$t(label)"
    class="ma-0"
    v-bind:hide-details="hideDetails"
  />
</template>

<script>
export default {
  name: "CheckBox",
  props: {
    value: {},
    hideDetails: { type: String, default: "auto" },
    label: { type: String, require: true },
    dense: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
  },
  data() {
    return {
      model: this.value,
    };
  },
  watch: {
    value(newVal, oldVal) {
      if (newVal !== oldVal) this.model = newVal;
    },
    model(newVal, oldVal) {
      if (newVal !== oldVal) this.$emit("input", newVal);
    },
  },
};
</script>

<style></style>
