<template>
  <section>
    <data-table
      :headers="headers"
      :items="bestSales"
      commit="SET_BEST_SALE"
      :viewItem="viewItem"
    >
      <div slot="activator" class="row">
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-3">
          <v-card-title class="px-0 pt-0">
            {{ $t("best_sale") }}
          </v-card-title>
        </div>
        <v-spacer />

        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-3">
          <TextSearch
            v-model="search"
            @search="fetchItem()"
            @onClear="
              search = '';
              fetchItem();
            "
          />
        </div>
      </div>
    </data-table>
    <app-custom-dialog
      v-model="dialog"
      :footer="false"
      title="description"
      @submit="submitForm"
    >
      <div slot="activator">
        <AppDetail :item="form" />
      </div>
    </app-custom-dialog>
    <app-custom-dialog
      v-model="dialogAdd"
      :footer="true"
      :title="titleStatus"
      @submit="submitForm"
    >
      <div slot="activator">
        <TTextField label="category" v-model="form.name" />
      </div>
    </app-custom-dialog>
    <!-- AppLoading -->
    <app-loading :overlay="overlay" />
  </section>
</template>

<script>
import DataTable from "@/components/DataTable";
import { mapGetters, mapActions } from "vuex";
import { productHead } from "@/utils/head";
import TTextField from "@/components/global/TextField";
import AppDetail from "@/components/products/details";
import { success, confirm } from "@/controllers/alert";

export default {
  data: () => ({
    update: false,
    overlay: false,
    search: "",
    dialogAdd: false,
    dialog: false,

    form: {},
  }),
  async created() {
    await this.fetchItem();
  },
  components: { DataTable, TTextField, AppDetail },
  computed: {
    ...mapGetters({
      category: "index/category",
      bestSales: "index/bestSales",
      user: "auth/user",
    }),

    titleStatus() {
      return this.update ? "update" : "new_data";
    },
    headers() {
      return productHead(this);
    },
  },

  methods: {
    ...mapActions({
      fetch: "index/fetch",
    }),
    async fetchItem() {
      const body = {
        that: this,
        url: `best_products?q=${this.search}`,
        commit: "SET_BEST_SALE",
      };
      await this.fetch(body);
    },
    async newItem() {
      await Help.clearForm(this.form, this);
      await this.fetchItem();
      this.update = false;
      this.dialogAdd = !this.dialogAdd;
    },
    editItem(item) {
      this.update = true;
      this.form = item;
      this.dialogAdd = !this.dialogAdd;
    },
    async deleteItem({ id }) {
      const { isConfirmed } = await confirm({
        props: this,
        text: "want_to_delete",
      });
      if (isConfirmed) {
        const { data } = await this.$http.post(`categories?id=${id}`);
        if (data.status) {
          await Help.clearForm(this.form, this);
          await this.fetchItem();
          await success(this, data.message);
        }
      }
    },
    async viewItem({ id }) {
      try {
        const { data } = await this.$http.get(`products/${id}`);
        if (data.status) {
          this.form = data.data;
          this.dialog = !this.dialog;
        }
      } catch (error) {
        console.log(error);
      }
    },
    async submitForm() {
      var result;
      try {
        if (this.update) {
          result = await this.$http.post(
            `categories/${this.form.id}`,
            this.form
          );
        } else {
          result = await this.$http.post("categories", this.form);
        }
        if (result.data.status) {
          this.dialogAdd = !this.dialogAdd;
          await Help.clearForm(this.form, this);
          await this.fetchItem();
          await success(this, result.data.message);
        }
      } catch (error) {
        console.log("error", error);
      }
    },
  },
};
</script>

<style></style>
