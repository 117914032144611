<template>
  <section>
    <data-table
      :headers="headers"
      :items="branches"
      commit="SET_BRANCHES"
      :viewItem="viewItem"
      :editItem="editItem"
      :setBranch="setBranch"
      :deleteItem="deleteItem"
    >
      <div slot="activator" class="row justify-content-between">
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-2">
          <v-card-title class="px-0 pt-0">{{ $t("branch") }} </v-card-title>
        </div>

        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-4">
          <TextSearch
            v-model="search"
            @search="fetchItem()"
            @onClear="
              search = '';
              fetchItem();
            "
          />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-2 text-end">
          <v-btn depressed color="primary" @click="newItem">
            <v-icon left>fal fa-plus-circle</v-icon>
            {{ $t("new_data") }}
          </v-btn>
        </div>
      </div>
    </data-table>
    <app-custom-dialog
      v-model="dialog"
      :footer="false"
      title="description"
      @submit="submitForm"
    >
      <div slot="activator">
        <v-row>
          <v-col cols="4">{{ $t("no") }}</v-col>
          <v-col cols="8">{{ form.id }}</v-col>
          <v-col cols="4">{{ $t("name") }}</v-col>
          <v-col cols="8">{{ form.name }}</v-col>
          <v-col cols="4">{{ $t("address") }}</v-col>
          <v-col cols="8">{{ form.address }}</v-col>
          <v-col cols="4">{{ $t("phone_no") }}</v-col>
          <v-col cols="8">{{ form.phone_no }}</v-col>
          <v-col cols="4">{{ $t("mobile_no") }}</v-col>
          <v-col cols="8">{{ form.mobile_no }}</v-col>
          <v-col cols="4">{{ $t("users_count") }}</v-col>
          <v-col cols="8">{{ form.users_count }}</v-col>
          <v-col cols="4">{{ $t("products_count") }}</v-col>
          <v-col cols="8">{{ form.products_count }}</v-col>
          <v-col cols="4">{{ $t("tickets_sum_total_price") }}</v-col>
          <v-col cols="8">{{
            form.tickets_sum_total_price | NumberFormat
          }}</v-col>
          <v-col cols="4">{{ $t("date") }}</v-col>
          <v-col cols="8">{{ form.created_at | moment }}</v-col>
        </v-row>
      </div>
    </app-custom-dialog>
    <app-custom-dialog
      v-model="dialogAdd"
      :footer="true"
      :title="titleStatus"
      @submit="submitForm"
    >
      <div slot="activator">
        <FileInput label="logo" v-model="imgFile" />
        <TTextField
          label="name"
          v-model="form.name"
          :errorMessages="nameErrors"
          @input="$v.form.name.$touch()"
          @blur="$v.form.name.$touch()"
        />

        <TTextField
          label="address"
          v-model="form.address"
          :errorMessages="addressErrors"
          @input="$v.form.address.$touch()"
          @blur="$v.form.address.$touch()"
        />
        <TTextField
          label="phone_no"
          v-model="form.phone_no"
          :errorMessages="phone_noErrors"
          @input="$v.form.phone_no.$touch()"
          @blur="$v.form.phone_no.$touch()"
        />
        <TTextField
          label="mobile_no"
          v-model="form.mobile_no"
          :errorMessages="mobile_noErrors"
          @input="$v.form.mobile_no.$touch()"
          @blur="$v.form.mobile_no.$touch()"
        />
      </div>
    </app-custom-dialog>
    <!-- AppLoading -->
    <app-loading :overlay="overlay" />
  </section>
</template>

<script>
import DataTable from "@/components/DataTable";
import { mapGetters, mapActions } from "vuex";
import { branchHead } from "@/utils/head";
import TTextField from "@/components/global/TextField";
import { setUser } from "@/controllers/auth";
import { myMixin } from "@/mixin/main";
import { success, confirm, error, toast } from "@/controllers/alert";
import { BRANCHES } from "@/validations/shop";
import FileInput from "@/components/global/FileInput";

export default {
  mixins: [BRANCHES, myMixin],
  data: () => ({
    imgFile: null,
    update: false,
    dialogAdd: false,
    dialog: false,
    overlay: false,
    search: "",

    form: {},
  }),

  async created() {
    await this.fetchItem();
  },
  components: { DataTable, TTextField, FileInput },
  computed: {
    ...mapGetters({
      branches: "index/branches",
    }),

    titleStatus() {
      return this.update ? "update" : "new_data";
    },
    headers() {
      return branchHead(this);
    },
  },

  methods: {
    ...mapActions({
      fetch: "index/fetch",
    }),
    async fetchItem() {
      const body = {
        that: this,
        url: `branches_paginate?q=${this.search}`,
        commit: "SET_BRANCHES",
      };
      await this.fetch(body);
    },

    async newItem() {
      await Help.clearForm(this.form, this);
      this.update = false;
      this.dialogAdd = !this.dialogAdd;
    },
    editItem(item) {
      this.update = true;
      this.form = item;
      this.dialogAdd = !this.dialogAdd;
    },
    async deleteItem({ products_count, id }) {
      if (products_count > 0) {
        toast(this, "product_contain", "error");
      } else {
        const { isConfirmed } = await confirm({
          props: this,
          text: "want_to_delete",
        });
        if (isConfirmed) {
          const { data } = await this.$http.delete(`branches/${id}`);
          if (data.status) {
            await Help.clearForm(this.form, this);
            this.branches.data = this.branches.data.filter(
              (item) => item.id !== id
            );
            await success(this, data.message);
          }
        }
      }
    },
    viewItem(item) {
      this.form = item;
      this.dialog = !this.dialog;
    },
    async setBranch(val) {
      if (this.user.branch_id && val.id === this.user.branch_id.id) {
        return;
      }
      if (this.MyRole("change_branch")) {
        const { data } = await this.$http.post(`set_branch/${val.id}`);
        if (data.status) {
          await this.fetchItem();
          await success(this, data.message);
          await setUser(this);
        }
      } else {
        await error(this, "ທ່ານບໍສາມາດປ່ຽນສາຂາໄດ້");
      }
    },

    async submitForm() {
      try {
        this.$v.form.$touch();
        if (!this.$v.form.$invalid) {
          this.overlay = true;
          let formData = new FormData();
          formData.append("name", this.form.name);
          formData.append("address", this.form.address);
          formData.append("phone_no", this.form.phone_no);
          formData.append("mobile_no", this.form.mobile_no);
          if (this.imgFile) {
            formData.append("file", this.imgFile);
          }
          if (this.update) {
            const { data } = await this.$http.post(
              `update_branches/${this.form.id}`,
              formData
            );
            if (data.status) {
              this.overlay = false;
              this.dialogAdd = !this.dialogAdd;
              await Help.clearForm(this.form, this);
              await this.fetchItem();
              await success(this, data.message);
            }
          } else {
            const { data } = await this.$http.post("branches", formData);
            if (data.status) {
              this.overlay = false;
              this.dialogAdd = !this.dialogAdd;
              await Help.clearForm(this.form, this);
              // add products_count : 0 in data before push
              data.data.products_count = 0;
              // unshift new item
              this.branches.data.push(data.data);
              await success(this, data.message);
            }
          }
        }
      } catch (error) {
        this.overlay = false;
        console.log("error", error);
      }
    },
  },
};
</script>

<style></style>
