<template>
  <section class="py-12">
    <div class="container-xl">
      <h4 class="text-center text-h4 font-weight-bold">PosSmart</h4>
      <h5 class="text-center text-h5">ຄວາມສາມາດຂອງລະບົບ</h5>

      <v-row class="pt-12">
        <v-col
          xs="12"
          sm="12"
          md="6"
          lg="4"
          cols="12"
          v-for="(item, index) in items"
          :key="index"
        >
          <v-card flat color="transparent">
            <v-card-title class="font-weight-bold body">
              {{ item.title }}
            </v-card-title>
            <v-card-text>
              <ul>
                <li
                  class="font-weight-medium body-1"
                  v-for="(data, key) in item.items"
                  :key="key"
                >
                  {{ data }}
                </li>
              </ul>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </div>
  </section>
</template>

<script>
export default {
  props: {
    items: {
      type: Array,
      default: [],
    },
  },
};
</script>

<style></style>
