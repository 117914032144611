<template>
  <v-app dark>
    <app-drawer-left ref="drawer" />
    <v-app-bar flat height="65" fixed app>
      <v-btn
        small
        fab
        elevation="0"
        @click.stop="$refs.drawer.drawer = !$refs.drawer.drawer"
      >
        <v-icon dark small>fal fa-stream</v-icon>
      </v-btn>

      <v-toolbar-title class="ml-3" v-if="$vuetify.breakpoint.mdAndUp">{{
        pos
      }}</v-toolbar-title>
      <v-spacer />
    </v-app-bar>
    <v-main>
      <v-container fluid>
        <div class="title pb-1">{{ $t("barcode_print") }}</div>
        <v-breadcrumbs class="pl-0 py-0 primary--text" :items="items">
          <template v-slot:divider>
            <v-icon>fal fa-chevron-right</v-icon>
          </template>
        </v-breadcrumbs>
        <v-row class="pt-4">
          <v-col cols="3">
            <v-card rounded="lg">
              <v-toolbar flat>
                <v-toolbar-title>{{ $t("product") }}</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn icon @click="fetchItem()">
                  <v-icon x-small> fal fa-redo-alt </v-icon>
                </v-btn>
              </v-toolbar>

              <v-card-text class="px-2 pt-0">
                <div class="d-flex px-3">
                  <TextSearch
                    v-model="search"
                    @search="fetchItem()"
                    @onClear="
                      search = '';
                      fetchItem();
                    "
                  />
                </div>

                <v-list dense height="68vh" class="overflow-auto">
                  <template v-for="(item, index) in product_barcode.data">
                    <v-list-item :key="item.name + index">
                      <v-list-item-avatar tile class="rounded-lg">
                        <app-img :img="item.img" />
                      </v-list-item-avatar>

                      <v-list-item-content>
                        <v-list-item-title> {{ item.name }}</v-list-item-title>
                        <v-list-item-subtitle>
                          {{ moneyFormat(item.price_sale) }}
                        </v-list-item-subtitle>
                      </v-list-item-content>
                      <v-list-item-action>
                        <v-btn
                          @click="searchProducts(item.code)"
                          fab
                          depressed
                          small
                        >
                          <v-icon x-small> fal fa-plus </v-icon>
                        </v-btn>
                      </v-list-item-action>
                    </v-list-item>
                    <v-divider
                      v-if="index < product_barcode.data.length - 1"
                      :key="index"
                      inset
                      class="my-0"
                    ></v-divider>
                  </template>
                </v-list>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="9" id="barcode">
            <div :class="is_print ? '' : 'row'">
              <div class="w-15" v-for="(item, index) in barcode" :key="index">
                <app-barcode
                  :item="item"
                  :fontSize="setup.fontSize"
                  :width="setup.width"
                  :height="setup.height"
                  :selected="setup.selected"
                />
              </div>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </v-main>

    <v-navigation-drawer app right>
      <v-card flat>
        <v-card-text>
          <v-row class="mt-2">
            <v-col cols="12">
              <div class="pb-2">{{ $t("amount") }}</div>
              <div class="d-flex align-center">
                <v-btn
                  fab
                  x-small
                  depressed
                  color="#f0f0f030"
                  @click="action(amount - 1)"
                >
                  <v-icon small color="red">fal fa-minus</v-icon>
                </v-btn>

                <v-text-field
                  dense
                  type="number"
                  :min="0"
                  outlined
                  disabled
                  v-model.number="amount"
                  hide-details
                  placeholder="1"
                />
                <v-btn
                  fab
                  x-small
                  depressed
                  color="#f0f0f030"
                  @click="action(amount + 1)"
                >
                  <v-icon color="green" small>fal fa-plus</v-icon>
                </v-btn>
              </div>
            </v-col>
            <v-col cols="12">
              <div class="pb-2">{{ $t("font_size") }}</div>
              <div class="d-flex align-center">
                <v-text-field
                  dense
                  outlined
                  v-model.number="setup.fontSize"
                  type="number"
                  hide-details
                  placeholder="1"
                />
              </div>
            </v-col>
            <v-col cols="12">
              <div class="pb-2">{{ $t("width") }}</div>
              <div class="d-flex align-center">
                <v-text-field
                  dense
                  type="number"
                  outlined
                  v-model.number="setup.width"
                  hide-details
                  placeholder="1"
                />
              </div>
            </v-col>
            <v-col cols="6">
              <div class="pb-2">{{ $t("height") }}</div>
              <div class="d-flex align-center">
                <v-text-field
                  dense
                  outlined
                  v-model.number="setup.height"
                  type="number"
                  hide-details
                  placeholder="1"
                />
              </div>
            </v-col>
            <v-col cols="12">
              <div class="pb-2">{{ $t("barcode_option") }}</div>
              <v-checkbox
                v-model="setup.selected"
                dense
                color="primary"
                :label="$t('display_name')"
                class="ma-0"
                value="name"
              ></v-checkbox>
              <v-checkbox
                v-model="setup.selected"
                class="ma-0"
                dense
                color="primary"
                :label="$t('display_currency')"
                value="money"
              ></v-checkbox>

              <v-checkbox
                v-model="setup.selected"
                class="ma-0"
                dense
                color="primary"
                :label="$t('display_unit')"
                value="size"
              ></v-checkbox>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
      <template v-slot:append>
        <div class="pa-3">
          <v-btn
            @click="printBarcode()"
            class="white--text"
            depressed
            large
            block
            color="primary"
          >
            <v-icon small left>fal fa-print</v-icon>{{ $t("display_barcode") }}
          </v-btn>
        </div>
      </template>
    </v-navigation-drawer>
    <app-loading :overlay="overlay" />
  </v-app>
</template>

<script>
import AppDrawerLeft from "@/components/layouts/drawerLeft";
import { mapGetters, mapMutations, mapActions } from "vuex";
import AppBarcode from "@/components/barcode";
import { myMixin } from "@/mixin/main";

export default {
  mixins: [myMixin],
  components: {
    AppDrawerLeft,
    AppBarcode,
  },
  data() {
    return {
      overlay: false,
      amount: 0,
      search: "",
      is_print: false,
      setup: {
        selected: [],
        newPro: false,
        objectFound: {},
        last_code: "",
        search: "",
        fontSize: 15,
        width: 2,
        height: 28,
      },
      items: [],
    };
  },
  created() {
    this.fetchItem();
    this.items = [
      {
        text: this.$t("sale"),
        disabled: false,
        href: "/sale",
      },
      {
        text: this.$t(this.$route.name),
        disabled: false,
        href: this.$route.path,
      },
    ];
  },
  watch: {
    amount(val) {
      this.is_print = false;
    },
  },
  computed: {
    ...mapGetters({
      user: "auth/user",
      barcode: "index/barcode",
      product_barcode: "index/product_barcode",
    }),
    pos() {
      if (
        this.user != null &&
        this.user != undefined &&
        this.user.stores != null &&
        this.user.stores != undefined &&
        this.user.stores.store_id != null &&
        this.user.stores.store_id != undefined &&
        this.user.stores.branch_id != undefined &&
        this.user.stores.store_id.name != null &&
        this.user.stores.store_id.name &&
        this.user.stores.branch_id.name != null &&
        this.user.stores.branch_id.name
      ) {
        return `${this.user.stores.store_id.name} - ${this.user.stores.branch_id.name}`;
      }
    },
  },
  methods: {
    ...mapActions({
      fetch: "index/fetch",
    }),
    ...mapMutations({
      SET_BAR_CODE: "index/SET_BAR_CODE",
    }),
    async fetchItem() {
      this.overlay = true;
      const body = {
        that: this,
        url: `products_all?q=${this.search}`,
        commit: "SET_PRODUCT_BARCODE",
      };
      await this.fetch(body);
      this.overlay = false;
    },
    async searchProducts(code) {
      try {
        this.setup.newPro = true;
        const { data } = await this.$http.get(`products_barcode/${code}`);
        if (data.status) {
          this.amount = this.amount + 1;
          this.setup.search = "";
          this.setup.objectFound = {};
          this.setup.last_code = code;
          this.setup.objectFound = data.data;
          // remove product from product_barcode list
          if (this.product_barcode.data.length > 0) {
            this.product_barcode.data = this.product_barcode.data.filter(
              (item) => item.code !== code
            );
          }
          this.SET_BAR_CODE(data.data);
        }
        this.setup.newPro = false;
      } catch (error) {
        console.log("error", error);
      }
    },
    action(val) {
      var { objectFound } = this.setup;
      if (this.barcode.length > 0) {
        if (val > 0) {
          if (val > this.amount) {
            this.SET_BAR_CODE(objectFound);
          } else {
            this.barcode.pop();
          }
          this.amount = val;
        } else {
          this.barcode.pop();
          this.amount = 0;
          this.setup.last_code = "";
        }
      }
    },
    async printBarcode() {
      this.overlay = true;
      this.is_print = !this.is_print;
      setTimeout(() => {
        this.overlay = false;
        this.$htmlToPaper("barcode");
      }, 2000);
    },
  },
};
</script>

<style></style>
