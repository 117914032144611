<template>
  <v-container fluid fill-height class="bg-white">
    <v-row justify="center" align="center">
      <v-col
        v-if="$vuetify.breakpoint.mdAndUp"
        cols="4"
        class="side-color bg-primary"
      >
        <v-card max-width="550" outlined color="transparent" flat>
          <v-img src="/images/login.png" />
        </v-card>
      </v-col>
      <v-col xs="12" sm="12" md="8" lg="8" xl="8">
        <v-btn color="primary" @click="$router.back()" fab outlined icon small>
          <v-icon small>fal fa-chevron-left</v-icon>
        </v-btn>
        <v-row justify="center">
          <v-col xs="12" sm="12" md="8" lg="6" xl="6">
            <div
              :class="$vuetify.breakpoint.mdAndUp ? 'mb-8' : ''"
              class="text-center"
            >
              <v-avatar size="120">
                <v-img src="/images/pos.png"></v-img>
              </v-avatar>
            </div>
            <v-form @submit.prevent="submitForm(form)">
              <v-card-text>
                <div class="text-center">
                  <h2 class="font-weight-black">ປ່ຽນລະຫັດຜ່ານ</h2>
                  <p class="py-3">ປ້ອນລະຫັດຜ່ານ ແລະ ຢືນຢັນລະຫັດຜ່ານໃໝ່</p>
                </div>

                <t-password
                  v-model="form.password"
                  @input="$v.form.password.$touch()"
                  @blur="$v.form.password.$touch()"
                  label="password"
                  showKey="showOldPassword"
                  :errorMessages="passwordErrors"
                  @showOldPassword="showPassword = !showPassword"
                  :type="showPassword"
                />
                <t-password
                  v-model="form.confirm_password"
                  @input="$v.form.confirm_password.$touch()"
                  @blur="$v.form.confirm_password.$touch()"
                  label="confirm_password"
                  showKey="confirmPassword"
                  :errorMessages="confirmPasswordErrors"
                  @confirmPassword="confirmPassword = !confirmPassword"
                  :type="confirmPassword"
                />
              </v-card-text>
              <v-card-actions class="px-4">
                <v-btn type="submit" color="primary" large block>{{
                  $t("change_password")
                }}</v-btn>
              </v-card-actions>
            </v-form>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <!-- AppLoading -->
    <app-loading :overlay="overlay" />
  </v-container>
</template>

<script>
import { toast } from "@/controllers/alert";
import TPassword from "@/components/global/TextPassword";
import { authenticated } from "@/controllers/auth";
import { required, minLength, sameAs } from "vuelidate/lib/validators";

export default {
  layout: "blank",
  components: { TPassword },
  data: () => ({
    overlay: false,
    showPassword: false,
    confirmPassword: false,
    form: {
      password: "",
      confirm_password: "",
    },
  }),
  validations: {
    form: {
      password: { required, minLength: minLength(8) },
      confirm_password: { required, sameAsPassword: sameAs("password") },
    },
  },
  computed: {
    passwordErrors() {
      const errors = [];
      if (!this.$v.form.password.$dirty) return errors;
      !this.$v.form.password.required && errors.push("ກະລຸນາປ້ອນລະຫັດຜ່ານ!");
      !this.$v.form.password.minLength &&
        errors.push("ລະຫັດຜ່ານຢ່າງນ້ອຍ 8 ຕົວຂື້ນໄປ!");
      return errors;
    },
    confirmPasswordErrors() {
      const errors = [];
      if (!this.$v.form.confirm_password.$dirty) return errors;
      !this.$v.form.confirm_password.required &&
        errors.push("ກະລຸນາປ້ອນລະຫັດຍຶນຍັນ!");
      !this.$v.form.confirm_password.sameAsPassword &&
        errors.push("ລະຫັດຍືນຍັນບໍຕົງກັບລະຫັດຜ່ານ!");
      return errors;
    },
  },
  methods: {
    async submitForm(form) {
      try {
        this.$v.form.$touch();
        if (!this.$v.form.$invalid) {
          this.overlay = true;
          const body = {
            email: this.$route.query.email,
            otp: this.$route.query.otp,
            token: this.$route.query.token,
            password: form.password,
            confirm_password: form.confirm_password,
          };
          const { data } = await this.$http.post("new_password", body);
          if (data.status) {
            await toast(this, data.message);
            this.overlay = false;
            this.$router.push("/login");
          }
        }
      } catch (error) {
        this.overlay = false;
        console.log("submitForm ===> ", error);
      }
    },
  },
};
</script>

<style></style>
