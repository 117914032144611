<template>
  <section class="bg-white">
    <v-card
      color="primary"
      rounded="0"
      flat
      :height="$vuetify.breakpoint.smAndDown ? 300 : 500"
      dark
    >
      <v-card-text class="py-8">
        <v-row align="center" justify="center">
          <v-col class="text-center" cols="12">
            <h1 class="mb-4 font-weight-bold text-h2 white--text">PosSmart</h1>
            <h4 class="subheading white--text">ລະບົບການຂາຍທີ່ທັນສະໄຫມ</h4>
            <v-img
              :height="$vuetify.breakpoint.smAndDown ? 200 : 350"
              aspect-ratio="2"
              contain
              src="/images/02.png"
            ></v-img>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <div class="container-xl py-12">
      <div class="row pb-8 justify-content-between">
        <div class="d-flex pt-4">
          <v-icon> fal fa-play </v-icon>
          <h4 class="ml-4">ຄຣິບແນະນຳ</h4>
        </div>
        <v-btn rounded depressed @click="openYoutube">
          <v-icon left x-small> fal fa-layer-group </v-icon>
          Youtube
        </v-btn>
      </div>
      <v-row class="py-12">
        <v-col
          xs="12"
          sm="12"
          md="6"
          cols="12"
          lg="4"
          v-for="(item, index) in items"
          :key="index"
        >
          <v-card flat class="rounded-xl" color="#f8f5fd">
            <v-card-text class="pb-0">
              <LazyYoutube
                :src="'https://www.youtube.com/embed/' + item.video"
              />
            </v-card-text>
            <v-card-title class="pb-0">{{ item.title }}</v-card-title>

            <v-card-text>
              <v-chip-group
                active-class="deep-purple accent-4 white--text"
                column
              >
                <template v-for="(data, key) in item.desc">
                  <v-chip :key="key">{{ data }}</v-chip>
                </template>
              </v-chip-group>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </div>
  </section>
</template>

<script>
import { LazyYoutube, LazyVimeo } from "vue-lazytube";

export default {
  components: {
    LazyYoutube,
    LazyVimeo,
  },
  data: () => ({
    items: [],
  }),
  created() {
    this.getTutorials();
  },

  methods: {
    async getTutorials() {
      try {
        const { data } = await this.$http.get("tutorials");
        if (data.status) {
          this.items = data.data;
        }
      } catch (error) {
        console.log(error);
      }
    },
    openYoutube() {
      window.open("https://youtube.com/@PosSmart?si=yl019yALXNeYpy4F");
    },
  },
};
</script>

<style></style>
