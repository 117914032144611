<template>
  <v-app dark>
    <app-drawer-left ref="drawer" />
    <v-app-bar height="65" fixed app flat>
      <v-btn
        small
        fab
        depressed
        @click.stop="$refs.drawer.drawer = !$refs.drawer.drawer"
      >
        <v-icon small>fal fa-stream</v-icon>
      </v-btn>

      <v-breadcrumbs class="pl-2 py-0" :items="items">
        <template v-slot:divider>
          <v-icon>fal fa-chevron-right</v-icon>
        </template>
        <template v-slot:item="{ item }">
          <v-breadcrumbs-item
            :disabled="item.disabled"
            :href="item.href"
            v-if="item.href"
          >
            {{ $t(item.text) }}
          </v-breadcrumbs-item>
          <v-breadcrumbs-item :disabled="item.disabled" v-else>
            {{ $t(item.text) }}
          </v-breadcrumbs-item>
        </template>
      </v-breadcrumbs>

      <v-spacer />
      <v-btn fab depressed small to="/settings-general">
        <v-icon small>fal fa-cog</v-icon>
      </v-btn>
      <v-btn class="ml-3" fab depressed small @click="changeTheme">
        <v-icon small>fal fa-{{ darkMode === true ? "sun" : "moon" }}</v-icon>
      </v-btn>
    </v-app-bar>
    <v-main>
      <v-container fluid>
        <router-view />
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import AppDrawerLeft from "@/components/layouts/drawerLeft";
import { mapGetters } from "vuex";
import { setUser } from "@/controllers/auth";
import { checkToken } from "@/controllers/auth";

export default {
  components: { AppDrawerLeft },
  name: "product",
  data() {
    return {
      modal: false,
      darkMode: false,
      items: [
        {
          text: "sale",
          disabled: false,
          href: "/sale",
        },
      ],
    };
  },

  created() {
    const thems = localStorage.getItem("darkMode");
    if (thems) {
      if (thems.length === 4) {
        this.$vuetify.theme.dark = true;
        this.darkMode = true;
      } else {
        this.$vuetify.theme.dark = false;
        this.darkMode = false;
      }
    }
    this.items = [
      {
        text: "sale",
        disabled: false,
        href: "/sale",
      },
      {
        text: this.$route.name,
        disabled: false,
        href: this.$route.path,
      },
    ];
  },

  mounted() {
    setUser(this);
    checkToken(this);
  },
  watch: {
    $route(to, from) {
      this.items = [
        {
          text: "sale",
          disabled: false,
          href: "/sale",
        },
        {
          text: to.name,
          disabled: false,
          href: to.path,
        },
      ];
    },
    darkMode(value) {
      this.$vuetify.theme.dark = value;
      localStorage.setItem("darkMode", value);
    },
  },
  computed: {
    ...mapGetters({
      user: "auth/user",
    }),
    posName() {
      if (
        this.user != null &&
        this.user != undefined &&
        this.user.stores != null &&
        this.user.stores != undefined &&
        this.user.stores.name != null &&
        this.user.stores.name
      ) {
        return this.user.stores.name;
      }
    },
  },
  methods: {
    changeTheme() {
      this.darkMode = !this.darkMode;
      const value = !this.$vuetify.theme.dark;
      this.$vuetify.theme.dark = value;
      localStorage.setItem("darkMode", value);
    },
  },
};
</script>
