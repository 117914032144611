<template>
  <section>
    <data-table
      :headers="headers"
      :items="stocks"
      commit="SET_STOCKS"
      :viewItem="viewItem"
    >
      <div slot="activator" class="row justify-content-between">
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-2">
          <v-card-title class="px-0 pt-0"> {{ $t("purchase") }} </v-card-title>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-2">
          <TextSelect
            :dense="true"
            :placeholder="false"
            :items="statusList"
            label="status"
            itemValue="value"
            v-model="status"
          />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-2">
          <DatePicker
            :dense="true"
            :placeholder="false"
            label="start_date"
            v-model="start_date"
          />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-2">
          <DatePicker
            :dense="true"
            :placeholder="false"
            label="end_date"
            v-model="end_date"
          />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-1">
          <v-btn
            depressed
            outlined
            color="primary"
            @click="filterDate(start_date, end_date)"
          >
            <v-icon left small>fal fa-search</v-icon>
            Filter
          </v-btn>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-3">
          <TextSearch
            v-model="search"
            @search="fetchItem()"
            @onClear="
              search = '';
              fetchItem();
            "
          />
        </div>
        <v-spacer />
      </div>
    </data-table>
    <app-custom-dialog
      width="900px"
      v-model="dialog"
      :footer="false"
      title="description"
    >
      <div slot="activator">
        <AppDetail :item="form" />
        <template v-if="form.status === 'pending'">
          <v-divider />
          <div class="text-right">
            <v-btn
              color="red"
              depressed
              outlined
              class="mr-4"
              @click="purchaseAction(form.id, 'rejected')"
            >
              <v-icon left small> fal fa-times </v-icon>
              {{ $t("cancel") }}
            </v-btn>
            <v-btn
              depressed
              color="primary"
              @click="purchaseAction(form.id, 'approved')"
            >
              <v-icon left small> fal fa-check </v-icon>
              {{ $t("approved") }}
            </v-btn>
          </div>
        </template>
      </div>
    </app-custom-dialog>
    <!-- AppLoading -->
    <app-loading :overlay="overlay" />
  </section>
</template>

<script>
import DataTable from "@/components/DataTable";
import { mapGetters, mapActions } from "vuex";
import { historyStock } from "@/utils/head";
import TTextField from "@/components/global/TextField";
import { sizeValidate } from "@/validations/shop";
import AppDetail from "@/components/products/stockHistory";
import TextSelect from "@/components/global/TextSelect";
import { success, confirm, error } from "@/controllers/alert";
import { purchaseStatus } from "@/utils/mock";
// get today date
const today = new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
  .toISOString()
  .substr(0, 10);

// get tomorrow date
const tomorrow = new Date(
  Date.now() - new Date().getTimezoneOffset() * 60000 + 86400000
)
  .toISOString()
  .substr(0, 10);

export default {
  mixins: [sizeValidate],

  data: () => ({
    update: false,
    dialogAdd: false,
    dialog: false,

    overlay: false,
    search: "",

    status: {
      text: "ທັງໝົດ",
      value: "all",
    },
    form: {
      unit: "piece",
    },
    start_date: today,
    end_date: tomorrow,
  }),
  created() {
    this.fetchItem();
  },
  watch: {
    status(val) {
      this.overlay = true;
      this.fetchItem();
    },
  },
  components: {
    DataTable,
    AppDetail,
    TTextField,
    TextSelect,
  },

  computed: {
    ...mapGetters({
      stocks: "index/stocks",
    }),
    headers() {
      return historyStock(this);
    },
    statusList() {
      return purchaseStatus(this);
    },
  },

  methods: {
    ...mapActions({
      fetch: "index/fetch",
    }),

    async fetchItem() {
      const body = {
        that: this,
        url: `stock?status=${this.status.value}&start_date=${this.start_date}&end_date=${this.end_date}&?q=${this.search}`,
        commit: "SET_STOCKS",
      };
      this.overlay = false;
      await this.fetch(body);
    },

    async viewItem({ id }) {
      const { data } = await this.$http.get(`stock/${id}`);
      this.form = data.data;
      this.dialog = !this.dialog;
    },
    async filterDate(start_date, end_date) {
      try {
        await Help.getDifferentMonth(this, start_date, end_date);
        this.overlay = true;
        // setTimeOut for 1 second to show overlay
        setTimeout(() => {
          this.fetchItem();
          this.overlay = false;
        }, 1000);
      } catch (err) {
        console.log("error", err.message);
        error(this, err.message);
        return;
      }
    },
    async purchaseAction(id, status) {
      const msg =
        status === "approved" ? "is_approved_purchase" : "is_cancel_purchase";
      const { isConfirmed } = await confirm({
        props: this,
        text: msg,
      });
      if (isConfirmed) {
        const form = {
          id: id,
          status: status,
        };
        const { data } = await this.$http.post(`stock_update`, form);
        if (data.status) {
          this.dialog = !this.dialog;
          await Help.clearForm(this.form, this);
          await this.fetchItem();
          await success(this, data.message);
        }
      }
    },
  },
};
</script>

<style></style>
