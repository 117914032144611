<template>
  <div>
    <div v-if="placeholder" class="pb-2">{{ $t(label) }}</div>
    <v-select
      color="primary"
      :flat="flat"
      :outlined="outlined"
      :dense="dense"
      :rounded="rounded"
      :disabled="disabled"
      :hide-details="hideDetails"
      :items="items"
      v-model="model"
      :item-text="text"
      :item-value="itemValue"
      :error-messages="errorMessages"
      :return-object="returnObject"
      :no-data-text="$t('no_data')"
      :filled="filled"
    />
    <div v-if="hideDetails" class="mb-2" />
  </div>
</template>

<script>
export default {
  props: {
    value: {},
    placeholder: { type: Boolean, default: true },
    returnObject: { type: Boolean, default: true },
    label: { type: String, require: true },
    items: { type: Array, require: true },
    errorMessages: { type: Array, require: true },
    flat: { type: Boolean, default: false },
    outlined: { type: Boolean, default: true },
    dense: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    counter: { type: Boolean, default: false },
    filled: { type: Boolean, default: false },
    rounded: { type: Boolean, default: false },
    text: { type: String, default: "text" },
    itemValue: { type: String, default: "text" },
  },

  data() {
    return {
      model: this.value,
    };
  },
  watch: {
    value(newVal, oldVal) {
      if (newVal !== oldVal) this.model = newVal;
    },
    model(newVal, oldVal) {
      if (newVal !== oldVal) this.$emit("input", newVal);
    },
  },
  computed: {
    hideDetails() {
      if (this.errorMessages && this.errorMessages.length) {
        return false;
      }
      return true;
    },
  },
};
</script>

<style></style>
