<template>
  <v-row>
    <v-col xs="12" sm="12" md="6" lg="6" xl="6">
      <v-row>
        <v-col cols="4">{{ $t("code") }}</v-col>
        <v-col cols="8">
          {{ item.code }}
          <v-btn icon small>
            <v-icon x-small @click="copyToClipboard(item.code)"
              >fal fa-copy</v-icon
            >
          </v-btn></v-col
        >
        <v-col cols="4">{{ $t("status") }}</v-col>
        <v-col cols="8">
          <v-chip label small :color="orderStatusColor(item.status)" dark>
            {{ $t(item.status) }}
          </v-chip>
        </v-col>
        <template v-if="item.customer_id">
          <v-col cols="4">{{ $t("customer_name") }}</v-col>
          <v-col cols="8">{{ customerPoint }} </v-col>
          <v-col cols="4">{{ $t("phone_no") }}</v-col>
          <v-col cols="8">{{ item.customer_id.phone_no }}</v-col>
        </template>
        <v-col v-if="item.user_id" cols="4">{{ $t("seller") }}</v-col>
        <v-col cols="8">{{ item.user_id.first_name }}</v-col>
        <v-col cols="4">{{ $t("product_item") }} </v-col>
        <v-col cols="8"
          >{{ item.history_count | ToNumber }} {{ $t("item") }}</v-col
        >
        <v-col cols="4">{{ $t("payment_type") }} </v-col>
        <v-col cols="8">{{ $t(item.type) }}</v-col>
        <template v-if="!item.customer_id">
          <v-col cols="4">{{ $t("sale_date") }} </v-col>
          <v-col cols="8">{{ item.created_at | moment }}</v-col>
        </template>
      </v-row>
    </v-col>
    <v-col xs="12" sm="12" md="6" lg="6" xl="6">
      <v-row>
        <v-col cols="4">{{ $t("amount_paid") }}</v-col>
        <v-col cols="8">{{
          (item.total_price - item.tax_amount) | NumberFormat
        }}</v-col>
        <template v-if="taxInclude || item.tax_amount">
          <v-col cols="4"> {{ $t("tax") }} {{ tax }}</v-col>
          <v-col cols="8">{{ item.tax_amount | NumberFormat }}</v-col>
        </template>
        <v-col cols="4">{{ $t("total_price") }}</v-col>
        <v-col cols="8">{{ item.total_price | NumberFormat }}</v-col>
        <v-col cols="4">{{ $t("discount") }} </v-col>
        <v-col cols="8">{{ total_discount }}</v-col>
        <v-col cols="4">{{ $t("grand_total") }}</v-col>
        <v-col cols="8" class="font-weight-bold">{{
          item.total_paid | NumberFormat
        }}</v-col>

        <!-- <v-col cols="4">{{ $t("sum_price_buy") }} </v-col>
        <v-col cols="8">{{ item.total_price_buy | NumberFormat }}</v-col>
        <v-col cols="4">{{ $t("total_sale") }}</v-col>
        <v-col cols="8">{{ item.total_price_sale | NumberFormat }}</v-col> -->
        <v-col cols="4">{{ $t("profit") }} </v-col>
        <v-col cols="8"
          >{{ item.total_profit | NumberFormat }}
          <v-icon x-small :color="item.total_profit > 0 ? 'green' : 'red'">
            {{
              item.total_profit > 0 ? "fal fa-arrow-up" : "fal fa-arrow-down"
            }}
          </v-icon>
        </v-col>
        <template v-if="item.customer_id">
          <v-col cols="4">{{ $t("sale_date") }} </v-col>
          <v-col cols="8">{{ item.created_at | moment }}</v-col>
        </template>
      </v-row>
    </v-col>
    <v-col cols="12">
      <v-simple-table>
        <template v-slot:default>
          <thead>
            <tr>
              <th scope="col">{{ $t("no") }}</th>
              <th scope="col">{{ $t("image") }}</th>
              <th scope="col">{{ $t("name") }}</th>
              <th scope="col">{{ $t("category") }}</th>
              <th scope="col" class="text-right">{{ $t("price") }}</th>
              <th scope="col" class="text-right">{{ $t("amount") }}</th>
              <th scope="col" class="text-right">{{ $t("total") }}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(data, index) in item.history" :key="index">
              <th scope="row">{{ index + 1 }}</th>
              <td>
                <app-img :img="data.product_id.img" width="40" height="40" />
              </td>
              <td>{{ data.product_id.name.substring(0, 8) + ".." }}</td>
              <td>{{ data.product_id.category.la_name }}</td>

              <td class="text-right">
                {{ data.price_sale | NumberFormat }}
              </td>
              <td class="text-right">{{ data.qty | ToNumber }}</td>
              <td class="text-right">
                {{ data.total_price | NumberFormat }}
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
      <template v-if="item.note">
        <div class="text-left font-weight-bold">
          {{ $t("noted") }}
        </div>
        <span class="text-left">- {{ item.note }}</span>
      </template>
    </v-col>
  </v-row>
</template>

<script>
import { myMixin } from "@/mixin/main";

export default {
  mixins: [myMixin],

  props: {
    item: {
      type: Object,
    },
  },
  computed: {
    totalPrice() {
      if (Object.keys(this.item).length > 0) {
        const { total_discount, discount_type, total_price } = this.item;
        if (discount_type === "percentage") {
          var percent = (total_discount / 100) * total_price;
          return this.$options.filters.NumberFormat(percent);
        } else {
          const price = total_price - total_discount;
          return this.$options.filters.NumberFormat(price);
        }
      }
    },
    total_discount() {
      if (Object.keys(this.item).length > 0) {
        const { total_discount } = this.item;
        if (total_discount === 0) return this.$t("no_discount");
        return `- ${this.$options.filters.NumberFormat(total_discount)}`;
      }
    },

    customerPoint() {
      if (this.item && this.item.customer_id && this.item.customer_id.point) {
        return `${this.item.customer_id.first_name} / ${
          this.item.customer_id.point
        } ${this.$t("point")}`;
      }
      return this.$t("no_customer");
    },
  },
  methods: {
    showImg(val) {
      const path = Help.img(val);
      return path;
    },
  },
};
</script>
