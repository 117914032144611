<template>
  <div>
    <div v-if="label" class="pb-2">{{ $t(label) }}</div>
    <v-text-field
      color="primary"
      v-bind:rounded="rounded"
      v-bind:flat="flat"
      v-bind:outlined="outlined"
      v-bind:dense="dense"
      v-bind:disabled="disabled"
      v-bind:hide-details="hideDetails"
      v-model="model"
      v-bind:error-messages="errorMessages"
      v-bind:counter="counter"
      v-bind:type="typeShow"
      v-bind:filled="filled"
    />
    <div v-if="hideDetails" class="mb-2" />
  </div>
</template>

<script>
export default {
  props: {
    value: {},
    flat: { type: Boolean, default: false },
    label: { type: String, require: true },
    errorMessages: { type: Array, require: true },
    outlined: { type: Boolean, default: true },
    dense: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    counter: { type: Boolean, default: false },
    filled: { type: Boolean, default: false },
    rounded: { type: Boolean, default: false },
    typeShow: { type: String, default: "text" },
  },
  data() {
    return {
      model: this.value,
    };
  },
  watch: {
    value(newVal, oldVal) {
      if (newVal !== oldVal) this.model = newVal;
    },
    model(newVal, oldVal) {
      if (newVal !== oldVal) this.$emit("input", newVal);
    },
  },
  computed: {
    hideDetails() {
      if (this.errorMessages && this.errorMessages.length) {
        return false;
      }
      return true;
    },
  },
};
</script>

<style></style>
