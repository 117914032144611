<template>
  <div>
    <div class="pb-2">{{ $t(label) }}</div>

    <v-text-field
      color="primary"
      :outlined="outlined"
      :rounded="rounded"
      :flat="flat"
      :dense="dense"
      :filled="filled"
      :disabled="disabled"
      :hide-details="hideDetails"
      v-model="displayValue"
      :error-messages="errorMessages"
      :min="minValue"
      :max="maxValue"
      @blur="isInputActive = false"
      @focus="isInputActive = true"
    />
    <div v-if="hideDetails" class="mb-2" />
  </div>
</template>

<script>
export default {
  props: {
    value: {
      required: true,
    },
    maxValue: {},
    minValue: {
      default: 0,
      type: Number,
    },
    flat: { type: Boolean, default: false },
    label: { type: String, require: true },
    errorMessages: { type: Array, require: true },
    outlined: { type: Boolean, default: true },
    dense: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    counter: { type: Boolean, default: false },
    filled: { type: Boolean, default: false },
    rounded: { type: Boolean, default: false },
    typeShow: { type: String, default: "number" },
  },
  data: () => ({
    isInputActive: false,
  }),

  computed: {
    hideDetails() {
      if (this.errorMessages && this.errorMessages.length) {
        return false;
      }
      return true;
    },
    displayValue: {
      get: function () {
        if (this.isInputActive) {
          // Cursor is inside the input field. unformat display value for user
          return this.value.toString();
        } else {
          // User is not modifying now. Format display value for user interface
          return (
            this.value
              .toFixed(2)
              .replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,") + " ກີບ"
          );
        }
      },
      set: function (modifiedValue) {
        // Recalculate value after ignoring "$" and "," in user input
        let newValue = parseFloat(modifiedValue.replace(/[^\d\.]/g, ""));
        // Ensure that it is not NaN
        if (isNaN(newValue)) {
          newValue = 0;
        }
        // Note: we cannot set this.value as it is a "prop". It needs to be passed to parent component
        // $emit the event so that parent component gets it
        this.$emit("input", newValue);
      },
    },
  },
};
</script>

<style></style>
