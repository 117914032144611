<template>
  <section>
    <v-card flat rounded="lg" color="transparent">
      <v-card-title class="pt-0"> {{ $t("import_data") }} </v-card-title>

      <v-card-text>
        <div class="row">
          <div
            class="col-sm-12 col-md-6 col-lg-3"
            v-for="(item, index) in items"
            :key="index"
          >
            <v-card rounded="lg">
              <v-card-text class="text-center py-6">
                <v-avatar size="90" color="#f0f0f0">
                  <v-icon size="35" color="primary">
                    {{ item.icon }}
                  </v-icon>
                </v-avatar>
                <div class="font-weight-bold py-4 title">
                  {{ $t(item.title) }}
                </div>

                <v-btn
                  text
                  outlined
                  depressed
                  small
                  color="primary"
                  class="mr-5 px-2"
                  @click="exampleAction(item)"
                >
                  <v-icon x-small left> fal fa-receipt </v-icon>

                  {{ $t("example") }}</v-btn
                >
                <v-btn
                  color="primary"
                  depressed
                  small
                  @click="importAction(item)"
                  class="px-2"
                >
                  <v-icon x-small left> fal fa-receipt </v-icon>
                  {{ $t("import") }}</v-btn
                >
              </v-card-text>
            </v-card>
          </div>
        </div>
      </v-card-text>
    </v-card>

    <app-custom-dialog
      v-model="dialog"
      :footer="true"
      title="import_data"
      @submit="submitForm"
    >
      <div slot="activator">
        <v-col cols="12">
          <div class="pb-2">{{ $t("file_upload") }}</div>
          <v-file-input
            v-model="file"
            accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
            outlined
            counter
            show-size
            color="deep-purple accent-4"
            prepend-icon="fal fa-paperclip"
          >
          </v-file-input>
        </v-col>
      </div>
    </app-custom-dialog>
  </section>
</template>

<script>
import { success, toast } from "@/controllers/alert";

export default {
  data: () => ({
    dialog: false,
    file: null,
    select: {
      title: "ສິນຄ້າ",
      value: 1,
      icon: "fal fa-shopping-cart",
      api: "categories",
    },
    headers: [
      { text: "no", value: "index" },
      { text: "icon", value: "icon" },
      { text: "info", value: "title" },
      { text: "manage", align: "right", value: "action" },
    ],
    items: [
      {
        title: "category",
        value: 4,
        icon: "fal fa-badge-check",
        api: "categories",
      },
      {
        title: "unit",
        value: 5,
        icon: "fal fa-box",
        api: "product_size",
      },
      {
        title: "product",
        value: 1,
        icon: "fal fa-shopping-cart",
        api: "products",
      },
      {
        title: "customer",
        value: 2,
        icon: "fal fa-users",
        api: "customers",
      },
      {
        title: "user",
        value: 3,
        icon: "fal fa-user-cog",
        api: "users",
      },
    ],
  }),
  methods: {
    async exampleAction(item) {
      const data = await this.$http.get(`${item.api}_format`, {
        responseType: "blob",
      });
      const url = window.URL.createObjectURL(new Blob([data.data]));
      const link = document.createElement("a");
      link.href = url;
      const date = new Date();
      const fileName = `${item.api}-${date.getDate()}-${
        date.getMonth() + 1
      }-${date.getFullYear()}.xlsx`;
      link.setAttribute("download", fileName); //any other extension
      document.body.appendChild(link);
      link.click();
      // Remove the link from the body
      document.body.removeChild(link);
      await toast(this, "downloaded_format_file_successfully");
    },
    async importAction(item) {
      this.dialog = !this.dialog;
      this.select = item;
    },
    async submitForm() {
      let formData = new FormData();
      formData.append("file", this.file);
      const { data } = await this.$http.post(
        `${this.select.api}_import`,
        formData
      );
      if (data.status) {
        this.file = null;
        this.dialog = !this.dialog;
        success(this);
      }
    },
  },
};
</script>

<style></style>
