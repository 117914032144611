<template>
  <v-row justify="center" align="center">
    <v-col cols="12" align="center">
      <v-card rounded="lg">
        <v-card-text class="img">
          <v-img max-width="20%" src="/images/no_product.png" />
        </v-card-text>
        <v-card-title class="text-center d-flex justify-content-center">{{
          $t("have_no_data")
        }}</v-card-title>
        <v-card-title>{{ $t("sale_step") }}</v-card-title>
        <v-card-text class="d-block text-left">
          <p>{{ $t("easy_step_to_sale") }}</p>
          <br />
          <ul>
            <li v-for="(item, index) in items" :key="index">
              {{ $t(item) }}
            </li>
          </ul>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
export default {
  data() {
    return {
      items: ["add_product", "add_category", "add_unit"],
    };
  },
};
</script>

<style></style>
