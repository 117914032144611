<template>
  <v-container fluid fill-height class="bg-white">
    <v-row justify="center" align="center">
      <v-col
        v-if="$vuetify.breakpoint.mdAndUp"
        cols="4"
        class="side-color bg-primary"
      >
        <v-card flat max-width="550" outlined color="transparent">
          <v-img src="/images/login.png" />
        </v-card>
      </v-col>
      <v-col xs="12" sm="12" md="8" lg="8" xl="8">
        <v-btn color="primary" to="/" fab outlined icon small>
          <v-icon small>fal fa-home</v-icon>
        </v-btn>
        <v-row justify="center">
          <v-col xs="12" sm="12" md="8" lg="6" xl="6">
            <div
              :class="$vuetify.breakpoint.mdAndUp ? 'mb-2' : ''"
              class="text-center"
            >
              <v-avatar size="120">
                <v-img src="/images/pos.png"></v-img>
              </v-avatar>
            </div>
            <v-form @submit.prevent="userLogin(form)">
              <v-card-text>
                <div class="text-center">
                  <h2 class="font-weight-black">
                    {{ $t("login") }}
                  </h2>
                  <p>{{ $t("login.description") }}</p>
                </div>

                <TTextField
                  label="email"
                  v-model="form.email"
                  :errorMessages="emailError"
                  @input="$v.form.email.$touch()"
                  @blur="$v.form.email.$touch()"
                />

                <t-password
                  v-model="form.password"
                  @input="$v.form.password.$touch()"
                  @blur="$v.form.password.$touch()"
                  label="password"
                  showKey="showOldPassword"
                  :errorMessages="passwordError"
                  @showOldPassword="showPassword = !showPassword"
                  :type="showPassword"
                />
                <div class="d-flex justify-space-between">
                  <CheckBox v-model="rememberEmail" label="remember_me" />

                  <v-btn to="/forgot-password" class="pt-2" text color="primary"
                    >{{ $t("forgot_password") }}?</v-btn
                  >
                </div>
              </v-card-text>
              <v-card-actions class="px-4 d-block">
                <v-btn type="submit" color="primary" large block>
                  {{ $t("login") }}
                </v-btn>
                <div class="mt-4 align-center text-center">
                  <span class="text-try"
                    >{{ $t("no_account") }}
                    <v-btn
                      plain
                      to="/register"
                      class="font-weight-bold text-capitalize"
                      color="primary"
                      text
                    >
                      {{ $t("register") }}
                    </v-btn></span
                  >
                  <v-row align="center" justify="center" class="py-2 mb-1">
                    <v-col cols="7">
                      <v-card flat>
                        <div class="row align-center">
                          <v-divider />
                          <span class="px-2">{{ $t("or") }}</span>
                          <v-divider />
                        </div>
                      </v-card>
                    </v-col>
                  </v-row>
                </div>

                <v-btn color="primary" large block outlined @click="testUse()">
                  {{ $t("test_use") }}
                </v-btn>
                <div class="pa-2 text-center">
                  {{ `${$t("version")} : ${version}` }}
                </div>
              </v-card-actions>
            </v-form>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <app-loading :overlay="overlay" />
    <app-custom-dialog
      v-model="dialog"
      title="choose_branch"
      @submit="setBranch()"
    >
      <div slot="activator">
        <v-list>
          <v-list-item-group v-model="branch_id" mandatory color="primary">
            <template v-for="(branch, index) in branches">
              <v-list-item two-line :key="index + branch.id + branch.name">
                <template v-slot:default="{ active }">
                  <v-list-item-avatar>
                    <app-img v-if="branch.logo" :img="branch.logo" />
                    <v-icon v-else>fas fa-store</v-icon>
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title>{{ branch.name }}</v-list-item-title>
                    <v-list-item-subtitle>{{
                      branch.address
                    }}</v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-action>
                    <v-checkbox
                      :input-value="active"
                      color="primary"
                      hide-details
                    ></v-checkbox>
                  </v-list-item-action>
                </template>
              </v-list-item>
              <v-divider
                inset
                class="my-0"
                v-if="index < branches.length - 1"
                :key="index"
              ></v-divider>
            </template>
          </v-list-item-group>
        </v-list>
      </div>
    </app-custom-dialog>
  </v-container>
</template>

<script>
import { authenticated, setNewUser } from "@/controllers/auth";
import { toast } from "@/controllers/alert";
import TPassword from "@/components/global/TextPassword";
import TTextField from "@/components/global/TextField";
import { login } from "@/validations/user";
import Cookies from "js-cookie";
import CheckBox from "@/components/global/CheckBox";

export default {
  layout: "blank",
  components: { TPassword, TTextField, CheckBox },
  mixins: [login],
  data: () => ({
    rememberEmail: false,
    overlay: false,
    showPassword: false,
    version: process.env.VUE_APP_VERSION,
    form: {},
    dialog: false,
    branches: [],
    branch_id: 0,
    data: {},
  }),
  mounted() {
    this.form.email = Cookies.get("email") || "";
    if (Cookies.get("remember")) {
      this.rememberEmail = true;
    } else {
      this.rememberEmail = false;
    }
  },
  watch: {
    rememberEmail(val) {
      if (val) {
        Cookies.set("remember", true);
      } else {
        Cookies.remove("remember");
      }
    },
  },
  methods: {
    async userLogin(form) {
      try {
        this.$v.form.$touch();
        if (!this.$v.form.$invalid) {
          this.overlay = true;
          const { data } = await this.$http.post("login", form);
          if (data.code === 403) {
            await setNewUser(this, data.data);
            this.$router.push("/open-store");
          } else if (data.status) {
            await toast(this, "login.success");
            Cookies.set("email", this.form.email);
            this.overlay = false;
            await authenticated(this, data);
          }
        }
      } catch (error) {
        this.overlay = false;
        // check status code is 403
        if (error.code === 403) {
          await setNewUser(this, error.data);
          this.$router.push("/open-store");
        }
      }
    },

    async testUse() {
      try {
        this.overlay = true;
        const body = {
          email: "admin123@gmail.com",
          password: "admin123",
        };
        const { data } = await this.$http.post("login", body);
        if (data.status) {
          this.data = data;
          await setNewUser(this, data.access_token);
          await this.fetchBranches();
          this.overlay = false;
        }
      } catch (error) {
        this.overlay = false;
        console.log(error);
      }
    },

    async fetchBranches() {
      try {
        const { data } = await this.$http.get("branches");
        if (data.status) {
          // check if branches is empty
          if (data.data.length === 0) {
            await toast(this, "no_branch");
          } else {
            this.branches = data.data;
            this.dialog = true;
          }
        }
      } catch (error) {
        console.log(error);
      }
    },

    async setBranch() {
      try {
        // get id from branches[branch_id]
        const branch_id = this.branches[this.branch_id].id;
        const { data } = await this.$http.post(
          `set_branch/${branch_id}?skip=1`
        );
        if (data.status) {
          await toast(this, "login.success");
          await authenticated(this, this.data);
        }
      } catch (error) {
        this.overlay = false;
        console.log(error);
      }
    },
  },
};
</script>

<style></style>
