<template>
  <v-navigation-drawer
    :mini-variant="miniVariant"
    :clipped="clipped"
    v-model="drawer"
    fixed
    app
    class="drawer-left"
    width="255"
  >
    <template v-slot:prepend v-if="user && user.first_name">
      <v-list-item class="px-2">
        <v-badge bordered bottom color="green" dot offset-x="25" offset-y="20">
          <v-list-item-avatar>
            <v-avatar color="#f0f0f0">
              <v-img :src="Img" />
            </v-avatar>
          </v-list-item-avatar>
        </v-badge>

        <v-list-item-content>
          <v-list-item-title> {{ user.first_name }}</v-list-item-title>
          <v-list-item-subtitle>
            {{
              user.branch_id ? user.branch_id.name : user.email
            }}</v-list-item-subtitle
          >
        </v-list-item-content>
        <v-list-item-action>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                fab
                depressed
                small
                v-bind="attrs"
                v-on="on"
                to="/manage-branches"
              >
                <v-icon small color="grey">fal fa-store</v-icon>
              </v-btn>
            </template>
            <span>
              {{ $t("choose_branch") }}
            </span>
          </v-tooltip>
        </v-list-item-action>
      </v-list-item>
    </template>

    <v-list v-if="menus.length">
      <template v-for="(item, i) in menus">
        <v-list-group
          v-if="item.menu.length"
          :key="item.name_la"
          sub-group
          no-action
          :value="item.value"
        >
          <template v-slot:prependIcon>
            <v-icon small>{{ item.icon }}</v-icon>
          </template>
          <template v-slot:appendIcon>
            <v-icon small>fal fa-chevron-down</v-icon>
          </template>
          <template v-slot:activator>
            <v-list-item-title>
              {{
                $i18n.locale === "en" ? item.name_en : item.name_la
              }}</v-list-item-title
            >
          </template>
          <v-list-item
            v-for="(data, key) in item.menu"
            :key="key"
            :to="data.to"
            link
          >
            <v-list-item-icon>
              <v-icon small>{{ data.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-title>{{
              $i18n.locale === "en" ? data.name_en : data.name_la
            }}</v-list-item-title>
          </v-list-item>
        </v-list-group>

        <v-list-item v-else :key="i + 1" :to="item.to">
          <v-list-item-icon>
            <v-icon small>{{ item.icon }}</v-icon>
          </v-list-item-icon>
          <v-list-item-title>{{
            $i18n.locale === "en" ? item.name_en : item.name_la
          }}</v-list-item-title>
        </v-list-item>
      </template>
    </v-list>
    <template v-slot:append>
      <v-divider class="ma-0" />
      <div class="pa-2 text-center">
        {{ miniVariant ? version : `${$t("version")} : ${version}` }}
      </div>
    </template>
  </v-navigation-drawer>
</template>

<script>
import { mapGetters } from "vuex";
import { myMixin } from "@/mixin/main";
const drawer = localStorage.getItem("drawer") === "true" ? true : false;
export default {
  data() {
    return {
      clipped: false,
      drawer: drawer,
      miniVariant: false,
      version: process.env.VUE_APP_VERSION,
    };
  },

  watch: {
    drawer(val) {
      localStorage.setItem("drawer", val);
    },
  },
  mixins: [myMixin],
  created() {
    if (this.$vuetify.breakpoint.mobile) {
      this.drawer = false;
    }
  },

  computed: {
    ...mapGetters({
      user: "auth/user",
      isLoggedIn: "auth/isLoggedIn",
    }),
    Img() {
      return Help.img(this.user.avatar);
    },
    menus() {
      const data = Help.addValueKey(this.user.auth_menus);
      return data;
    },
  },
};
</script>

<style></style>
