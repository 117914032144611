<template>
  <AppReceipt
    :item="item"
    :show_logo="show_logo"
    :branch="branch"
    :customer_point="customer_point"
    :customer_address="customer_address"
    :thb_currency="thb_currency"
    :usd_currency="usd_currency"
    :tax_include="tax_include"
    :barcode="barcode"
    :show_qr="show_qr"
    :footer_text="footer_text"
  />
</template>

<script>
import { mapGetters } from "vuex";
import AppReceipt from "@/components/settings/bills/AppReceipt";

export default {
  components: {
    AppReceipt,
  },
  props: {
    item: {
      type: Object,
    },
  },
  computed: {
    ...mapGetters({
      user: "auth/user",
    }),
    show_logo() {
      if (this.user && this.user.stores) {
        return this._convertValue(this.user.stores.show_logo);
      }
    },
    branch() {
      if (this.user && this.user.stores) {
        return this._convertValue(this.user.stores.branch);
      }
    },
    customer_point() {
      if (this.user && this.user.stores) {
        return this._convertValue(this.user.stores.customer_point);
      }
    },
    customer_address() {
      if (this.user && this.user.stores) {
        return this._convertValue(this.user.stores.customer_address);
      }
    },
    thb_currency() {
      if (this.user && this.user.stores) {
        return this._convertValue(this.user.stores.thb_currency);
      }
    },
    usd_currency() {
      if (this.user && this.user.stores) {
        return this._convertValue(this.user.stores.usd_currency);
      }
    },
    tax_include() {
      if (this.user && this.user.stores) {
        return this._convertValue(this.user.stores.tax_include);
      }
    },
    barcode() {
      if (this.user && this.user.stores) {
        return this._convertValue(this.user.stores.barcode);
      }
    },
    show_qr() {
      if (this.user && this.user.stores) {
        return this._convertValue(this.user.stores.show_qr);
      }
    },
    footer_text() {
      if (this.user && this.user.stores) {
        return this.user.stores.footer_text;
      }
    },
  },
  methods: {
    _convertValue(value) {
      return value ? true : false;
    },
  },
};
</script>
