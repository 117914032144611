<template>
  <section class="bg-white py-12">
    <div class="container-xl">
      <h4 class="text-center text-h4 font-weight-bold">ຕິດຕໍພວກເຮົາ</h4>
      <h5 class="text-center text-h5">
        ທ່ານສາມາດຕິດຕໍຫາພວກເຮົາໂດຍຕົງໂດຍການຕື່ມແບບຝອມດ້ານລຸ່ມນີ້
      </h5>
      <v-divider class="mt-12" />
      <v-row class="pt-12">
        <v-col cols="5">
          <v-card>
            <v-card-text>
              <v-list-item two-line v-for="(item, index) in items" :key="index">
                <v-list-item-icon>
                  <v-avatar color="#f0f0f0">
                    <v-icon>
                      {{ item.icon }}
                    </v-icon>
                  </v-avatar>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>{{ item.title }}</v-list-item-title>
                  <v-list-item-subtitle>{{
                    item.subtitle
                  }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="7">
          <v-card>
            <v-card-text>
              <v-form>
                <TTextField label="ຊື່" />
                <TTextField label="ອີເມວ" />
                <TextArea label="ເນື້ອໃນ" />
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-btn type="submit" color="primary" large block>
                <v-icon left small> fal fa-paper-plane </v-icon>

                ສົ່ງຝອມ</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </div>
  </section>
</template>

<script>
import TTextField from "@/components/global/TextField";
import TextArea from "@/components/global/TextArea";
export default {
  components: { TTextField, TextArea },
  data() {
    return {
      siteKey: "6LeWbsYlAAAAAIA2UelzMBzBu7-PePSPYb4aJxCV",
      items: [
        {
          icon: "fal fa-map-pin",
          title: "ທີ່ຢູ່",
          subtitle: "ບ້ານ ຫົວຂົວ, ເມືອງ ໄຊເສດຖາ, ນະຄອນຫຼວງວຽງຈັນ",
        },
        {
          icon: "fal fa-envelope",
          title: "ອີເມວ",
          subtitle: "vongkeokeosavanh@gmail.com",
        },
        {
          icon: "fal fa-phone",
          title: "ເບີໂທ",
          subtitle: "020 28492947",
        },
        {
          icon: "fal fa-globe",
          title: "ເວັບໄຊ",
          subtitle: "https://pos.devla.la",
        },
      ],
    };
  },
  methods: {},
};
</script>

<style></style>
