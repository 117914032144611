<template>
  <section>
    <app-hero />
    <app-service />
    <app-intro />
    <app-platform />
    <app-package :items="packages" />
    <app-featured :items="featured" />
    <app-tutorial :items="tutorials" />
  </section>
</template>

<script>
import AppHero from "@/components/landing/hero";
import AppService from "@/components/landing/services";
import AppIntro from "@/components/landing/intro";
import AppPlatform from "@/components/landing/platform";
import AppPackage from "@/components/landing/package";
import AppFeatured from "@/components/landing/featured";
import AppTutorial from "@/components/landing/tutorials";
export default {
  components: {
    AppHero,
    AppService,
    AppIntro,
    AppPlatform,
    AppPackage,
    AppFeatured,
    AppTutorial,
  },

  data: () => ({
    packages: [],
    featured: [],
    tutorials: [],
  }),
  created() {
    this.getPackage();
    this.getFeatured();
    this.getTutorials();
  },
  methods: {
    async getPackage() {
      try {
        const { data } = await this.$http.get("packages");
        if (data.status) {
          this.packages = data.data;
        }
      } catch (error) {
        console.log(error);
      }
    },
    async getFeatured() {
      try {
        const { data } = await this.$http.get("featured");
        if (data.status) {
          this.featured = data.data;
        }
      } catch (error) {
        console.log(error);
      }
    },
    async getTutorials() {
      try {
        const { data } = await this.$http.get("tutorials");
        if (data.status) {
          this.tutorials = data.data;
        }
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>
