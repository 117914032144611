import { defineStore } from "pinia";
import { toast, successPos } from "@/controllers/alert";

export const mainStore = defineStore("counter", {
  state: () => ({
    cart: JSON.parse(localStorage.getItem("cart")) || [],
    sub_total: Number(localStorage.getItem("sub_total") || 0),
    total_discount: Number(localStorage.getItem("total_discount") || 0),
    total_price: Number(localStorage.getItem("total_price") || 0),
    received: Number(localStorage.getItem("received") || 0),
  }),

  actions: {
    addToCart({ that, payload }) {
      let same = false;
      let qty = 1;
      let price = payload.price_sale;
      this.cart.map((e) => {
        if (e.id === payload.id) {
          same = true;
          if (payload.stock >= e.qty + 1) {
            const price = e.price / e.qty;
            e.qty = e.qty + 1;
            e.price = price * e.qty;
          } else {
            toast(that, "stock_not_enough", "error");
          }
        }
      });
      if (!same) {
        if (payload.stock >= 1) {
          const item = {
            txn: null,
            id: payload.id,
            qty: qty,
            discount: 0,
            price: price,
            item: payload,
          };
          this.cart.push(item);
        }
      }
      localStorage.setItem("cart", JSON.stringify(this.cart));
      this.updateCart();
    },
    setCart(payload) {
      // remove all cart and set new cart
      this.cart = payload;
      localStorage.setItem("cart", JSON.stringify(this.cart));
      this.updateCart();
    },
    removeCart({ payload }) {
      // remove product from cart
      const index = this.cart.findIndex((item) => item.id === payload.id);
      // remove object
      this.cart.splice(index, 1);
      // update cart
      this.cart = this.cart;
      // update local storage
      localStorage.setItem("cart", JSON.stringify(this.cart));
      this.updateCart();
    },

    decrementCart({ payload }) {
      // decrement product from cart
      this.cart.map((e) => {
        if (e.id === payload.id) {
          if (e.qty > 1) {
            const price = e.price / e.qty;
            e.qty = e.qty - 1;
            e.price = price * e.qty;
          } else if (e.qty == 1) {
            this.removeCart({ payload });
          }
        }
      });
      localStorage.setItem("cart", JSON.stringify(this.cart));
      this.updateCart();
    },

    deleteCart() {
      this.cart = [];
      localStorage.setItem("cart", JSON.stringify(this.cart));
      this.updateCart();
    },

    updateCart() {
      if (this.cart != null && this.cart != undefined && this.cart.length > 0) {
        var total = this.cart.map((e) => e.price).reduce((a, b) => a + b);
        this.sub_total = total;
        this.total_price = total;
      } else {
        this.sub_total = 0;
        this.total_discount = 0;
        this.received = 0;
        this.total_price = 0;
      }
      localStorage.setItem("cart", JSON.stringify(this.cart));
      localStorage.setItem("sub_total", this.sub_total.toString());
      localStorage.setItem("total_price", this.total_price.toString());
      localStorage.setItem("total_discount", this.total_discount.toString());
      localStorage.setItem("received", this.received.toString());
    },

    setQty(payload) {
      const { that, qty, id } = payload;
      this.cart.map((e) => {
        if (e.id === id) {
          if (qty > 0 && qty <= e.item.stock) {
            e.qty = qty;
            e.price = e.item.price_sale * e.qty;
          } else {
            toast(that, "stock_not_enough", "error");
          }
        }
      });
      localStorage.setItem("cart", JSON.stringify(this.cart));
      this.updateCart();
    },

    setReceived(amount) {
      this.received = amount;
      localStorage.setItem("received", this.received.toString());
    },

    setDiscount(amount) {
      this.total_discount = amount;
      localStorage.setItem("total_discount", this.total_discount.toString());
    },

    setTotalPrice(amount) {
      this.total_price = amount;
      localStorage.setItem("total_price", this.total_price.toString());
    },

    removeTxnCart(txn) {
      this.cart = this.cart.filter((e) => e.txn != txn);
      localStorage.setItem("cart", JSON.stringify(this.cart));
      this.updateCart();
    },

    async saleProduct(payload) {
      try {
        const _this = payload.that;
        const form = await Help.posSale(this, payload);
        const { data, status } = await _this.$http.post("sale_product", form);
        if (status) {
          _this.dialogPay = false;
          this.cart = [];
          this.sub_total = 0;
          this.total_discount = 0;
          this.total_price = 0;
          this.received = 0;
          this.updateCart();
          this.payment = 1;
          const body = {
            that: _this,
            url: "products_sale",
            commit: "SET_PRODUCT",
          };
          // call the fetch action from index store
          await _this.$store.dispatch("index/fetch", body);
          const { isConfirmed } = await successPos(_this, data.data);
          if (isConfirmed) {
            return data;
          }
        }
      } catch (error) {
        console.error("saleProduct ", error);
      }
    },
  },
});

// Step 3: Synchronize State Across Tabs
// In your main.js or equivalent entry file, after initializing your Vue app and Pinia store:
window.addEventListener("storage", (event) => {
  if (event.key === "cart") {
    const counterStore = mainStore();
    counterStore.cart = JSON.parse(event.newValue);
  }

  if (event.key === "sub_total") {
    const counterStore = mainStore();
    counterStore.sub_total = Number(event.newValue);
  }

  if (event.key === "total_price") {
    const counterStore = mainStore();
    counterStore.total_price = Number(event.newValue);
  }

  if (event.key === "total_discount") {
    const counterStore = mainStore();
    counterStore.total_discount = Number(event.newValue);
  }
});
