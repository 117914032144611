<template>
  <div class="mt-2 pb-2 row">
    <div
      class="col-xs-12 col-ms-12 col-md-6 col-lg-6 col-xl-6"
      v-for="(item, index) in expense"
      :key="index"
    >
      <template>
        <v-card class="rounded-lg">
          <v-card-title>
            {{ $t(item.name) }}
          </v-card-title>
          <v-card-text>
            <p
              class="text-h5"
              :class="item.name === 'income' ? 'green--text' : 'red--text'"
            >
              {{ moneyFormat(item.total) }}
            </p>
            <p>{{ $t("month") }} : {{ item.created_at }}</p>
          </v-card-text>
        </v-card>
      </template>
    </div>
  </div>
</template>

<script>
import { myMixin } from "@/mixin/main";

export default {
  props: ["expense"],
  mixins: [myMixin],
};
</script>

<style></style>
