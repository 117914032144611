<template>
  <v-row>
    <v-col v-if="item.product_id" cols="4">ລະຫັດ</v-col>
    <v-col v-if="item.product_id" cols="8">{{ item.product_id.code }}</v-col>

    <v-col v-if="item.product_id" cols="4">ຮູບພາບ</v-col>
    <v-col v-if="item.product_id" cols="8">
      <v-img :src="item.product_id.img" width="50">
        <template v-slot:placeholder>
          <v-row class="fill-height ma-0" align="center" justify="center">
            <v-progress-circular
              indeterminate
              color="primary"
            ></v-progress-circular>
          </v-row>
        </template>
      </v-img>
    </v-col>

    <v-col v-if="item.product_id" cols="4">ຊື່</v-col>
    <v-col v-if="item.product_id" cols="8">{{ item.product_id.name }}</v-col>
    <v-col v-if="item.product_id.category" cols="4">ປະເພດ</v-col>
    <v-col v-if="item.product_id.category" cols="8">{{
      item.product_id.category.name
    }}</v-col>
    <v-col v-if="item.product_id.category.size" cols="4">ຂະໜາດ</v-col>
    <v-col v-if="item.product_id.category.size" cols="8">{{
      item.product_id.size.name
    }}</v-col>
    <v-col cols="4">stock ກ່ອນຂາຍ</v-col>
    <v-col cols="8">{{ item.stock }}</v-col>
    <v-col cols="4">ຈຳນວນທີ່ໄດຂາຍ</v-col>
    <v-col cols="8">{{ item.qty }}</v-col>
    <v-col v-if="item.product_id.stock" cols="4">stock ຫຼັງຂາຍ</v-col>
    <v-col v-if="item.product_id.stock" cols="8">{{
      item.product_id.stock
    }}</v-col>

    <v-col cols="4">ຕົ້ນທືນ</v-col>
    <v-col cols="8">{{ moneyFormat(item.price_buy) }}</v-col>
    <v-col cols="4">ລາຄາຂາຍ</v-col>
    <v-col cols="8">{{ moneyFormat(item.price_sale) }}</v-col>
    <v-col cols="4">ຍອດກຳໄລ</v-col>
    <v-col cols="8"
      >{{ moneyFormat(item.price_sale - item.price_buy) }} / ອັນ
    </v-col>
    <v-col cols="4">ຍອດຫຼຸດ</v-col>
    <v-col cols="8">{{ moneyFormat(item.discount) }}</v-col>
    <v-col v-if="item.product_id.price_sale" cols="4">ຍອດຂາຍ</v-col>
    <v-col v-if="item.product_id.price_sale" cols="8">{{
      moneyFormat(item.product_id.price_sale * item.qty)
    }}</v-col>

    <v-col v-if="item.product_id.price_sale && item.discount" cols="4"
      >ຍອດເງີນທີ່ໄດ້ຮັບ</v-col
    >
    <v-col v-if="item.product_id.price_sale && item.discount" cols="8">{{
      moneyFormat(item.product_id.price_sale * item.qty - item.discount)
    }}</v-col>
    <v-col cols="4">ວັນທີ່ເພີ້ມ</v-col>
    <v-col cols="8">{{ item.created_at | moment }}</v-col>
  </v-row>
</template>

<script>
import { myMixin } from "@/mixin/main";

export default {
  props: {
    item: {
      type: Object,
    },
  },
  mixins: [myMixin],
};
</script>

<style></style>
