import Vue from "vue";
import axios from "axios";
import store from "@/stores";
import i18n from "@/plugins/i18n";

// get host url from window
const baseURL = process.env.VUE_APP_BASE_URL;

// create axios instance
const service = axios.create({
  baseURL: `${baseURL}/api/`,
  timeout: 5000,
  headers: {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
    "X-Requested-With": "XMLHttpRequest",
  },
});

// request interceptor
service.interceptors.request.use((config) => {
  const isLoggedIn = store.getters["auth/isLoggedIn"];
  // if token exists, add it to the header
  if (isLoggedIn) {
    const token = store.getters["auth/token"];
    config.headers["X-Token"] = token;
    config.headers["Authorization"] = `Bearer ${token}`;
  }
  // check token expiration
  return config;
});

// response interceptor
service.interceptors.response.use(
  (res) => {
    return res;
  },
  (error) => {
    const data = error.response.data;
    if (data.code === 401) {
      Vue.$toast.error(data.message);
      store.dispatch("auth/removeAuth");
      window.location.href = "/login";
    }
    // handle error
    return handleError(error);
  }
);

// handle error
const handleError = (error) => {
  // check if error has response
  if (error.response) {
    // check if error response has data
    if (error.response.data) {
      // check if error response data has message
      if (error.response.data.message) {
        // show error message
        const message = error.response.data.message;
        // check if error message is an object
        if (typeof message === "object") {
          // iterate over object and show each message
          Object.keys(message).forEach((key) => {
            // show toast message
            const msg = i18n.t(message[key][0]);
            // Vue.$toast.error(msg);
            errorAlert(msg);
          });
        } else {
          const msg = i18n.t(message);
          errorAlert(msg);
        }
        // return error message
        return Promise.reject(error.response.data);
      }
    }
  }
};

const AxiosPlugin = {
  install(Vue) {
    Vue.prototype.$http = service;
  },
};
// export axios instance
export default AxiosPlugin;

const errorAlert = async (message) => {
  // swal.fire
  const _title = i18n.t("failed");
  await Vue.swal.fire({
    icon: "error",
    title: _title,
    text: message,
    showConfirmButton: false,
  });
};
