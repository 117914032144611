<template>
  <v-container fluid fill-height class="bg-white">
    <v-row justify="center" align="center">
      <v-col
        v-if="$vuetify.breakpoint.mdAndUp"
        cols="4"
        class="side-color bg-primary"
      >
        <v-card max-width="550" outlined color="transparent" flat>
          <v-img src="/images/04.png" />
        </v-card>
      </v-col>
      <v-col xs="12" sm="12" md="8" lg="8" xl="8">
        <v-btn color="primary" to="/login" fab outlined icon small>
          <v-icon small>fal fa-chevron-left</v-icon>
        </v-btn>
        <v-row justify="center">
          <v-col xs="12" sm="12" md="8" lg="6" xl="6">
            <v-form @submit.prevent="submitForm(form)">
              <div
                :class="$vuetify.breakpoint.mdAndUp ? 'mb-8' : ''"
                class="text-center"
              >
                <v-avatar size="120">
                  <v-img src="/images/pos.png"></v-img>
                </v-avatar>
              </div>
              <v-card>
                <v-card-text>
                  <div class="text-center">
                    <h2 class="font-weight-black">ຍຶນຍັນອີເມວ</h2>
                    <p class="py-3">ປ້ອນຂໍ້ມູນຂອງທ່ານເພື່ອຮັບລະຫັດ OTP</p>
                  </div>
                  <TTextField
                    :errorMessages="emailError"
                    label="email"
                    v-model="form.email"
                    @input="$v.form.email.$touch()"
                    @blur="$v.form.email.$touch()"
                  />
                </v-card-text>
                <v-card-actions class="mx-2">
                  <v-btn
                    type="submit"
                    color="primary"
                    large
                    block
                    class="px-12"
                    >{{ $t("submit") }}</v-btn
                  >
                </v-card-actions>
              </v-card>
            </v-form>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <!-- AppLoading -->
    <app-loading :overlay="overlay" />
  </v-container>
</template>

<script>
import { toast } from "@/controllers/alert";
import TTextField from "@/components/global/TextField";

import { required, email } from "vuelidate/lib/validators";
export default {
  layout: "blank",
  components: { TTextField },
  data: () => ({
    overlay: false,
    form: {
      email: "",
    },
  }),
  validations: {
    form: {
      email: {
        email,
        required,
      },
    },
  },
  computed: {
    emailError() {
      const errors = [];
      if (!this.$v.form.email.$dirty) return errors;
      !this.$v.form.email.required && errors.push("ກະລຸນາປ້ອນອີເມວ!");
      !this.$v.form.email.email && errors.push("ພີມອີເມວຜິດຫຼັກການ");
      return errors;
    },
  },
  methods: {
    async submitForm(form) {
      try {
        this.$v.form.$touch();
        if (!this.$v.form.$invalid) {
          this.overlay = true;
          const { data } = await this.$http.post("verify", form);
          if (data.status) {
            await toast(this, data.message);
            this.overlay = false;
            this.$router.push({
              name: "VerifyNewUser",
              query: { email: form.email },
            });
          }
        }
      } catch (error) {
        this.overlay = false;
        console.log("submitForm ===> ", error);
      }
    },
  },
};
</script>

<style></style>
