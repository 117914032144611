<template>
  <v-card class="rounded-lg">
    <v-col cols="12">
      <v-row justify="space-between">
        <v-col cols="6">
          <v-card-title> {{ $t(title) }} </v-card-title></v-col
        >
        <div
          class="col col-xs-12 col-ms-12"
          :class="'col-md-' + cols + ' col-lg-' + cols + ' col-xl-' + cols"
        >
          <v-dialog
            ref="dialog"
            v-model="modal"
            :return-value.sync="date"
            persistent
            width="300"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="date"
                hide-details
                outlined
                dense
                color="primary"
                placeholder="ລາຍງານຕາມເດືອນ ແລະ ປີ"
                readonly
                v-bind="attrs"
                v-on="on"
              >
                <template v-slot:append>
                  <v-icon small> fal fa-calendar </v-icon>
                </template></v-text-field
              >
            </template>
            <v-date-picker v-model="date" type="month" scrollable>
              <v-spacer></v-spacer>
              <v-btn
                text
                outlined
                depressed
                color="primary"
                class="px-5"
                @click="modal = false"
              >
                {{ $t("cancel") }}
              </v-btn>
              <v-btn
                color="primary"
                dark
                depressed
                class="px-5"
                @click="submit(date)"
              >
                {{ $t("ok") }}
              </v-btn>
            </v-date-picker>
          </v-dialog>
        </div>
      </v-row>
    </v-col>
  </v-card>
</template>

<script>
export default {
  props: {
    cols: {
      default: 2,
    },

    title: {
      default: "summary",
    },
  },
  data: () => ({
    date: new Date().toISOString().substr(0, 7),
    modal: false,
  }),
  methods: {
    submit(val) {
      this.$emit("filter", val);
      this.modal = false;
    },
  },
};
</script>

<style></style>
