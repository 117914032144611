<template>
  <section>
    <data-table
      :headers="headers"
      :items="customers"
      commit="SET_CUSTOMER"
      :viewItem="viewItem"
      :editItem="editItem"
      :deleteItem="deleteItem"
    >
      <div slot="activator" class="row justify-content-between">
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-2">
          <v-card-title class="px-0 pt-0"> {{ $t("customer") }} </v-card-title>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-4">
          <TextSearch
            v-model="search"
            @search="fetchItem()"
            @onClear="
              search = '';
              fetchItem();
            "
          />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-2 text-end">
          <v-btn depressed color="primary" @click="newItem">
            <v-icon left>fal fa-plus-circle</v-icon>
            {{ $t("add_new_customer") }}
          </v-btn>
        </div>
      </div>
    </data-table>
    <app-custom-dialog
      v-model="dialog"
      :footer="false"
      title="description"
      @submit="submitForm"
    >
      <div slot="activator">
        <v-row>
          <v-col cols="4">{{ $t("id") }}</v-col>
          <v-col cols="8">{{ form.id }}</v-col>
          <v-col cols="4">{{ $t("first_name") }}</v-col>
          <v-col cols="8">{{ form.first_name }}</v-col>
          <v-col cols="4">{{ $t("last_name") }}</v-col>
          <v-col cols="8">{{ form.last_name }}</v-col>
          <v-col cols="4">{{ $t("phone_no") }}</v-col>
          <v-col cols="8">{{ form.phone_no }}</v-col>
          <v-col cols="4">{{ $t("point") }}</v-col>
          <v-col cols="8">{{ form.point | NumberFormat }}</v-col>
          <v-col cols="4">{{ $t("address") }}</v-col>
          <v-col cols="8">{{ form.address }}</v-col>
          <v-col cols="4">{{ $t("created_at") }}</v-col>
          <v-col cols="8">{{ form.created_at | moment }}</v-col>
        </v-row>
      </div>
    </app-custom-dialog>
    <app-custom-dialog
      v-model="dialogAdd"
      :footer="true"
      :title="titleStatus"
      @submit="submitForm"
    >
      <div slot="activator">
        <TTextField
          @input="$v.form.first_name.$touch()"
          @blur="$v.form.first_name.$touch()"
          :errorMessages="first_nameError"
          label="first_name"
          v-model="form.first_name"
        />
        <TTextField
          @input="$v.form.last_name.$touch()"
          @blur="$v.form.last_name.$touch()"
          :errorMessages="last_nameError"
          label="last_name"
          v-model="form.last_name"
        />
        <TTextField
          @input="$v.form.phone_no.$touch()"
          @blur="$v.form.phone_no.$touch()"
          :errorMessages="phone_numberError"
          label="phone_no"
          typeShow="number"
          v-model="form.phone_no"
        />
        <VuetifyMoney
          v-model="form.point"
          @input="$v.form.point.$touch()"
          @blur="$v.form.point.$touch()"
          :errorMessages="pointError"
          label="point"
        />
        <TTextField label="address" v-model="form.address" />
      </div>
    </app-custom-dialog>
    <!-- AppLoading -->
    <app-loading :overlay="overlay" />
  </section>
</template>

<script>
import DataTable from "@/components/DataTable";
import { mapGetters, mapActions } from "vuex";
import { customerHead } from "@/utils/head";
import TTextField from "@/components/global/TextField";
import { customerValidate } from "@/validations/shop";
import { success, confirm } from "@/controllers/alert";
import { myMixin } from "@/mixin/main";
import VuetifyMoney from "@/components/VuetifyMoney";

export default {
  mixins: [customerValidate, myMixin],

  data: () => ({
    overlay: false,
    search: "",
    update: false,
    dialogAdd: false,
    dialog: false,
    form: {},
  }),
  async created() {
    await this.fetchItem();
  },
  components: { DataTable, TTextField, VuetifyMoney },
  computed: {
    ...mapGetters({
      customers: "index/customers",
    }),

    titleStatus() {
      return this.update ? "update" : "new_data";
    },
    headers() {
      return customerHead(this);
    },
  },

  methods: {
    ...mapActions({
      fetch: "index/fetch",
    }),
    async fetchItem() {
      const body = {
        that: this,
        url: `customers_paginate?q=${this.search}`,
        commit: "SET_CUSTOMER",
      };
      await this.fetch(body);
    },

    async newItem() {
      await Help.clearForm(this.form, this);
      this.update = false;
      this.dialogAdd = !this.dialogAdd;
    },
    editItem(item) {
      this.update = true;
      this.form = item;
      this.dialogAdd = !this.dialogAdd;
    },
    async deleteItem({ id }) {
      const { isConfirmed } = await confirm({
        props: this,
        text: "want_to_delete",
      });
      if (isConfirmed) {
        const { data } = await this.$http.delete(`customers_delete/${id}`);
        if (data.status) {
          await Help.clearForm(this.form, this);
          // find id in array.data amd remove it
          this.customers.data.splice(
            this.customers.data.findIndex((item) => item.id === id),
            1
          );
          await success(this, data.message);
        }
      }
    },
    viewItem(item) {
      this.form = item;
      this.dialog = !this.dialog;
    },
    async submitForm() {
      try {
        this.$v.form.$touch();
        if (!this.$v.form.$invalid) {
          if (this.update) {
            const { data } = await this.$http.post(
              `customers_update/${this.form.id}`,
              this.form
            );
            if (data.status) {
              this.dialogAdd = !this.dialogAdd;
              await Help.clearForm(this.form, this);
              //    fidn the id and update it
              await this.fetchItem();
              await success(this, data.message);
            }
          } else {
            const { data } = await this.$http.post("customers_add", this.form);
            if (data.status) {
              this.dialogAdd = !this.dialogAdd;
              await Help.clearForm(this.form, this);
              // push array.data
              this.customers.data.push(data.data);
              await success(this, data.message);
            }
          }
        }
      } catch (error) {
        console.log("error", error);
      }
    },
  },
};
</script>

<style></style>
